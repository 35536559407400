import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { listWishes } from '../actions/wishActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import currencyFormat from '../utils/currency';
import { dateFormat } from '../utils/date';

export default function WishesListScreen(props) {
    const wishList = useSelector(state => state.wishList);
    const { loading, error, wishes } = wishList;
    const dispatch = useDispatch();
    useEffect(()=> {
        dispatch(listWishes());
    }, [dispatch]);
    return (
        <div className="px-5p space-y-2">
            <h1 className="text-3xl font-extrabold text-gray-900 tracking-tight py-2">Wunschlisten</h1>
            {loading? <LoadingBox></LoadingBox>:
            error? <MessageBox variant="danger">{error}</MessageBox>:
            (
            <div className="-my-2 overflow-x-auto md:-mx-6">
                <div className="py-2 align-middle inline-block min-w-full md:px-6">
                    <div className="shadow overflow-hidden border-b border-gray-200 rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                                    <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Datum</th>
                                    <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User</th>
                                    <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ArtikelNr</th>
                                    <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Artikel</th>
                                    <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Preis</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {wishes.map((wish) => (
                                    <tr key={wish._id}>
                                        <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-900">{wish._id}</td>
                                        <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-500">{dateFormat(wish.createdAt)}</td>
                                        <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-500">{wish.user.firstName ? wish.user.firstName : ""} {wish.user.lastName ? wish.user.lastName : ""} ({wish.user.email})</td>
                                        <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-900">{wish.product.item_number}</td>
                                        <td className="px-2 py-1 md:py-4 whitespace-nowrap block md:table-cell">
                                            <div className="flex items-center">
                                                                <div className="flex-shrink-0 h-10 w-10">
                                                                 
                                                                    <Link to={`/product/${wish.product._id}`}><img className="h-10 w-10 rounded-full" src={wish.product.image[0]} alt="bild" /></Link>
                                                                </div>
                                                                <div className="ml-4">
                                                                    <Link to={`/product/${wish.product._id}`}>
                                                                        <div className="text-sm font-medium text-gray-900">
                                                                            {wish.product.brand}
                                                                        </div>
                                                                        <div className="text-sm text-gray-500">
                                                                            {wish.product.name.substring(0, 50)}{wish.product.name.length>50 && "..."}
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </td> 
                                        <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-500">{currencyFormat(wish.product.price)}</td>
                                        <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-500 text-right"><button type="button" className="px-2 border border-transparent rounded-md bg-yellow-100 hover:bg-yellow-200 focus:outline-none" onClick={() => console.log('test')}>Benachrichtigen</button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            )}
        </div>
    )
}
