import React from 'react'
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../BreadCrumbs';

export default function AuslandBanknotenSubcategoryGrid() {
    const { t } = useTranslation(["navbar"]);
    return (
        <div>
            <BreadCrumbs steps={[{title:t("banknotes", "Banknoten"), link:"/category/banknoten"}, {title:t("foreign_banknoten", "Ausländische Banknoten"), link:"/category/auslaendische_banknoten"}]}/>
        </div>
    )
}

