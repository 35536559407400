import React from 'react'
import { Link } from 'react-router-dom'
import EmailAddress from '../components/EmailAddress'
import PhoneNumber from '../components/PhoneNumber'
import ScrollToTopMount from '../components/ScrollToTopMount'
import currencyFormat from '../utils/currency'

export default function AGBEngScreen() {

    return (
        <div className="px-5p ">
            <ScrollToTopMount />
            <Link to="/agb"><span className="text-white p-2 rounded float-right bg-blue-500">Deutsche Version</span></Link>
            <h1 className="text-3xl font-extrabold text-gray-900 tracking-tight py-2">General Terms and Conditions</h1>
            <div className="space-y-4 divide-y">
                <div className="space-y-2">
                    <b>§1 Scope and Definition of Terms</b>
                    <div className="text-sm space-y-2">
                        <p>(1) Our below General Terms and Conditions apply to all legal transactions between  Münzgalerie München MGM Handelsgmbh &amp; Co. Joker KG and the buyer. These Terms and Conditions are applicable for consumers and entrepreneurs. </p>
                        <p>(2) Consumer means any natural person who enters into a transaction for a purpose that is neither commercial nor their independent vocational activity may be attributed. An entrepreneur is any natural person or legal entity or company with legal personality, which carries out commercial or independent professional activity on entering into a legal transaction.</p>
                        <p>(3) For entrepreneurs and legal entities our General Terms and Conditions apply also for all future business relations.</p>
                        <p>(4) We expressly do not recognize any purchasing conditions that deviate from our General Terms and Conditions; this non-recognition also applies to cases where the buyer has stipulated a particular formality for non-recognition. If non-recognition is excluded, legal regulations shall take the place of deviating conditions. Deviating purchasing conditions shall only be recognized if we confirm in writing that they are to be applied.</p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§2 Contractual partner, formation of contract, correction facilities and storing the contract text</b>
                    <div className="space-y-2 text-sm">
                        <p>(1) The General Terms and Conditions are applied to orders on our webshop <Link className="underline" to="/">shop.muenzgalerie.de</Link>.</p>
                        <p>(2) The contract is concluded with:</p>
                        <p className="pl-10">Münzgalerie München MGM</p>
                        <p className="pl-10">Handelsgesellschaft mbH &amp; Co. Joker KG</p>
                        <p className="pl-10">Stiglmaierplatz 2</p>
                        <p className="pl-10">80333 München</p>
                        <p className="pl-10">Germany</p>
                        <br />
                        <p className="pl-10">The place of jurisdiction is Munich, Germany.</p>
                        <p className="pl-10">Company Register Number: 59787</p>
                        <br />
                        <p>(3) Your order constitutes a legally-binding offer to us to conclude a purchasing contract. A legally-valid purchasing contract only takes effect if we have accepted your offer within 14 days. This shall proceed via a separate written statement of acceptance or if we send the merchandise to you within 14 days. We will immediately send an e-mail to the e-mail address you have specified confirming that we have received your order placed via the Internet. This confirmation of receipt still does not constitute a binding acceptance of the order; the notice of order acceptance, however, may indeed be sent along with the confirmation of order receipt. We cannot accept any responsibility for the correctness, completeness, or topicality of written and visual depictions in the Internet and the stock lists or catalogues. Errors and misprints may occur. Any verbal information provided outside a written contract is nonbinding and must be confirmed in writing in order to become effective. By submitting an order, you agree to accept our General Terms and Conditions.</p>
                        <p>(4) The customer may correct his/her personal data before submitting the request by clicking on the “back” button of the internet browser or cancel the order by closing the internet browser. </p>
                        <p>(5) Münzgalerie München MGM Handelsgmbh &amp; Co. Joker KG is saving the contract text and sending your order details and our Terms and Conditions via Email. You can also access our Terms and Conditions on our website on: <Link className="underline" to="/terms">shop.muenzgalerie.de/terms</Link> or in your personal online account (Your Name -&gt; My orders).</p>

                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§3 Contract language</b>
                    <div className="space-y-2 text-sm">
                        <p>The languages available for concluding a contract with Münzgalerie München MGM Handelsgmbh &amp; Co. Joker KG are German and English</p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§4 Delivery Conditions</b>
                    <div className="space-y-2 text-sm">
                        <p> All our prices are including any applicable taxes. Delivery costs may be added to the product prices displayed. Further information on delivery costs, if applicable, are explained within individual product offers. A part of the costs for shipping involves carefully packaging and reliably dispatching the merchandise. Pick up by the customer in our shop is possible. In this case no delivery costs are charged. For shipping and packaging the merchandise we charge the following costs: </p>
                        <div className="pl-10 space-y-2 text-sm">
                            <p>- Shipping within Germany: {currencyFormat(5)} per parcel, free shipping for order value above {currencyFormat(300)}</p>
                            <p>- Shipping within the EU (excl. Switzerland and Norway): {currencyFormat(9.50)}, free shipping for order value above {currencyFormat(1000)}.</p>
                            <p>- Shipping to the rest of the world (incl. Switzerland and Norway) {currencyFormat(15)}.</p>
                        </div>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§5 Terms of Delivery and Delivery Period</b>
                    <div className="space-y-2 text-sm">
                        <p>(1) Unless not explicitly mentioned all of our goods are on stock and can be sent out immediately. </p>
                        <p>(2) In case the customer chose to pay in advance we will not send out the merchandise before we received the payment in full on our bank account,</p>
                        <p>(3) Unless otherwise agreed, delivery terms are cash in advance, in particular for first-time buyers. As regards cash in advance, upon acceptance of your offer we will send you an invoice to be paid within 14 days. Upon receipt of payment, we will ship the merchandise to you within 14 days.</p>
                        <p>(4) Should a particular item not be available, we will send you an item that is equivalent in quality and price (substitute item), to the extent that you have specified this when ordering. Exception: We are entitled to withdraw the order in case our supplier fails to deliver the merchandise ordered despite a contractual obligation. In this case, the buyer shall be informed immediately that the product ordered is not available. Any purchase price already paid shall be reimbursed without delay. If we are temporarily unable to deliver the purchased item within the agreed delivery period, or within 14 days, or to duly fulfil the contract due to shutdowns, strikes, lockouts, regulatory actions or other cases of force majeure, the delivery period shall be extended by the duration of the hindrance. In this case, we shall immediately inform the customer of the hindrance and its anticipated duration. Should the hindrance persist beyond four weeks, both parties shall thus have the right to rescind the contract.</p>
                        <p>(5) If the customer is a business entity, the risk of incidental demise as well as incidental deterioration of the merchandise is transferred at the time the merchandise is handed over, in the case of shipped items, when the merchandise is handed over to the shipping agent, carrier or person or institution charged with expediting the shipment.
                        If the buyer is a consumer, for a mail-order purchase the risk of incidental demise or deterioration of the purchased item is only transferred when the merchandise is handed over to the buyer. The merchandise shall be considered handed over if the consumer is in default of accepting the purchased merchandise.</p>
                        <p>(6) The transport risk assumed by us is covered by sufficient insurance policies.</p>
                        <p>(7) In case of any transportation damages please claim these defects immediately to the shipping company and to us. Failure to raise objections or to get in contact does not affect your statutory claims and in particular warranty rights. However, you help us claim the damage from the shipping company or the shipping insurance.</p>
                        <p>(8) A delivery in Non EU countries might be subject to additional costs for customs clearance, which will be charged to the customer. Münzgalerie München Handelsgmbh &amp; Co. Joker KG has no influence on these additional costs and can not provide an estimation of these costs.</p>
                        <p>(9) Cross-border parcels might be opened for customs clearance by customs-authorities.</p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§6 Payment</b>
                    <div className="space-y-2 text-sm">
                        <p>(1) All prices are in Euros, including any value-added tax applicable at the time of the order.</p>
                        <p>(2) Any other taxes, along with import and export taxes, are to be paid by the customer. Wherever applicable, the customer is to inquire about this beforehand or contact us.</p>
                        <p>(3) The following payment methods are basically available in our online shop:</p>
                        <div className="pl-10 space-y-2 text-sm">
                            <p>(a) Advance payment: If you select advance payment we provide you with our bank details in a separate e-mail and deliver the goods on receipt of funds.</p>
                            <p>(b) Paypal: If you have selected the PayPal payment option, in order to pay the invoice amount, you must be registered there or register first and identify yourself with your access data. The payment transaction will be carried out automatically by PayPal directly after confirmation of the payment instructions. You will receive additional information during the ordering process.</p>
                            <p>(c) Credit Card: If you have selected the credit card payment option, in order to pay the invoice amount, you just need a valid credit card. The payment transaction will be carried out by your credit card company upon request by our payment provider and debited to your card, directly after confirmation of the payment instructions and after your identification as the legal cardholder. You will receive additional information during the ordering process.</p>
                            <p>(d) Cash: You may pay for your purchase using cash while picking up your merchandise at our shop.</p>
                        </div>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§7 Right of Withdrawal</b>
                    <div className="space-y-2 text-sm">
                        <p><b>Right of Withdrawal Disclosure</b></p>
                        <p>Consumers (any natural person who conducts a legal transaction which may not be attributed to any of his or her commercial or independent professional activities) are entitled to a statutory right of withdrawal.</p>
                        <p>Consumers have the right to withdraw from their contract declaration within 14 days for any reason by giving written notice (e.g. letter, fax, e-mail) within two weeks or – if you have already received the item prior to expiry of this period -- by sending back the item. The withdrawal period shall commence upon receipt of this written notification, however not prior to the buyer's receiving merchandise and not prior to our fulfilling our duty to inform pursuant to §§ 312c Section 2 of the German Civil Code (BGB) in conjunction with § 1 Section 1.2 and 4 German Civil Code Disclosure Requirements (BGB-InfoV), along with our obligations pursuant to § 312e Section 1 Subsection 1 of the German Civil Code (BGB) in conjunction with § 3 of German Civil Code Disclosure Requirements (BGB-InfoV). In order to uphold the withdrawal period, it is sufficient to timely dispatch the withdrawal notice or the merchandise. The withdrawal is to be addressed to:</p>
                        <p></p>
                        <br />
                        <div className="pl-10 space-y-2">
                            <p>Münzgalerie München MGM</p>
                            <p>Handelsgesellschaft mbH &amp; Co. Joker KG</p>
                            <p>Stiglmaierplatz 2</p>
                            <p>D-80333 München</p>
                            <p>Telefon:<PhoneNumber number={"+49895233660"} text={"+49 (89) 5233660"}/></p>
                            <br />
                            <p>Telefax: <PhoneNumber number="+498932795990" text="089 32 79 59 90"/></p>
                            <p>E-Mail: <EmailAddress email="mgm@muenzgalerie.de"/></p>
                        </div>
                        <br />
                        <Link className="underline" to="/documents/Withdrawal_Form_MGM.pdf">Model withdrawal form</Link>
                        <br />
                        <p><b>Effects of Withdrawal</b></p>
                        <p>In the event of a valid withdrawal, each party shall return to the other party any benefits received (e.g. interest). In case you are unable to return the goods or services rendered by us in whole or part, or only return them in a deteriorated condition, you must provide compensation for their value. This does not apply to the provision of goods if the deterioration of the goods results exclusively from inspecting them – as would have been possible, for example, in a shop. Otherwise you can avoid the obligation to provide compensation for the value of goods which have deteriorated through their proper use by not taking the goods into use as if they were your property and by refraining from any action that would diminish their value. Goods that are suitable for dispatch by parcel are to be sent back at our risk. You are to bear the cost of return shipping if the goods were delivered as ordered and the price of the goods being returned does not exceed 40 Euros or, in the event of a higher value, if at the time of revocation you have not yet paid in full or contractually arranged to make partial payment. Otherwise return shipping shall be free of charge to you. Any goods that are unsuitable for dispatch will be collected at your address. Obligations to reimburse payments must be fulfilled within 30 days. For you this term begins when you dispatch either your declaration of revocation or the goods; for us it begins upon their receipt.</p>
                        <p><b>Exclusion of Withdrawal</b></p>
                        <p>(1) The right of withdrawal may not apply to contracts for supply of goods to be manufactured according to customer specifications or clearly tailored to personal needs according to § 312g 2 Nr. 1 BGB.</p>
                        <p>(2) We wish to point out that there is no right of withdrawal for the delivery of goods or the rendering of financial services, the price of which is subject to financial market fluctuations on which the company has no influence and which may occur within the withdrawal period according to § 312 d IV Nr. 6 BGB. In particular, this applies to services in connection with stocks, share certificates issued by an investment company or a foreign investment firm and other tradable securities, foreign currencies, derivatives or money market instruments. We further wish to point out that some coins are traded at "spot prices" and therefore losses may occur during the withdrawal period due to market fluctuation.</p>
                        <p><b>End of Right of Withdrawal Disclosure</b></p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§8 Reservation of Title</b>
                    <div className="space-y-2 text-sm">
                        <p>In contracts with consumers, we retain the title to goods until full payment of the purchase price has been made. In contracts with companies, we retain the title to goods until all claims from an ongoing business relationship have been satisfied.</p>
                        <p>(1) The customer may pay by using cash in advance, Paypal, cash, or by credit card. Payment upon invoicing is only possible upon special request.</p>
                        <p>(2) Once the customer chose “payment in advance” as payment option he/she is obliged to pay the purchase price in full immediately after receiving the confirmation. In case the merchandise was sent out upon invoicing the customer is obliged to pay the outstanding amount in full immediately after receiving the invoice, if not stated differently. </p>
                        <p>(3) As long as the purchase price has not been paid in full, the customer is obliged,</p>
                        <div className="pl-10 space-y-2 text-sm">
                            <p>a) to handle the merchandise with care, in particular to not clean coins or jewellery or treat them with chemicals or other objects, and</p>
                            <p>b) to notify us immediately if a third party has placed a claim, such as a lien, on the goods, or if the goods have suffered damage or demise and/or</p>
                            <p>c) change of ownership of the goods has occurred, as well as a change in the buyer's own domicile.</p>
                        </div>
                        <p>(4) An entrepreneur has the right to sell the goods to a third party in the course of normal business. In doing so, he already assigns to us all receivables vis-à-vis third parties that accrue to him by reselling the goods in the amount of the invoiced value. We already accept this assignment. Subsequent to assignment, the company is entitled to collect the receivables. We do, however, reserve the right to collect the receivables ourselves, if the company does not duly fulfil its payment obligations and falls into default.</p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§ 9 Liability for Defects</b>
                    <div className="space-y-2 text-sm">
                        <p>(1) All our items, if not explicitly mentioned, are unique, historical objects and so subject to signs of usage. We do our best effort to correctly describe and photograph the objects to our knowledge and belief. Should the items delivered prove to have obvious defects, which were not mentioned in the description of the goods or were not obviously recognisable on the photography of the merchandise, whereby this also includes shipping damage, you are obliged to file a claim for such defects without delay. If you are a consumer, the failure to give notice of defect, however, shall not have any consequences on your legal rights.</p>

                        <p>(2) Any warranty claims of the customer shall be in accordance with the statutory provisions. </p>

                        <p>(3) In some cases there may exist multiple copies of individual pieces. Not all pieces can be illustrated or listed in a catalogue or on the website. The delivery of a piece not illustrated in the catalogue does not constitute a defect, unless you have expressly stated in your order that you wish to receive exactly the piece illustrated and described.</p>

                        <p>(4) The authenticity of all coins, medals and pieces of jewellery offered is guaranteed. The buyer is entitled to all the warranties required by law.
                        You can reach our Customer Service Department by mail, telephone, and e-mail during our normal business hours.
</p>
                        <p>(5) As regards warranty, the consumer has the choice of exercising his right of removal of defects or delivery of merchandise free of defects (supplementary performance). To the extent that the method of supplementary performance is considered to entail disproportionate costs, the entitlement shall be limited to the remaining options for supplementary performance. Additional rights, in particular the rescission of the purchase contract, can only be exercised after an adequate period of time has passed, allowing for supplementary performance or two unsuccessful attempts of supplementary performance or if we have refused supplementary performance.</p>
                        <p>(6) If the customer is an entrepreneur, we are entitled to choose to either remove defects or deliver merchandise free of defects within one year after date of delivery pursuant to § 439 of the German Civil Code (BGB). After a year has passed after the date of delivery, the warranty claims of a business entity are limited to the removal of defects or the provision of a credit of the current cash value, whichever we deem appropriate.</p>
                        <p>(7) Is the customer a consumer warranty claims for “used” goods are limited to one year.</p>
                        <p>(8) We do not accept any liability arising from a different grading of the state of preservation, when performed by a grading company or any third party. Any complaint resulting from a different third party grading do not entitle for the unravelling of the contract.</p>
                        <p>(9) No liability will be accepted for so called “slabbed” coins or medals given out by Grading companies. This applies in particular to rim edges or traces of mounting, which are not obvious within the folder. </p>
                        <p>(10) The place of recession is Munich</p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§10 Data Protection</b>
                    <div className="space-y-2 text-sm">
                        <p>All personal data will be kept confidential. For more information please visit our <Link className="underline" to="/privacy">data and privacy regulations</Link>.</p>

                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§11 Liability Disclaimer</b>
                    <div className="space-y-2 text-sm">
                        <p>In all cases where we are obliged on the basis of contractual or legal grounds to pay for damages or reimbursements, we are only liable to the extent that we have been proven to have acted wilfully and with gross negligence or to have caused injury to life, limb, or health. This shall not affect liability regardless of negligence or fault according to the Product Liability Act. Nor shall it affect liability for gross breach of contract; to this degree, liability is limited to damages that are foreseeable and typical for this type of contract, except for the cases referred to in the first two sentences. The aforementioned provisions are not coupled with a change in the burden of proof to the disadvantage of the customer.</p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§12 Customer Account</b>
                    <div className="space-y-2 text-sm">
                        <p>(1) Please notice that your Login Data for your customer account has to be kept confidential. The customer has to keep his password secret. In case a third party gains knowledge of the password or there are grounds for supposing that this is the case he is obliged to change his password to a new one. In case a third person might have the possibility to place an unwanted order at the shop of Münzgalerie München the customer is obliged to immediately inform us. </p>
                        <p>(2) Münzgalerie München reserves the right to block, disable or even remove the customer account without previous warning in case of violation of the terms and conditions.   </p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§13 Modification of the Terms and Conditions</b>
                    <div className="space-y-2 text-sm">
                        <p>Münzgalerie München reserves the right to change its web-display and Terms and Conditions at any time. </p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§14 Minors</b>
                    <div className="space-y-2 text-sm">
                        <p>Any person under the age of 18 years or persons without the capacity to contract need the permission of their legal representative in order to enter a contract with us. </p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§15 Applicable Law, Venue</b>
                    <div className="space-y-2 text-sm">
                        <p>The place of fulfilment is Munich. German law shall apply at the exclusion of the United Nations Convention on Contracts for the International Sale of Goods. <br />

If the customer is an entrepreneur, a legal entity under public law or a special fund under public law, the exclusive venue for all disputes arising from this contract shall be the place of business Münzgalerie München MGM Handelsgmbh &amp; Co. Joker KG. The same applies if the customer has no general legal venue in the Federal Republic of Germany or his domicile or normal place of abode is not known at the time a lawsuit is filed.
<br />
Should any of the provisions of this contract with the customer, including these General Terms and Conditions, be determined unlawful in part or whole, this shall not invalidate the remaining provisions. The provision found to be unlawful in part or whole shall be replaced with a provision that most closely achieves the economic purpose of the unlawful provision.
<br />
In case there is an unforeseen eventuality, the parties shall agree upon provisions that, on the basis of the entire body of the contract, shall help execute the contract according to the apparent will of both parties. .
</p>
                    </div>
                </div>

                <div className="py-2 space-y-2">
                    <b>§16 Trademarks and Photos </b>
                    <div className="space-y-2 text-sm">
                        <p>The trademarks, logos, marks and photos displayed on or in connection with the Digital Platforms (or on content available on the Digital Platforms) and all rights therein belong to Münzgalerie München MGM Handelsgmbh &amp; Co. Joker KG and may not be used in any manner unless expressly authorized in writing by the trademark or rights owner. </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
