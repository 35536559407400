import React from 'react'
import { Link } from 'react-router-dom'

export default function SubcategoryTile(props) {
    return (
        <Link className="focus:outline-none" to={props.link}>
            <div className="transition duration-500 ease-in-out  transform hover:-translate-y-1 mr-2 my-1 ">
                <div className="border text-gray-700 px-2 uppercase text-xs tracking-widest bg-white rounded p-2 bg-gray-100">
                    {props.name}
                </div>
            </div>
        </Link>
    )
}

