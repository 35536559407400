import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { forgotPassword } from '../actions/userActions';
import { USER_PASSWORD_RESET_HASH_RESET } from '../constants/userConstants';

export default function ForgotPasswordScreen(props) {
    const { t } = useTranslation(["signin"]);

    const [email, setEmail] = useState('');

    const userForgotPass = useSelector(state => state.userForgotPass);
    const { success, loading, error} = userForgotPass;

    const dispatch = useDispatch();
    const submitHandler = (e) => {
        e.preventDefault();
        const lng = localStorage.getItem("lngCode") ? localStorage.getItem("lngCode") : "de";
        dispatch(forgotPassword(email, lng));
    };

    useEffect(() => {
        dispatch({type: USER_PASSWORD_RESET_HASH_RESET})
    }, [dispatch]);

    return (
        
        <div className="flex flex-col px-5p">
            {success && <MessageBox variant="success">{t("success_reset","Eine Anleitung zum Zurücksetzen des Passworts wurde an Ihre E-Mail-Adresse gesendet. Wenden Sie sich an den Kundendienst, wenn Sie weitere Hilfe benötigen.")}</MessageBox>}
            {loading && <LoadingBox></LoadingBox>}
            {error && <MessageBox variant="danger">{error}</MessageBox>}

            <div className="min-h-screen flex flex-row items-start justify-center py-12 px-4 px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8">
                    <div>
                        <img className="mx-auto h-12 w-auto" src="/images/favicon.png" alt="Workflow" />
                        <h2 className="mt-2 text-center text-3xl font-extrabold text-gray-900">
                            {t("forgot_password", "Passwort vergessen?")}
                    </h2>
                        <p className="mt-2 text-center text-sm text-gray-600">
                            {t("reset_password_msg", "Bitte geben Sie die E-Mail-Adresse des Kontos ein. Eine Anleitung zum Zurücksetzen des Passworts wird an diese E-Mail-Adresse gesendet.")}
                        </p>
                    </div>
                    <form className="mt-8 space-y-6" onSubmit={submitHandler}>
                        <input type="hidden" name="remember" value="true" />
                        <div className="rounded-md shadow-sm -space-y-px">
                            <div>
                                <label htmlFor="email-address" className="sr-only">{t("email", "E-Mail Adresse")}</label>
                                <input id="email-address" name="email-address" type="email" autoComplete="email-address" onChange={(e) => setEmail(e.target.value)} required className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" placeholder={t("email", "E-Mail Adresse")} />
                            </div>
               
                        </div>

  

                        <div>
                            <button type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                    <FontAwesomeIcon size="lg" icon="lock"/>
                                </span>
                            {t("reset", "Passwort zurücksetzen")}

                        </button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    )
}
