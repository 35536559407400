import React from 'react'
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../BreadCrumbs';
import SubcategoryTile from './SubcategoryTile';

export default function MedalSubcategoryGrid() {
    const { t } = useTranslation(["navbar"]);
    return (
        <div>
            <BreadCrumbs steps={[{ title: t("medals", "Medaillen") }]} />
            <div className="flex flex-wrap md:justify-start justify-center pt-2">
                <SubcategoryTile name={t("foreign_medals", "Ausländische Medaillen")} link={"/category/auslaendische_medaillen"} image={"/images/auslandmedcat.jpg"} />
                <SubcategoryTile name={t("german_medals", "Deutsche Medaillen")} link={"/category/deutsche_medaillen"} image={"/images/deutschmedcat.jpg"} />
                <SubcategoryTile name={t("karl_goetz_medals", "Medaillen von Karl Goetz")} link={"/category/karl_goetz_medaillen"} image={"/images/karlgoetzcat.jpg"} />
                <SubcategoryTile name={t("themed_medals", "Thematische Medaillen")} link={"/category/thematische_medaillen"} image={"/images/themmedcat.jpg"} />
            </div>
        </div>
    )
}

