import React from 'react'
import { Link } from 'react-router-dom'
import EmailAddress from '../components/EmailAddress'
import ScrollToTopMount from '../components/ScrollToTopMount'

export default function DatenschutzEngScreen() {
    return (
        <div className="px-5p ">
            <ScrollToTopMount />
            <Link to="/datenschutz"><span className="text-white p-2 rounded float-right bg-blue-500">Deutsche Version</span></Link>
            <h1 className="text-3xl font-extrabold text-gray-900 tracking-tight py-2">Data privacy statement</h1>
            <div className="space-y-4 divide-y">
                <div className="space-y-2">
                    <b>§1 NAME AND CONTACT DETAILS OF CONTROLLER AND DATA PROTECTION OFFICER</b>
                    <div className="text-sm space-y-2">
                        <p>This privacy policy applies to data processing carried out by:</p>
                        <p>Controller: Münzgalerie München MGM Handelsgmbh &amp; Co. Joker KG, Stiglmaierplatz 2, 80333 Munich, Germany, represented by its managing directors Susanne Benz and Carl Benz. Munich Local Court (Amtsgericht), company number HRA 59787 / VAT ID: DE130006976. Hereinafter referred to as Münzgalerie München</p>
                        <p>You can Our Data protection officer by E-mail to <b><EmailAddress email="datenschutz@muenzgalerie.de"/></b> or by postal mail to our address adding “Data Protection Officer” or “Der Datenschutzbeauftragte”</p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§2 COLLECTION AND STORAGE OF PERSONAL DATA, AS WELL AS KIND AND PURPOSE OF THEIR USE</b>
                    <div className="space-y-2 text-sm">
                        <p><b>a) When visiting the website</b></p>
                        <p>When you visit our website <Link className="underline" to="/">shop.muenzgalerie.de</Link>,  the browser on your device automatically sends information to the server of our website. These data are temporarily stored in a so-called log file. The following data are recorded without your intervention and stored until they are automatically deleted:</p>
                        <div className="space-y-2 pl-10">
                            <p>– IP address of the used computer</p>
                            <p>– date and time of the access</p>
                            <p>– name and URL of the retrieved file</p>
                            <p>– website from which our website was accessed (referrer URL)</p>
                            <p>– used browser and operating system of your computer, if applicable, as well as name of the access provider</p>
                        </div>
                        <p>We process these data for the following purposes:</p>
                        <div className="pl-10 space-y-2">
                            <p>- ensuring a smooth connection to the website</p>
                            <p>- ensuring a convenient use of our website</p>
                            <p>- evaluating system security and stability, as well as</p>
                            <p>- for other administrative purposes</p>

                        </div>
                        <p>The legal basis for the processing of these data is point f) of sentence 1 of Art. 6(1) GDPR. Our legitimate interest results from the above-mentioned purposes of data collection. Under no circumstances do we use the data collected for the purpose of drawing conclusions about you as a person.
                            <br />
                            When you visit our website we also use cookies and analytics services. Please see no. 4 and 5 hereof for detailed explanations.
                        </p>
                        <p><b>(b) When subscribing our newsletter</b></p>
                        <p>If you have expressly consented to in accordance with point a) of sentence 1 of Art. 6(1) GDPR, we use your e-mail address to send you our newsletter on a regular basis. In order to receive the newsletter, you only have to provide an e-mail address.


                        You can unsubscribe from the newsletter at any time, for example, by clicking the link at the end of each newsletter. You can alternatively send your unsubscribe request by e-mail to <EmailAddress email="mgm@muenzgalerie.de"/> at any time.
                        
                        We use MailChimp as a provider for automated mailing. By signing up for the newsletter you accept that your data will be progressed to MailChimp in order with their data processing guidelines. Further Information can be derived from:
 <Link className="underline" to="//mailchimp.com/legal/data-processing-addendum/">https://mailchimp.com/legal/data-processing-addendum/ </Link> and <Link className="underline" to="//mailchimp.com/legal/privacy/">https://mailchimp.com/legal/privacy/ </Link>.</p>
                        <p><b>c) When using our contact form</b></p>
                        <p>If you have questions of any kind, you can contact us by using the form on our website. In order for us to know who sent the request and to be able to answer it, you have to provide a valid e mail address. Further information can be provided voluntarily.

Data processing for the purpose of contacting us is carried out in accordance with point a) of sentence 1 of Art. 6(1) GDPR on the basis of your voluntary consent.

The personal data collected by us for the use of the contact form will be automatically deleted after the completion of your request.
</p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§3 TRANSMISSION OF DATA</b>
                    <div className="space-y-2 text-sm">
                        <p>Your personal data will not be transmitted to third parties for other purposes than those listed below. We only transmit your personal data to third parties if:
</p>
                        <div className="pl-10 space-y-2">
                            <p>- you have expressly given your consent in accordance with point a) of sentence 1 of Art. 6(1) GDPR;</p>
                            <p>- transmission in accordance with point f) of sentence 1 of Art. 6(1) GDPR is necessary to establish, exercise or defend legal claims and if there is no reason to believe that you have a predominant interest worthy of protection in the non-transmission of your data;</p>
                            <p>- there is a legal obligation for transmission in accordance with point c) of sentence 1 of Art. 6(1) GDPR; as well as</p>
                            <p>- it is legally permissible and necessary pursuant to point b) of sentence 1 of Art. (6)(1) GDPR to perform a contract entered into with you.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§4 COOKIES</b>
                    <div className="space-y-2 text-sm">
                        <p>We use cookies on our website. Cookies are smile files that are automatically created by your browser and stored on your terminal device (laptop, tablet, smartphone, or the like) when you visit our website. Cookies do not cause any damage to your device, do not contain viruses, Trojans or other malware.
A cookie stores information, which is linked to the respective device used. This does not mean, however, that we will gain immediate knowledge of your identity.
On the one hand, cookies help to make the use of our offer more pleasant for you. For example, we use so-called session cookies to recognise that you have already visited individual pages of our website. They will be deleted automatically after leaving our site.
In addition, we also use temporary cookies to optimise user-friendliness. These cookies are stored on your device for a specified period of time. If you visit our website again to use our services, it is automatically recognised that you have already visited the website and what inputs and settings you have made so that you do not have to enter them again.
On the other hand, we use cookies to record the use of our website statistically and to evaluate it for the purpose of optimising our offer for you (see no. 5). These cookies enable us to automatically recognise that you have already visited our website when you visit it again. They are automatically deleted after a defined period of time.
The data processed by cookies are necessary for the mentioned purposes to safeguard our legitimate interests and those of third parties pursuant to point f) of sentence 1 of Art. 6(1) GDPR.
Most browsers accept cookies automatically. You can, however, configure your browser so that no cookies are stored on your computer or that a message always appears before a new cookie is created. The complete deactivation of cookies can, however, prevent you from using all functions of our website.
</p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§5 ANALYTICAL TOOLS</b>
                    <div className="space-y-2 text-sm">
                        <p><b>a) Tracking-Tools</b></p>

                        <p>The tracking measures listed below and taken by us are implemented on the basis of point f) of sentence 1 of Art. 6(1) GDPR. With the implemented tracking measures, we want to ensure that our website is designed to meet your needs and is continually optimised. On the other hand, we implement the tracking measures to record the use of our website statistically and to evaluate it for the purpose of optimising our offer for you. These interests are to be regarded as legitimate within the meaning of the above-mentioned provision.</p>

                        <p>The respective data processing purposes and data categories can be found in the corresponding tracking tools.</p>

                        <p><b>I) Google Analytics</b></p>

                        <p>For the purpose of a needs-based design and continuing optimisation of our pages, we use Google Analytics, a web analytics service of Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; hereinafter “Google”). In this connection, pseudonymised user profiles are created and cookies (see no. 4) are used. The data created by the cookie when you visit this website, such as</p>
                        <div className="pl-10 space-y-2">
                            <p>- type and version of browser,</p>
                            <p>- operating system used,</p>
                            <p>- referrer URL (the site visited before),</p>
                            <p>- host name of the accessing computer (IP address),</p>
                            <p>- time of the server inquiry,</p>
                        </div>
                        <p>
                        are transmitted to and stored on a server of Google in the United States. These data are used to evaluate the use of the website, compile reports on website activity and provide other services relating to website activity and internet usage for the purpose of market research and designing these internet pages to meet your needs. These data may also be transferred to third parties where required by law or where third parties process the information on instructions. Your IP address will in no case be associated with any other data held by Google. The IP addresses are anonymised so that no association is possible (IP masking).

                        <br />
                        You may refuse the installation of cookies by selecting the appropriate settings on your browser, however, please note that if you do this, you may not be able to use the full functionality of this website.

                        <br />
                        You may further avoid the collection of the data generated by the cookie and relating to your use of the website (including your IP address) and its processing by Google by downloading and installing a <Link className="underline" to="//tools.google.com/dlpage/gaoptout?hl=de">Browser-Add-on</Link>.
                        <br />
                        As an alternative to the browser add-on, especially for browsers on mobile devices, you can also prevent Google Analytics from collecting data by clicking this link. An opt-out cookie is set to prevent your data from being collected in the future when you visit this website. The opt-out cookie only applies to this browser and only to our website and is stored on your device. When you delete the cookies in this browser, you have to set the opt-out cookie again.
                        <br />
                        You can find more information about privacy in connection with Google Analytics in the <Link className="underline" to="//support.google.com/analytics/answer/6004245">Google Analytics Help</Link>.
                        </p>
                        <p><b>II) Google Adwords Conversion Tracking</b></p>
                        <p>We also use Google Conversion Tracking to record the use of our website statistically and to evaluate it for the purpose of optimising our website for you. Google Adwords places a cookie (see no. 4) on your computer if you have reached our website via a Google advertisement.
                        <br />
                        These cookies lose their validity after 30 days and are not used for personal identification. If the user visits certain pages of the Adwords customer’s website and the cookie has not expired, Google and the customer will be able to tell that the user clicked on the ad and was directed to that page. Each Adwords customer receives a different cookie. Therefore, cookies cannot be traced via the websites of Adwords customers. The data collected using the conversion cookie are used to generate conversion statistics for Adwords customers who have opted for conversion tracking. Adwords customers see the total number of users who clicked on their ad and were redirected to a page with a conversion tracking tag. However, they do not receive any information that personally identifies users.
                        <br />
                        If you do not wish to participate in the tracking procedure, you can also refuse to set a cookie as required for this purpose, for example, by setting your browser to deactivate the automatic setting of cookies in general. You can also disable cookies for conversion tracking by setting your browser to block cookies from the domain “www.googleadservices.com” <Link className="underline" to="//services.google.com/sitestats/en.html">Google's Privacy Policy for Conversion Tracking</Link> can be found here.
                        </p>
                    </div>
                </div>

                <div className="py-2 space-y-2">
                    <b>§6 SOCIAL MEDIA PLUG-INS</b>
                    <div className="space-y-2 text-sm">
                        <p>In order to make our auction house better known, we use plug-ins of the social networks Facebook, Twitter and Instagram on our website on the basis of point f) of sentence 1 of Art. 6(1) GDPR. The underlying advertising purpose is to be regarded as a legitimate interest within the meaning of the GDPR. The responsibility for the operation in compliance with data protection regulations lies with the respective provider. We have integrated these plug-ins by means of the so-called two-click method in order to protect visitors to our website in the best possible way.</p>

                        <p><b>a) Facebook</b></p>

                        <p>We have implemented social media plug-ins of Facebook on our website to make its use more personal. We use the “LIKE” and “SHARE” button in this regard. This is an offer by Facebook.
<br />
When you access a page of our website that contains such a plug-in, your browser establishes a direct connection with the Facebook servers. The content of the plug-ins is directly transmitted from Facebook to your browser, which integrates it into the website.
<br />
By integrating the plug-ins, Facebook is provided with the information that your browser has accessed the corresponding page of our website, even if you do not have a Facebook account or are not logged in to Facebook. This information (including your IP address) is transmitted from your browser directly to and stored on a Facebook server in the United States.
<br />
If you are logged in to Facebook, Facebook may directly link your visit of our website with your Facebook account. If you interact with the plug-ins, for example, if you click the “LIKE” or “SHARE” button, this information is also directly transmitted to and stored on a Facebook server. This information is also published on Facebook and shown to your Facebook friends.
<br />
Facebook may use this information for advertising purposes, market research and for designing Facebook pages to meet your needs. For this purpose, Facebook creates profiles regarding usage, interests and relationships, for example, to evaluate your use of our website with regard to the advertisements displayed to you on Facebook, to inform other Facebook users about your activities on our website and to provide further services related to the use of Facebook.
<br />
If you do not want Facebook to link the data collected from our website with your Facebook account, you have to log off from Facebook first.
<br />
Please see <Link className="underline" to="//www.facebook.com/about/privacy/">Facebook's Data Policy</Link> for information about the purpose and scope of data collection and the further processing and use of the data by Facebook as well as your rights in that respect and the various settings for protecting your privacy.
</p>

                        <p><b>b) Instagram</b></p>
                        <p>b) Instagram
                        So-called social plug-ins (“plug-ins”) of Instagram operated by Instagram LLC., 1601 Willow Road, Menlo Park, CA 94025, USA (“Instagram”) are also used on our website.
<br />
The plug-ins are marked with an Instagram logo, for example, in the form of an “Instagram camera”.
<br />
When you access a page of our website that contains such a plug-in, your browser establishes a direct connection with the Instagram servers. The content of the plug-ins is directly transmitted from Instagram to your browser, which integrates it into the website. By integrating the plug-ins, Instagram is provided with the information that your browser has accessed the corresponding page of our website, even if you do not have an Instagram account or are not logged in to Instagram.
<br />
This information (including your IP address) is transmitted from your browser directly to and stored on an Instagram server in the United States. If you are logged in to Instagram, Instagram may directly link your visit to our website with your Instagram account. If you interact with the plug-ins, for example, if you click the “Instagram” button, this information is also directly transmitted to and stored on an Instagram server.
<br />
The information is also published on your Instagram profile and shown to your contacts.
<br />
If you do not want Instagram to directly link the data collected from our website with your Instagram account, you have to log off from Instagram first.
<br />
Please see the  <Link className="underline" to="//help.instagram.com/155833707900388">Data Policy for Instagram</Link> for further information.</p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§7 RIGHTS OF DATA SUBJECTS</b>
                    <div className="space-y-2 text-sm">
                        <p>You have the right,</p>
                        <div className="space-y-2 pl-10">
                            <p>- pursuant to Art. 15 GDPR, to obtain access to the personal data concerning you, which are processed by us. You may particularly obtain information about the purposes of the processing, the category of personal data, the categories of recipients to whom your data have been or will be disclosed, the envisaged period for which personal data will be stored, the existence of the right to request rectification, erasure, restriction of processing or objection, the right to lodge a complaint, the source of your data if they were not collected from you, as well as the existence of an automated decision-making, including profiling, and meaningful information about their details, if applicable;</p>
                            <p>- pursuant to Art. 16 GDPR, to obtain the rectification of inaccurate personal data concerning you, which have been stored by us, or their completion without undue delay;</p>
                            <p>- pursuant to Art. 17 GDPR, to obtain the erasure of personal data concerning you, which have been stored by us, without undue delay unless their processing is required to exercise the right to freedom of expression and information, to comply with a legal obligation, for reasons of public interest or to establish, exercise or defend legal claims;</p>
                            <p>- pursuant to Art. 18 GDPR, to obtain restriction of the processing of your personal data if you contest the accuracy of the data, if the processing is unlawful and you oppose the erasure, and if we no longer need these data, but you require them to establish, exercise or defend legal claims or if you have objected to the processing in accordance with Art. 21 GDPR;</p>
                            <p>- pursuant to Art. 20 GDPR, to receive the personal data concerning you, which have been provided to us, in a structured, commonly used and machine-readable format or to transmit those data to another controller;</p>
                            <p>- pursuant to Art. 7(3) GDPR, to withdraw any consent given by you at any time. This means that we are not allowed to continue the data processing based on this consent in future; and</p>
                            <p>- pursuant to Art. 77 GDPR, to lodge a complaint with a supervisory authority. If you believe that the processing of personal data concerning yourself by Münzgalerie München MGM Handelsgmbh &amp; co. Joker KG in Munich, is in violation of the GDPR, you have the right to lodge a complaint with the relevant office, e.g. in Bavaria with the Data Protection Authority of Bavaria (Bayerisches Landesamt für Datenschutzaufsicht, BayLDA), Promenade 27 (Schloss), D-91522 Ansbach.8. 

</p>
                        </div>
                        <p><b>How Long We Store Data</b></p>
                        <p>Multiple storage periods and obligations to archive data have been stipulated in various pieces of legislation; for example, there is a 10-year archiving period (Sec. 147 (2) in conjunction with (1) nos. 1, 4, and 4a of the German Tax Code (Abgabenordnung), Sec. 14b (1) of the German VAT Act (Umsatzsteuergesetz)) for certain kinds of business documents such as invoices. We would like to draw your attention to the fact that in the case of contracts, the archiving period does not start until the end of the contract term. We would also like to advise you that in the case of cultural property we are obligated pursuant to Sec. 45 and Sec. 42 of the German Cultural Property Protection Act (Kulturgutschutzgesetz) to record proof of meeting our due diligence requirements and to retain this for a period of 30 years. This may also include the recording and retention of some or all of your personal data. Once the periods prescribed by law or necessary to pursue or defend against claims (e.g., statutes of limitations) have expired, the corresponding data is routinely deleted. Data not subject to storage periods and obligations is deleted once the storage of such data is no longer required for the performance of activities and satisfaction of duties under the contract. If you do not have a contractual relationship with us but have shared your personal data with us, for example because you would like to obtain information about our services or you are interested in the purchase or sale a certain coin, medal, piece of jewellery or other work of art we take the liberty of assuming that you would like to remain in contact with us, and that we may thus process the personal data provided to us in this context until such time as you object to this on the basis of your aforementioned rights under the GDPR, withdraw your consent, or exercise your right to erasure or data transmission.
</p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§8 RIGHT TO OBJECT</b>
                    <div className="space-y-2 text-sm">
                        <p>If your personal data are processed on the basis of legitimate interests pursuant to point f) of sentence 1 of Art. 6(1) GDPR, you have the right to object to the processing of your personal data pursuant to Art. 21 GDPR if there are reasons for doing so which arise from your particular situation or if you have objected to direct marketing. In the latter case, you have a general right of objection, which we will implement, without the need of specifying a particular situation.</p>

                        <p>If you wish to exercise your right to object, please send an e-mail to <b><EmailAddress email="datenschutz@muenzgalerie.de"/></b></p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§9 DATA SECURITY</b>
                    <div className="space-y-2 text-sm">
                        <p>We apply the common SSL (Secure Socket Layer) procedure in conjunction with the highest level of encryption supported by your browser during your visit within our website. Normally, this is a 256-bit encryption. If your browser does not support a 256-bit encryption, the 128-bit v3 technology is used. You can recognize an encrypted connection to an individual page of our web presence by the closed padlock symbol in your browser line.</p>
                        <p>We also apply suitable technical and organisational security measures to protect your data against accidental or intentional manipulation, partial or complete loss, destruction or against unauthorised access by third parties. Our security measures are continuously improved in line with technological developments.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
