import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProductBox from '../components/ProductBox'
import { detailsCatalogs } from '../actions/catalogActions';
import { useDispatch, useSelector } from 'react-redux';
import MessageBox from '../components/MessageBox';
import LoadingBox from '../components/LoadingBox';
import Pagination from '../components/Pagination';
import { useLocation } from 'react-router';
import AutocompleteSelect from '../components/filters/AutocompleteSelect';
import useDebouncedValue from '../components/useDebouncedValue';
import NormalInput from '../components/filters/NormalInput';

export default function CatalogItemScreen(props) {
    const { t } = useTranslation(["catalog"])

    const dispatch = useDispatch();
    const catalogId = props.match.params.id;
    const catalogDetail = useSelector(state => state.catalogDetail);
    const { loading, error, catalog } = catalogDetail;
    const location = useLocation();

    const [pagesize, setPagesize] = useState(20);
    const [page, setPage] = useState(location.search ? new URLSearchParams(location.search).get('page') : "1");

    const [categoryValue, setCategoryValue] = useState(null);

    const categoryOptions = catalog && catalog.groups && catalog.groups.length > 0 ? catalog.groups.map((item) => { return { "label": item.group_name, "value": item.group_name, ...item } }) : [];
    const [queryValue, setQueryValue] = useState(null);
    const [knrValue, setKNRValue] = useState(null);

    function hasValueDeep(json, findValue) {
        const jsonString = JSON.stringify(json).toUpperCase();
        return jsonString.includes(findValue.toUpperCase());
    }

    const debouncedQuery = useDebouncedValue(queryValue, 500);
    const debouncedKNR = useDebouncedValue(knrValue, 500);

    const applyFilter = (items) => {
        return items.filter(debouncedQuery ?
            item => hasValueDeep(item, debouncedQuery) : item => item)
            .filter(debouncedKNR ?
                item => String(item.number) === String(debouncedKNR) : item => item)
            .filter(
                categoryValue ?
                    item => item.number_prefix === categoryValue.group_abbreviation
                        && item.number <= categoryValue.group_end
                        && item.number >= categoryValue.group_begin : item => item)
    }
    useEffect(() => {
        dispatch(detailsCatalogs(catalogId));
    }, [dispatch, catalogId]);

    useEffect(() => {
        if (location.search && new URLSearchParams(location.search).get('page') !== page) {
            window.scrollTo(0, 0);
            setPage(new URLSearchParams(location.search).get('page'))
        }
    }, [location.search])




    return (

        <div className="px-5p space-y-2">
            {error && <MessageBox variant="danger">{error}</MessageBox>}
            {loading ? <LoadingBox /> : catalog ?
                <>

                    <div className="py-2 flex md:flex-row flex-col justify-between">
                        <div className="flex md:flex-col flex-row md:space-x-0 space-x-2 pb-2 items-center md:items-start">
                            <h1 className="text-xl md:text-3xl font-extrabold text-gray-900 tracking-tight">{catalog.name}</h1>
                            <h2 className="text-sm md:text-base md:text-xl md:pt-2 uppercase text-gray-900 tracking-widest">{catalog.subtext}</h2>
                        </div>
                        <div className='flex md:flex-row  flex-col items-center md:space-x-2  md:space-y-0 space-y-2 '>
                            {catalog.groups && catalog.groups.length > 1 && <AutocompleteSelect value={categoryValue} setValue={setCategoryValue} stretch changeKey="category" isSearchable={false} options={categoryOptions} title={"Kategorie"} />}
                            <NormalInput value={queryValue} setValue={setQueryValue} placeholder={"Suchbegriff"} />
                            <NormalInput type="number" value={knrValue} setValue={setKNRValue} placeholder={"Katalognummer"} />

                            <a href={catalog.pdf} className="flex space-x-2 hover:text-blue-700 items-center border p-3 text-blue-500" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon="file-pdf" /><span>PDF</span></a>

                        </div>
                    </div>
                    {catalog.products && catalog.products.length > 0 ?
                        <>
                            {debouncedQuery ? <div className="text-gray-600 text-sm">Suchbegriff: "<span className="font-bold">{debouncedQuery}</span>" <button onClick={() => setQueryValue(null)} className="focus:outline-none text-red-500 px-2 hover:text-red-600" ><FontAwesomeIcon icon="times" /> </button></div> : <></>}

                            {debouncedKNR ? <div className="text-gray-600 text-sm">Suche Katalognummer: "<span className="font-bold">{debouncedKNR}</span>" <button onClick={() => setKNRValue(null)} className="focus:outline-none text-red-500 px-2 hover:text-red-600" ><FontAwesomeIcon icon="times" /> </button></div> : <></>}

                    {applyFilter(catalog.products).length > 0 ?
                        <div className="grid grid-cols-2 md:grid-cols-4 list-none align-center bg-gray-100 border p-3 gap-3">
                            {applyFilter(catalog.products.sort(function (x, y) {
                                var customSort = ["A", "G", "", "M"]
                                var identX = x.number_prefix ? x.number_prefix : "";
                                var identY = y.number_prefix ? y.number_prefix : "";
                                if (identX === identY) {
                                    return x.number - y.number;

                                } else {
                                    var a = customSort.indexOf(identX);
                                    var b = customSort.indexOf(identY);
                                    return a - b;
                                }


                            })).slice(pagesize * (page - 1), pagesize * (page)).map((product, i) => (
                                product && product.product ? <ProductBox key={i} catalogident={product.number_prefix ? product.number_prefix + product.number : product.number} product={product.product} /> : <></>
                            ))}

                        </div> : <MessageBox hideCloseButton>Keine Artikel zu dieser Suche gefunden</MessageBox>
                    }

                    <div className="flex py-2 justify-center">
                        <Pagination page={parseInt(page)} pages={Math.ceil(applyFilter(catalog.products).length / pagesize)} />
                    </div>
                </> : <></>
            }

        </>
                :

    <></>
}
        </div >
    )
}
