import { CATALOG_DETAILS_FAIL, CATALOG_DETAILS_REQUEST, CATALOG_DETAILS_SUCCESS, CATALOG_LIST_FAIL, CATALOG_LIST_REQUEST, CATALOG_LIST_SUCCESS } from "../constants/catalogConstants";

export const catalogListReducer = (
    state = { loading: true, catalogs: [] }, action) => {
    switch (action.type) {
        case CATALOG_LIST_REQUEST:
            return { loading: true };
        case CATALOG_LIST_SUCCESS:
            return { loading: false, catalogs: action.payload.catalogs, pages: action.payload.pages, page: action.payload.page, count: action.payload.count };
        case CATALOG_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const catalogDetailsReducer = (state = { loading: true }, action) => {
    switch (action.type) {
        case CATALOG_DETAILS_REQUEST:
            return { loading: true };
        case CATALOG_DETAILS_SUCCESS:
            return { loading: false, catalog: action.payload };
        case CATALOG_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};