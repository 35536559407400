import React from 'react'
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../BreadCrumbs';

export default function BullionCoinGrid() {
    const { t } = useTranslation(["navbar"]);
    return ( 
    <div>
        <BreadCrumbs steps={[{title:t("coins", "Münzen"), link:"/category/muenzen"}, {title:t("bullion_coins", "Bullion Münzen")}]}/>
    </div>
    )
}

