import React, { useEffect, useState } from 'react';
import useKeyPress from '../components/useKeyPress'
import { useDispatch, useSelector } from 'react-redux';
import { confirmOrder, deleteOrder, listOrders, payOrder, sendOrder, modalOrder, makeGreen } from '../actions/orderActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import currencyFormat from '../utils/currency';
import { dateFormat, dateMediumFormat } from '../utils/date';
import { optionalField } from '../utils/string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OldPagination from '../components/OldPagination';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { shippings } from "../utils/enum";

export default function OrderListScreen(props) {
    const orderList = useSelector((state) => state.orderList);
    // const { loading, error, orders, page, pages, count } = orderList;
    const { loading, error, orders, page, pages } = orderList;


    const { id = 'all', name = 'all', surname = 'all', city = 'all', minPrice = 0, maxPrice = 0, dateFrom = 'all', dateTo = 'all', paymentMethod = 'all', paid = 'all', sent = 'all', confirmed = 'all', pageNumber = 1 } = useParams();

    const orderDetails = useSelector((state) => state.orderDetails);
    const { errorDetail, successDetail, order: orderDetail } = orderDetails;
    const orderDelete = useSelector((state) => state.orderDelete);
    const { loading: loadingDelete, error: errorDelete, success: successDelete } = orderDelete;

    const orderConfirm = useSelector((state) => state.orderConfirm);
    const { loading: loadingConfirm, error: errorConfirm, success: successConfirm } = orderConfirm;

    const orderConfirmNoMail = useSelector((state) => state.orderConfirmNoMail);
    const { loading: loadingConfirmNoMail, error: errorConfirmNoMail, success: successConfirmNoEmail } = orderConfirmNoMail;

    const orderPay = useSelector((state) => state.orderPay);
    const { loading: loadingPay, error: errorPay, success: successPay } = orderPay;

    const orderSend = useSelector((state) => state.orderSend);
    const { loading: loadingSend, error: errorSend, success: successSend } = orderSend;

    const dispatch = useDispatch();

    const history = useHistory();

    const [orderModalVisible, setOrderModalVisible] = useState(false);
    const [versandVisible, setVersandVisible] = useState(false);
    const [trackingNumber, setTrackingNumber] = useState('');
    const [selectedOrder, setSelectedOrder] = useState('');

    const getFilterUrl = (filter) => {
        const filterId = filter.id || id;
        const filterName = filter.name || name;
        const filterSurname = filter.surname || surname;
        const filterCity = filter.city || city;
        const filterMin = filter.minPrice ? filter.minPrice : filter.minPrice === 0 ? 0 : minPrice;
        const filterMax = filter.maxPrice ? filter.maxPrice : filter.maxPrice === 0 ? 0 : maxPrice;
        const filterDateFrom = filter.dateFrom || dateFrom;
        const filterDateTo = filter.dateTo || dateTo;
        const filterPaymentMethod = filter.paymentMethod || paymentMethod;
        const filterPaid = filter.paid || paid;
        const filterSent = filter.sent || sent;
        const filterConfirmed = filter.confirmed || confirmed;
        const filterPageNumber = filter.page || pageNumber;


        return `/orderlist/id/${filterId}/name/${filterName}/surname/${filterSurname}/city/${filterCity}/min/${filterMin}/max/${filterMax}/dateFrom/${filterDateFrom}/dateTo/${filterDateTo}/paymentMethod/${filterPaymentMethod}/paid/${filterPaid}/sent/${filterSent}/confirmed/${filterConfirmed}/page/${filterPageNumber}`;
    };

    const activateFilter = () => {
        var id = document.getElementById('idInput').value || "all";
        var name = document.getElementById('nameInput').value || "all";
        var surname = document.getElementById('surnameInput').value || "all";
        var city = document.getElementById('cityInput').value || "all";
        var minPrice = document.getElementById('minPriceInput').value || 0;
        var maxPrice = document.getElementById('maxPriceInput').value || 0;
        var dateFrom = document.getElementById('dateFromInput').value || "all";
        var dateTo = document.getElementById('dateToInput').value || "all";
        var paymentMethod = document.getElementById('paymentMethodInput').value || "all";
        var paid = document.getElementById('paidInput').value || "all";
        var sent = document.getElementById('sentInput').value || "all";
        var confirmed = document.getElementById('confirmedInput').value || "all";
        history.push(getFilterUrl({ id: id, name: name, surname: surname, city: city, minPrice: minPrice, maxPrice: maxPrice, dateFrom: dateFrom, dateTo: dateTo, paymentMethod: paymentMethod, paid: paid, sent: sent, confirmed: confirmed, page: 1 }));
    };

    // When the 'Bestätigen' button is clicked
    const confirmHandler = (order) => {
        if (order.shippingInfo.shippingMethod === shippings.SELF_PICKUP) {
            if (window.confirm(`Sind Sie sicher, dass Sie diese Bestellung zur Abholung (${order._id}) bestätigen möchten? Achtung: Der Kunde wird infolgedessen per E-Mail benachrichtigt!`)) {
                dispatch(confirmOrder(order._id));
            }
        } else if (order.shippingInfo.shippingMethod === shippings.STANDARD) {
            setSelectedOrder(order._id);
            setVersandVisible(true);
        } else {
            alert("Ungültige Versandmethode")
        }
    };

    const makeGreenHandler = (order) => {
        if (window.confirm(`Sind Sie sicher, dass Sie diese Bestellung (${order._id}) als bezahlt + versandt bzw. abgeholt markieren möchten? Es wird keine E-Mail verschickt!`)) {
            dispatch(makeGreen(order._id));
        }
    }
    // When the 'Bezahlt' button is clicked
    const paidHandler = (order) => {
        if (window.confirm(`Sind Sie sicher, dass Sie diese Bestellung (${order._id}) als bezahlt markieren möchten?`)) {
            dispatch(payOrder(order._id));
        }
    };

    // When the 'Verschickt/Abgeholt' button is clicked
    const collectedSentHandler = (order) => {
        if (window.confirm(`Sind Sie sicher, dass Sie diese Bestellung (${order._id}) verschickt/abgeholt wurde?`)) {
            dispatch(sendOrder(order._id));
        }
    };

    // When the 'Löschen' button is clicked
    const deleteHandler = (order) => {
        if (window.confirm(`Sind Sie sicher, dass Sie diese Bestellung (${order._id}) löschen möchten?`)) {
            dispatch(deleteOrder(order._id));
        }
    };

    // HelperFunction VersandOverlay
    const shippingNumberHelper = (e, orderId) => {
        e.preventDefault();
        if (trackingNumber.length > 0 && window.confirm(`Sind Sie sicher, dass ${trackingNumber} die richtige Versandnummer für Bestellung ${selectedOrder} ist? Achtung: Der Kunde wird infolgedessen per E-Mail benachrichtigt!`)) {
            dispatch(confirmOrder(orderId, trackingNumber));
        }
        resetVersandOverlay();
    };

    useEffect(() => {
        dispatch(
            listOrders({
                id: id !== "all" ? id : '',
                name: name !== "all" ? name : '',
                surname: surname !== "all" ? surname : '',
                city: city !== "all" ? city : '',
                minPrice,
                maxPrice,
                dateFrom: dateFrom !== "all" ? dateFrom : '',
                dateTo: dateTo !== "all" ? dateTo : '',
                paymentMethod: paymentMethod !== "all" ? paymentMethod : '',
                paid: paid !== "all" ? paid : '',
                sent: sent !== "all" ? sent : '',
                confirmed: confirmed !== "all" ? confirmed : '',
                pageNumber
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, successDelete, successConfirm, successPay, successSend, successDetail, pageNumber]);

    useKeyPress('Escape', () => {
        resetVersandOverlay();
        setOrderModalVisible(false);
    });

    const resetVersandOverlay = () => {
        setTrackingNumber('');
        setSelectedOrder('');
        setVersandVisible(false);
    };

    const getOrderModal = (orderId) => {
        dispatch(modalOrder(orderId));
        setOrderModalVisible(true);
    }

    const printHandler = () => {
        const elementId = "modalcontents";
        const uniqueIframeId = "iframeunique";
        const content = document.getElementById(elementId)
        let pri
        if (document.getElementById(uniqueIframeId)) {
            pri = document.getElementById(uniqueIframeId).contentWindow
        } else {
            const iframe = document.createElement('iframe')
            iframe.setAttribute('title', uniqueIframeId)
            iframe.setAttribute('id', uniqueIframeId)
            iframe.setAttribute('style', 'height: 0px; width: 0px; position: absolute;')
            document.body.appendChild(iframe)
            pri = iframe.contentWindow
        }
        pri.document.open()
        pri.document.write(content.innerHTML)
        pri.document.close()
        pri.focus()
        pri.print()
    }
    return (
        <div className="px-5p space-y-2">
            {(loadingDelete || loadingConfirm || loadingPay || loadingSend) && <LoadingBox></LoadingBox>}
            {(errorDelete || errorConfirm || errorPay || errorSend || errorDetail) && <MessageBox variant="danger">{errorDelete || errorConfirm || errorPay || errorSend || errorDetail}</MessageBox>}
            {successDelete && <MessageBox variant="success">Bestellung wurde erfolgreich gelöscht.</MessageBox>}
            {successConfirm && <MessageBox variant="success">Bestellung wurde erfolgreich bestätigt. Der Kunde wurde per E-Mail benachrichtigt.</MessageBox>}
            {successConfirmNoEmail && <MessageBox variant="success">Bestellung wurde erfolgreich bestätigt. Keine E-Mail wurde verschickt.</MessageBox>}
            {successPay && <MessageBox variant="success">Bestellung wurde als bezahlt markiert.</MessageBox>}
            {successSend && <MessageBox variant="success">Bestellung wurde verschickt/abgeholt.</MessageBox>}
            {versandVisible &&
                <div className="bg-black bg-opacity-70 h-full w-full fixed z-10 top-0 left-0 overflow-y-scroll">
                    <div className="relative top-1/4 -mt-32 md:-mt-20 overlay-content text-center flex justify-center">
                        <form className="w-96 flex flex-col bg-gray-50 m-10 py-4 rounded justify-center space-y-4">
                            <span className="">Bitte geben Sie die Versandnummer ein:</span>
                            <input className="border mx-10 px-2" type="text" value={trackingNumber} onChange={(e) => setTrackingNumber(e.target.value)} />
                            <button className="transition duration-300 mx-10 rounded bg-blue-300 hover:bg-blue-400" onClick={(e) => shippingNumberHelper(e, selectedOrder)}>Schicken</button>
                            <button className="transition duration-300 mx-10 rounded bg-gray-200 hover:bg-gray-300" onClick={() => resetVersandOverlay()}>Abbrechen</button>
                        </form>
                    </div>
                </div>
            }
            {orderModalVisible && orderDetail &&
                <div id="modalcontents" className="printme bg-black bg-opacity-70 h-full w-full fixed z-10 top-0 left-0 overflow-y-scroll">
                    <button className="transition duration-300 ease-in-out absolute right-0 text-white focus:outline-none pt-2 px-2 md:pt-10 md:px-10 hover:opacity-60" onClick={() => setOrderModalVisible(false)} ><FontAwesomeIcon icon="times" size="2x" /></button>
                    <div className="relative top-1/4 -mt-32 md:-mt-20 overlay-content flex justify-center">
                        <div className="bg-white shadow border border-black p-10">
                            <div className='flex w-full justify-between'>
                                <div className="font-bold text-lg p">Bestellung {orderDetail._id}</div>
                                <button type="button" onClick={() => printHandler()} className="px-2 border border-blue-400 rounded-md bg-blue-100 hover:bg-blue-200 focus:outline-none disabled:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed ">

                                    <FontAwesomeIcon icon="print" />
                                </button>
                            </div>
                            <div className="text-sm space-y-2">
                                <div className="">{dateMediumFormat(orderDetail.createdAt)}</div>
                                {orderDetail.shippingInfo.firma && <div className="">Firma: {orderDetail.shippingInfo.firma}</div>}
                                <div className="flex space-x-4 divide-x flex-row">
                                    <div className="space-y-2">
                                        <div>
                                            <p className="text-gray-700">Kundeninformation:</p>
                                        </div>
                                        <table className="text-left table-auto ">
                                            <tbody >
                                                <tr className="">
                                                    <td className="text-gray-700">Vorname:</td>
                                                    <td className="pl-10">{orderDetail.shippingInfo.firstName}</td>
                                                </tr>
                                                <tr className="">
                                                    <td className="text-gray-700">Nachname:</td>
                                                    <td className="pl-10">{orderDetail.shippingInfo.lastName}</td>
                                                </tr>
                                                {orderDetail.shippingInfo.company &&
                                                    <tr className="">
                                                        <td className="text-gray-700">Firma:</td>
                                                        <td className="pl-10">{orderDetail.shippingInfo.company}</td>
                                                    </tr>
                                                }
                                                {orderDetail.shippingInfo.phone &&
                                                    <tr className="">
                                                        <td className="text-gray-700">Telefon/Handy:</td>
                                                        <td className="pl-10">{orderDetail.shippingInfo.phone}</td>
                                                    </tr>
                                                }
                                                <tr className="">
                                                    <td className="text-gray-700">E-Mail:</td>
                                                    <td className="pl-10">{orderDetail.shippingInfo.email}</td>
                                                </tr>
                                                <tr className="">
                                                    <td className="text-gray-700">Adresse:</td>
                                                    <td className="pl-10">
                                                        {orderDetail.shippingInfo.address}
                                                    </td>
                                                </tr>

                                                <tr className="">
                                                    <td></td>
                                                    <td className="pl-10">{orderDetail.shippingInfo.postalCode} {orderDetail.shippingInfo.city} {orderDetail.shippingInfo.country}</td>
                                                </tr>
                                                {orderDetail.shippingInfo.addressExtra &&
                                                    <tr className="">
                                                        <td className="text-gray-700">Adresszusatz:</td>
                                                        <td className="pl-10">
                                                            {orderDetail.shippingInfo.addressExtra}
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                        {orderDetail.shippingInfo.deliveryAddress &&
                                            <>
                                                <hr />
                                                <div>
                                                    <p className="text-gray-700">Lieferanschrift:</p>
                                                </div>
                                                <table className="text-left table-auto">
                                                    <tbody >
                                                        <tr className="">
                                                            <td className="text-gray-700">Vorname:</td>
                                                            <td className="pl-10">{orderDetail.shippingInfo.deliveryFirstName}</td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="text-gray-700">Nachname:</td>
                                                            <td className="pl-10">{orderDetail.shippingInfo.deliveryLastName}</td>
                                                        </tr>
                                                        {orderDetail.shippingInfo.deliveryCompany &&
                                                            <tr className="">
                                                                <td className="text-gray-700">Firma:</td>
                                                                <td className="pl-10">{orderDetail.shippingInfo.deliveryCompany}</td>
                                                            </tr>
                                                        }
                                                        {orderDetail.shippingInfo.deliveryPhone &&
                                                            <tr className="">
                                                                <td className="text-gray-700">Telefon/Handy:</td>
                                                                <td className="pl-10">{orderDetail.shippingInfo.deliveryPhone}</td>
                                                            </tr>
                                                        }
                                                        <tr className="">
                                                            <td className="text-gray-700">E-Mail:</td>
                                                            <td className="pl-10">{orderDetail.shippingInfo.deliveryEmail}</td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="text-gray-700">Adresse:</td>
                                                            <td className="pl-10">
                                                                {orderDetail.shippingInfo.deliveryAddress}
                                                            </td>
                                                        </tr>
                                                        <tr className="">
                                                            <td></td>
                                                            <td className="pl-10">{orderDetail.shippingInfo.deliveryPostalCode} {orderDetail.shippingInfo.deliveryCity} {orderDetail.shippingInfo.deliveryAddress}</td>
                                                        </tr>
                                                        {orderDetail.shippingInfo.deliveryAddressExtra &&
                                                            <tr className="">
                                                                <td className="text-gray-700">Adresszusatz:</td>
                                                                <td className="pl-10">
                                                                    {orderDetail.shippingInfo.deliveryAddressExtra}
                                                                </td>
                                                            </tr>}
                                                    </tbody>
                                                </table>
                                            </>
                                        }
                                        <hr />
                                        <div>
                                            <p className="text-gray-700">Bestellungsinformation:</p>
                                        </div>
                                        <table className="text-left table-auto ">
                                            <tbody >
                                                <tr className="">
                                                    <td className="text-gray-700">Bezahlungsart:</td>
                                                    <td className="pl-5 capitalize">{orderDetail.shippingInfo.paymentMethod}</td>
                                                </tr>
                                                <tr className="">
                                                    <td className="text-gray-700">Versandart:</td>
                                                    <td className="pl-5 capitalize">{orderDetail.shippingInfo.shippingMethod}</td>
                                                </tr>
                                                <tr className="">
                                                    <td className="text-gray-700">Artikelpreis:</td>
                                                    <td className="pl-5">{currencyFormat(orderDetail.itemsPrice)}</td>
                                                </tr>
                                                <tr className="">
                                                    <td className="text-gray-700">Versand:</td>
                                                    <td className="pl-5">{currencyFormat(orderDetail.shippingPrice)}</td>
                                                </tr>
                                                <tr className="">
                                                    <td className="text-gray-700">Summe:</td>
                                                    <td className="pl-5">{currencyFormat(orderDetail.totalPrice)}</td>
                                                </tr>
                                                <tr className="">
                                                    <td className="text-gray-700">Status:</td>
                                                    <td className="pl-5 py-2">
                                                        {orderDetail.isConfirmed
                                                            ? <div> <span className="text-green-800 bg-green-200 rounded-xl p-1 px-2 text-xs">Bestätigt</span>  <span className="text-gray-500 text-xs">({dateMediumFormat(orderDetail.confirmedAt)})</span></div>
                                                            : <span className="text-red-800 bg-red-200 rounded-xl p-1 px-2 text-xs">Nicht bestätigt</span>}
                                                    </td>
                                                </tr>
                                                <tr className="">
                                                    <td className=""></td>
                                                    <td className="pl-5 py-2">
                                                        {orderDetail.isPaid
                                                            ? <div> <span className="text-green-800 bg-green-200 rounded-xl p-1 px-2 text-xs">Bezahlt</span>  <span className="text-gray-500 text-xs">({dateMediumFormat(orderDetail.paidAt)})</span></div>
                                                            : <span className="text-red-800 bg-red-200 rounded-xl p-1 px-2 text-xs">Nicht bezahlt</span>}
                                                    </td>
                                                </tr>
                                                <tr className="">
                                                    <td className=""></td>
                                                    <td className="pl-5 py-2">
                                                        {orderDetail.isSent
                                                            ? <div> <span className="text-green-800 bg-green-200 rounded-xl p-1 px-2 text-xs">Verschickt</span>  <span className="text-gray-500 text-xs">({dateMediumFormat(orderDetail.sentAt)})</span></div>
                                                            : <span className="text-red-800 bg-red-200 rounded-xl p-1 px-2 text-xs">Nicht verschickt</span>}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="pl-4 ">
                                        <div className="pb-2">
                                            <p className="text-gray-700">Artikelinformation:</p>
                                        </div>
                                        <table className="text-left">
                                            <thead className="border">
                                                <tr className="bg-gray-600 text-white">
                                                    <th className="font-normal px-2">Artikelname</th>
                                                    <th className="font-normal px-2">ANr.</th>
                                                    <th className="font-normal px-2">Anzahl</th>
                                                    <th className="font-normal px-2">Einzelpreis</th>
                                                    <th className="font-normal px-2">Gesamtpreis</th>
                                                </tr>
                                            </thead>
                                            <tbody className="border">
                                                {orderDetail.orderItems.map((item, i) => (
                                                    <tr className="border cursor-pointer hover:bg-gray-100">
                                                        <Link to={`/product/${item.product}`} target="_blank" rel="noopener noreferrer">
                                                            <td className="px-2 text-gray-900">
                                                                <div className="text-sm font-medium text-gray-900">
                                                                    {item.brand}
                                                                </div>
                                                                <div className="text-sm text-gray-500">
                                                                    {item.name}
                                                                </div>
                                                            </td>
                                                        </Link>
                                                        <td className="px-2 text-gray-900">{item.item_number}</td>
                                                        <td className="px-2 text-gray-900">{item.qty}</td>
                                                        <td className="px-2 text-gray-900">{currencyFormat(item.price)}</td>
                                                        <td className="px-2 text-gray-900">{currencyFormat(item.price * item.qty)}</td>

                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <h1 className="text-3xl font-extrabold text-gray-900 tracking-tight py-2">Bestellungsliste</h1>

            {
                loading ? (
                    <LoadingBox></LoadingBox>
                ) : error ? (
                    <MessageBox variant="danger">{error}</MessageBox>
                ) : (
                    <div className="-my-2 overflow-x-auto md:-mx-6">

                        <form className="w-full text-sm">
                            <div className="grid grid-cols-4 grid-rows-2 px-3 mb-3 justify-center">
                                <div className="px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="idInput">
                                        ID
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded px-1 py-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="idInput" type="text" placeholder="" defaultValue={id === "all" ? '' : id} />
                                </div>
                                <div className="px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="nameInput">
                                        Vorname
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded px-1 py-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="nameInput" type="text" placeholder="" defaultValue={name === "all" ? '' : name} />
                                </div>
                                <div className="px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="surnameInput">
                                        Nachname
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded px-1 py-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="surnameInput" type="text" placeholder="" defaultValue={surname === "all" ? '' : surname} />
                                </div>
                                <div className="px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="cityInput">
                                        Stadt
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded px-1 py-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="cityInput" type="text" placeholder="" defaultValue={city === "all" ? '' : city} />
                                </div>
                                <div className="px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="minPriceInput">
                                        Gesamtsumme von
                                    </label>
                                    <div className="flex flex-rowflex-wrap items-stretch w-full relative">
                                        <input id="minPriceInput" type="number" className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded-l px-1 py-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" defaultValue={minPrice} />
                                        <div className="flex -mr-px">
                                            <span className="flex items-center leading-normal bg-gray-200 rounded rounded-l-none border border-l-0 border-grey-light px-1 whitespace-no-wrap text-grey-dark text-sm">€</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="maxPriceInput">
                                        Gesamtsumme bis
                                    </label>
                                    <div className="flex flex-rowflex-wrap items-stretch w-full relative">
                                        <input id="maxPriceInput" type="number" className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded-l px-1 py-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" defaultValue={maxPrice} />
                                        <div className="flex -mr-px">
                                            <span className="flex items-center leading-normal bg-gray-200 rounded rounded-l-none border border-l-0 border-grey-light px-1 whitespace-no-wrap text-grey-dark text-sm">€</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="dateFromInput">
                                        Datum von
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded px-1 py-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="dateFromInput" type="datetime-local" placeholder="" defaultValue={dateFrom} />
                                </div>
                                <div className="px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="dateToInput">
                                        Datum bis
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded px-1 py-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="dateToInput" type="datetime-local" placeholder="" defaultValue={dateTo} />
                                </div>
                                <div className="px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="paymentMethodInput">
                                        Bezahlungsart
                                    </label>
                                    <div className="relative">
                                        <select className="block appearance-none w-full bg-gray-50 border border-gray-200 text-gray-700 py-1 px-1 pr-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="paymentMethodInput" defaultValue={paymentMethod}>
                                            <option value="all">Egal</option>
                                            <option value="amex">American Express</option>
                                            <option value="cash">Bar</option>
                                            <option value="mastercard">Mastercard</option>
                                            <option value="visa">Visa</option>
                                            <option value="vorkasse">Vorkasse</option>
                                        </select>
                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <FontAwesomeIcon icon="caret-down" />
                                        </div>
                                    </div>
                                </div>
                                <div className="px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="confirmedInput">
                                        Bestätigt
                                    </label>
                                    <div className="relative">
                                        <select className="block appearance-none w-full bg-gray-50 border border-gray-200 text-gray-700 py-1 px-1 pr-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="confirmedInput" defaultValue={confirmed}>
                                            <option value="all">Egal</option>
                                            <option value="true">Ja</option>
                                            <option value="false">Nein</option>
                                        </select>
                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <FontAwesomeIcon icon="caret-down" />
                                        </div>
                                    </div>
                                </div>
                                <div className="px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="paidInput">
                                        Bezahlt
                                    </label>
                                    <div className="relative">
                                        <select className="block appearance-none w-full bg-gray-50 border border-gray-200 text-gray-700 py-1 px-1 pr-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="paidInput" defaultValue={paid} >
                                            <option value="all">Egal</option>
                                            <option value="true">Ja</option>
                                            <option value="false">Nein</option>
                                        </select>
                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <FontAwesomeIcon icon="caret-down" />
                                        </div>
                                    </div>
                                </div>
                                <div className="px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="sentInput">
                                        Verschickt/Abgeholt
                                    </label>
                                    <div className="relative">
                                        <select className="block appearance-none w-full bg-gray-50 border border-gray-200 text-gray-700 py-1 px-1 pr-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="sentInput" defaultValue={sent}>
                                            <option value="all">Egal</option>
                                            <option value="true">Ja</option>
                                            <option value="false">Nein</option>
                                        </select>
                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <FontAwesomeIcon icon="caret-down" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex px-6 pb-2 justify-end">
                                <button onClick={activateFilter} className="mr-4 transition duration-300 ease-in-out hover:bg-gray-400 hover:border-gray-800 hover:text-white bg-gray-200 p-2 border border-gray-400 rounded px-6">Suchen</button>
                                <Link to="/orderlist" className="transition duration-300 ease-in-out hover:bg-gray-400 hover:border-gray-800 hover:text-white bg-gray-200 p-2 border border-gray-400 rounded px-6">Filter zurücksetzen</Link>

                            </div>
                        </form>
                        <div className="py-2 align-middle inline-block min-w-full md:px-6">
                            <div className="shadow overflow-hidden border-b border-gray-200 rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                                            <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User</th>
                                            <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Stadt</th>
                                            <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Gesamtsumme</th>
                                            <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Bezahlungs-/Versandart</th>
                                            <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Bestätigt</th>
                                            <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Bezahlt</th>
                                            <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Verschickt/Abgeholt</th>
                                            <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Datum</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-300 ">
                                        {orders.length > 0 && orders.map((order) => (
                                            <tr className={
                                                order.isPaid && order.isConfirmed && order.isSent
                                                    ? "bg-green-100 hover:bg-green-200"
                                                    : order.isPaid && !order.isConfirmed && !order.isSent
                                                        ? "bg-yellow-100 hover:bg-yellow-200"
                                                        : order.isPaid && order.isConfirmed && !order.isSent
                                                            ? "bg-blue-100 hover:bg-blue-200"
                                                            : ""
                                            } key={order._id}>
                                                <td onClick={() => getOrderModal(order._id)} className="cursor-pointer px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-blue-500">{order._id}</td>
                                                <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-900">{order.shippingInfo.firstName} {order.shippingInfo.lastName} {optionalField(order.shippingInfo.company)} </td>
                                                <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-900">{order.shippingInfo.city} </td>
                                                <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-500">{currencyFormat(order.totalPrice)}</td>
                                                <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-500 capitalize">{order.shippingInfo.paymentMethod} / {order.shippingInfo.shippingMethod}</td>
                                                <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-500">{order.isConfirmed ? `Ja (${dateFormat(order.confirmedAt)})` : <b>Nein</b>}</td>
                                                <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-500">{order.isPaid ? `Ja (${dateFormat(order.paidAt)})` : 'Nein'}</td>
                                                <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-500">{order.isSent ? `Ja (${dateFormat(order.sentAt)})` : 'Nein'}</td>
                                                <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-500">{dateMediumFormat(order.createdAt)}</td>
                                                <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-500 space-x-1 text-right">
                                                    <button type="button" onClick={() => confirmHandler(order)} disabled={order.isConfirmed} className="px-2 border border-gray-400 rounded-md bg-green-100 hover:bg-green-200 focus:outline-none disabled:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed ">
                                                        Bestätigen
                                                    </button>
                                                    <button type="button" onClick={() => paidHandler(order)} disabled={order.isPaid || !order.isConfirmed} className="px-2 border border-gray-400 rounded-md bg-purple-100 hover:bg-purple-200 focus:outline-none disabled:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed">
                                                        Bezahlt
                                                    </button>
                                                    <button type="button" onClick={() => collectedSentHandler(order)} disabled={order.isSent || !order.isPaid || !order.isConfirmed} className="px-2 border border-gray-400 rounded-md bg-yellow-100 hover:bg-yellow-200 focus:outline-none disabled:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed">
                                                        Verschickt/Abgeholt
                                                    </button>
                                                    <button type="button" onClick={() => deleteHandler(order)} className="px-2 border border-gray-400 rounded-md bg-red-100 hover:bg-red-200 focus:outline-none disabled:cursor-not-allowed">
                                                        Löschen
                                                    </button>
                                                    <button type="button" onClick={() => makeGreenHandler(order)} disabled={order.isConfirmed} className="px-2 border border-gray-400 rounded-md bg-green-50 hover:bg-green-100 focus:outline-none disabled:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed ">
                                                        😡
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="flex py-2 justify-center">
                            <OldPagination page={page} pages={pages} link={getFilterUrl({}).substr(0, getFilterUrl({}).lastIndexOf('/') + 1)} />
                        </div>
                    </div>
                )
            }
        </div >
    )
}
