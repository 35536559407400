import React, { useEffect, useState } from 'react'
import useKeyPress from './useKeyPress'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import useDebouncedValue from './useDebouncedValue';
import { useDispatch, useSelector } from 'react-redux';
import { searchProducts } from '../actions/productActions';
import LoadingBox from './LoadingBox';
import { Link } from 'react-router-dom';
import currencyFormat from '../utils/currency';

export default function SearchBoxOverlay(props) {
    const { t } = useTranslation(["search"]);

    const [query, setQuery] = useState('');

    const productSearch = useSelector(state => state.productSearch);
    const { loading, results } = productSearch;
    const debouncedQuery = useDebouncedValue(query.trim(), 500);
    const dispatch = useDispatch();
    const handleChange = (e) => {
        setQuery(e.target.value);
    }

    useKeyPress('Escape', () => {
        props.setScreenVisible(false);
    });

    useEffect(() => {
        if (debouncedQuery) {
            dispatch(searchProducts(debouncedQuery));
        }
    }, [dispatch, debouncedQuery]);

    const searchLink = (query) => { return `/category/all/?text=${query}` }
    const searchButtonhandler = (e) => {
        e.preventDefault();
        if (query) {
            props.setScreenVisible(false);
            props.history.push(searchLink(query))
        } else {
            alert(t("enter_search", "Bitte einen Suchbegriff eingeben!"))
        }
    };

    const findDuplicates = (arr) => {
        let sorted_arr = arr.slice().sort();
        let results = [];
        for (let i = 0; i < sorted_arr.length - 1; i++) {
            if (sorted_arr[i + 1] == sorted_arr[i]) {
                results.push(sorted_arr[i]);
            }
        }
        return results;
    }

    const realName = (input) => {
        switch (input) {
            case "MUENZEN": return "Münzen";
            case "BANKNOTEN": return "Banknoten";
            case "MEDAILLEN": return "Medaillen";
            case "ALTDEUTSCHE_MUENZEN": return "Altdeutsche Münzen";
            case "ANTIKE_MUENZEN": return "Antike Münzen";
            case "AUSLAENDISCHE_MEDAILLEN": return "Ausländische Medaillen";
            case "AUSLAENDISCHE_MUENZEN": return "Ausländische Münzen";
            case "AUSLAENDISCHE_BANKNOTEN": return "Ausländische Banknoten";
            case "BULLION_MUENZEN": return "Bullion Münzen";
            case "DEUTSCHE_MEDAILLEN": return "Deutsche Medaillen";
            case "DEUTSCHLAND_BANKNOTEN": return "Deutsche Banknoten";
            case "NUMISMATISCHE_LITERATUR": return "Numismatische Literatur";
            case "DEUTSCHLAND_1871_MUENZEN": return "Deutschland ab 1871 Münzen";
            case "KARL_GOETZ_MEDAILLEN": return "Medaillen von Karl Goetz";
            case "THEMATISCHE_MEDAILLEN": return "Thematische Medaillen";
            default: return input;
        }
    }

    return (
        <div className="bg-black bg-opacity-70 h-full w-full fixed z-10 top-0 left-0 overflow-y-scroll">
            <button className="transition duration-300 ease-in-out absolute right-0 text-white focus:outline-none pt-2 px-2 md:pt-10 md:px-10 hover:opacity-60" onClick={() => props.setScreenVisible(false)} title={t("close_overlay", "Suche schließen")}><FontAwesomeIcon icon="times" size="2x" /></button>
            <div className="relative top-1/4 -mt-32 md:-mt-20 overlay-content text-center">
                <form autoComplete="off">
                    <input value={query} onChange={handleChange} type="search" className="text-sm md:text-base p-3 rounded-tl md:w-8/12 w-10/12" placeholder={t("search_term", "Suchen Sie nach Artikeln, Gebieten oder Kategorien")} name="search" />

                    <button onClick={searchButtonhandler} className="transition duration-300 ease-in-out hover:bg-gray-200 hover:text-blue-500 text-sm md:text-base bg-gray-100 w-10 md:w-32 p-3 rounded-r focus:outline-none">
                        <FontAwesomeIcon size="lg" icon="search" />
                    </button>
                </form>
                {loading ? <LoadingBox /> :
                    results && query &&
                    <div className="pb-4">
                        {results && results.categories && results.categories.length > 0 &&
                            <div className="w-full flex justify-center">
                                <ul className="w-10/12 md:w-8/12 bg-gray-100 text-left text-sm">
                                    <li className="bg-gray-200 pl-3 py-1 text-gray-400">Kategorien</li>
                                    {results.categories.map((category, i) => (
                                        category &&
                                        <li className="border-b hover:bg-gray-200 px-3 py-1" key={i}>
                                            <Link className="flex w-full" onClick={() => props.setScreenVisible(false)} to={category.link}>
                                                {localStorage.getItem("lngCode") && localStorage.getItem("lngCode") === "en" ? category.en : category.de}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                                <span className="text-sm md:text-base w-10 md:w-32 p-3" />
                            </div>
                        }
                        {results && results.brands && results.brands.length > 0 &&
                            <div className="w-full flex justify-center">
                                <ul className="w-10/12 md:w-8/12 bg-gray-100 text-left text-sm">
                                    <li className="bg-gray-200 pl-3 py-1 text-gray-400">Regionen/Länder</li>
                                    {results.brands.map((brand, i) => (
                                        brand &&
                                        <li className="border-b hover:bg-gray-200 px-3 py-1" key={i}>
                                            <Link className="capitalize flex w-full items-center " onClick={() => props.setScreenVisible(false)} to={brand.link}>
                                                {brand.label} {findDuplicates(results.brands.map(item => item.label)).indexOf(brand.label) > -1 ? <span className='text-gray-500 pl-2 text-xs '>({realName(brand.type)})</span> : <></>}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>

                                <span className="text-xs md:text-base w-10 md:w-32 p-3" />
                            </div>
                        }
                        {results && results.products && results.products.length > 0 &&
                            <div className="w-full flex justify-center">
                                <ul className="w-10/12 md:w-8/12 bg-gray-100 text-left text-sm">
                                    <li className="bg-gray-200 pl-3 py-1 text-gray-400">Artikel</li>
                                    {results.products.map((product, i) => (
                                        product &&
                                        <li className="border-b hover:bg-gray-200 px-3 py-1 flex justify-between" key={i}>
                                            <Link className="capitalize flex w-full" onClick={() => props.setScreenVisible(false)} to={`/product/${product.id}`}>
                                                <span>{product.brand}{' - '}{product.name}</span>{' - '}
                                            </Link>
                                            <span className="">{currencyFormat(product.price)}</span>
                                        </li>
                                    ))}
                                    <li className="w-full bg-gray-200 hover:bg-gray-300 px-3 py-1 uppercase tracking-wide">
                                        <Link onClick={() => props.setScreenVisible(false)} className="flex w-full justify-center" to={searchLink(query)}>
                                            {t("show_all", "Alle Artikel anzeigen")}
                                        </Link>
                                    </li>
                                </ul>

                                <span className="text-sm md:text-base w-10 md:w-32 p-3" />
                            </div>
                        }
                    </div>}
            </div>
        </div>
    )
}


// <form action="/action_page.php">
