import Axios from "axios";
import { CATALOG_DETAILS_FAIL, CATALOG_DETAILS_REQUEST, CATALOG_DETAILS_SUCCESS, CATALOG_LIST_FAIL, CATALOG_LIST_REQUEST, CATALOG_LIST_SUCCESS } from "../constants/catalogConstants";

export const listCatalogs = () => async (dispatch) => {
    dispatch({
        type: CATALOG_LIST_REQUEST,
    });
    try {
        const { data } = await Axios.get(`/api/catalogs/`);
        dispatch({ type: CATALOG_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: CATALOG_LIST_FAIL, payload: error.message });
    }
};

export const detailsCatalogs = (catalogId) => async (dispatch) => {
    dispatch({
        type: CATALOG_DETAILS_REQUEST, payload: catalogId
    });
    try {
        const { data } = await Axios.get(`/api/catalogs/${catalogId}`);
        dispatch({ type: CATALOG_DETAILS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: CATALOG_DETAILS_FAIL, payload: error.response || error.data.message
                ? error.response.data.message
                : error.message
        });
    }
};