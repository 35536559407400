import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useKeyPress from '../useKeyPress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { searchIndex } from '../../actions/productActions';
import LoadingBox from '../LoadingBox';
import MessageBox from '../MessageBox';

export default function NominalWidget(props) {

    const [screenVisible, setScreenVisible] = useState(false);
    const productIndex = useSelector(state => state.productIndex);
    const { loading, error, result } = productIndex;
    const dispatch = useDispatch();

    useKeyPress('Escape', () => {
        setScreenVisible(false);
    });

    const textString = (input) => {
        if (input) {
            var regexConst = new RegExp("[a-zA-ZÀ-ž\u0370-\u03FF\u0400-\u04FF]+");
            const matches = input.match(regexConst);
            return matches && matches.length > 0 ? matches[0] : "";
        }
        return "";
    }

    const searchIndexHandler = (e) => {
        e.preventDefault();
        const indexStr = textString(props.value.toString());
        dispatch(searchIndex(indexStr));
        setScreenVisible(true);
    }
    return (
        <>
            {screenVisible &&
                <div className="bg-black bg-opacity-70 h-full w-full fixed z-10 top-0 left-0 overflow-y-scroll">
                    <button className="transition duration-300 ease-in-out absolute right-0 text-white focus:outline-none pt-2 px-2 md:pt-10 md:px-10 hover:opacity-60" onClick={() => setScreenVisible(false)}><FontAwesomeIcon icon="times" size="2x" /></button>
                    <div className="p-5 md:p-40 relative top-1/4 -mt-32 md:-mt-20 overlay-content text-center">
                        {loading ? <LoadingBox />
                            : error ? <MessageBox hideCloseButton={true}>{error}</MessageBox>
                                : <div className="bg-white p-3 rounded-lg text-gray-500" dangerouslySetInnerHTML={{ __html: result }}></div>
                        }
                    </div>
                </div>}
                <div className="font-semibold col-span-2">{props.keyx}</div><div className="col-span-4"><span onClick={searchIndexHandler} className="text-blue-500 underline cursor-pointer">{props.value.toString()}</span></div> 

        </>
    )
}
