import React from 'react'

export default function SubcategoryBottomText(props) {
    return (
        <div className="pt-20 ">
            <div className="tracking-widest font-bold ">{props.title}</div>
            <div className="text-xs md:text-sm text-gray-600 py-10 ">
                {props.description}
            </div>
        </div>
    )
}
