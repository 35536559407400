import Axios from "axios";
import { ORDER_CREATE_FAIL, ORDER_CREATE_REQUEST, ORDER_CREATE_SUCCESS, ORDER_DELETE_FAIL, ORDER_DELETE_REQUEST, ORDER_DELETE_SUCCESS, ORDER_SEND_FAIL, ORDER_SEND_REQUEST, ORDER_SEND_SUCCESS, ORDER_DETAILS_FAIL, ORDER_DETAILS_REQUEST, ORDER_DETAILS_SUCCESS, ORDER_LIST_FAIL, ORDER_LIST_REQUEST, ORDER_LIST_SUCCESS, ORDER_PAYMENT_FAIL, ORDER_PAYMENT_REQUEST, ORDER_PAYMENT_SUCCESS, ORDER_SELF_LIST_FAIL, ORDER_SELF_LIST_REQUEST, ORDER_SELF_LIST_SUCCESS, ORDER_CONFIRM_REQUEST, ORDER_CONFIRM_FAIL, ORDER_CONFIRM_SUCCESS, ORDER_CONFIRM_NO_EMAIL_REQUEST, ORDER_CONFIRM_NO_EMAIL_FAIL, ORDER_CONFIRM_NO_EMAIL_SUCCESS} from "../constants/orderConstants"
import { CART_EMPTY } from "../constants/cartConstants"

export const createOrder = (order) => async (dispatch, getState) => {
    dispatch({ type: ORDER_CREATE_REQUEST, payload: order });
    try {
        const { userSignin: { userInfo } } = getState();
        const { data } = await Axios.post('/api/orders', order, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        });
        dispatch({ type: ORDER_CREATE_SUCCESS, payload: data.order });
        dispatch({ type: CART_EMPTY });
        localStorage.removeItem('cartItems');

    } catch (error) {
        dispatch({
            type: ORDER_CREATE_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        });
    }
};

export const detailsOrder = (orderId) => async (dispatch, getState) => {
    dispatch({ type: ORDER_DETAILS_REQUEST, payload: orderId });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.get(`/api/orders/${orderId}`, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: ORDER_DETAILS_SUCCESS, payload: data });
    } catch (error) {
        const message = error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        dispatch({ type: ORDER_DETAILS_FAIL, payload: message });

    }
};

export const payOrder = (orderId) => async (dispatch, getState) => {
    dispatch({ type: ORDER_PAYMENT_REQUEST, payload: orderId });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = Axios.put(`/api/orders/${orderId}/pay`, {}, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: ORDER_PAYMENT_SUCCESS, payload: data });

    } catch (error) {
        const message = error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        dispatch({ type: ORDER_PAYMENT_FAIL, payload: message });
    }
};

export const makeGreen = (orderId, trackingNumber) => async (dispatch, getState) => {
    dispatch({ type: ORDER_CONFIRM_NO_EMAIL_REQUEST, payload: orderId });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.put(`/api/orders/${orderId}/noemailconfirm`, { trackingNumber }, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: ORDER_CONFIRM_NO_EMAIL_SUCCESS, payload: data });
    } catch (error) {
        const message = error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        dispatch({ type: ORDER_CONFIRM_NO_EMAIL_FAIL, payload: message });
    }
};


export const listOrderSelf = () => async (dispatch, getState) => {
    dispatch({ type: ORDER_SELF_LIST_REQUEST });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.get('/api/orders/self', {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: ORDER_SELF_LIST_SUCCESS, payload: data });

    } catch (error) {
        const message = error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        dispatch({ type: ORDER_SELF_LIST_FAIL, payload: message });
    }

};

export const listOrders = ({ id = '', name = '', surname = '', city = '', minPrice = 0, maxPrice = 0, dateFrom = '', dateTo = '', paymentMethod = '', paid = '', sent = '', confirmed = '', pageNumber = '' }) => async (dispatch, getState) => {
    dispatch({ type: ORDER_LIST_REQUEST });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.get(`/api/orders?id=${id}&name=${name}&surname=${surname}&city=${city}&minPrice=${minPrice}&maxPrice=${maxPrice}&dateFrom=${dateFrom}&dateTo=${dateTo}&paymentMethod=${paymentMethod}&paid=${paid}&sent=${sent}&confirmed=${confirmed}&pageNumber=${pageNumber}`, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: ORDER_LIST_SUCCESS, payload: data });
    } catch (error) {
        const message = error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        dispatch({ type: ORDER_LIST_FAIL, payload: message });
    }
};

export const deleteOrder = (orderId) => async (dispatch, getState) => {
    dispatch({ type: ORDER_DELETE_REQUEST, payload: orderId });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.delete(`/api/orders/${orderId}`, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: ORDER_DELETE_SUCCESS, payload: data });
    } catch (error) {
        const message = error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        dispatch({ type: ORDER_DELETE_FAIL, payload: message });
    }
};

export const sendOrder = (orderId) => async (dispatch, getState) => {
    dispatch({ type: ORDER_SEND_REQUEST, payload: orderId });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.put(`/api/orders/${orderId}/send`, {}, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: ORDER_SEND_SUCCESS, payload: data });
    } catch (error) {
        const message = error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        dispatch({ type: ORDER_SEND_FAIL, payload: message });
    }
};

export const confirmOrder = (orderId, trackingNumber) => async (dispatch, getState) => {
    dispatch({ type: ORDER_CONFIRM_REQUEST, payload: orderId });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.put(`/api/orders/${orderId}/confirm`, { trackingNumber }, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: ORDER_CONFIRM_SUCCESS, payload: data });
    } catch (error) {
        const message = error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        dispatch({ type: ORDER_CONFIRM_FAIL, payload: message });
    }
};

export const modalOrder = (orderId) => async (dispatch, getState) => {
    dispatch({ type: ORDER_DETAILS_REQUEST, payload: orderId });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.get(`/api/orders/modal/${orderId}`, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: ORDER_DETAILS_SUCCESS, payload: data });
    } catch (error) {
        const message = error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        dispatch({ type: ORDER_DETAILS_FAIL, payload: message });

    }
};

export const paypalOrder = (order, payment) => async (dispatch, getState) => {
    dispatch({ type: ORDER_CREATE_REQUEST, payload: order });
    try {
        const { userSignin: { userInfo } } = getState();
        const { data } = await Axios.post(`/api/orders/paypal/`, { order, payment }, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        });
        dispatch({ type: ORDER_CREATE_SUCCESS, payload: data.order });
        dispatch({ type: CART_EMPTY });
        localStorage.removeItem('cartItems');

    } catch (error) {
        dispatch({
            type: ORDER_CREATE_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        });
    }
};
