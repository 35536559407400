import React from 'react'
import { Link } from 'react-router-dom'

export default function VerticalTileVisible(props) {
    return (
        <div className="h-full w-full items-center justify-center flex flex-col md:flex-row 
        ">
            <Link to={props.buttons[0].link} className="md:w-1/2 justify-center flex flex-col items-center p-3 md:p-4 md:h-full space-y-2 md:space-y-5 md:p-10">
              <div className="text-center w-full tracking-widest text-xl md:text-3xl uppercase font-semibold">{props.title}</div>
              <hr className="w-full md:w-1/2" />
              <div className="whitespace-pre-line text-xs md:text-base">{props.description}</div>
            </Link>
            <div className="md:w-1/2 md:flex md:flex-col md:p-1 space-y-3">
              {props.buttons.map((item, i) => (
                <Link key={i} to={item.link} className="md:transform md:hover:-translate-x-2 rounded md:transition md:duration-500 md:ease-in-out flex flex-row bg-gray-50 border w-full space-x-2  md:space-x-10 items-center">
                  <img className="border  h-32" src={item.image} alt="img" />
                  <div className="md:w-1/2 md:space-y-0 space-y-2">
                    <div className="text-sm  md:text-base font-bold md:px-0 px-2">{item.name}</div>
                    <p className="text-xs md:text-sm text-gray-500 md:px-0 px-2 ">{item.description}</p>
                  </div>
                </Link>
              ))}
            </div>
          </div>
    )
}
