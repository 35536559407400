import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function JsonKeyValueInsert(props) {

    const [key, setKey] = useState("");
    const [value, setValue] = useState("");

    const insertEntry = (e) => {
        e.preventDefault();
        var details = props.detail || {};
        details[key] = value;
        props.onSelectDetail(details);
        setKey("");
        setValue("");
    };

    return (
        <>
            <div className="space-x-4">
                <input className="w-40 border h-10 border-grey-light rounded px-3 relative" type="text" value={key || ""} onChange={(e) => setKey(e.target.value)} />
                <input className="w-96 border h-10 border-grey-light rounded px-3 relative" type="text" value={value || ""} onChange={(e) => setValue(e.target.value)} />
                <button onClick={(e) => insertEntry(e)} className="bg-blue-500 p-2 rounded hover:bg-blue-400"><FontAwesomeIcon color="white" icon="plus" /></button>
            </div>
        </>
    )
}
