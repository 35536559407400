import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { cartReducer, cartAvailableReducer } from "./reducers/cartReducers";
import { metalReducer } from "./reducers/metalReducers";

import {
    orderConfirmReducer,
    orderConfirmNoMailReducer,
    orderCreateReducer,
    orderDeleteReducer,
    orderSendReducer,
    orderDetailsReducer,
    orderListReducer,
    orderPayReducer,
    orderSelfListReducer,
} from "./reducers/orderReducers";
import {
    productCreateReducer,
    productDeleteReducer,
    productDetailsReducer,
    productFilterReducer,
    productImportReducer,
    productIndexReducer,
    productListReducer,
    productLiteratureReducer,
    productNewestReducer,
    productSearchReducer,
    productUpdateReducer,
    productZeroReducer,
} from "./reducers/productReducers";
import {
    userContactFormReducer,
    userDeleteReducer,
    userDetailsReducer,
    userForgotPassReducer,
    userGuestReducer,
    userListReducer,
    userRegisterReducer,
    userResetPassReducer,
    userSellItemReducer,
    userSendQuestionReducer,
    userSigninReducer,
    userUpdateProfileReducer,
    userUpdateReducer,
} from "./reducers/userReducers";
import {
    wishListSelfReducer,
    wishCreateReducer,
    wishDeleteReducer,
    wishListReducer,
    wishReducer,
} from "./reducers/wishReducers";
import { ppAssertReducer, ppInitReducer } from "./reducers/transactionReducers";
import { collectiblesReducer } from "./reducers/collectibleReducers";
import { catalogDetailsReducer, catalogListReducer } from "./reducers/catalogReducers";

const initialState = {
    userSignin: {
        userInfo: localStorage.getItem("userInfo")
            ? JSON.parse(localStorage.getItem("userInfo"))
            : null,
    },
    cart: {
        cartItems: localStorage.getItem("cartItems")
            ? JSON.parse(localStorage.getItem("cartItems"))
            : [],
        shippingInfo: localStorage.getItem("shippingInfo")
            ? JSON.parse(localStorage.getItem("shippingInfo"))
            : {},
    },

    wishes_cached: {
        wishlistItems: localStorage.getItem("wishlistItems")
            ? JSON.parse(localStorage.getItem("wishlistItems"))
            : [],
    },
};
const reducer = combineReducers({
    cart: cartReducer,
    cartAvailable: cartAvailableReducer,
    orderCreate: orderCreateReducer,
    orderDetails: orderDetailsReducer,
    orderPay: orderPayReducer,
    orderSelfList: orderSelfListReducer,
    orderConfirm: orderConfirmReducer,
    orderConfirmNoMail: orderConfirmNoMailReducer,
    orderDelete: orderDeleteReducer,
    orderSend: orderSendReducer,
    orderList: orderListReducer,
    userDetails: userDetailsReducer,
    userUpdateProfile: userUpdateProfileReducer,
    userRegister: userRegisterReducer,
    userSignin: userSigninReducer,
    userList: userListReducer,
    userDelete: userDeleteReducer,
    userUpdate: userUpdateReducer,
    userGuest: userGuestReducer,
    userForgotPass: userForgotPassReducer,
    userResetPass: userResetPassReducer,
    userSendQuery: userSendQuestionReducer,
    userSellItem: userSellItemReducer,
    userContactForm: userContactFormReducer,
    productList: productListReducer,
    productDetails: productDetailsReducer,
    productCreate: productCreateReducer,
    productUpdate: productUpdateReducer,
    productDelete: productDeleteReducer,
    productImport: productImportReducer,
    productLiterature: productLiteratureReducer,
    productSearch: productSearchReducer,
    productIndex: productIndexReducer,
    productZero: productZeroReducer,
    productNewest: productNewestReducer,
    productFilter: productFilterReducer,
    wishes_cached: wishReducer,
    wishListSelf: wishListSelfReducer,
    wishCreate: wishCreateReducer,
    wishDelete: wishDeleteReducer,
    wishList: wishListReducer,
    metals: metalReducer,
    ppInit: ppInitReducer,
    ppAssert: ppAssertReducer,
    collectibles: collectiblesReducer,
    catalogList : catalogListReducer,
    catalogDetail: catalogDetailsReducer,
    
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const composeEnhancer = compose;

const store = createStore(
    reducer,
    initialState,
    composeEnhancer(applyMiddleware(thunk))
);


export default store;
