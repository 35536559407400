import React from 'react'
import { Link } from 'react-router-dom';

export default function LinkedWidget({title, value, category, searchKey, searchValue}) {
    return (
        <>
        
            <div className="font-semibold col-span-2">{title}</div><div className="col-span-4 flex space-x-1 ">
               <Link to={`/category/${category ? category.toLowerCase() : "all"}/?${searchKey}=${value}`} className="text-blue-500 hover:underline cursor-pointer">{value}</Link>
            </div>
        </>
    )
}
