import React from 'react';
import { useTranslation } from 'react-i18next';
import NavbarChild from './NavbarChild';
import NavbarParent from './NavbarParent';

export default function MainNavbar(props) {
    const { t } = useTranslation(["navbar"]);
    return (
        <>
            <nav className={"flex flex-wrap items-center justify-between px-2 py-3 w-full md:flex" + (props.navbarOpen ? " flex" : " hidden")}>
                <div className="container  mx-auto flex flex-wrap items-center justify-between">
                    <div className={"flex flex-grow items-center justify-center flex-col"}>
                        <ul className="flex flex-col md:flex-row list-none w-full md:w-auto md:tracking-widest text-justify">
                            <NavbarParent link={"/category/muenzen/"} title={t("coins", "Münzen")}>
                                <NavbarChild link={"/category/altdeutsche_muenzen/"} title={t("old_german_coins", "Altdeutsche Münzen")} />
                                <NavbarChild link={"/category/antike_muenzen/"} title={t("ancient_coins", "Antike Münzen")} />
                                <NavbarChild link={"/category/auslaendische_muenzen/"} title={t("foreign_coins", "Ausländische Münzen")} />
                                <NavbarChild link={"/category/bullion_muenzen/"} title={t("bullion_coins", "Bullion Münzen")} />
                                <NavbarChild link={"/category/deutschland_1871_muenzen/"} title={t("germany_1871_coins", "Deutschland ab 1871 Münzen")} />
                            </NavbarParent>
                            <NavbarParent link={"/category/medaillen/"} title={t("medals", "Medaillen")}>
                                <NavbarChild link={"/category/auslaendische_medaillen/"} title={t("foreign_medals", "Ausländische Medaillen")} />
                                <NavbarChild link={"/category/deutsche_medaillen/"} title={t("german_medals", "Deutsche Medaillen")} />
                                <NavbarChild link={"/category/karl_goetz_medaillen/"} title={t("karl_goetz_medals", "Medaillen von Karl Goetz")} />
                                <NavbarChild link={"/category/thematische_medaillen/"} title={t("themed_medals", "Thematische Medaillen")} />
                            </NavbarParent>
                            <NavbarParent link={"/category/banknoten/"} title={t("banknotes", "Banknoten")}>
                                <NavbarChild link={"/category/auslaendische_banknoten/"} title={t("foreign_banknoten", "Ausländische Banknoten")} />
                                <NavbarChild link={"/category/deutschland_banknoten/"} title={t("german_banknoten", "Deutsche Banknoten")} />
                            </NavbarParent>
                            <NavbarParent link={"/category/numismatische_literatur"} title={t("numlit", "Numismatische Literatur")}></NavbarParent>
                        <div>
                            <div className="px-3 py-2 flex items-center text-xs uppercase">
                                <div className="w-full md:w-auto dropdown md:hover:bg-gray-100 md:px-3 md:py-2 rounded transition duration-300 ease-in-out">
                                    <a href="//mgm-schmuck.de">Schmuck</a>
                                </div>
                            </div>
                        </div>
                        </ul>

                        <ul className="flex flex-col md:flex-row list-none w-full md:w-auto md:tracking-widest text-justify">
                            <NavbarParent bold link={"/kataloge/662152fb80d51baadade01f5"} title="Intermünz-Kurier 193" sup={"NEU"} />
                            <NavbarParent bold link={"/kataloge"} title={t("catalogs", "Kataloge")} />
                            {/* <NavbarParent bold link={"/world"} title={t("worldmapview", "Weltkartensuche")}/> */}

                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}