import React, { useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import LoadingBox from './LoadingBox';

const PaypalButton = props => {
  const [sdkReady, setSdkReady] = useState(false);

  const addPaypalSdk = () => {
    const clientID = 'AYyse1rkitp4vREXH7S8DlsVH8tiXZab5pv9VClWetNpniYv2nps5wzqrJvPs81YmypKaYAddeoZi1Vc';
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://www.paypal.com/sdk/js?client-id=${clientID}&currency=EUR`;
    script.async = true;
    script.onload = () => {
      setSdkReady(true);
    };
    script.onerror = () => {
      throw new Error('Paypal SDK could not be loaded.');
    };

    document.body.appendChild(script);
  };

  useEffect(() => {
    if (window !== undefined && window.paypal === undefined) {
      addPaypalSdk();
    } else if (
      window !== undefined &&
      window.paypal !== undefined &&
      props.onButtonReady
    ) {
      props.onButtonReady();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  //amount goes in the value field we will use props of the button for this   
  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            currency_code: 'EUR',
            value: props.amount,
          }
        }
      ],
      application_context: {
          shipping_preference: 'NO_SHIPPING'
      }
    });
  };

  const onApprove = (data, actions) => {
    return actions.order
      .capture()
      .then(details => {
        if (props.onSuccess) {
          return props.onSuccess(data);
        }
      })
      .catch(err => {
        console.log(err)
      });
  };

  if (!sdkReady && window.paypal === undefined) {
    return (
      <div><LoadingBox/></div>
    );
  }

  const Button = window.paypal.Buttons.driver('react', {
    React,
    ReactDOM
  });

  //you can set your style to whatever read the documentation for different styles I have put some examples in the style tag
  return (
    <Button
      {...props}
      createOrder={
        props.amount && !createOrder
          ? (data, actions) => createOrder(data, actions)
          : (data, actions) => createOrder(data, actions)
      }
      onApprove={
        props.onSuccess
          ? (data, actions) => onApprove(data, actions)
          : (data, actions) => onApprove(data, actions)
      }

      style={{
        layout: 'horizontal',
        color: 'black',
        label: 'pay',
        size: 'responsive',

      }}

      fundingSource={window.paypal.FUNDING.PAYPAL} 
    />
  );
};

export default PaypalButton;
