import ReactGA from "react-ga";


export function sendEvent(payload) {
    ReactGA.event(payload)
}

export function sendPageview(path) {
    ReactGA.set({ page: path })
    ReactGA.pageview(path)
}

const analytics = {
    sendEvent,
    sendPageview,
}
export default analytics;