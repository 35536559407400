import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { removeFromCart, saveShippingInfo, checkCartAvailable } from "../actions/cartActions";
import CheckoutSteps from "../components/CheckoutSteps";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountryOptions from "../components/CountryOptions";
import { Link } from "react-router-dom";
import { createOrder, paypalOrder } from "../actions/orderActions";
import { ORDER_CREATE_RESET } from "../constants/orderConstants";
import currencyFormat from "../utils/currency";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import { shippingCourierDisplay, shippingDurationDisplay, shippingPriceDisplay } from "../utils/payment";
import { payments, shippings } from "../utils/enum";
import { PP_INIT_RESET } from "../constants/transactionConstants";
import { paymentPageInit } from '../actions/transactionActions';
import useBackButton from "../components/useBackButton";
import PaypalButton from "../components/PaypalButton";

export default function ShippingInfoScreen(props) {
    const { t } = useTranslation(["shipping"]);

    const userSignin = useSelector((state) => state.userSignin);
    const { userInfo } = userSignin;
    const cart = useSelector((state) => state.cart);
    const { shippingInfo } = cart;


    const [firstName, setFirstName] = useState(shippingInfo.firstName || "");
    const [lastName, setLastName] = useState(shippingInfo.lastName || "");
    const [company, setCompany] = useState(shippingInfo.company || "");
    const [email, setEmail] = useState(shippingInfo.email || "");
    const [phone, setPhone] = useState(shippingInfo.phone || "");
    const [address, setAddress] = useState(shippingInfo.address || "");
    const [addressExtra, setAddressExtra] = useState(shippingInfo.addressExtra || "");
    const [city, setCity] = useState(shippingInfo.city || "");
    const [postalCode, setPostalCode] = useState(shippingInfo.postalCode || "");
    const [country, setCountry] = useState(shippingInfo.country || "Deutschland");

    const [deliveryAddressVisible, setDeliveryAddressVisible] = useState(shippingInfo.deliveryAddressVisible || false);

    const [deliveryFirstName, setDeliveryFirstName] = useState(shippingInfo.deliveryFirstName || "");
    const [deliveryLastName, setDeliveryLastName] = useState(shippingInfo.deliveryLastName || "");
    const [deliveryCompany, setDeliveryCompany] = useState(shippingInfo.deliveryCompany || "");
    const [deliveryEmail, setDeliveryEmail] = useState(shippingInfo.deliveryEmail || "");
    const [deliveryPhone, setDeliveryPhone] = useState(shippingInfo.deliveryPhone || "");
    const [deliveryAddress, setDeliveryAddress] = useState(shippingInfo.deliveryAddress || "");
    const [deliveryAddressExtra, setDeliveryAddressExtra] = useState(shippingInfo.deliveryAddressExtra || "");
    const [deliveryCity, setDeliveryCity] = useState(shippingInfo.deliveryCity || "");
    const [deliveryPostalCode, setDeliveryPostalCode] = useState(shippingInfo.deliveryPostalCode || "");
    const [deliveryCountry, setDeliveryCountry] = useState(shippingInfo.deliveryCountry || "Deutschland");

    const [paymentMethod, setPaymentMethod] = useState(shippingInfo.paymentMethod || payments.VORKASSE);
    const [shippingMethod, setShippingMethod] = useState(shippingInfo.shippingMethod || shippings.STANDARD);
    const [shippingPrice, setShippingPrice] = useState(5);

    const [shippingDuration, setShippingDuration] = useState("1-3");
    const [shippingCourier, setShippingCourier] = useState("DHL Standard");

    const orderCreate = useSelector(state => state.orderCreate);
    const { loading: loadingOrder, success: successOrder, error: errorOrder, order } = orderCreate;

    const ppInit = useSelector(state => state.ppInit);
    const { loading: loadingPPInit, success: successPPInit, error: errorPPInit, transaction } = ppInit;


    const dispatch = useDispatch();
    const submitHandler = (e) => {
        e.preventDefault();

        const orderJson = generateOrderJson();

        switch (paymentMethod) {
            case payments.CASH:
            case payments.VORKASSE:
                dispatch(createOrder(orderJson));
                break;
            case payments.AMEX:
            case payments.MASTERCARD:
            case payments.VISA:
                dispatch(paymentPageInit(orderJson));
                break;
            default:
                break;
        }
    }

    const generateOrderJson = () => {
        const billingInfo = { firstName, lastName, company, email, phone, address, addressExtra, country, postalCode, city };
        const deliveryInfo = deliveryAddressVisible ? { deliveryFirstName, deliveryLastName, deliveryCompany, deliveryEmail, deliveryPhone, deliveryAddress, deliveryAddressExtra, deliveryCountry, deliveryPostalCode, deliveryCity } : {}
        cart.itemsPrice = cart.cartItems.reduce((a, c) => a + c.qty * c.price, 0);
        cart.totalPrice = cart.itemsPrice + shippingPrice;
        dispatch(saveShippingInfo(
            { ...billingInfo, ...deliveryInfo })
        );

        const orderJson = {
            orderItems: cart.cartItems,
            shippingInfo: { ...billingInfo, ...deliveryInfo, paymentMethod, shippingMethod },
            itemsPrice: cart.itemsPrice,
            shippingPrice: shippingPrice,
            totalPrice: cart.totalPrice,
            lng: localStorage.getItem("lngCode") ? localStorage.getItem("lngCode") : "de"
        };

        return orderJson;
    }
    const paymentHandler = (e, method) => {
        e.preventDefault();
        setPaymentMethod(method);
        if (method === payments.CASH) {
            shippingHandler(e, shippings.SELF_PICKUP)
        } else {
            shippingHandler(e, shippings.STANDARD)
        }
    }

    const shippingHandler = (e, method) => {
        e.preventDefault();
        setShippingMethod(method);
    }

    const deliveryAddressVisibleHandler = (e) => {
        setDeliveryAddressVisible(!deliveryAddressVisible)
    }

    useBackButton(() => {
        props.history.go(-1);
    })

    const onSuccess = payment => {
        const orderJson = generateOrderJson();
        dispatch(paypalOrder(orderJson, payment));
        console.log("success complete");
    }

    const onCancel = data => {
        console.log("pay cancel")
    };

    const onError = err => {
        console.log("pay error");
    };

    const removeFromCartHandler = (id) => {
        dispatch(removeFromCart(id));
    };

    useEffect(() => {
        dispatch({ type: PP_INIT_RESET });


        cart.itemsPrice = cart.cartItems.reduce((a, c) => a + c.qty * c.price, 0);

        if (!userInfo) {
            props.history.push('/signin');
        }

        if (cart.cartItems.length < 1) {
            props.history.push('/cart');
        }

        if (shippingMethod === shippings.STANDARD) {
            setShippingPrice(shippingPriceDisplay(country, deliveryAddressVisible, deliveryCountry, cart.itemsPrice));
        } else {
            setShippingPrice(0);
        }


        setShippingCourier(shippingCourierDisplay(country, deliveryAddressVisible, deliveryCountry));
        setShippingDuration(shippingDurationDisplay(country, deliveryAddressVisible, deliveryCountry));

        if (successPPInit) {
            window.location.replace(transaction.redirectUrl);
            dispatch({ type: PP_INIT_RESET });
        }
        if (successOrder) {
            props.history.push(`/order/${order._id}`);
            dispatch({ type: ORDER_CREATE_RESET });
            return;
        }
        if (!loadingOrder) {
            dispatch(checkCartAvailable(cart.cartItems));
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, order, props.history, successOrder, successPPInit, userInfo, country, shippingMethod, deliveryCountry, deliveryAddressVisible, cart]);

    return (
        loadingOrder || loadingPPInit ? <LoadingBox /> :
            <div className="flex flex-col justify-center px-5p items-center">
                <div className="flex flex-col md:flex-row justify-between w-full">
                    <CheckoutSteps step1 step2></CheckoutSteps>
                </div>

                <div className="px-5p w-full">
                    {(loadingOrder || loadingPPInit) && <LoadingBox></LoadingBox>}
                    {errorOrder && <MessageBox variant="danger">{errorOrder}</MessageBox>}
                    {errorPPInit && <MessageBox variant="danger">{errorPPInit}</MessageBox>}
                    <div className="flex flex-col md:flex-row justify-center ">
                        <div className="overflow-x-auto w-full">
                            <div className="py-2 align-middle inline-block min-w-full space-y-2">
                                <div className="border overflow-hidden border-b border-gray-200 rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    {t("item", "Artikel")}
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden md:table-cell">
                                                    {t("qty", "Menge")}
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden md:table-cell">
                                                    {t("single_price", "Einzelpreis")}
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden md:table-cell">
                                                    {t("total_price", "Gesamtpreis")} 
                                                </th>
                                                <th scope="col" className="relative px-6 py-3 hidden md:table-cell">
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {
                                                cart.cartItems.map((item) => (
                                                    <tr key={item.product}>
                                                        <td className="px-3 md:px-6 py-1 md:py-4 whitespace-nowrap block md:table-cell">
                                                            <div className="flex items-center">
                                                                <div className="flex-shrink-0 h-10 w-10">

                                                                    <Link to={`/product/${item.product}`}><img className="h-10 w-10 rounded-full" src={item.image} alt={item.name} /></Link>
                                                                </div>
                                                                <div className="ml-4">
                                                                    <Link to={`/product/${item.product}`}>
                                                                        <div className="text-sm font-medium text-gray-900">
                                                                            {item.brand}
                                                                        </div>
                                                                        <div className="text-sm text-gray-500">
                                                                            {item.name}
                                                                        </div>
                                                                        <div className="text-sm text-gray-900">
                                                                            {item.item_number}
                                                                        </div>

                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="px-3 md:px-6 py-1 md:py-4  text-xs whitespace-nowrap block  md:table-cell">
                                                            <div className="flex justify-between"><div className=" whitespace-nowrap text-sm text-gray-500 inline: md:hidden">Menge:</div>

                                                                {item.qty}
                                                            </div>
                                                        </td>
                                                        <td className="px-3 md:px-6 py-1 md:py-4  whitespace-nowrap text-sm text-gray-500 text-right md:text-left block md:table-cell">
                                                            <div className="flex justify-between"><div className="inline: md:hidden">{t("single_price", "Einzelpreis")}:</div> <div>{currencyFormat(item.price)}</div> </div>
                                                        </td>
                                                        <td className="px-3 md:px-6 py-1 md:py-4  whitespace-nowrap text-sm text-right md:text-left text-gray-500 block md:table-cell">
                                                            <div className="flex justify-between"><div className="inline: md:hidden">{t("total_price", "Gesamtpreis")}:</div> <div>{currencyFormat(item.price * item.qty)}</div> </div>
                                                        </td>
                                                        <td className="px-6 py-1 md:py-4  whitespace-nowrap text-sm font-medium text-right md:text-left justify-between block  md:table-cell">
                                                            <button className="transition duration-300 ease-in-out text-red-600 hover:text-red-900" type="button" onClick={() => removeFromCartHandler(item.product)}><FontAwesomeIcon icon="times" /></button>
                                                        </td>
                                                    </tr>
                                                )
                                                )}

                                        </tbody>
                                    </table>
                                </div>
                                <div className="border sticky rounded-lg bg-gray-50">
                                    <div className="flex">
                                        <div className="flex-grow p-2 items-center font-medium uppercase tracking-wider select-none flex">
                                            <span className="w-full text-sm text-right">
                                                {t("shipping", "Versand")}:</span>
                                            <span className="px-2 font-bold text-sm  text-right">{currencyFormat(shippingPrice)}</span>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="flex-grow p-2 items-center font-medium uppercase tracking-wider select-none flex">
                                            <span className="w-full text-sm text-right">
                                                {t("total_sum", "Gesamtsumme")} ({t("taxes_incl", "inkl. MwSt")}):</span>
                                            <span className="px-2 font-bold text-base text-right">{currencyFormat(cart.cartItems.reduce((a, c) => a + c.price * c.qty, 0) + (shippingMethod === shippings.STANDARD ? shippingPrice : 0))}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full space-y-8">
                        <form className="mt-8 space-y-6" onSubmit={submitHandler}>
                            <div className="flex flex-col space-y-4  divide-y">
                                <div className="rounded-md flex-grow space-y-4 text-sm ">
                                    <label className="uppercase font-bold py-2">{t("billing_address", "Rechnungsanschrift")}</label>

                                    <div className="space-y-3">
                                        <div className="flex flex-col md:flex-row justify-between md:space-x-6 space-y-2 md:space-y-0 ">

                                            <div className="w-full flex flex-col md:flex-row justify-between md:space-x-6 space-y-2 md:space-y-0 ">
                                                <div className="w-full required-wrapper">
                                                    <label htmlFor="first_name" >{t("first_name", "Vorname")}</label>
                                                    <input value={firstName} id="first_name" type="text" onChange={(e) => setFirstName(e.target.value)} className="appearance-none  relative block w-full px-3 py-2 border border-gray-300  text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" required />
                                                </div>
                                                <div className="w-full required-wrapper">
                                                    <label htmlFor="last_name" >{t("last_name", "Nachname")}</label>
                                                    <input value={lastName} id="last_name" type="text" onChange={(e) => setLastName(e.target.value)} className="appearance-none relative block w-full px-3 py-2 border border-gray-300  text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" required />
                                                </div>
                                            </div>
                                            <div className="w-full">
                                                <label htmlFor="company">{t("company", "Firma")}</label>
                                                <input value={company} id="company" type="text" onChange={(e) => setCompany(e.target.value)} className="appearance-none  relative block w-full px-3 py-2 border border-gray-300  text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" />
                                            </div>
                                        </div>
                                        <div className="flex flex flex-col md:flex-row justify-between md:space-x-6 space-y-2 md:space-y-0 ">
                                            <div className="w-full required-wrapper">
                                                <label htmlFor="email">{t("email", "E-Mail Adresse")}</label>
                                                <input value={email} id="email" type="email" onChange={(e) => setEmail(e.target.value)} className="appearance-none relative block w-full px-3 py-2 border border-gray-300 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" required />
                                            </div>
                                            <div className="w-full">
                                                <label htmlFor="phone">{t("phone", "Telefonnummer")}</label>
                                                <input value={phone} id="phone" type="tel" onChange={(e) => setPhone(e.target.value)} className="appearance-none relative block w-full px-3 py-2 border border-gray-300 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" />
                                            </div>
                                        </div>
                                        <div className="flex flex flex-col md:flex-row justify-between md:space-x-6 space-y-2 md:space-y-0 ">
                                            <div className="w-full required-wrapper">
                                                <label htmlFor="address" >{t("address", "Adresse")}</label>
                                                <input value={address} id="address" type="text" onChange={(e) => setAddress(e.target.value)} className="appearance-none  relative border- block w-full px-3 py-2 border border-gray-300  text-gray-900  focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" required />
                                            </div>
                                            <div className="w-full">
                                                <label htmlFor="addressExtra" >{t("addressExtra", "Adresszusatz")}</label>
                                                <input value={addressExtra} id="addressExtra" type="text" onChange={(e) => setAddressExtra(e.target.value)} className="appearance-none  relative border- block w-full px-3 py-2 border border-gray-300  text-gray-900  focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" />
                                            </div>
                                        </div>
                                        <div className="flex flex flex-col md:flex-row justify-between md:space-x-6 space-y-2 md:space-y-0 ">
                                            <div className="w-full required-wrapper">
                                                <label htmlFor="country">{t("country", "Land")}</label>
                                                <select onChange={(e) => setCountry(e.target.value)} defaultValue={country || "Deutschland"} id="country" name="country" className="appearance-none  relative border- block w-full px-3 py-2 border border-gray-300  text-gray-900  focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" >
                                                    <CountryOptions></CountryOptions>
                                                </select>
                                            </div>
                                            <div className="w-full flex flex flex-col md:flex-row justify-between space-y-2 md:space-y-0 md:space-x-6">
                                                <div className="w-full required-wrapper">
                                                    <label htmlFor="plz">{t("plz", "Postleitzahl")}</label>
                                                    <input value={postalCode} id="plz" type="text" onChange={(e) => setPostalCode(e.target.value)} className="appearance-none  relative block w-full px-3 py-2 border border-gray-300  text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" required />
                                                </div>
                                                <div className="w-full required-wrapper">
                                                    <label htmlFor="city">{t("city", "Stadt")}</label>
                                                    <input value={city} id="city" type="text" onChange={(e) => setCity(e.target.value)} className="appearance-none relative block w-full px-3 py-2 border border-gray-300  text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" required />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex md:flex-row flex-col-reverse items-center justify-between">
                                        <div className="flex flex-row">
                                            <label className="inline-flex items-center mt-3">
                                                <input checked={deliveryAddressVisible} onChange={deliveryAddressVisibleHandler} type="checkbox" className="form-checkbox h-5 w-5 text-gray-600" /><div className="ml-2 text-gray-700">{t("different_message1", "Ich möchte die Bestellung an eine")} <b>{t("different_message2", "abweichende Lieferadresse")}</b> {t("different_message3", "versenden lassen.")}</div>
                                            </label>
                                        </div>
                                        {!deliveryAddressVisible && <div className="text-sm pt-2 text-gray-400">{t("asterisk_must", "Die mit Stern markierten Felder (*) sind Pflichtfelder.")}</div>}
                                    </div>
                                    {deliveryAddressVisible &&
                                        <div className="rounded-md flex-grow space-y-4 text-sm ">
                                            <label className="uppercase font-bold py-2">{t("delivery_address", "Lieferanschrift")}</label>

                                            <div className="space-y-3">
                                                <div className="flex flex-col md:flex-row justify-between md:space-x-6 space-y-2 md:space-y-0 ">

                                                    <div className="w-full flex flex-col md:flex-row justify-between md:space-x-6 space-y-2 md:space-y-0 ">
                                                        <div className="w-full required-wrapper">
                                                            <label htmlFor="first_name2" >{t("first_name", "Vorname")}</label>
                                                            <input value={deliveryFirstName} id="first_name2" type="text" onChange={(e) => setDeliveryFirstName(e.target.value)} className="appearance-none  relative block w-full px-3 py-2 border border-gray-300  text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" required />
                                                        </div>
                                                        <div className="w-full required-wrapper">
                                                            <label htmlFor="last_name2" >{t("last_name", "Nachname")}</label>
                                                            <input value={deliveryLastName} id="last_name2" type="text" onChange={(e) => setDeliveryLastName(e.target.value)} className="appearance-none relative block w-full px-3 py-2 border border-gray-300  text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" required />
                                                        </div>
                                                    </div>
                                                    <div className="w-full">
                                                        <label htmlFor="company2">{t("company", "Firma")}</label>
                                                        <input value={deliveryCompany} id="company2" type="text" onChange={(e) => setDeliveryCompany(e.target.value)} className="appearance-none  relative block w-full px-3 py-2 border border-gray-300  text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" />
                                                    </div>
                                                </div>
                                                <div className="flex flex flex-col md:flex-row justify-between md:space-x-6 space-y-2 md:space-y-0 ">
                                                    <div className="w-full required-wrapper">
                                                        <label htmlFor="email2">{t("email", "E-Mail Adresse")}</label>
                                                        <input value={deliveryEmail} id="email2" type="email" onChange={(e) => setDeliveryEmail(e.target.value)} className="appearance-none relative block w-full px-3 py-2 border border-gray-300 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" required />
                                                    </div>
                                                    <div className="w-full">
                                                        <label htmlFor="phone2">{t("phone", "Telefonnummer")}</label>
                                                        <input value={deliveryPhone} id="phone2" type="tel" onChange={(e) => setDeliveryPhone(e.target.value)} className="appearance-none relative block w-full px-3 py-2 border border-gray-300 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" />
                                                    </div>
                                                </div>
                                                <div className="flex flex flex-col md:flex-row justify-between md:space-x-6 space-y-2 md:space-y-0 ">
                                                    <div className="w-full required-wrapper">
                                                        <label htmlFor="address2" >{t("address", "Adresse")}</label>
                                                        <input value={deliveryAddress} id="address2" type="text" onChange={(e) => setDeliveryAddress(e.target.value)} className="appearance-none  relative border- block w-full px-3 py-2 border border-gray-300  text-gray-900  focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" required />
                                                    </div>
                                                    <div className="w-full">
                                                        <label htmlFor="addressExtra2" >{t("addressExtra", "Adresszusatz")}</label>
                                                        <input value={deliveryAddressExtra} id="addressExtra2" type="text" onChange={(e) => setDeliveryAddressExtra(e.target.value)} className="appearance-none  relative border- block w-full px-3 py-2 border border-gray-300  text-gray-900  focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" />
                                                    </div>
                                                </div>
                                                <div className="flex flex flex-col md:flex-row justify-between md:space-x-6 space-y-2 md:space-y-0 ">
                                                    <div className="w-full required-wrapper">
                                                        <label htmlFor="country2">{t("country", "Land")}</label>
                                                        <select onChange={(e) => setDeliveryCountry(e.target.value)} defaultValue={deliveryCountry || "Deutschland"} id="country2" name="country2" className="appearance-none  relative border- block w-full px-3 py-2 border border-gray-300  text-gray-900  focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" >
                                                            <CountryOptions></CountryOptions>
                                                        </select>
                                                    </div>
                                                    <div className="w-full flex flex flex-col md:flex-row justify-between space-y-2 md:space-y-0 md:space-x-6">
                                                        <div className="w-full required-wrapper">
                                                            <label htmlFor="plz2">{t("plz", "Postleitzahl")}</label>
                                                            <input value={deliveryPostalCode} id="plz2" type="text" onChange={(e) => setDeliveryPostalCode(e.target.value)} className="appearance-none  relative block w-full px-3 py-2 border border-gray-300  text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" required />
                                                        </div>
                                                        <div className="w-full required-wrapper">
                                                            <label htmlFor="city2">{t("city", "Stadt")}</label>
                                                            <input value={deliveryCity} id="city2" type="text" onChange={(e) => setDeliveryCity(e.target.value)} className="appearance-none relative block w-full px-3 py-2 border border-gray-300  text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" required />
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                            <div className="text-sm float-right pt-2 text-gray-400">{t("asterisk_must", "Die mit Stern markierten Felder (*) sind Pflichtfelder.")}</div>
                                        </div>


                                    }
                                </div>
                                <div className="pt-3 flex flex-col md:space-x-4 divide-x-0 md:divide-x md:flex-row ">
                                    <div className="space-y-4">
                                        <label className="uppercase text-sm font-bold">{t("payment_method", "Zahlungsmethode")}</label>
                                        <div className="block md:hidden w-full">
                                            <select value={paymentMethod} onChange={(e) => paymentHandler(e, e.target.value)} id="paymentMobile" name="paymentMobile" className="appearance-none  relative border- block w-full px-3 py-2 border border-gray-300  text-gray-900  focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" >
                                                <option value={payments.VORKASSE}>{t("payment_in_advance", "Vorkasse")}</option>
                                                <option value={payments.VISA}>Visa</option>
                                                <option value={payments.AMEX}>American Express</option>
                                                <option value={payments.MASTERCARD}>Mastercard</option>
                                                <option value={payments.PAYPAL}>PayPal</option>
                                                <option value={payments.CASH}>{t("cash", "Barzahlung")}</option>
                                            </select>
                                        </div>
                                        <div className="hidden md:flex space-x-4 flex-nowrap">
                                            <button className={paymentMethod && paymentMethod === payments.VORKASSE ? "focus:outline-none cursor-default flex flex-col rounded md:w-28 md:h-32 border items-center justify-between justify-center text-center bg-blue-100" : "focus:outline-none  cursor-pointer flex flex-col rounded md:w-28 md:h-32 border items-center justify-between justify-center text-center hover:bg-gray-50"} onClick={(e) => paymentHandler(e, payments.VORKASSE)}>
                                                <span className="pt-5"><FontAwesomeIcon size="3x" icon={['fas', 'money-check']} /></span>
                                                <span className="pb-7 text-sm">{t("payment_in_advance", "Vorkasse")}</span>
                                            </button>
                                            <button className={paymentMethod && paymentMethod === payments.VISA ? "focus:outline-none cursor-default flex flex-col rounded md:w-28 md:h-32 border items-center justify-between justify-center text-center bg-blue-100" : "focus:outline-none  cursor-pointer flex flex-col rounded md:w-28 md:h-32 border items-center justify-between justify-center text-center hover:bg-gray-50"} onClick={(e) => paymentHandler(e, payments.VISA)}>
                                                <span className="pt-5"><FontAwesomeIcon size="3x" icon={['fab', 'cc-visa']} /></span>
                                                <span className="pb-7 text-sm">Visa</span>
                                            </button>
                                            <button className={paymentMethod && paymentMethod === payments.AMEX ? "focus:outline-none cursor-default flex flex-col rounded md:w-28 md:h-32 border items-center justify-between justify-center text-center bg-blue-100" : "focus:outline-none  cursor-pointer flex flex-col rounded md:w-28 md:h-32 border items-center justify-between justify-center text-center hover:bg-gray-50"} onClick={(e) => paymentHandler(e, payments.AMEX)}>
                                                <span className="pt-5"><FontAwesomeIcon size="3x" icon={['fab', 'cc-amex']} /></span>
                                                <span className="pb-7 text-sm">American<br /> Express</span>
                                            </button>
                                            <button className={paymentMethod && paymentMethod === payments.MASTERCARD ? "focus:outline-none cursor-default flex flex-col rounded md:w-28 md:h-32 border items-center justify-between justify-center text-center bg-blue-100" : "focus:outline-none  cursor-pointer flex flex-col rounded md:w-28 md:h-32 border items-center justify-between justify-center text-center hover:bg-gray-50"} onClick={(e) => paymentHandler(e, payments.MASTERCARD)}>
                                                <span className="pt-5"><FontAwesomeIcon size="3x" icon={['fab', 'cc-mastercard']} /></span>
                                                <span className="pb-7 text-sm">Mastercard</span>
                                            </button>
                                            <button className={paymentMethod && paymentMethod === payments.PAYPAL ? "focus:outline-none cursor-default flex flex-col rounded md:w-28 md:h-32 border items-center justify-between justify-center text-center bg-blue-100" : "focus:outline-none  cursor-pointer flex flex-col rounded md:w-28 md:h-32 border items-center justify-between justify-center text-center hover:bg-gray-50"} onClick={(e) => paymentHandler(e, payments.PAYPAL)}>
                                                <span className="pt-5"><FontAwesomeIcon size="3x" icon={['fab', 'cc-paypal']} /></span>
                                                <span className="pb-7 text-sm">PayPal</span>
                                            </button>
                                            <button className={paymentMethod && paymentMethod === payments.CASH ? "focus:outline-none cursor-default flex flex-col rounded md:w-28 md:h-32 border items-center justify-between justify-center text-center bg-blue-100" : "focus:outline-none  cursor-pointer flex flex-col rounded md:w-28 md:h-32 border items-center justify-between justify-center text-center hover:bg-gray-50"} onClick={(e) => paymentHandler(e, payments.CASH)}>
                                                <span className="pt-5"><FontAwesomeIcon size="3x" icon={['fas', 'money-bill-wave']} /></span>
                                                <span className="pb-7 text-sm">{t("cash", "Barzahlung")}</span>
                                            </button>
                                        </div>
                                        <div className="text-sm py-4 text-gray-400">
                                            {paymentMethod && [payments.VISA, payments.AMEX, payments.MASTERCARD].includes(paymentMethod) && t("credit_card_message", "Um Ihre Bestellung über diese Kreditkarte abzuschließen, werden Sie auf eine gesicherte Seite (SSL) von Saferpay weitergeleitet.")}
                                        </div>

                                    </div>
                                    <div className="md:pl-4 space-y-4">
                                        <label className="uppercase text-sm font-bold">{t("shipment_method", "Versandmethode")}</label>
                                        <div className="block md:hidden w-full">
                                            <select value={shippingMethod} onChange={(e) => shippingHandler(e, e.target.value)} id="shippingMobile" name="shippingMobile" className="appearance-none  relative border- block w-full px-3 py-2 border border-gray-300  text-gray-900  focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" >
                                                {paymentMethod !== payments.CASH && <option value={shippings.STANDARD}>{shippingCourier}</option>}
                                                <option value={shippings.SELF_PICKUP}>{t("customer_pick_up", "Selbstabholung")}</option>
                                            </select>
                                        </div>
                                        <div className="hidden md:flex space-x-4">
                                            {paymentMethod && paymentMethod !== payments.CASH &&
                                                <button className={shippingMethod && shippingMethod === shippings.STANDARD ? "focus:outline-none cursor-default flex flex-col rounded md:w-28 md:h-32 border items-center justify-between justify-center text-center bg-blue-100" : "focus:outline-none  cursor-pointer flex flex-col rounded md:w-28 md:h-32 border items-center justify-between justify-center text-center hover:bg-gray-50"} onClick={(e) => shippingHandler(e, shippings.STANDARD)}>
                                                    <span className="pt-5">
                                                        {shippingCourier === "DHL Standard" ?
                                                            <FontAwesomeIcon size="3x" icon={['fab', 'dhl']} /> :
                                                            <img src="/images/deutsche-post-logo.svg" alt="deutschepost" width="50" />
                                                        }
                                                    </span>
                                                    <span className="pb-7 text-sm">{shippingCourier}</span>
                                                </button>}
                                            <button className={shippingMethod && shippingMethod === shippings.SELF_PICKUP ? "focus:outline-none cursor-default flex flex-col rounded md:w-28 md:h-32 border items-center justify-between justify-center text-center bg-blue-100" : "focus:outline-none  cursor-pointer flex flex-col rounded md:w-28 md:h-32 border items-center justify-between justify-center text-center hover:bg-gray-50"} onClick={(e) => shippingHandler(e, shippings.SELF_PICKUP)}>
                                                <span className="pt-5"><FontAwesomeIcon size="3x" icon={['fas', 'people-carry']} /></span>
                                                <span className="pb-7 text-sm">{t("customer_pick_up", "Selbstabholung")}</span>
                                            </button>
                                        </div>
                                        <div className="text-sm py-4 text-gray-400">
                                            {shippingMethod && shippingMethod === shippings.STANDARD &&
                                                <div>
                                                    <span className="float-left w-28"> {t("shippingFees", "Versandkosten")}:</span> {currencyFormat(shippingPrice)}
                                                    <br />
                                                    <span className="float-left w-28"> {t("shippingDuration", "Versanddauer")}:</span> {shippingDuration} {t("werktage", "Werktage")}
                                                </div>}
                                            {shippingMethod && shippingMethod === shippings.SELF_PICKUP &&
                                                <div>
                                                    {t("selbstabholung_message1", "Ihre Ware liegt max. 7 Werktage für Sie zur Abholung bereit.")}
                                                    <br />
                                                    {t("selbstabholung_message2", "Sollten Sie Ihre Bestellung innerhalb dieses Zeitraums nicht abholen, wird diese storniert.")}
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="border rounded-lg bg-gray-50">
                                <div className="flex">
                                    <div className="flex-grow p-2 items-center font-medium uppercase tracking-wider select-none flex">
                                        <span className="text-sm text-left">
                                            {t("shipping", "Versand")}:</span>
                                        <span className="px-2 font-bold text-sm  text-left">{currencyFormat(shippingPrice)}</span>
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="flex-grow p-2 items-center font-medium uppercase tracking-wider select-none flex">
                                        <span className="text-sm text-left">
                                            {t("total_sum", "Gesamtsumme")} ({t("taxes_incl", "inkl. MwSt")}):</span>
                                        <span className="px-2 font-bold text-base text-left">{currencyFormat(cart.cartItems.reduce((a, c) => a + c.price * c.qty, 0) + (shippingMethod === shippings.STANDARD ? shippingPrice : 0))}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <div className="flex space-y-4 md:space-y-0 flex-col md:flex-row items-start justify-between w-full ">
                                    <div className="w-52">
                                        {paymentMethod === payments.PAYPAL ?
                                            <PaypalButton amount={cart.itemsPrice + shippingPrice} onError={onError} onSuccess={onSuccess} onCancel={onCancel} /> :
                                            <button type="submit" className="w-full left group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                {t("commit", "Kostenpflichtig bestellen")}
                                            </button>
                                        }
                                    </div>
                                </div>
                                <div className="text-sm py-4 text-gray-400">{t("commit_message", "Durch Anklicken von Kostenpflichtig bestellen bestätigen Sie den Warenkorb und Ihre eingegebenen Daten und stimmen unseren")} {localStorage.getItem("lngCode") === "en" ? <Link to="/privacy" className="text-blue-500">{t("data-privacy", "Datenschutzbestimmungen")}</Link> : <Link to="/datenschutz" className="text-blue-500">{t("data-privacy", "Datenschutzbestimmungen")}</Link>} {t("and", "sowie")} {localStorage.getItem("lngCode") === "en" ? <Link to="/terms" className="text-blue-500">{t("agb", "AGB ")}</Link> : <Link to="/agb" className="text-blue-500">{t("agb", "AGB ")}</Link>}{t("zu")}.</div>
                            </div>
                        </form>
                    </div>
                </div>
            </div >


    );

}
