import React from 'react'
import SubcategoryBottomText from './SubcategoryBottomText';

export default function SubcategoryBottomTextRouter(props) {
    return (
        <>
            {props.category === 'muenzen' && <SubcategoryBottomText title={"Historische Sammlermünzen"} description={"Das wohl beliebteste Sammelgebiet, wenn es um Münzen geht ist – neben den ebenfalls beliebten Euro-Münzen – die klassische Numismatik. Münzen aus der Antike, dem Mittelalter oder der Neuzeit sehen (je nach Erhaltung und Zustand der Münze) nicht nur schön aus, sondern sind als solche auch in Edelmetall geprägte oder gegossene Zeugen ihrer Zeit. Hier sind der Fantasie keine Grenzen gesetzt; wer mag wohl diese Münzen in der Hand gehalten, mit ihnen gehandelt haben? Und das gilt nicht nur für die Zeit, in der die Münze im Umlauf war, sondern auch die Jahrhunderte danach – wie mag wohl die Münze, die man vor sich sieht, die Jahrhunderte bis zum heutigen Tag verbracht haben? Solche romantischen Überlegungen machen historische Münzen zu fesselnden Sammlerstücken, und die mit der zeit einhergehende Verknappung und Seltenheit vieler Stücke machen Sie zu interessanten Sammlerstücken und Raritäten, die in der neusten Zeit auch zu beliebten Investment-Objekten werden. Für viele Sammler sind historische Münzen das Sammelgebiet. Hier findet sich für jedes Interesse eine Nische zum Sammeln; die Münzen der römischen Kaiser, griechische Münzen der Polis wie z.B. die Eule von Athen, die Münzen der eigenen Heimat aus dem Mittelalter oder doch lieber altdeutsche Münzen des Kaiserreiches? Es gibt viele Sammelgebiete, aus denen Sie aussuchen können. Alternativ sammeln Sie „kreuz und quer“ oder sogar moderne Münzen, wie z.B. Euro-Sonderprägungen."}/>}
        
        </>
    )
}
