import React from 'react'
import { Link } from 'react-router-dom'
import EmailAddress from '../components/EmailAddress'
import PhoneNumber from '../components/PhoneNumber'
import ScrollToTopMount from '../components/ScrollToTopMount'
import currencyFormat from '../utils/currency'

export default function AGBScreen() {

    return (
        <div className="px-5p ">
            <ScrollToTopMount />
            <Link to="/terms"><span className="text-white p-2 rounded float-right bg-blue-500">English Version</span></Link>
            <h1 className="text-3xl font-extrabold text-gray-900 tracking-tight py-2">Allgemeine Geschäftsbedingungen</h1>
            <div className="space-y-4 divide-y">
                <div className="space-y-2">
                    <b>§1 Geltung gegenüber Unternehmern und Begriffsdefinitionen</b>
                    <div className="text-sm space-y-2">
                        <p>(1) Die nachfolgenden Allgemeinen Geschäftsbeziehungen gelten für alle Rechtsgeschäfte zwischen der Münzgalerie München MGM Handelsgmbh &amp; Co. Joker KG und dem Besteller oder Verkäufer. Diese AGB gelten gleichermaßen für Verbrauer sowie für Unternehmer.</p>
                        <p>(2) Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können. Unternehmer ist eine natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt.</p>
                        <p>(3) Gegenüber Unternehmern behalten unsere AGB auch bei zukünftigen Geschäftsbeziehungen Gültigkeit, ohne dass wir erneut auf diese hinweisen.</p>
                        <p>(4) Verwenden Unternehmer entgegenstehende oder ergänzende AGB, so widersprechen wir hiermit ausdrücklich deren Einbeziehung in den Vertrag; dieser Widerspruch gilt auch für den Fall, dass der Besteller für den Widerspruch eine besondere Form vorgeschrieben hat.  Solche abweichende AGB werden nur dann Vertragsbestandteil, wenn wir ihnen ausdrücklich zustimmen.</p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§2 Vertragspartner, Vertragsschluss, Speicherung des Vertragstextes &amp; Fehlerkorrektur</b>
                    <div className="space-y-2 text-sm">
                        <p>(1) Die folgenden Regelungen über den Vertragsabschluss gelten für Bestellungen über unseren Internetshop <Link className="underline" to="/">shop.muenzgalerie.de</Link>.</p>
                        <p>(2) Im Falle des Vertragsschlusses wir dieser mit folgendem Vertragspartner geschlossen.</p>
                        <p className="pl-10">Münzgalerie München MGM</p>
                        <p className="pl-10">Handelsgesellschaft mbH &amp; Co. Joker KG</p>
                        <p className="pl-10">Stiglmaierplatz 2</p>
                        <p className="pl-10">D-80333 München</p>
                        <br />
                        <p className="pl-10">Registergericht: Amtsgericht München</p>
                        <p className="pl-10">Registernummer: HRA 59787</p>
                        <p >zustande.</p>
                        <br />
                        <p>(3) Ihre Bestellung stellt ein verbindliches Angebot an uns zum Abschluss eines Kaufvertrages dar. Ein rechtsgültiger Kaufvertrag kommt erst dann zustande, wenn wir Ihr Angebot innerhalb von 14 Tagen angenommen haben. Das erfolgt durch unsere schriftliche Bestätigung oder durch Versendung des bestellten Produktes an Sie. Den Eingang einer über das Internet getätigten Bestellung werden wir unverzüglich durch E-Mail an die von Ihnen mitgeteilte E-Mail-Adresse bestätigen. Die Zugangsbestätigung stellt noch keine verbindliche Annahme der Bestellung dar, ggf. kann die Annahmeerklärung jedoch mit der Zugangsbestätigung verbunden werden. Für die Richtigkeit, Vollständigkeit und Aktualität der dargestellten Texte und Abbildungen im Internet und der Lagerliste bzw. Lagerkataloge wird keine Gewähr übernommen. Druckfehler und Irrtümer bleiben vorbehalten. Außerhalb eines schriftlichen Auftrags erteilte mündliche Informationen sind unverbindlich und bedürfen zur Wirksamkeit der Schriftform. Mit der Aufgabe einer Bestellung akzeptieren Sie unsere allgemeinen Geschäftsbedingungen.</p>
                        <p>(4) Der Kunde kann vor dem verbindlichen Absenden der Bestellung durch Betätigen der in dem von ihm verwendeten Internet-Browser enthaltenen „Zurück“-Taste nach Kontrolle seiner Angaben wieder zu der Internetseite gelangen, auf der die Angaben des Kunden erfasst werden und Eingabefehler berichtigen bzw. durch Schließen des Internetbrowsers den Bestellvorgang abbrechen.</p>
                        <p>(5) Die Münzgalerie München speichert den Vertragstext und sendet Ihnen die Bestelldaten und unsere AGB per E-Mail zu. Die AGB können Sie jederzeit auch unter <Link className="underline" to="/agb">shop.muenzgalerie.de/agb</Link> einsehen. Ihre vergangenen Bestellungen können Sie in unserem Kunden-Bereich unter [Ihren Namen] -&gt; "Meine Bestellungen" einsehen.</p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§3 Vertragssprache</b>
                    <div className="space-y-2 text-sm">
                        <p>Verträge mit der Münzgalerie München Handelsgmbh &amp; Co. Joker KG können in Deutsch sowie in Englisch geschlossen werden. </p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§4 Preise, Versandkosten, Zahlung, Fälligkeit</b>
                    <div className="space-y-2 text-sm">
                        <p>Die angegebenen Preise enthalten die gesetzliche Umsatzsteuer und ggf. gesetzliche Margen- und Differenzbesteuerung sowie sonstige Preisbestandteile. Hinzu kommen etwaige Versandkosten. Weitere Informationen zu unseren Versandkosten finden Sie unter den einzelnen Produkten. Einen Teil der in Rechnung gestellten Versandkosten umfasst das sorgfältige Verpacken und auspolstern der Verkaufsgüter. Der Verkäufer bietet an, die bestellten Waren in seinem Ladenlokal abzuholen. In diesem Fall fallen keine Versandkosten an. Für den Postversand werden Ihnen folgende Kosten in Rechnung gestellt:</p>
                        <div className="pl-10 space-y-2 text-sm">
                            <p>- Versand innerhalb Deutschlands: {currencyFormat(5)} pro Paket, versandkostenfrei ab {currencyFormat(300)} Bestellwert.</p>
                            <p>- Versand innerhalb der restlichen EU (exkl. Schweiz &amp; Norwegen): {currencyFormat(9.50)}, versandkostenfrei ab {currencyFormat(1000)} Bestellwert.</p>
                            <p>- Versand in die restliche Welt (inkl. Schweiz &amp; Norwegen) {currencyFormat(15)}.</p>
                        </div>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§5 Lieferung, Lieferfrist</b>
                    <div className="space-y-2 text-sm">
                        <p>(1) Sofern in der Produktbeschreibung nicht deutlich anders angegeben, sind alle von uns angebotenen Artikel sofort versandfertig.</p>
                        <p>(2) Hat der Kunde die Zahlung per Vorkasse gewählt, so wird die Ware nicht vor Zahlungseingang versendet.</p>
                        <p>(3) Die Lieferung, insbesondere an uns unbekannte Käufer, erfolgt nur gegen Vorauskasse, soweit nichts anderes vereinbart wurde. Im Fall der Vorauskasse wird Ihnen mit Annahme Ihres Angebotes eine Rechnung übersandt, die Sie innerhalb von 14 Tagen an uns zu zahlen haben. Nach Zahlungseingang werden wir die Ware innerhalb von 14 Tagen an Sie übersenden.</p>
                        <p>(4) Sollte ein bestimmter Artikel nicht lieferbar sein, senden wir Ihnen, sofern von Ihnen bei der Bestellung gewünscht, einen qualitativ und preislich gleichwerten Artikel (Ersatzartikel) zu. Vorbehalt: Falls unser Lieferant uns trotz vertraglicher Verpflichtung nicht mit der bestellten Ware beliefert, sind wir zum Rücktritt berechtigt. In diesem Fall wird der Besteller unverzüglich darüber informiert, dass das bestellte Produkt nicht zur Verfügung steht. Ein bereits bezahlter Kaufpreis wird unverzüglich erstattet.
Sind wir aufgrund von bei uns oder bei einem unserer Lieferanten eintretenden Betriebsstörung, Arbeitsausständen, Aussperrungen, behördlichen Anordnungen oder anderen Fällen höherer Gewalt vorübergehend daran gehindert, den Kaufgegenstand innerhalb der vereinbarten Frist bzw. innerhalb von 14 Tagen zu liefern oder die vertraglich geschuldete Leistung zu erbringen, so verlängert sich diese Frist um die Dauer der Behinderung. Wir werden in diesem Fall den Kunden unverzüglich von der Behinderung und deren voraussichtlichen Dauer in Kenntnis setzen. Dauert die Behinderung länger als vier Wochen, so haben beide Parteien das Recht, von dem Kaufvertrag zurückzutreten.</p>
                        <p>(5) Ist der Kunde Unternehmer, so geht die Gefahr des zufälligen Untergangs sowie der zufälligen Verschlechterung der Ware mit der Übergabe, beim Versendungskauf mit der Auslieferung der Sache an den Spediteur, den Frachtführer oder die sonst zur Ausführung der Versendung bestimmte Person oder Anstalt auf den Kunden über. Ist der Käufer Verbraucher, so geht die Gefahr des zufälligen Untergangs und der zufälligen Verschlechterung der verkauften Sache beim Versendungskauf erst mit der Übergabe der Sache auf den Käufer über. Der Übergabe steht es gleich, wenn der Verbraucher in Verzug mit der Annahme der verkauften Sache ist.</p>
                        <p>(6) Die von uns zu tragende Versandgefahr ist durch den Abschluss ausreichender Versicherungen abgedeckt.</p>
                        <p>(7) Sollte es vorkommen, dass Waren mit offensichtlichen Transportschäden angeliefert werden, so reklamieren Sie solche Fehler bitte möglichst sofort beim Zusteller, und unterrichten uns hierüber bitte unverzüglich. Das Unterlassen einer Reklamation oder Kontaktaufnahme hat für Ihre gesetzlichen Ansprüche und deren Durchsetzung, insbesondere Ihre Gewährleistungsrechte, keinerlei Konsequenzen. Jedoch helfen Sie uns dabei, eigene Ansprüche gegenüber dem Frachtunternehmen bzw. der Transportversicherung geltend machen zu können.</p>
                        <p>(8) Bei einer Lieferung durch die Münzgalerie München in Nicht-EU-Länder können zusätzliche Kosten für die Zollabfertigung entstehen, die vom Kunden getragen werden. Die Münzgalerie München hat auf diese Kosten keinen Einfluss und kann auch keine Schätzung dieser Kosten vornehmen.</p>
                        <p>(9) Die Münzgalerie München weist darauf hin, dass grenzüberschreitende Lieferungen von den Zollbehörden untersucht und geöffnet werden können. Darauf hat die Münzgalerie München ebenso keinen Einfluss.</p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§6 Zahlung</b>
                    <div className="space-y-2 text-sm">
                        <p>(1) Alle Preise verstehen sich in Euro inkl. der zum Zeitpunkt der Bestellung geltenden gesetzlichen Mehrwertsteuer.</p>
                        <p>(2) Etwaige weitere anfallende Steuern sowie Einfuhr- und Ausfuhrsteuern sind vom Kunden zu zahlen. Ggf. hat sich der Kunde hierüber zuvor selbst zu informieren oder mit uns Kontakt aufzunehmen.</p>
                        <p>(3) Die folgenden Zahlungsmethoden werden in unserem Online Shop akzeptiert:</p>
                        <div className="pl-10 space-y-2 text-sm">
                            <p>(a) Vorkasse: Falls Sie als Zahlungsart Vorkasse wählen, senden wir Ihnen eine E-Mail mit unseren Bankdetails zu. Wir werden die bestellten Waren erst nach Geldeingang an Sie versenden.</p>
                            <p>(b) Paypal: Wenn Sie als Zahlungsart „Paypal“ wählen müssen Sie bei diesem Zahlungsdienstleister registriert sein, oder sich zunächst mit Ihren Identitätsdaten bei diesem registrieren. Sie werden hierzu von Paypal durch den Zahlungsprozess geführt und erhalten nach Abschluss eine Bestätigung.</p>
                            <p>(c) Kreditkarte: Wenn Sie als Zahlungsart „Kreditkarte“ wählen, benötigen Sie lediglich eine gültige Kreditkarte. Die Zahlungstransaktion wird von Ihrer Kreditkartenfirma durchgeführt.</p>
                            <p>(d) Barzahlung bei Abholung: Falls Sie Barzahlung bei Abholung gewählt haben können Sie Ihre Waren gegen Barzahlung in unserem Ladengeschäft abholen. </p>
                        </div>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§7 Widerrufsbelehrung</b>
                    <div className="space-y-2 text-sm">
                        <p><b>Widerrufsbelehrung</b></p>
                        <p><b>Widerrufsrecht</b></p>
                        <p>Sie können Ihre im Onlineshop der Münzgalerie München abgegebene Vertragserklärung innerhalb von 14 Tagen ohne Angabe von Gründen mündlich oder schriftlich in Textform (z. B. Brief, Fax, E-Mail [Münzgalerie München MGM Handelsgmbh &amp; Co. Joker KG, Stiglmaierplatz 2, 80333 München, Deutschland]) widerrufen. Die Widerrufsfrist beginnt, sobald Sie oder ein von Ihnen benannter Dritter, der nicht Frachtführer ist, die Waren erhalten haben bzw. hat. Sofern Sie mehrere Waren im Rahmen einer einheitlichen Bestellung bestellt haben und die Waren getrennt geliefert werden, beginnt die Widerrufsfrist, sobald Sie oder ein von Ihnen benannter Dritter, der nicht Frachtführer ist, die letzte Ware erhalten haben bzw. hat. Sollten Sie ihr Widerrufsrecht ausüben wollen, müssen Sie uns hierüber mittels einer eindeutigen Erklärung, in der Sie angeben, den Vertrag widerrufen zu wollen, informieren. Zur Wahrung der Widerrufsfrist genügt die rechtzeitige Absendung des Widerrufs vor Ablauf der Widerrufsfrist. Ein Musterformular zur Erklärung des Widerrufs (nachfolgend „Widerrufsformular“) finden Sie <Link className="underline" to="/documents/Withdrawal_Form_MGM.pdf">HIER</Link> zum Download. Ein Widerrufsformular senden wir Ihnen zudem per E-Mail zu und/oder fügen dies der bestellten Ware in Papierform bei. Die Verwendung des Musterformulars ist freiwillig und dient dazu, Ihnen die Ausübung des Widerrufsrechts zu erleichtern.
</p>
                        <br />
                        <p>Der Widerruf ist zu richten an</p>
                        <br />
                        <div className="pl-10 space-y-2">
                            <p>Münzgalerie München MGM</p>
                            <p>Handelsgesellschaft mbH &amp; Co. Joker KG</p>
                            <p>Stiglmaierplatz 2</p>
                            <p>D-80333 München</p>
                            <p>Telefon: <PhoneNumber number={"+49895233660"} text={"+49 (89) 5233660"}/></p>
                            <br />
                            <p>Telefax: <PhoneNumber number="+498932795990" text="(089) 32 79 59 90"/></p>
                            <p>E-Mail: <EmailAddress email="mgm@muenzgalerie.de"/></p>
                        </div>
                        <br />
                        <p><b>Widerrufsfolgen</b></p>
                        <p>Im Falle eines wirksamen Widerrufs sind die beiderseits empfangenen Leistungen zurückzugewähren und ggf. gezogene Nutzungen (z. B. Zinsen) herauszugeben. Können die empfangenen Leistungen sowie Nutzungen (z. B. Gebrauchsvorteile) nicht oder teilweise nicht oder nur in verschlechtertem Zustand zurückgewähren beziehungsweise herausgeben, müssen Sie insoweit Wertersatz leisten. Für die Verschlechterung der Sache und für gezogene Nutzungen müssen Sie Wertersatz nur leisten, soweit die Nutzungen oder die Verschlechterung auf einen Umgang mit der Sache zurückzuführen ist, der über die Prüfung der Eigenschaften und der Funktionsweise hinausgeht. Unter „Prüfung der Eigenschaften und der Funktionsweise“ versteht man das Testen und Ausprobieren der jeweiligen Ware, wie es etwa im Ladengeschäft möglich und üblich ist. Die Rücksendung erfolgt auf unsere Gefahr. Sofern der Käufer von seinem Widerrufsrecht Gebrauch macht, wird vereinbart, dass dieser die Kosten der Rücksendung zu tragen hat, wenn die gelieferte Ware der bestellten entspricht und wenn der Preis der zurückzusendenden Sache einen Betrag von 40 Euro nicht übersteigt, oder wenn der Käufer bei einem höheren Preis der Sache zum Zeitpunkt des Widerrufs noch nicht die Gegenleistung oder eine vertraglich vereinbarte Teilzahlung erbracht hat. Anderenfalls ist die Rücksendung für den Käufer kostenfrei. Die Münzgalerie München MGM Handelsgmbh &amp; Co. Joker KG  erstattet den gezahlten Kaufpreis im Falle eines erfolgreichen Widerrufs auf ein zu benennendes Konto des Kunden. Sollte die Rücknahme der Ware aus Kulanz erfolgen, besteht kein Anspruch auf eine Rückzahlung, sondern lediglich auf einen Gutschein.</p>
                        <p><b>Ausschluss des Widerrufsrechts</b></p>
                        <p>(1) Das Widerrufsrecht besteht gemäß § 312g Absatz 2 Nr. 1 BGB nicht bei Verträgen zur Lieferung von Waren, die nicht vorgefertigt sind und für deren Herstellung eine individuelle Auswahl oder Bestimmung durch den Verbraucher maßgeblich ist oder die eindeutig auf die persönlichen Bedürfnisse zugeschnitten sind. </p>
                        <p>(2) Kein Widerrufsrecht besteht gem. § 312 d IV Nr. 6 BGB bei Fernabsatzverträgen die die Lieferung von Waren zum Gegenstand haben, deren Preis auf dem Finanzmarkt Schwankungen unterliegt, auf die der Unternehmer keinen Einfluss hat und die innerhalb der Widerrufsfrist auftreten können. Hierzu zählen auch Edelmetalle, deren Wert täglichen Schwankungen unterliegt.</p>
                        <p><b>Ende der Widerrufsbelehrung</b></p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§8 Eigentumsvorbehalt</b>
                    <div className="space-y-2 text-sm">
                        <p>Die Münzgalerie München behält sich das Eigentum an der Ware bis zur vollständigen Zahlung des Kaufpreises vor.</p>
                        <p>(1) Der Kunde hat die Möglichkeit der Zahlung per Vorkasse, PayPal oder per Kreditkarte (Visa, Mastercard, American Express). Eine Zahlung auf Rechnung ist nur bei ausdrücklicher Zustimmung durch die Münzgalerie München im Einzelfall möglich.</p>
                        <p>(2) Hat der Kunde die Zahlung per Vorkasse gewählt, so verpflichtet er sich, den Kaufpreis unverzüglich nach Vertragsschluss zu zahlen. Wird die Ware mit Zustimmung der Münzgalerie München auf Rechnung bestellt, so verpflichtet sich der Kunde, den Kaufpreis unverzüglich nach Lieferung zu zahlen.</p>
                        <p>(3) Solange der Kaufpreis noch nicht vollständig bezahlt ist, ist der Kunde verpflichtet,</p>
                        <div className="pl-10 space-y-2 text-sm">
                            <p>a) die Ware pfleglich zu behandeln, insbesondere Münzen und Schmuck nicht zu reinigen und mit chemischen Mitteln oder anderen Gegenständen zu behandeln, sowie
</p>
                            <p>b) einen Zugriff Dritter auf die Ware, etwa im Falle einer Pfändung, etwaige Beschädigung der Ware oder deren Vernichtung uns und/oder</p>
                            <p>c) einen Besitzwechsel der Ware sowie den eigenen Wohnsitzwechsel unverzüglich anzuzeigen.</p>
                        </div>
                        <p>(4) Ein Unternehmer ist berechtigt, die Ware im ordentlichen Geschäftsverkehr weiterzuveräußern. Er tritt uns bereits jetzt alle Forderungen in Höhe des Rechnungsbetrages ab, die ihm durch die Weiterveräußerung gegen einen Dritten erwachsen. Wir nehmen die Abtretung bereits jetzt an. Nach der Abtretung ist der Unternehmer zur Einziehung der Forderung berechtigt. Wir behalten uns allerdings vor, die Forderung selbst einzuziehen, sobald der Unternehmer seinen Zahlungsverpflichtungen nicht ordnungsgemäß nachkommt und in Zahlungsverzug gerät.</p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§ 9 Mängelhaftung &amp; Gewährleistung</b>
                    <div className="space-y-2 text-sm">
                        <p>(1) Alle verkauften Artikel, sind, sofern nicht explizit anders beschrieben, einzigartige gebrauchte historische Gegenstände und zeigen daher Abnutzungsspuren auf. Wir beschreiben die und fotografieren die Gegenstände nach bestem Wissen und Gewissen. Sollten gelieferte Artikel offensichtliche Mängel aufweisen die nicht bereits davor offensichtlich in der Beschreibung oder auf Fotos erkennbar gemacht wurden, wozu auch Transportschäden zählen, sind Sie verpflichtet solche Mängel unverzüglich zu reklamieren. Für Verbraucher hat das Versäumnis dieser Rüge allerdings keine Konsequenzen.</p>

                        <p>(2) Die Gewährleistungsansprüche richten sich grundsätzlich nach den gesetzlichen Bestimmungen.</p>

                        <p>(3) In einigen Fällen existieren Artikel mehrfach. Es können nicht alle unsere Stücke in einem Katalog oder auf der Website dargestellt werden. Die Lieferung eines nicht abgebildeten gleichen Stückes stellt keinen Mangel dar, außer Sie haben ausdrücklich in Ihrer Bestellung darauf hingewiesen, dass Sie exakt das abgebildete und beschriebene Stück erhalten wollen. </p>

                        <p>(4) die Echtheit aller unserer angebotenen Münzen, Medaillen und Schmuckstücke wird gewährleistet. Dem Besteller stehen die gesetzlichen Gewährungsrechte zu. Bei Fragen erreichen uns jederzeit, telefonisch, per Post oder per E-Mail zu unseren üblichen Geschäftszeiten.</p>
                        <p>(5) Im Gewährleistungsfalle ist der Verbraucher nach seiner Wahl zur Geltendmachung eines Rechts auf Mängelbeseitigung oder Lieferung mangelfreier Ware berechtigt (Nacherfüllung). Sofern die gewählte Art der Nacherfüllung mit unverhältnismäßig hohen Kosten verbunden ist, beschränkt sich der Anspruch auf die jeweils verbliebene Art der Nacherfüllung. Weitergehende Rechte, insbesondere die Rückgängigmachung des Kaufvertrages, können nur nach Ablauf einer angemessenen Frist zur Nacherfüllung oder dem zweimaligen Fehlschlagen der Nacherfüllung oder auch unserer Verweigerung der Nacherfüllung geltend gemacht werden.</p>
                        <p>(6) Ist der Kunde Unternehmer, ist der Gewährleistungsanspruch bei Bestellung einer neuen Sache auf ein Jahr beschränkt, handelt es sich um eine gebrauchte Sache, ist der Gewährleistungsanspruch ausgeschlossen. Wird der Gewährleistungsanspruch auf Nacherfüllung geltend gemacht, hat die Münzgalerie München das Wahlrecht, die Sache zu reparieren oder neu zu liefern.</p>
                        <p>(7) Ist der Kunde Verbraucher, beschränkt sich der Gewährleistungsanspruch bei Bestellung einer gebrauchten Sache auf ein Jahr.</p>
                        <p>(8) Wir übernehmen keine Verantwortung dafür, dass durch uns verkaufte Ware von den sogenannten Grading-Unternehmen angenommen oder die Erhaltungsqualität in einer ähnlichen Form eingeschätzt wird, wie durch uns. Reklamationen, die daraus resultieren, dass ein Grading-Unternehmen zu einer abweichenden Qualitätsbewertung gekommen ist, berechtigen nicht zu einer Rückabwicklung des Kaufes.</p>
                        <p>(9) Bei Stücken in sogenannten „Slabs“ (Münzen sind eingeschweißt in Plastikholder, ausgegeben von Grading-Unternehmen) übernehmen wir keine Mängelhaftung für versteckte Mängel, z. B. Randfehler, Henkelspuren, Schleifspuren, etc., die durch den Plastikholder verdeckt werden.</p>
                        <p>(10) Die vorstehenden Einschränkungen und Fristverkürzungen gelten nicht für Ansprüche aufgrund von Schäden, die durch uns, unsere gesetzlichen Vertreter oder Erfüllungsgehilfen verursacht wurden</p>
                        <div className="pl-10 space-y-2">
                            <p>- bei Verletzung von Lebens, Körper oder Gesundheit</p>
                            <p>- bei Vorsatz, Arglist oder grober Fahrlässigkeit</p>
                            <p>- wenn wesentliche Vertragspflichten verletzt werden, auf deren Einhaltung Vertragspartner regelmäßig vertrauen dürfen und deren Erfüllung die ordnungsgemäße Abwicklung des Vertrages überhaupt erst ermöglicht (Kardinalpflichten)</p>
                            <p>- falls ein Garantieversprechen vereinbart wurde im Rahmen desselben</p>
                            <p>- soweit der Anwendungsbereich des Produkthaftungsgesetzes eröffnet ist.</p>
                        </div>
                        <p>(11) Ort der Rückabwicklung ist München</p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§10 Datenschutz</b>
                    <div className="space-y-2 text-sm">
                        <p>Alle personenbezogenen Daten werden vertraulich behandelt. Mehr Informationen zu diesem Thema finden Sie unter <Link className="underline" to="/datenschutz">Datenschutz</Link>.</p>

                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§11 Haftungsbeschränkung</b>
                    <div className="space-y-2 text-sm">
                        <p>(1) Für Ansprüche aufgrund von Schäden, die durch uns, unsere gesetzlichen Vertreter oder Erfüllungsgehilfen verursacht wurden, haften wir stets unbeschränkt</p>
                        <div className="pl-10 space-y-2">
                            <p>- bei Verletzung von Lebens, Körper oder Gesundheit</p>
                            <p>- bei Vorsatz oder grober Fahrlässigkeit</p>
                            <p>- falls ein Garantieversprechen vereinbart wurde im Rahmen desselben</p>
                            <p>- soweit der Anwendungsbereich des Produkthaftungsgesetzes eröffnet ist.</p>
                        </div>
                        <p>(2) Wenn unsererseits bzw. durch unsere gesetzlichen Vertreter oder Erfüllungsgehilfen leicht fahrlässig wesentliche Vertragspflichten verletzt werden, auf deren Einhaltung Vertragspartner regelmäßig vertrauen dürfen und deren Erfüllung die ordnungsgemäße Abwicklung des Vertrages überhaupt erst ermöglicht (Kardinalpflichten), so ist unsere Haftung in der Höhe auf den bei Abschluss des Vertrages vorhersehbaren Schaden, mit dessen Entstehung typischerweise gerechnet werden muss beschränkt.
Alle weiteren Ansprüche auf Schadensersatz sind ausgeschlossen.</p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§12 Verwendung des Kundenkontos</b>
                    <div className="space-y-2 text-sm">
                        <p>(1) Bitte beachten Sie: Die Daten zur Nutzung des persönlichen Kundenkontos sind vertraulich zu behandeln. Der Kunde unternimmt alle erforderlichen Schritte um das Passwort geheim zu halten. Sollte ein Dritter Kenntnis vom Passwort des Kunden erlangen oder Anlass zur Annahme bestehen, dass dies der Fall ist, hat der Kunde sein Passwort neu zu sichern. Dies ist durch Anlegen eines neuen Passworts zu bewerkstelligen, sollte die Möglichkeit einer vom Kunden ungewollten Bestellung durch die Benutzung des Dritten bestehen, hat der Kunde die Münzgalerie München unverzüglich zu informieren.</p>
                        <p>(2) Die Münzgalerie München behält sich das Recht vor, bei Verstoß gegen die AGB, anwendbare Gesetze oder andere Vertragsbedingungen oder Richtlinien, das betreffende Kundenkonto zu sperren und ggf. zu löschen.</p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§13 Änderungen der Allgemeinen Geschäftsbedingungen</b>
                    <div className="space-y-2 text-sm">
                        <p>Änderungen am Internetauftritt der Münzgalerie München, den Bedingungen, einschließlich der AGB, behält sich die Münzgalerie München jederzeit vor. Auf einen zwischen einem Kunden und der Münzgalerie München geschlossenen Vertrag kommen die Vertragsbedingungen im Augenblick des Vertragsschlusses zur Anwendung, soweit es sich nicht um gesetzliche oder behördliche Anordnungen handelt.</p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§14 Minderjährige</b>
                    <div className="space-y-2 text-sm">
                        <p>Personen unter 18 Jahren oder sonstige nicht unbeschränkt geschäftsfähige Personen dürfen in unserem Internetshop Bestellungen nur mit vorheriger Zustimmung der Eltern/Erziehungsberechtigten oder gesetzlichen Vertreter aufgeben.</p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§15 Anwendbares Recht</b>
                    <div className="space-y-2 text-sm">
                        <p>Erfüllungsort ist München. Es gilt Deutsches Recht unter Ausschluss des UN-Kaufrechtes.
                        Ist der Kunde Kaufmann, juristische Person des öffentlichen Rechts oder öffentlichen-rechtlichen Sondervermögens, ist ausschließlicher Gerichtsstand für alle Streitigkeiten aus diesem Vertrag der Geschäftssitz der Münzgalerie München MGM Handelsgmbh &amp; Co. Joker KG, München. Dasselbe gilt, wenn der Kunde keinen allgemeinen Gerichtsstand in der Bundesrepublik Deutschland hat oder der Wohnsitz oder gewöhnliche Aufenthalt im Zeitpunkt der Klageerhebung nicht bekannt ist.
                        Sollten einzelne Bestimmungen des Vertrags mit dem Kunden einschließlich dieser AGB ganz oder teilweise unwirksam sein oder werden, so wird hierdurch die Wirksamkeit der übrigen Bestimmungen nicht berührt. Die ganz oder teilweise unwirksame Regelung soll durch eine Regelung ersetzt werden, deren wirtschaftlicher Erfolg dem der unwirksamen Regelung möglichst nahe kommt.
                        Im Falle einer Regelungslücke werden die Parteien eine Regelung treffen, die dem nach dem gesamten Vertragsinhalt erkennbaren Parteiwillen zur Durchsetzung verhilft.</p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§16  Logo, verwendete Bilder &amp; Designs</b>
                    <div className="space-y-2 text-sm">
                        <p>Sämtliche auf diesen Seiten verwendeten Marken, Namen, Titel, Logos, Bilder, Designs, Texte und andere Materialien sind Eigentum der Münzgalerie München Handelsgmbh &amp; Co. Joker KG . Die bereitgestellten Text- und Bilddateien sind urheberrechtlich und/oder markenrechtlich und/oder als Design oder anderweitig besitzrechtlich geschützt und dürfen nicht verwendet werden, es sei denn der Eigentümer hat hierzu sein schriftliches Einverständnis erklärt.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
