import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { detailsOrder } from '../actions/orderActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import currencyFormat from '../utils/currency';
import { ORDER_SEND_RESET, ORDER_PAYMENT_RESET } from '../constants/orderConstants';
import { useTranslation } from 'react-i18next';
import { optionalField } from '../utils/string';
import { dateFormat, dateMediumFormat, dateFullFormat } from '../utils/date';
import CheckoutSteps from '../components/CheckoutSteps';


export default function OrderScreen(props) {
    const { t } = useTranslation(["shipping"]);

    const orderId = props.match.params.id;

    const orderDetails = useSelector(state => state.orderDetails);
    const { order, loading, error } = orderDetails;

    const orderPay = useSelector(state => state.orderPay);
    const { loading: loadingPay, error: errorPay, success: successPay } = orderPay;

    const dispatch = useDispatch();
    useEffect(() => {
        if (!order || successPay || (order && order._id !== orderId)) {
            dispatch({ type: ORDER_PAYMENT_RESET });
            dispatch({ type: ORDER_SEND_RESET });
            dispatch(detailsOrder(orderId));
        }
    }, [dispatch, order, orderId, successPay]);



    return (
        <div>
            <div className="flex flex-col justify-center px-5p items-center w-full ">
                <CheckoutSteps step1 step2 step3 />
                {order && <div className="w-full">
                    <MessageBox hideCloseButton={true} variant="success">
                        {t("thanks", "Vielen Dank! Wir haben Ihre Bestellung am")} {dateMediumFormat(order.createdAt)} {t("erhalten", "erhalten und Ihnen eine Bestätigungsemail zukommenlassen.")}
                    </MessageBox>
                </div>}

                {loading || loadingPay ? <LoadingBox></LoadingBox> :
                    error || errorPay ? <MessageBox variant="danger">{error || errorPay}</MessageBox> :
                        <div className="text-gray-700 text-sm mt-6 flex flex-col md:flex-row space-y-4 md:space-y-0 w-full md:space-y-0 md:space-x-2 justify-center ">

                            <div className=" w-full">
                                <div className="bg-gray-100 p-5 rounded ">
                                    <div className="flex flex-row">
                                        <div className="flex justify-between w-full">
                                            <div className="text-xl">
                                                <b>{t("order", "Bestellung")} {order._id} ({dateFullFormat(order.createdAt)})</b>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="divide-solid divide-y space-y-2">
                                            <div>
                                                <div>
                                                <div className="py-2 font-bold">{t("billing_address", "Rechnungsanschrift")}:</div>
                                                    <div className="flex space-x-2">
                                                        <div className="">{t("name", "Name")}:</div>
                                                        <div className="font-medium">{order.shippingInfo.firstName} {order.shippingInfo.lastName}</div>
                                                    </div>
                                                    {order.shippingInfo.company &&
                                                        <div className="flex space-x-2">
                                                            <div className="">{t("company", "Firma")}:</div>
                                                            <div className="font-medium">{order.shippingInfo.company}</div>
                                                        </div>
                                                    }
                                                    <div className="flex space-x-2">
                                                        <div className="">{t("email", "E-Mail Adresse")}:</div>
                                                        <div className="font-medium">{order.shippingInfo.email}</div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="flex space-x-2">
                                                        <div className="">{t("address", "Adresse")}:</div>
                                                        <div className="font-medium">{order.shippingInfo.address} {optionalField(order.shippingInfo.addressExtra)}</div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="flex space-x-2">
                                                        <div className="">{t("location", "Ort")}:</div>
                                                        <div className="font-medium">{order.shippingInfo.postalCode} {order.shippingInfo.city}</div>
                                                    </div>
                                                </div>

                                            </div>
                                            {order.shippingInfo.deliveryAddress &&
                                                <div>
                                                    <div className="py-2 font-bold">{t("shipping_address", "Lieferanschrift")}:</div>
                                                    <div>
                                                        <div className="flex space-x-2">
                                                            <div className="">{t("name", "Name")}:</div>
                                                            <div className="font-medium">{order.shippingInfo.deliveryFirstName} {order.shippingInfo.deliveryLastName}</div>
                                                        </div>
                                                        {order.shippingInfo.deliveryCompany &&
                                                            <div className="flex space-x-2">
                                                                <div className="">{t("company", "Firma")}:</div>
                                                                <div className="font-medium">{order.shippingInfo.deliveryCompany}</div>
                                                            </div>
                                                        }
                                                        <div className="flex space-x-2">
                                                            <div className="">{t("email", "E-Mail Adresse")}:</div>
                                                            <div className="font-medium">{order.shippingInfo.deliveryEmail}</div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="flex space-x-2">
                                                            <div className="">{t("address", "Adresse")}:</div>
                                                            <div className="font-medium">{order.shippingInfo.deliveryAddress} {optionalField(order.shippingInfo.deliveryAddressExtra)}</div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="flex space-x-2">
                                                            <div className="">{t("location", "Ort")}:</div>
                                                            <div className="font-medium">{order.shippingInfo.deliveryPostalCode} {order.shippingInfo.deliveryCity}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div>
                                                <div className="mt-2">
                                                    <div className="flex space-x-2">
                                                        <div className="font-bold">{t("payment_method", "Zahlungsmethode")}:</div>
                                                        <div className="font-medium capitalize">{t(order.shippingInfo.paymentMethod.toLowerCase(), order.shippingInfo.paymentMethod.toLowerCase())}</div>
                                                    </div>
                                                    <div className="flex space-x-2">
                                                        <div className="font-bold">{t("shipment_method", "Versandmethode")}:</div>
                                                        <div className="font-medium capitalize">{t(order.shippingInfo.shippingMethod.toLowerCase(), order.shippingInfo.shippingMethod.toLowerCase())}</div>
                                                    </div>
                                                </div>

                                            </div>
                                            {order.isSent ? <div className="pt-2 mt-2"> <MessageBox hideCloseButton={true} variant="success">{t("sent_at", "Verschickt am")} {dateFormat(order.sentAt)}</MessageBox></div> : <div className="pt-2 mt-2"> <MessageBox hideCloseButton={true} variant="info">{t("not_sent_at", "Noch nicht verschickt")}</MessageBox></div>}
                                        </div>
                                        <div className="-my-2 mt-2 overflow-x-auto md:-mx-6 lg:-mx-8">
                                            <div className="py-2 align-middle inline-block min-w-full md:px-6 lg:px-8">
                                                <div className="shadow overflow-hidden border-b border-gray-200 rounded-lg">
                                                    <table className="min-w-full divide-y divide-gray-200">
                                                        <thead className="bg-gray-50">
                                                            <tr>
                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                    {t("item", "Artikel")}
                                                                </th>
                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden md:table-cell">
                                                                    {t("qty", "Menge")}
                                                                </th>
                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden md:table-cell">
                                                                    {t("single_price", "Einzelpreis")}
                                                                </th>
                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden md:table-cell">
                                                                    {t("total_price", "Gesamtpreis")}
                                                                </th>

                                                            </tr>
                                                        </thead>
                                                        <tbody className="bg-white divide-y divide-gray-200">
                                                            {
                                                                order.orderItems.map((item) => (
                                                                    <tr key={item.product}>
                                                                        <td className="px-6 py-1 md:py-4 whitespace-nowrap block md:table-cell">
                                                                            <div className="flex items-center">
                                                                                <div className="flex-shrink-0 h-10 w-10">
                                                                                    <Link to={`/product/${item.product}`}><img className="h-10 w-10 rounded-full" src={item.image} alt={item.name} /></Link>
                                                                                </div>
                                                                                <div className="ml-4">
                                                                                    <Link to={`/product/${item.product}`}>
                                                                                        <div className="text-sm font-medium text-gray-900">
                                                                                            {item.brand}
                                                                                        </div>
                                                                                        <div className="text-sm text-gray-500">
                                                                                            {item.name}
                                                                                        </div>
                                                                                        <div className="text-sm text-gray-900">
                                                                                            {item.item_number}
                                                                                        </div>

                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-1 md:py-4  whitespace-nowrap block  md:table-cell">
                                                                            <div className="flex justify-between"><div className=" whitespace-nowrap text-sm text-gray-500 inline: md:hidden">{t("qty", "Menge")}:</div>

                                                                                <span className="text-sm text-gray-500">
                                                                                    {item.qty}
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-1 md:py-4  whitespace-nowrap text-sm text-gray-500 text-right md:text-left block md:table-cell">
                                                                            <div className="flex justify-between"><div className="inline: md:hidden">{t("single_price", "Einzelpreis")}:</div> <div>{currencyFormat(item.price)}</div> </div>
                                                                        </td>
                                                                        <td className="px-6 py-1 md:py-4  whitespace-nowrap text-sm text-right md:text-left text-gray-500 block md:table-cell">
                                                                            <div className="flex justify-between"><div className="inline: md:hidden">{t("total_price", "Gesamtpreis")}:</div> <div>{currencyFormat(item.price * item.qty)}</div> </div>
                                                                        </td>

                                                                    </tr>
                                                                )
                                                                )}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div className="w-full md:w-96">
                                <div className="bg-gray-100 p-5 rounded ">
                                    <div className="flex flex-row">
                                        <div className="flex justify-between w-full">
                                            <div className="text-xl">
                                                <b>{t("order_overview", "Bestellübersicht")}</b>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="divide-solid divide-y space-y-2">
                                        <div className="">
                                            <div>
                                                <div className="flex justify-between">
                                                    <div className="">{t("items", "Artikel")}:</div>
                                                    <div className="">{order.orderItems.length}</div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="flex justify-between">
                                                    <div className="">{t("subtotal", "Zwischensumme")}:</div>
                                                    <div className="">{currencyFormat(order.itemsPrice)}</div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="flex justify-between">
                                                    <div className="">{t("shipping", "Versand")}:</div>
                                                    <div className="">{currencyFormat(order.shippingPrice)}</div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="flex justify-between">
                                                    <div className="">{t("total_sum", "Gesamtsumme")}: <span className="text-gray-500">({t("taxes_incl", "inkl. Steuer")})</span></div>
                                                    <div className=""><strong>{currencyFormat(order.totalPrice)}</strong></div>
                                                </div>
                                            </div>
                                        </div>
                                        {order.isPaid ? <div className="pt-2 mt-2"> <MessageBox hideCloseButton={true} variant="success">{t("paid_at", "Bezahlt am")} {dateFormat(order.paidAt)}</MessageBox></div> : <div className="pt-2 mt-2"> <MessageBox hideCloseButton={true} variant="danger">{t("not_paid_yet", "Noch nicht bezahlt")}</MessageBox></div>}



                                    </div>
                                </div>
                            </div>
                        </div>
                }

            </div >
        </div>
    )
}