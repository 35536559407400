import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { paymentPageAssert } from '../actions/transactionActions';
import LoadingBox from '../components/LoadingBox'
import MessageBox from '../components/MessageBox';

export default function TransactionCheckScreen(props) {
    const dispatch = useDispatch();
    const transactionId = props.match.params.id;

    const ppAssert = useSelector(state => state.ppAssert);
    const { loading: loadingAssert, error: errorAssert, success: successAssert, orderId } = ppAssert;


    useEffect(() => {
        if (successAssert) {
            props.history.push(`/order/${orderId}`)
        }
        
        if (transactionId) {
            dispatch(paymentPageAssert(transactionId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, successAssert, orderId]);


    return (
        <div className="px-5p">
            { (errorAssert) && <MessageBox variant="danger">{errorAssert}. <Link className="underline text-blue-500" to="/cart">Zum Warenkorb</Link></MessageBox>}
            <div className="h-96 w-full flex justify-center items-center">
                {(loadingAssert) && <LoadingBox />}
            </div>
        </div>
    )
}
