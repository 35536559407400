import Axios from 'axios';
import { CART_EMPTY } from '../constants/cartConstants';
import { PP_ASSERT_FAIL, PP_ASSERT_REQUEST, PP_ASSERT_SUCCESS, PP_INIT_FAIL, PP_INIT_REQUEST, PP_INIT_SUCCESS } from '../constants/transactionConstants';

export const paymentPageInit = (order) => async (dispatch, getState) => {
    const { userSignin: { userInfo } } = getState();
    dispatch({ type: PP_INIT_REQUEST, payload: order });
    try {
        const { data } = await Axios.post('/api/transactions/', order, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        });
        dispatch({ type: PP_INIT_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: PP_INIT_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        });
        dispatch({ type: CART_EMPTY});
        localStorage.removeItem('cartItems');
    }
};

export const paymentPageAssert = (transaction) => async (dispatch, getState) => {
    const { userSignin: { userInfo } } = getState();
    dispatch({ type: PP_ASSERT_REQUEST, payload: transaction });
    try {
        const { data } = await Axios.get(`/api/transactions/check/${transaction}`, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        });
        dispatch({ type: PP_ASSERT_SUCCESS, payload: data });
        dispatch({ type: CART_EMPTY });
        localStorage.removeItem('cartItems');
    } catch (error) {
        dispatch({
            type: PP_ASSERT_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        });
    }
};
