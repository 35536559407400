import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import ReactTooltip from "react-tooltip";

export default function ErhaltungWidget(props) {
    return (
        <>


            <ReactTooltip id="erhaltungGrad" >

                <div className='max-w-lg'>
                    <div className='flex flex-col space-y-2 text-sm'>

                        <div>
                            <b className="text-green-300">Stempelglanz (Stgl.)</b> : Die Münze zeigt den Prägeglanz einer ungebrauchten Münze, ist frei von allen Abnutzungen bei scharfem Gepräge und frei von jeglichen mit bloßem Auge erkennbaren Verletzungen wie groben und feinen Kratzern.
                        </div>

                        <div>
                            <b className="text-green-300">Vorzüglich (vz)</b> : Die Münze darf leichte Beschädigungen (Kratzer) im Feld aufweisen, das Relief muss jedoch scharf und nicht abgenutzt sein.

                        </div>
                        <div>


                            <b className="text-green-300">Sehr schön (ss)</b> : Die Münze darf nicht nur im Feld Beschädigungen aufweisen, sondern auch im Relief leichte Abnutzungsspuren. Das Relief soll allerdings nicht wesentlich beeinträchtigt sein.

                        </div>
                        <div>




                            <b className="text-green-300">Schön (s)</b> : Bei diesem Erhaltungsgrad liegen deutliche Abnutzungsspuren in Relief und Schrift vor. Das Motiv (Portrait, Wappen, Nominal) muss jedoch erkennbar sein.
                        </div>
                    </div>

                </div>
            </ReactTooltip>

            <div className="font-semibold col-span-2">{props.keyx}</div><div className="col-span-4"> <span data-tip data-for="erhaltungGrad" className="text-green-500 cursor-help dotted-underline">{props.value.toString()}</span></div>
        </>
    )
}
