import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

export default function LanguageSelector() {
    const { i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem("lngCode"));
 
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem("lngCode", lng)
        setCurrentLanguage(lng);
    }
    return (
        <div className="md:w-52 divide-x text-gray-500 inline-flex"> 
            <button className={currentLanguage === "de" ? "underline px-2 focus:outline-none" : "px-2 focus:outline-none"} onClick={() => changeLanguage("de")}>DE</button>
            <button className={currentLanguage === "en" ? "underline px-2 focus:outline-none" : "px-2 focus:outline-none"} onClick={() => changeLanguage("en")}>EN</button>
        </div>
    );
}
