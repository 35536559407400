import React, { useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { sendProductQuestion } from '../actions/userActions';
import { USER_SEND_QUERY_RESET } from '../constants/userConstants';
import LoadingBox from './LoadingBox';
import MessageBox from './MessageBox'

export default function ProductQuestion(props) {
    const { t } = useTranslation(["product"]);

    const userSendQuery = useSelector(state => state.userSendQuery);
    const { success, error, loading} = userSendQuery;
    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [question, setQuestion] = useState('');
  
    const reRef = useRef();

    const submitHandler = async (e) => {
        e.preventDefault();
        const token = await reRef.current.executeAsync();
        reRef.current.reset();
        dispatch(sendProductQuestion({product: props.product.item_number, name: name, surname: surname, email: email, question: question, token: token}));
    };

    useEffect(() => {
        dispatch({ type: USER_SEND_QUERY_RESET });
    }, [dispatch]);

    return (
        <>
            <h2><MessageBox variant="info" hideCloseButton="true">{t("got_question", "Haben Sie eine Frage zu diesem Produkt?")}</MessageBox></h2>
            {loading && <LoadingBox></LoadingBox>}
            {error && <MessageBox variant="danger">{error}</MessageBox>}
            {success && <MessageBox variant="success">{t("message_sent","Die Nachricht wurde erfolgreich abgesendet")}</MessageBox>}
            <form onSubmit={submitHandler}>
                <div className="shadow overflow-hidden rounded-md">
                    <div className="px-4 py-5 bg-white p-6">
                        <div className="grid grid-cols-6 gap-3 md:gap-6">
                            <div className="col-span-6 md:col-span-3">
                                <label htmlFor="name" className="block text-sm font-medium text-gray-700">{t("first_name", "Vorname")}:</label>
                                <input type="text" name="name" id="name" value={name} onChange={(e) => setName(e.target.value)} className="border px-2 h-7 mt-1 block w-full shadow-sm border-gray-300 rounded-md" required />
                            </div>
                            <div className="col-span-6 md:col-span-3">
                                <label htmlFor="surname" className="block text-sm font-medium text-gray-700">{t("last_name", "Nachname")}:</label>
                                <input type="text" name="surname" id="surname" value={surname} onChange={(e) => setSurname(e.target.value)} className="border px-2 h-7 mt-1 block w-full shadow-sm border-gray-300 rounded-md" required />
                            </div>
                            <div className="col-span-6 md:col-span-3">
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">{t("email", "E-Mail Adresse")}:</label>
                                <input type="email" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className="border px-2 h-7 mt-1 block w-full shadow-sm border-gray-300 rounded-md" required />
                            </div>
                        </div>
                        <div className="py-4">
                            <label htmlFor="question" className="block text-sm font-medium text-gray-700">
                                {t("question", "Frage")}:
                              </label>
                            <div className="mt-1">
                                <textarea id="question" name="question" rows="3" value={question} onChange={(e) => setQuestion(e.target.value)} className="border px-2 pt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md" required></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            {t("send_message", "Absenden")}
                        </button>
                        <ReCAPTCHA ref={reRef} sitekey="6Lc6d0UaAAAAAPtya1LLBJv5EuQP1kwv5EkLIG3G" size="invisible" />
                    </div>
                </div>
            </form>
        </>
    )
}
