import React from 'react'
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../BreadCrumbs';
import SubcategoryTile from './SubcategoryTile';

export default function CoinSubcategoryGrid() {
    const { t } = useTranslation(["navbar"]);
    return (
        <div>
            <BreadCrumbs steps={[{title:t("coins", "Münzen")}]}/>

            <div className="flex md:flex-row flex-col flex-wrap md:justify-start justify-center pt-2">
                <SubcategoryTile name={t("old_german_coins", "Altdeutsche Münzen")} link={"/category/altdeutsche_muenzen"}/>
                <SubcategoryTile name={t("ancient_coins", "Antike Münzen")} link={"/category/antike_muenzen"}/>
                <SubcategoryTile name={t("foreign_coins", "Ausländische Münzen")} link={"/category/auslaendische_muenzen"} />
                <SubcategoryTile name={t("bullion_coins", "Bullion Münzen")} link={"/category/bullion_muenzen"}/>
                <SubcategoryTile name={t("germany_1871_coins", "Deutschland ab 1871 Münzen")} link={"/category/deutschland_1871_muenzen"}/>
            </div>
        </div>
    )
}

