import { GET_COLLECTIBLES_FAIL, GET_COLLECTIBLES_REQUEST, GET_COLLECTIBLES_SUCCESS } from "../constants/collectibleConstants";

export const collectiblesReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_COLLECTIBLES_REQUEST:
            return { loading: true };
        case GET_COLLECTIBLES_SUCCESS:
            return { loading: false, collectibles: action.payload };
        case GET_COLLECTIBLES_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};
