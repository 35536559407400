import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { listOrderSelf } from '../actions/orderActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import currencyFormat from '../utils/currency';
import { dateFormat } from '../utils/date';


export default function OrderHistoryScreen(props) {
    const { t } = useTranslation(["orderhistory"]);

    const orderSelfList = useSelector(state => state.orderSelfList);
    const { loading, error, orders } = orderSelfList;
    const dispatch = useDispatch();
    useEffect(()=> {
        dispatch(listOrderSelf());
    }, [dispatch]);
    return (
        <div className="px-5p space-y-2">
            <h1 className="text-3xl font-extrabold text-gray-900 tracking-tight py-2">{t("my_orders","Meine Bestellungen")}</h1>
            {loading? <LoadingBox></LoadingBox>:
            error? <MessageBox variant="danger">{error}</MessageBox>:
            (
            <div className="-my-2 overflow-x-auto md:-mx-6">
                <div className="py-2 align-middle inline-block min-w-full md:px-6">
                    <div className="shadow overflow-hidden border-b border-gray-200 rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t("order_id","Bestellnummer")}</th>
                                    <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t("date","Datum")}</th>
                                    <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t("total_cost","Gesamtsumme")}</th>
                                    <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t("paid","Bezahlt")}</th>
                                    <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t("sent","Verschickt")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {orders.map((order) => (
                                    <tr key={order._id}>
                                        <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-900">{order._id}</td>
                                        <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-500">{dateFormat(order.createdAt)}</td>
                                        <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-500">{currencyFormat(order.totalPrice)}</td>
                                        <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-500">{order.isPaid? dateFormat(order.paidAt) : t("no","Nein") }</td>
                                        <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-500">{order.isSent? dateFormat(order.sentAt) : t("no","Nein") }</td>
                                        <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-500 text-right"><button type="button" className="px-2 border border-transparent rounded-md bg-blue-100 hover:bg-blue-200 focus:outline-none" onClick={() => {props.history.push(`/order/${order._id}`)}}>Details</button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            )}
        </div>
    );
}