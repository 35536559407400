import React from 'react'
import { Link } from 'react-router-dom'
import EmailAddress from '../components/EmailAddress'
import PhoneNumber from '../components/PhoneNumber'
import ScrollToTopMount from '../components/ScrollToTopMount'

export default function ImpressumEngScreen() {
    return (
        <div className="px-5p pb-5">
            <ScrollToTopMount />
            <Link to="/impressum"><span className="text-white p-2 rounded float-right bg-blue-500">Deutsche Version</span></Link>
            <h1 className="text-3xl font-extrabold text-gray-900 tracking-tight py-2">Imprint</h1>
            <div className="space-y-4 divide-y">
                <div className="space-y-2">
                    <div className="text-sm">
                        <p>Münzgalerie München MGM</p>
                        <p>Handelsgesellschaft mbH &amp; Co. Joker KG</p>
                        <p>Stiglmaierplatz 2</p>
                        <p>80333 München</p>
                        <p>Tel: +49 89 52 33 66 0 / +49 89 52 34 36 2</p>
                        <p>Fax: <PhoneNumber number="+498932795990" text="(089) 32 79 59 90"/></p>
                        <p>represented by Münzgalerie München MGM Handelsgesellschaft mbH</p>
                        <br />
                        <p>Email: <EmailAddress email="mgm@muenzgalerie.de"/></p>
                        <p>Main page: <Link className="underline" to="//muenzgalerie.de">muenzgalerie.de</Link></p>
                        <br />
                        <p>Registered in the commercial register of the local court of Munich under HRA 59787</p>
                        <br />
                        <p>Tax Identification Number</p>
                        <p>pursuant to §27a Value-added Tax Law: DE130006976</p>
                        <br />
                        <p>Personally liable shareholder: Münzgalerie München MGM Handelsgesellschaft mbH</p>
                        <p>Stiglmaierplatz 2, 80333 München, Germany, Managing Directors Suanne Benz and Carl Benz</p>
                        <br />
                        <p>Registered in the commercial register of the local court of Munich under HRA 49813</p>
                        <br />
                        <p>Responsible for content according to § 55 II RStV </p>
                        <br />

                        <p><b>Liability Disclaimer:</b></p>
                        <p>Despite careful monitoring of the contents of this site, we assume no liability for the accuracy, completeness or topicality of the data contained herein. The content of linked sites is the sole responsibility of their operators.
                        <br />
                        This website and its content (including for example texts, images or graphic elements) are protected by copyright.  Any distribution, publishing or exploitation of the website, or any content, is strictly prohibited. You may not download, display, copy, reproduce, distribute, modify, perform, transfer, create derivative works from, sell or otherwise exploit any content, software, code, data or materials on the website.
                        </p>
                        <br />
                        <p><b>Information on the Online-Dispute Resolution:</b></p>
                        <p>The EU commission has created a website for online dispute resolution (ODR). The ODR platform serves as a place to go for extrajudicial dispute settlement regarding contractual obligations that stem from online sale contracts. The client may reach the ODR platform at the following link: <Link className="underline" to="//ec.europa.eu/consumers/odr/">ec.europa.eu/consumers/odr/</Link>.</p>
                    </div>
                </div>
            </div>
        </div >
    )
}
