import {
    USER_DELETE_FAIL,
    USER_DELETE_REQUEST,
    USER_DELETE_RESET,
    USER_DELETE_SUCCESS,
    USER_DETAILS_FAIL,
    USER_DETAILS_REQUEST,
    USER_DETAILS_RESET,
    USER_DETAILS_SUCCESS,
    USER_GUEST_CREATE_FAIL,
    USER_GUEST_CREATE_REQUEST,
    USER_GUEST_CREATE_SUCCESS,
    USER_LIST_FAIL,
    USER_LIST_REQUEST,
    USER_LIST_SUCCESS,
    USER_REGISTER_FAIL,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_SIGNIN_FAIL,
    USER_SIGNIN_REQUEST,
    USER_SIGNIN_SUCCESS,
    USER_SIGNOUT,
    USER_UPDATE_FAIL,
    USER_UPDATE_PROFILE_FAIL,
    USER_UPDATE_PROFILE_REQUEST,
    USER_UPDATE_PROFILE_RESET,
    USER_UPDATE_PROFILE_SUCCESS,
    USER_UPDATE_RESET,
    USER_UPDATE_REQUEST,
    USER_UPDATE_SUCCESS,
    USER_PASSWORD_RESET_HASH_REQUEST,
    USER_PASSWORD_RESET_HASH_SUCCESS,
    USER_PASSWORD_RESET_HASH_FAIL,
    USER_PASSWORD_RESET_HASH_RESET,
    USER_PASSWORD_RESET_SAVE_RESET,
    USER_PASSWORD_RESET_SAVE_FAIL,
    USER_PASSWORD_RESET_SAVE_SUCCESS,
    USER_PASSWORD_RESET_SAVE_REQUEST,
    USER_SEND_QUERY_REQUEST,
    USER_SEND_QUERY_SUCCESS,
    USER_SEND_QUERY_FAIL,
    USER_SEND_QUERY_RESET,
    USER_SELL_PRODUCT_REQUEST,
    USER_SELL_PRODUCT_SUCCESS,
    USER_SELL_PRODUCT_FAIL,
    USER_SELL_PRODUCT_RESET,
    USER_CONTACT_FORM_RESET,
    USER_CONTACT_FORM_FAIL,
    USER_CONTACT_FORM_SUCCESS,
    USER_CONTACT_FORM_REQUEST,
} from '../constants/userConstants';

export const userSigninReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_SIGNIN_REQUEST:
            return { loading: true };
        case USER_SIGNIN_SUCCESS:
            return { loading: false, userInfo: action.payload };
        case USER_SIGNIN_FAIL:
            return { loading: false, error: action.payload };
        case USER_SIGNOUT:
            return {};
        default:
            return state;
    }
};

export const userRegisterReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_REGISTER_REQUEST:
            return { loading: true };
        case USER_REGISTER_SUCCESS:
            return { loading: false, userInfo: action.payload };
        case USER_REGISTER_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const userDetailsReducer = (state = { loading: true }, action) => {
    switch (action.type) {
        case USER_DETAILS_REQUEST:
            return { loading: true };
        case USER_DETAILS_SUCCESS:
            return { loading: false, user: action.payload };
        case USER_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        case USER_DETAILS_RESET:
            return {};
        default:
            return state;
    }
};

export const userUpdateProfileReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_UPDATE_PROFILE_REQUEST:
            return { loading: true };
        case USER_UPDATE_PROFILE_SUCCESS:
            return { loading: false, success: true };
        case USER_UPDATE_PROFILE_FAIL:
            return { loading: false, error: action.payload };
        case USER_UPDATE_PROFILE_RESET:
            return {};
        default:
            return state;
    }
};

export const userListReducer = (state = { loading: true }, action) => {
    switch (action.type) {
        case USER_LIST_REQUEST:
            return { loading: true };
        case USER_LIST_SUCCESS:
            return { loading: false, users: action.payload.users, pages: action.payload.pages, page: action.payload.page};
        case USER_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const userDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_DELETE_REQUEST:
            return { loading: true };
        case USER_DELETE_SUCCESS:
            return { loading: false, success: true };
        case USER_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case USER_DELETE_RESET:
            return {};
        default:
            return state;
    }
};

export const userUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_UPDATE_REQUEST:
            return { loading: true };
        case USER_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case USER_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case USER_UPDATE_RESET:
            return {};
        default:
            return state;
    }
};

export const userForgotPassReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_PASSWORD_RESET_HASH_REQUEST:
            return { loading: true };
        case USER_PASSWORD_RESET_HASH_SUCCESS:
            return { loading: false, success: true };
        case USER_PASSWORD_RESET_HASH_FAIL:
            return { loading: false, error: action.payload };
        case USER_PASSWORD_RESET_HASH_RESET:
            return {};
        default:
            return state;
    }
};

export const userResetPassReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_PASSWORD_RESET_SAVE_REQUEST:
            return { loading: true };
        case USER_PASSWORD_RESET_SAVE_SUCCESS:
            return { loading: false, success: true };
        case USER_PASSWORD_RESET_SAVE_FAIL:
            return { loading: false, error: action.payload };
        case USER_PASSWORD_RESET_SAVE_RESET:
            return {};
        default:
            return state;
    }
};

export const userSendQuestionReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_SEND_QUERY_REQUEST:
            return { loading: true };
        case USER_SEND_QUERY_SUCCESS:
            return { loading: false, success: true };
        case USER_SEND_QUERY_FAIL:
            return { loading: false, error: action.payload };
        case USER_SEND_QUERY_RESET:
            return {};
        default:
            return state;
    }
};

export const userSellItemReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_SELL_PRODUCT_REQUEST:
            return { loading: true };
        case USER_SELL_PRODUCT_SUCCESS:
            return { loading: false, success: true};
        case USER_SELL_PRODUCT_FAIL:
            return { loading: false, error: action.payload };
        case USER_SELL_PRODUCT_RESET:
            return {};
        default:
            return state;
    }
};

export const userContactFormReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_CONTACT_FORM_REQUEST:
            return { loading: true };
        case USER_CONTACT_FORM_SUCCESS:
            return { loading: false, success: true};
        case USER_CONTACT_FORM_FAIL:
            return { loading: false, error: action.payload };
        case USER_CONTACT_FORM_RESET:
            return {};
        default:
            return state;
    }
};

export const userGuestReducer = (state ={}, action) => {
    switch (action.type) {
        case USER_GUEST_CREATE_REQUEST:
            return { loading: true };
        case USER_GUEST_CREATE_SUCCESS:
            return { loading: false, success: true};
        case USER_GUEST_CREATE_FAIL:
            return { loading: false, error: action.payload};
        default:
            return state;
    }
};