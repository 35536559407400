import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { detailsUserSelf, updateUserProfile } from '../actions/userActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants';
import CountryOptions from '../components/CountryOptions';

export default function ProfileScreen() {
    const { t } = useTranslation(["account"]);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [addressExtra, setAddressExtra] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [country, setCountry] = useState('');
    const [birthdate, setBirthdate] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const userSignin = useSelector(state => state.userSignin);
    const { userInfo } = userSignin;
    const userDetails = useSelector(state => state.userDetails);
    const { loading, error, user } = userDetails;
    const userUpdateProfile = useSelector(state => state.userUpdateProfile);
    const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = userUpdateProfile;
    const dispatch = useDispatch();

    useEffect(() => {
        if (!user) {
            dispatch({ type: USER_UPDATE_PROFILE_RESET });
            dispatch(detailsUserSelf());
        } else {
            setFirstName(user.firstName);
            setLastName(user.lastName);
            setCompany(user.company);
            setEmail(user.email);
            setPhone(user.phone);
            setAddress(user.address);
            setAddressExtra(user.addressExtra);
            setCity(user.city);
            setPostalCode(user.postalCode);
            setCountry(user.country)
            if (user.birthdate) {
                setBirthdate(user.birthdate);
            }

        }

    }, [dispatch, userInfo._id, user]);
    const submitHandler = (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            alert(t("password_mismatch", "Passwörter stimmen nicht überein"));
        } else {
            dispatch(updateUserProfile({ userId: user._id, firstName, lastName, company, email, phone, address, addressExtra, city, postalCode, country, birthdate, password }));
        }
    }
    return (
        <div className="px-5p space-y-2">
            <div className="py-2">
                <h1 className="text-3xl font-extrabold text-gray-900 tracking-tight">{t("my_account", "Mein Konto")}</h1>
            </div>
            <form className="" onSubmit={submitHandler}>

                {loading ? <LoadingBox></LoadingBox> :
                    error ? <MessageBox variant="danger">{error}</MessageBox> :
                        <div className="text-sm space-y-2">
                            {loadingUpdate && <LoadingBox></LoadingBox>}
                            {errorUpdate && <MessageBox variant="danger">{errorUpdate}</MessageBox>}
                            {successUpdate && <MessageBox variant="success">{t("save_success", "Konto erfolgreich aktualisiert")}</MessageBox>}
                            <div className="flex flex-col py-2 space-y-2">
                                <label className="uppercase text-gray-900" htmlFor="firstName">{t("firstName", "Vorname")}</label>
                                <input className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" id="firstName" type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                            </div>
                            <div className="flex flex-col py-2 space-y-2">
                                <label className="uppercase text-gray-900" htmlFor="lastName">{t("lastName", "Nachname")}</label>
                                <input className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" id="lastName" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                            </div>
                            <div className="flex flex-col py-2 space-y-2">
                                <label className="uppercase text-gray-900" htmlFor="company">{t("company", "Firma")}</label>
                                <input className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" id="company" type="text" value={company} onChange={(e) => setCompany(e.target.value)} />
                            </div>
                            <div className="flex flex-col py-2 space-y-2">
                                <label className="uppercase text-gray-900" htmlFor="email">{t("email", "E-Mail Adresse")}</label>
                                <input className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" id="email" type="email" placeholder="E-Mail" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="flex flex-col py-2 space-y-2">
                                <label className="uppercase text-gray-900" htmlFor="phone">{t("phone", "Telefonnummer")}</label>
                                <input className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" id="phone" type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} />
                            </div>
                            <div className="flex flex-col py-2 space-y-2">
                                <label className="uppercase text-gray-900" htmlFor="address">{t("address", "Adresse")}</label>
                                <input className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" id="address" type="text" value={address} onChange={(e) => setAddress(e.target.value)} />
                            </div>
                            <div className="flex flex-col py-2 space-y-2">
                                <label className="uppercase text-gray-900" htmlFor="addressExtra">{t("addressExtra", "Adresszusatz")}</label>
                                <input className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" id="addressExtra" type="text" value={addressExtra} onChange={(e) => setAddressExtra(e.target.value)} />
                            </div>
                            <div className="flex flex-col py-2 space-y-2">
                                <label className="uppercase text-gray-900" htmlFor="city">{t("city", "Stadt")}</label>
                                <input className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" id="city" type="text" value={city} onChange={(e) => setCity(e.target.value)} />
                            </div>
                            <div className="flex flex-col py-2 space-y-2">
                                <label className="uppercase text-gray-900" htmlFor="postalCode">{t("postalCode", "PLZ")}</label>
                                <input className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" id="postalCode" type="text" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} />
                            </div>
                            <div className="flex flex-col py-2 space-y-2">
                                <label className="uppercase text-gray-900" htmlFor="country">{t("country", "Land")}</label>
                                <select onChange={(e) => setCountry(e.target.value)} defaultValue={country} id="country" name="country" className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" >
                                    <option className="" value="" disabled>Land</option>
                                    <CountryOptions></CountryOptions>
                                </select>
                            </div>
                            <div className="flex flex-col py-2 space-y-2">
                                <label className="uppercase text-gray-900" htmlFor="birthdate">{t("birthdate", "Geburtsdatum")}</label>
                                <input className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" id="birthdate" type="date" placeholder="Geburtsdatum" value={birthdate.substring(0, 10)} onChange={(e) => setBirthdate(e.target.value)} />
                            </div>
                            <div className="flex flex-col py-2 space-y-2">
                                <label className="uppercase text-gray-900" htmlFor="password">{t("password", "Passwort")}</label>
                                <input className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" id="password" type="password" onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            <div className="flex flex-col py-2 space-y-2">
                                <label className="uppercase text-gray-900" htmlFor="confirmPassword">{t("confirm_password", "Passwort bestätigen")}</label>
                                <input className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" id="confirmPassword" type="password" onChange={(e) => setConfirmPassword(e.target.value)} />
                            </div>
                            <div className="flex justify-end py-2">
                                <label />
                                <button className="group relative  flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md  bg-yellow-100 hover:bg-yellow-200 focus:outline-none" type="submit">{t("save", "Speichern")}</button>
                            </div>
                        </div>
                }
            </form>
        </div>);
}