import React, { useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { sendContactForm } from '../actions/userActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import OpeningHours from '../components/OpeningHours';
import { USER_CONTACT_FORM_RESET } from '../constants/userConstants';

export default function ContactScreen() {
  const { t } = useTranslation(["contact"]);

  const userContactForm = useSelector(state => state.userContactForm);
  const { success, error, loading } = userContactForm;
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  
  const reRef = useRef();

  const submitHandler = async (e) => {
    e.preventDefault();
    const token = await reRef.current.executeAsync();
    reRef.current.reset();
    dispatch(sendContactForm({name: name, surname: surname, email: email, subject: subject, message: message, token: token}));
  };

  useEffect(() => {
    dispatch({type: USER_CONTACT_FORM_RESET})
  }, [dispatch]);

  return (
    <div className="px-5p ">
      <h1 className="text-3xl font-extrabold text-gray-900 tracking-tight py-2">{t("contact_us", "Kontaktformular")}</h1>
      <div className="py-3 flex flex-col w-full place-content-center md:flex-row justify-items-center space-y-4 md:space-y-0 md:space-x-4">
        <div className="items-center "><OpeningHours></OpeningHours></div>
        <div className="w-auto">
          {loading && <LoadingBox></LoadingBox>}
          {error && <MessageBox variant="danger">{error}</MessageBox>}
          {success && <MessageBox variant="success">{t("message_sent","Die Nachricht wurde erfolgreich abgesendet")}</MessageBox>}
          <form onSubmit={submitHandler}>
            <div className="overflow-hidden rounded-md w-auto">
              <div className="md:px-4 bg-white" >
                <div className="grid grid-cols-2 gap-3 w-full md:w-96 md:gap-2">
                  <div className="col-span-6 md:w-54">
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">{t("first_name", "Vorname")}:</label>
                    <input type="text" name="name" id="name" value={name} onChange={(e) => setName(e.target.value)} className="border px-2 h-7 mt-1 block w-full shadow-sm border-gray-300 rounded-md" required />
                  </div>
                  <div className="col-span-6 md:w-54">
                    <label htmlFor="surname" className="block text-sm font-medium text-gray-700">{t("last_name", "Nachname")}:</label>
                    <input type="text" name="surname" id="surname" value={surname} onChange={(e) => setSurname(e.target.value)} className="border px-2 h-7 mt-1 block w-full shadow-sm border-gray-300 rounded-md" required />
                  </div>
                  <div className="col-span-6 md:w-54">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">{t("email", "E-Mail Adresse")}:</label>
                    <input type="email" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className="border px-2 h-7 mt-1 block w-full shadow-sm border-gray-300 rounded-md" required />
                  </div>
                  <div className="col-span-6 md:w-54">
                    <label htmlFor="subject" className="block text-sm font-medium text-gray-700">{t("subject", "Betreff")}:</label>
                    <input type="text" name="subject" id="subject" value={subject} onChange={(e) => setSubject(e.target.value)} className="border px-2 h-7 mt-1 block w-full shadow-sm border-gray-300 rounded-md" required />
                  </div>
                </div>
                <div className="py-4 md:w-96">
                  <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                    {t("message", "Nachricht")}:
                  </label>
                  <div className="mt-1">
                    <textarea id="message" name="message" rows="3" value={message} onChange={(e) => setMessage(e.target.value)} className="border px-2 pt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md" required></textarea>
                  </div>
                </div>
                <div className="md:w-96 text-right pb-2">
                <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  {t("send_message", "Absenden")}
                </button>
                <ReCAPTCHA
                  ref={reRef}
                  sitekey="6Lc6d0UaAAAAAPtya1LLBJv5EuQP1kwv5EkLIG3G"
                  size="invisible"
                />
              </div>
              </div>
             
            </div>
          </form>
        </div>
      </div>
    </div>

  )
}
