import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { detailsUser, updateUser } from '../actions/userActions';
import CountryOptions from '../components/CountryOptions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { USER_UPDATE_RESET } from '../constants/userConstants';

export default function UserEditScreen(props) {

    const userId = props.match.params.id;
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [addressExtra, setAddressExtra] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [country, setCountry] = useState('');
    const [birthdate, setBirthdate] = useState('');

    //const [isAdmin, setIsAdmin] = useState(false);

    const userDetails = useSelector((state) => state.userDetails);
    const { loading, error, user } = userDetails;

    const userUpdate = useSelector((state) => state.userUpdate);
    const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = userUpdate;

    const dispatch = useDispatch();
    useEffect(() => {
        if (successUpdate) {
            dispatch({ type: USER_UPDATE_RESET });
            props.history.push('/userlist');
        }

        if (!user) {
            dispatch(detailsUser(userId));
        } else {
            setFirstName(user.firstName);
            setLastName(user.lastName);
            setCompany(user.company);
            setEmail(user.email);
            setPhone(user.phone);
            setAddress(user.address);
            setAddressExtra(user.addressExtra);
            setCity(user.city);
            setPostalCode(user.postalCode);
            setCountry(user.country)
            setBirthdate(user.birthdate);
        }

    }, [dispatch, props.history, successUpdate, user, userId]);

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(updateUser({ _id: userId, firstName, lastName, company, email, phone, address, addressExtra, city, postalCode, country, birthdate })); //isAdmin}));
    };
    return (
        <div className="px-5p space-y-2">
            {successUpdate && <MessageBox variant="success">User wurde erfolgreich bearbeitet</MessageBox>}
            {errorUpdate && <MessageBox variant="danger">{errorUpdate}</MessageBox>}
            {loadingUpdate && <LoadingBox></LoadingBox>}
            <div className="py-2">
                <h1 className="text-3xl font-extrabold text-gray-900 tracking-tight">User "<i className="font-medium text-gray-500">{firstName} {lastName}</i>" bearbeiten:</h1>
            </div>

            <form onSubmit={submitHandler}>

                {loadingUpdate && <LoadingBox></LoadingBox>}
                {errorUpdate && <MessageBox variant="danger">{errorUpdate}</MessageBox>}
                {loading ? (
                    <LoadingBox></LoadingBox>
                ) : error ? (
                    <MessageBox variant="danger">{error}</MessageBox>
                ) : (
                            <div className="text-sm space-y-2">
                                <div className="flex flex-col py-2 space-y-2">
                                    <label className="uppercase text-gray-900" htmlFor="firstName">Vorname</label>
                                    <input id="firstName" type="text" className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" placeholder="Vorname" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                </div>
                                <div className="flex flex-col py-2 space-y-2">
                                    <label className="uppercase text-gray-900" htmlFor="lastName">Nachname</label>
                                    <input id="lastName" type="text" className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" placeholder="Nachname" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                </div>
                                <div className="flex flex-col py-2 space-y-2">
                                    <label className="uppercase text-gray-900" htmlFor="company">Firma</label>
                                    <input id="company" type="text" className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" placeholder="Firma" value={company} onChange={(e) => setCompany(e.target.value)} />
                                </div>
                                <div className="flex flex-col py-2 space-y-2">
                                    <label className="uppercase text-gray-900" htmlFor="email">E-Mail</label>
                                    <input id="email" type="email" className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" placeholder="E-Mail" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className="flex flex-col py-2 space-y-2">
                                    <label className="uppercase text-gray-900" htmlFor="phone">Telefon</label>
                                    <input id="phone" type="tel" className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" placeholder="Telefon" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                </div>
                                <div className="flex flex-col py-2 space-y-2">
                                    <label className="uppercase text-gray-900" htmlFor="address">Adresse</label>
                                    <input id="address" type="text" className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" placeholder="Adresse" value={address} onChange={(e) => setAddress(e.target.value)} />
                                </div>
                                <div className="flex flex-col py-2 space-y-2">
                                    <label className="uppercase text-gray-900" htmlFor="addressExtra">Adresszusatz</label>
                                    <input id="addressExtra" type="text" className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" placeholder="Adresszusatz" value={addressExtra} onChange={(e) => setAddressExtra(e.target.value)} />
                                </div>
                                <div className="flex flex-col py-2 space-y-2">
                                    <label className="uppercase text-gray-900" htmlFor="city">City</label>
                                    <input id="city" type="text" className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" placeholder="Adresszusatz" value={city} onChange={(e) => setCity(e.target.value)} />
                                </div>
                                <div className="flex flex-col py-2 space-y-2">
                                    <label className="uppercase text-gray-900" htmlFor="postalCode">PLZ</label>
                                    <input id="postalCode" type="text" className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" placeholder="PLZ" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} />
                                </div>
                                <div className="flex flex-col py-2 space-y-2">
                                    <label className="uppercase text-gray-900" htmlFor="country">Land</label>

                                    <select onChange={(e) => setCountry(e.target.value)} defaultValue={country} id="country" name="country" className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" >
                                          <option className="" value="" disabled>Land</option>
                                        <CountryOptions></CountryOptions>
                                    </select>
                                </div>               
                                <div className="flex flex-col py-2 space-y-2">
                                    <label className="uppercase text-gray-900" htmlFor="birthdate">Geburtsdatum</label>
                                    <input id="birthdate" type="date" className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" placeholder="PLZ" value={birthdate ? birthdate.substring(0,10) : ""} onChange={(e) => setBirthdate(e.target.value)} />
                                </div>
                                <div className="flex justify-end py-2">
                                    <label></label>
                                    <button className="group relative  flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md  bg-yellow-100 hover:bg-yellow-200 focus:outline-none" type="submit">Speichern</button>
                                </div>
                            </div>
                        )}
            </form>
        </div>
    );
}
