import React from 'react'
import { Link } from 'react-router-dom'
import EmailAddress from '../components/EmailAddress'
import ScrollToTopMount from '../components/ScrollToTopMount'

export default function DatenschutzScreen() {
    return (
        <div className="px-5p ">
            <ScrollToTopMount />
            <Link to="/privacy"><span className="text-white p-2 rounded float-right bg-blue-500">English Version</span></Link>
            <h1 className="text-3xl font-extrabold text-gray-900 tracking-tight py-2"> Datenschutzerklärung</h1>
            <div className="space-y-4 divide-y">
                <div className="space-y-2">
                    <b>§1 NAME UND KONTAKTDATEN DES FÜR DIE VERARBEITUNG VERANTWORTLICHEN SOWIE DES BETRIEBLICHEN DATENSCHUTZBEAUFTRAGTEN</b>
                    <div className="text-sm space-y-2">
                        <p>Diese Datenschutz-Information gilt für die Datenverarbeitung durch:</p>
                        <p>Verantwortlicher: Münzgalerie München MGM HandelsgmbH &amp; Co. Joker KG, Stiglmaierplatz 2, 80333 München, Deutschland, vertreten durch die Geschäftsführer Susanne Benz und Carl Benz, Zuständiges Registergericht: Amtsgericht München, HRA 59787 / Umsatzsteuer ID: DE130006976. Nachfolgend bezeichnet als Münzgalerie München.
                        Unseren Datenschutzbeauftragten erreichen per E-Mail an <b><EmailAddress email="datenschutz@muenzgalerie.de"/></b> oder unter unserer Postadresse mit dem Zusatz „Der Datenschutzbeauftragte“
</p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§2 ERHEBUNG UND SPEICHERUNG PERSONENBEZOGENER DATEN SOWIE ART UND ZWECK VON DEREN VERWENDUNG</b>
                    <div className="space-y-2 text-sm">
                        <p><b>a) Beim Besuch der Website</b></p>
                        <p>Beim Aufrufen unserer Website <Link className="underline" to="/">shop.muenzgalerie.de</Link> werden durch den auf Ihrem Endgerät zum Einsatz kommenden Browser automatisch Informationen an den Server unserer Website gesendet. Diese Informationen werden temporär in einem sog. Logfile gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung gespeichert:</p>
                        <div className="space-y-2 pl-10">
                            <p>– IP-Adresse des anfragenden Rechners</p>
                            <p>– Datum und Uhrzeit des Zugriffs</p>
                            <p>– Name und URL der abgerufenen Datei,</p>
                            <p>– Website, von der aus der Zugriff erfolgt (Referrer-URL),</p>
                            <p>– verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers</p>
                        </div>
                        <p>Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:</p>
                        <div className="pl-10 space-y-2">
                            <p>- Gewährleistung eines reibungslosen Verbindungsaufbaus der Website,</p>
                            <p>- Gewährleistung einer komfortablen Nutzung unserer Website,</p>
                            <p>- Auswertung der Systemsicherheit und -stabilität sowie</p>
                            <p>- zu weiteren administrativen Zwecken.</p>

                        </div>
                        <p>Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes Interesse folgt aus oben aufgelisteten Zwecken zur Datenerhebung. In keinem Fall verwenden wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen.
                        Darüber hinaus setzen wir beim Besuch unserer Website Cookies sowie Analysedienste ein. Nähere Erläuterungen dazu erhalten Sie unter den Ziff. 4 und 5 dieser Datenschutzerklärung.</p>
                        <p><b>(b) Bei Anmeldung für unseren Newsletter</b></p>
                        <p>Sofern Sie nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrücklich eingewilligt haben, verwenden wir Ihre E-Mail-Adresse dafür, Ihnen regelmäßig unseren Newsletter zu übersenden. Für den Empfang des Newsletters ist die Angabe einer E-Mail-Adresse ausreichend.

                        Die Abmeldung ist jederzeit möglich, zum Beispiel über einen Link am Ende eines jeden Newsletters. Alternativ können Sie Ihren Abmeldewunsch gerne auch jederzeit an <b><EmailAddress email="mgm@muenzgalerie"/></b> per E-Mail senden.

                        Wir verwenden Mailchimp als unsere Plattform zur Mailing-Automatisierung. Indem Sie auf Anmelden klicken, bestätigen Sie, dass die von Ihnen angegebenen Informationen an MailChimp zur Verarbeitung in Übereinstimmung mit deren Datenschutzrichtlinien und Bedingungen weitergegeben werden. Weitere Informationen zur Datenverarbeitung von Mailchimp finden Sie unter  <Link className="underline" to="/mailchimp">https://mailchimp.com/legal/data-processing-addendum</Link> sowie unter <Link className="underline" to="//mailchimp.com/legal/privacy/">https://mailchimp.com/legal/privacy</Link>.</p>
                        <p><b>c) Bei Nutzung unseres Kontaktformulars</b></p>
                        <p>Bei Fragen jeglicher Art bieten wir Ihnen die Möglichkeit, mit uns über ein auf der Website bereitgestelltes Formular Kontakt aufzunehmen. Dabei ist die Angabe einer gültigen E-Mail-Adresse erforderlich, damit wir wissen, von wem die Anfrage stammt und um diese beantworten zu können. Weitere Angaben können freiwillig getätigt werden.
                        Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns erfolgt nach Art. 6 Abs. 1 S. 1 lit. a DSGVO auf Grundlage Ihrer freiwillig erteilten Einwilligung.
                        Die für die Benutzung des Kontaktformulars von uns erhobenen personenbezogenen Daten werden nach Erledigung der von Ihnen gestellten Anfrage automatisch gelöscht.</p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§3 WEITERGABE VON DATEN</b>
                    <div className="space-y-2 text-sm">
                        <p>Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt.
                        Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:
</p>
                        <div className="pl-10 space-y-2">
                            <p>- Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu erteilt haben,</p>
                            <p>- die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben,</p>
                            <p>- für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung besteht, sowie</p>
                            <p>- dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist.</p>
                        </div>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§4 COOKIES</b>
                    <div className="space-y-2 text-sm">
                        <p>Wir setzen auf unserer Seite Cookies ein. Hierbei handelt es sich um kleine Dateien, die Ihr Browser automatisch erstellt und die auf Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie unsere Seite besuchen. Cookies richten auf Ihrem Endgerät keinen Schaden an, enthalten keine Viren, Trojaner oder sonstige Schadsoftware.
                        In dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch eingesetzten Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer Identität erhalten.
                        Der Einsatz von Cookies dient einerseits dazu, die Nutzung unseres Angebots für Sie angenehmer zu gestalten. So setzen wir sogenannte Session-Cookies ein, um zu erkennen, dass Sie einzelne Seiten unserer Website bereits besucht haben. Diese werden nach Verlassen unserer Seite automatisch gelöscht.
                        Darüber hinaus setzen wir ebenfalls zur Optimierung der Benutzerfreundlichkeit temporäre Cookies ein, die für einen bestimmten festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden. Besuchen Sie unsere Seite erneut, um unsere Dienste in Anspruch zu nehmen, wird automatisch erkannt, dass Sie bereits bei uns waren und welche Eingaben und Einstellungen sie getätigt haben, um diese nicht noch einmal eingeben zu müssen.
                        Zum anderen setzen wir Cookies ein, um die Nutzung unserer Website statistisch zu erfassen und zum Zwecke der Optimierung unseres Angebotes für Sie auszuwerten (siehe Ziff. 5). Diese Cookies ermöglichen es uns, bei einem erneuten Besuch unserer Seite automatisch zu erkennen, dass Sie bereits bei uns waren. Diese Cookies werden nach einer jeweils definierten Zeit automatisch gelöscht.
                        Die durch Cookies verarbeiteten Daten sind für die genannten Zwecke zur Wahrung unserer berechtigten Interessen sowie der Dritter nach Art. 6 Abs. 1 S. 1 lit. f DSGVO erforderlich.
Die meisten Browser akzeptieren Cookies automatisch. Sie können Ihren Browser jedoch so konfigurieren, dass keine Cookies auf Ihrem Computer gespeichert werden oder stets ein Hinweis erscheint, bevor ein neuer Cookie angelegt wird. Die vollständige Deaktivierung von Cookies kann jedoch dazu führen, dass Sie nicht alle Funktionen unserer Website nutzen können.</p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§5 ANALYSE-TOOLS</b>
                    <div className="space-y-2 text-sm">
                        <p><b>a) Tracking-Tools</b></p>

                        <p>Die im Folgenden aufgeführten und von uns eingesetzten Tracking-Maßnahmen werden auf Grundlage des Art. 6 Abs. 1 S. 1 lit. f DSGVO durchgeführt. Mit den zum Einsatz kommenden Tracking-Maßnahmen wollen wir eine bedarfsgerechte Gestaltung und die fortlaufende Optimierung unserer Webseite sicherstellen. Zum anderen setzen wir die Tracking-Maßnahmen ein, um die Nutzung unserer Webseite statistisch zu erfassen und zum Zwecke der Optimierung unseres Angebotes für Sie auszuwerten. Diese Interessen sind als berechtigt im Sinne der vorgenannten Vorschrift anzusehen.</p>

                        <p>Die jeweiligen Datenverarbeitungszwecke und Datenkategorien sind aus den entsprechenden Tracking-Tools zu entnehmen.
</p>

                        <p><b>I) Google Analytics</b></p>

                        <p>Zum Zwecke der bedarfsgerechten Gestaltung und fortlaufenden Optimierung unserer Seiten nutzen wir Google Analytics, ein Webanalysedienst der Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; im Folgenden „Google“). In diesem Zusammenhang werden pseudonymisierte Nutzungsprofile erstellt und Cookies (siehe unter Ziff. 4) verwendet. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website wie</p>
                        <div className="pl-10 space-y-2">
                            <p>- Browser-Typ/-Version,</p>
                            <p>- verwendetes Betriebssystem</p>
                            <p>- Referrer-URL (die zuvor besuchte Seite),</p>
                            <p>- Hostname des zugreifenden Rechners (IP-Adresse),</p>
                            <p>- Uhrzeit der Serveranfrage,
</p>
                        </div>
                        <p>
                            werden an einen Server von Google in den USA übertragen und dort gespeichert. Die Informationen werden verwendet, um die Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu Zwecken der Marktforschung und bedarfsgerechten Gestaltung dieser Internetseiten zu erbringen. Auch werden diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag verarbeiten. Es wird in keinem Fall Ihre IP-Adresse mit anderen Daten von Google zusammengeführt. Die IP-Adressen werden anonymisiert, so dass eine Zuordnung nicht möglich ist (IP-Masking).
                        <br />
                        Sie können die Installation der Cookies durch eine entsprechende Einstellung der Browser-Software verhindern; wir weisen jedoch darauf hin, dass in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich genutzt werden können.
                        <br />
                        Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie ein <Link className="underline" to="//tools.google.com/dlpage/gaoptout?hl=de">Browser-Add-on herunterladen</Link> und installieren.
                        <br />
                        Alternativ zum Browser-Add-on, insbesondere bei Browsern auf mobilen Endgeräten, können Sie die Erfassung durch Google Analytics zudem verhindern, indem Sie auf diesen Link klicken. Es wird ein Opt-out-Cookie gesetzt, das die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website verhindert. Der Opt-out-Cookie gilt nur in diesem Browser und nur für unsere Website und wird auf Ihrem Gerät abgelegt. Löschen Sie die Cookies in diesem Browser, müssen Sie das Opt-out-Cookie erneut setzen.
                        <br />
                        Weitere Informationen zum Datenschutz im Zusammenhang mit Google Analytics finden Sie etwa in der <Link className="underline" to="//support.google.com/analytics/answer/6004245?hl=de">Google Analytics-Hilfe</Link>.
                        </p>
                        <p><b>II) Google Adwords Conversion Tracking</b></p>
                        <p>Um die Nutzung unserer Webseite statistisch zu erfassen und zum Zwecke der Optimierung unserer Website für Sie auszuwerten, nutzen wir ferner das Google Conversion Tracking. Dabei wird von Google Adwords ein Cookie (siehe Ziffer 4) auf Ihrem Rechner gesetzt, sofern Sie über eine Google-Anzeige auf unsere Webseite gelangt sind.
                        <br />
                        Diese Cookies verlieren nach 30 Tagen ihre Gültigkeit und dienen nicht der persönlichen Identifizierung. Besucht der Nutzer bestimmte Seiten der Webseite des Adwords-Kunden und das Cookie ist noch nicht abgelaufen, können Google und der Kunde erkennen, dass der Nutzer auf die Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde. Jeder Adwords-Kunde erhält ein anderes Cookie. Cookies können somit nicht über die Webseiten von Adwords-Kunden nachverfolgt werden. Die mithilfe des Conversion-Cookies eingeholten Informationen dienen dazu, Conversion-Statistiken für Adwords-Kunden zu erstellen, die sich für Conversion-Tracking entschieden haben. Die Adwords-Kunden erfahren die Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit denen sich Nutzer persönlich identifizieren lassen.
                        <br />
                        Wenn Sie nicht an dem Tracking-Verfahren teilnehmen möchten, können Sie auch das hierfür erforderliche Setzen eines Cookies ablehnen – etwa per Browser-Einstellung, die das automatische Setzen von Cookies generell deaktiviert. Sie können Cookies für Conversion-Tracking auch deaktivieren, indem Sie Ihren Browser so einstellen, dass Cookies von der Domain „www.googleadservices.com“ blockiert werden. <Link className="underline" to="//services.google.com/sitestats/de.html">Googles Datenschutzbelehrung zum Conversion-Tracking</Link> finden Sie hier.
                        </p>
                    </div>
                </div>

                <div className="py-2 space-y-2">
                    <b>§6 SOCIAL MEDIA PLUG-INS</b>
                    <div className="space-y-2 text-sm">
                        <p>Wir setzen auf unserer Website auf Grundlage des Art. 6 Abs. 1 S. 1 lit. f DSGVO Social Plug-ins der sozialen Netzwerke Facebook, Twitter und Instagram ein, um unserer Auktionshaus hierüber bekannter zu machen. Der dahinterstehende werbliche Zweck ist als berechtigtes Interesse im Sinne der DSGVO anzusehen. Die Verantwortung für den datenschutzkonformen Betrieb ist durch deren jeweiligen Anbieter zu gewährleisten. Die Einbindung dieser Plug-ins durch uns erfolgt im Wege der sogenannten Zwei-Klick-Methode um Besucher unserer Webseite bestmöglich zu schützen.</p>

                        <p><b>a) Facebook</b></p>

                        <p>Auf unserer Website kommen Social-Media Plugins von Facebook zum Einsatz, um deren Nutzung persönlicher zu gestalten. Hierfür nutzen wir den „LIKE“ oder „TEILEN“-Button. Es handelt sich dabei um ein Angebot von Facebook.
<br />
Wenn Sie eine Seite unseres Webauftritts aufrufen, die ein solches Plugin enthält, baut Ihr Browser eine direkte Verbindung mit den Servern von Facebook auf. Der Inhalt des Plugins wird von Facebook direkt an Ihren Browser übermittelt und von diesem in die Webseite eingebunden.
<br />
Durch die Einbindung der Plugins erhält Facebook die Information, dass Ihr Browser die entsprechende Seite unseres Webauftritts aufgerufen hat, auch wenn Sie kein Facebook-Konto besitzen oder gerade nicht bei Facebook eingeloggt sind. Diese Information (einschließlich Ihrer IP-Adresse) wird von Ihrem Browser direkt an einen Server von Facebook in den USA übermittelt und dort gespeichert.
<br />
Sind Sie bei Facebook eingeloggt, kann Facebook den Besuch unserer Website Ihrem Facebook-Konto direkt zuordnen. Wenn Sie mit den Plugins interagieren, zum Beispiel den „LIKE“ oder „TEILEN“-Button betätigen, wird die entsprechende Information ebenfalls direkt an einen Server von Facebook übermittelt und dort gespeichert. Die Informationen werden zudem auf Facebook veröffentlicht und Ihren Facebook-Freunden angezeigt.
<br />
Facebook kann diese Informationen zum Zwecke der Werbung, Marktforschung und bedarfsgerechten Gestaltung der Facebook-Seiten benutzen. Hierzu werden von Facebook Nutzungs-, Interessen- und Beziehungsprofile erstellt, z. B. um Ihre Nutzung unserer Website im Hinblick auf die Ihnen bei Facebook eingeblendeten Werbeanzeigen auszuwerten, andere Facebook-Nutzer über Ihre Aktivitäten auf unserer Website zu informieren und um weitere mit der Nutzung von Facebook verbundene Dienstleistungen zu erbringen.
<br />
Wenn Sie nicht möchten, dass Facebook die über unseren Webauftritt gesammelten Daten Ihrem Facebook-Konto zuordnet, müssen Sie sich vor Ihrem Besuch unserer Website bei Facebook ausloggen.
<br />
Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den <Link className="underline" to="//www.facebook.com/about/privacy/">Datenschutzhinweisen von Facebook</Link>.
</p>

                        <p><b>b) Instagram</b></p>
                        <p>b) Instagram
                        Auf unserer Website werden auch sogenannte Social Plugins („Plugins“) von Instagram verwendet, das von der Instagram LLC., 1601 Willow Road, Menlo Park, CA 94025, USA („Instagram“) betrieben wird.
<br />
Die Plugins sind mit einem Instagram-Logo beispielsweise in Form einer „Instagram-Kamera“ gekennzeichnet.
<br />
Wenn Sie eine Seite unseres Webauftritts aufrufen, die ein solches Plugin enthält, stellt Ihr Browser eine direkte Verbindung zu den Servern von Instagram her. Der Inhalt des Plugins wird von Instagram direkt an Ihren Browser übermittelt und in die Seite eingebunden. Durch diese Einbindung erhält Instagram die Information, dass Ihr Browser die entsprechende Seite unseres Webauftritts aufgerufen hat, auch wenn Sie kein Instagram-Profil besitzen oder gerade nicht bei Instagram eingeloggt sind.
<br />
Diese Information (einschließlich Ihrer IP-Adresse) wird von Ihrem Browser direkt an einen Server von Instagram in die USA übermittelt und dort gespeichert. Sind Sie bei Instagram eingeloggt, kann Instagram den Besuch unserer Website Ihrem Instagram-Account unmittelbar zuordnen. Wenn Sie mit den Plugins interagieren, zum Beispiel das „Instagram“-Button betätigen, wird diese Information ebenfalls direkt an einen Server von Instagram übermittelt und dort gespeichert.
<br />
Die Informationen werden außerdem auf Ihrem Instagram-Account veröffentlicht und dort Ihren Kontakten angezeigt.
<br />
Wenn Sie nicht möchten, dass Instagram die über unseren Webauftritt gesammelten Daten unmittelbar Ihrem Instagram-Account zuordnet, müssen Sie sich vor Ihrem Besuch unserer Website bei Instagram ausloggen.
<br />
Weitere Informationen hierzu Sie in der <Link className="underline" to="/help.instagram.com/155833707900388">Datenschutzerklärung von Instagram</Link>.</p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§7 BETROFFENENRECHTE</b>
                    <div className="space-y-2 text-sm">
                        <p>Sie haben das Recht:</p>
                        <div className="space-y-2 pl-10">
                            <p>- gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft Ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen;</p>
                            <p>- gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;</p>
                            <p>- gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;</p>
                            <p>- gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;</p>
                            <p>- gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen;</p>
                            <p>- gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen und</p>
                            <p>- Beschwerderecht nach Art. 77 DSGVO: Wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten durch die Münzgalerie München MGM Handelsgmbh &amp; Co. Joker KG  mit Sitz in München gegen die DSGVO verstößt, so haben Sie das Recht sich mit einer Beschwerde an die zuständige Stelle, in Bayern an das Bayerische Landesamt für Datenschutzaufsicht, Promenade 27 (Schloss), D- 91522 Ansbach zu wenden.
</p>
                        </div>
                        <p><b>Datenspeicherzeitraum</b></p>
                        <p>Der Gesetzgeber schreibt vielfältige Aufbewahrungsfristen und -pflichten vor, so. z.B. eine 10-jährige Aufbewahrungsfrist (§ 147 Abs. 2 i. V. m. Abs. 1 Nr.1, 4 und 4a AO, § 14b Abs. 1 UStG) bei bestimmten Geschäftsunterlagen, wie z.B. für Rechnungen. Wir erlauben uns auch den Hinweis darauf, dass wir im Falle eines Kulturgutes nach § 45 KGSG i.V.m. § 42 KGSG verpflichtet sind, Nachweise über die Sorgfaltsanforderungen aufzuzeichnen und hierfür bestimmte personenbezogenen Daten für die Dauer von 30 Jahren aufzubewahren. Nach Ablauf der Fristen, die uns vom Gesetzgeber auferlegt werden, oder die zur Verfolgung oder die Abwehr von Ansprüchen (z.B. Verjährungsregelungen) nötig sind, werden die entsprechenden Daten routinemäßig gelöscht. Daten, die keinen Aufbewahrungsfristen und -pflichten unterliegen, werden gelöscht, wenn ihre Aufbewahrung nicht mehr zur Erfüllung der vertraglichen Tätigkeiten und Pflichten erforderlich ist. Stehen Sie zu uns in keinem Vertragsverhältnis, sondern haben uns personenbezogene Daten anvertraut, weil Sie bspw. über unsere Dienstleistungen informiert sein möchten, oder sich für einen Kauf oder Verkauf eines Kunstwerks interessieren, erlauben wir uns davon auszugehen, dass Sie mit uns so lange in Kontakt stehen möchten, wir also die hierfür uns übergebenen personenbezogenen Daten so lange verarbeiten dürfen, bis Sie dem aufgrund Ihrer vorbezeichneten Rechte aus der DSGVO widersprechen, eine Einwilligung widerrufen, von Ihrem Recht auf Löschung oder der Datenübertragung Gebrauch machen.</p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§8 WIDERSPRUCHSRECHT</b>
                    <div className="space-y-2 text-sm">
                        <p>Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer besonderen Situation von uns umgesetzt wird.</p>

                        <p>Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an <b><EmailAddress email="datenschutz@muenzgalerie.de"/></b></p>
                    </div>
                </div>
                <div className="py-2 space-y-2">
                    <b>§9 DATENSICHERHEIT</b>
                    <div className="space-y-2 text-sm">
                        <p>Wir verwenden innerhalb des Website-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird. In der Regel handelt es sich dabei um eine 256 Bit Verschlüsselung. Falls Ihr Browser keine 256-Bit Verschlüsselung unterstützt, greifen wir stattdessen auf 128-Bit v3 Technologie zurück. Ob eine einzelne Seite unseres Internetauftrittes verschlüsselt übertragen wird, erkennen Sie an der geschlossenen Darstellung des Schüssel- beziehungsweise Schloss-Symbols in der unteren Statusleiste Ihres Browsers.</p>
                        <p>Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
