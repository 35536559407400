import React from 'react'
import { Route } from 'react-router';

export default function ExternalRoute(props) {
    return (
        <Route path={props.path} component={() => {
            window.location.replace(`${props.url}`);
            return null;
        }}/>
    )
}
