import React from 'react'
import { Link } from 'react-router-dom'
import EmailAddress from '../components/EmailAddress'
import PhoneNumber from '../components/PhoneNumber'
import ScrollToTopMount from '../components/ScrollToTopMount'

export default function ImpressumScreen() {
    return (
        <div className="px-5p pb-5">
            <ScrollToTopMount />
            <Link to="/imprint"><span className="text-white p-2 rounded float-right bg-blue-500">English Version</span></Link>
            <h1 className="text-3xl font-extrabold text-gray-900 tracking-tight py-2">Impressum</h1>
            <div className="space-y-4 divide-y">
                <div className="space-y-2">
                    <div className="text-sm">
                        <p>Münzgalerie München MGM</p>
                        <p>Handelsgesellschaft mbH &amp; Co. Joker KG</p>
                        <p>Stiglmaierplatz 2</p>
                        <p>80333 München</p>
                        <p>Tel: <PhoneNumber number="+49895233660" text="(089) 52 33 660"/> / <PhoneNumber number="+49895234362" text="(089) 52 34 362"/></p>
                        <p>Fax: <PhoneNumber number="+498932795990" text="(089) 32 79 59 90"/></p>
                        <p>vertreten durch: Münzgalerie München MGM Handelsgesellschaft mbH</p>
                        <br />
                        <p>E-Mail: <EmailAddress email="mgm@muenzgalerie.de"/></p>
                        <p>Hauptseite: <Link className="underline" to="//muenzgalerie.de">muenzgalerie.de</Link></p>
                        <br />
                        <p>Registergericht: Amtsgericht München</p>
                        <p>Registernummer: HRA 59787</p>
                        <br />
                        <p>Umsatzsteuer-IdNr.: DE130006976</p>
                        <br />
                        <p>Komplementärin:</p>
                        <p>Münzgalerie München MGM Handelsgesellschaft mbH</p>
                        <p>Stiglmaierplatz 2</p>
                        <p>80333 München</p>
                        <p>Geschäftsführerin: Susanne Benz</p>
                        <p>Geschäftsführer: Carl Benz</p>
                        <br />
                        <p>Registergericht: Amtsgericht München</p>
                        <p>Registernummer: HRB 49813</p>
                        <br />
                        <p>Inhaltlich Verantwortlicher gem. § 55 II RStV:</p>
                        <p>Susanne Benz</p>
                        <p>Stiglmaierplatz 2</p>
                        <p>80333 München</p>
                        <p>E-Mail: <EmailAddress email="mgm@muenzgalerie.de"/></p>
                        <p>Fax: <PhoneNumber number="+498932795990" text="(089) 32 79 59 90"/></p>
                        <p>Tel: <PhoneNumber number="+49895233660" text="(089) 52 33 660"/> / <PhoneNumber number="+49895234362" text="(089) 52 34 362"/></p>

                        <br />
                        <p><b>Haftungshinweis:</b></p>
                        <p>Die Inhalte unseres Internetauftritts insbesondere der Website wurden mit größter Sorgfalt erstellt und recherchiert. Dennoch übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
                            <br />
                            Diese Webseite sowie alle Ihre Bestandteile (insbesondere Texte, Bilder, oder andere graphische Elemente) sind urheberrechtlich geschützt. Jede mögliche Verwertung, die nicht ausdrücklich vom Urheberrechtsgesetz zugelassen wird,  wird hiermit untersagt. Dies betrifft insbesondere die Verbreitung, Vervielfältigung, Bearbeitung, Speicherung, Übersetzung und Wiedergabe in Datenbanken.
                        </p>
                        <br />
                        <p><b>Online-Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO:</b></p>
                        <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie unter <Link className="underline" to="//ec.europa.eu/consumers/odr/">ec.europa.eu/consumers/odr/</Link> finden.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
