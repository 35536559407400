export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL';
export const ORDER_CREATE_RESET = 'ORDER_CREATE_RESET';

export const ORDER_PAYMENT_REQUEST = 'ORDER_PAYMENT_REQUEST';
export const ORDER_PAYMENT_SUCCESS = 'ORDER_PAYMENT_SUCCESS';
export const ORDER_PAYMENT_FAIL = 'ORDER_PAYMENT_FAIL';
export const ORDER_PAYMENT_RESET = 'ORDER_PAYMENT_RESET';

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST';
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS';
export const ORDER_DETAILS_FAIL = 'ORDER_DETAILS_FAIL';

export const ORDER_SELF_LIST_REQUEST = 'ORDER_SELF_LIST_REQUEST';
export const ORDER_SELF_LIST_SUCCESS = 'ORDER_SELF_LIST_SUCCESS';
export const ORDER_SELF_LIST_FAIL = 'ORDER_SELF_LIST_FAIL';

export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST';
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS';
export const ORDER_LIST_FAIL = 'ORDER_LIST_FAIL';

export const ORDER_DELETE_REQUEST = 'ORDER_DELETE_REQUEST';
export const ORDER_DELETE_SUCCESS = 'ORDER_DELETE_SUCCESS';
export const ORDER_DELETE_FAIL = 'ORDER_DELETE_FAIL';
export const ORDER_DELETE_RESET = 'ORDER_DELETE_RESET';

export const ORDER_SEND_REQUEST = 'ORDER_SEND_REQUEST';
export const ORDER_SEND_SUCCESS = 'ORDER_SEND_SUCCESS';
export const ORDER_SEND_FAIL = 'ORDER_SEND_FAIL';
export const ORDER_SEND_RESET = 'ORDER_SEND_RESET';

export const ORDER_CONFIRM_REQUEST = 'ORDER_CONFIRM_REQUEST';
export const ORDER_CONFIRM_SUCCESS = 'ORDER_CONFIRM_SUCCESS';
export const ORDER_CONFIRM_FAIL = 'ORDER_CONFIRM_FAIL';
export const ORDER_CONFIRM_RESET = 'ORDER_CONFIRM_RESET';

export const ORDER_CONFIRM_NO_EMAIL_REQUEST = 'ORDER_CONFIRM_NO_EMAIL_REQUEST';
export const ORDER_CONFIRM_NO_EMAIL_SUCCESS = 'ORDER_CONFIRM_NO_EMAIL_SUCCESS';
export const ORDER_CONFIRM_NO_EMAIL_FAIL = 'ORDER_CONFIRM_NO_EMAIL_FAIL';
export const ORDER_CONFIRM_NO_EMAIL_RESET = 'ORDER_CONFIRM_NO_EMAIL_RESET';