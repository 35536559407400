import { WISH_ADD_ITEM, WISH_ADD_ITEM_FAIL, WISH_CREATE_FAIL, WISH_CREATE_REQUEST, WISH_CREATE_RESET, WISH_CREATE_SUCCESS, WISH_DELETE_FAIL, WISH_DELETE_REQUEST, WISH_DELETE_RESET, WISH_DELETE_SUCCESS, WISH_LIST_FAIL, WISH_LIST_REQUEST, WISH_LIST_SELF_FAIL, WISH_LIST_SELF_REQUEST, WISH_LIST_SELF_SUCCESS, WISH_LIST_SUCCESS, WISH_REMOVE_ITEM } from "../constants/wishConstants";

export const wishReducer = (state = {wishlistItems: []}, action) => {
    switch(action.type){
        case WISH_ADD_ITEM:
            const item = action.payload;
            const existItem = state.wishlistItems.find(x => x.product === item.product);
            if (!existItem) {
                return { ...state, error: '', wishlistItems: [...state.wishlistItems, item]};
            } else {
                return { ...state, error: '', wishlistItems: [...state.wishlistItems]};
            }
        case WISH_REMOVE_ITEM:
            return { ...state, error: '', wishlistItems: state.wishlistItems.filter(x=> x.product !== action.payload)};
        case WISH_ADD_ITEM_FAIL:
            return { ...state, error: action.payload};
        default:
            return state;
    }
}
export const wishListSelfReducer = (state = {wishes: []}, action) => {
    switch(action.type) {
        case WISH_LIST_SELF_REQUEST:
            return { loading: true };
        case WISH_LIST_SELF_SUCCESS:
            return { loading: false, success: true, wishes: action.payload };
        case WISH_LIST_SELF_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};


export const wishCreateReducer = (state = {}, action) => {
    switch(action.type) {
        case WISH_CREATE_REQUEST:
            return { loading: true } ;
        case WISH_CREATE_SUCCESS:
            return { loading: false, success: true, wishes: action.payload};
        case WISH_CREATE_FAIL:
            return { loading: false, error: action.payload};
        case WISH_CREATE_RESET:
            return {};
        default:
            return state;
    }
};

export const wishDeleteReducer = (state = {}, action) => {
    switch(action.type) {
        case WISH_DELETE_REQUEST:
            return { loading: true };
        case WISH_DELETE_SUCCESS:
            return { loading: false, success: true};
        case WISH_DELETE_FAIL:
            return { loading: false, error: action.payload};
        case WISH_DELETE_RESET:
            return {};
        default:
            return state;
    }
};

export const wishListReducer = (state = { wishes: [] }, action ) => {
    switch(action.type){
        case WISH_LIST_REQUEST:
            return {loading: true};
        case WISH_LIST_SUCCESS:
            return {loading: false, wishes: action.payload};
        case WISH_LIST_FAIL:
            return {loading: false, error: action.payload};
        default:
            return state;
    }
};