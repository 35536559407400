import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import OpeningHours from './OpeningHours';
import { useLocation } from 'react-router-dom'

export default function FooterBox() {
    const { t } = useTranslation(["footer"]);
    const location = useLocation();


    return (
        location.pathname.indexOf('world') > -1 ? <></> :
            <footer className="flex flex-row flex-wrap bg-gray-100 py-1 px-5p text-sm fg-gray-700 md:justify-center">
                <div className="py-9 grid grid-rows-4 md:grid-rows-none md:grid-cols-4 md:gap-15	 ">
                    <div>
                        <OpeningHours></OpeningHours>
                    </div>
                    <div className="">
                        <ul className="space-y-1">
                            <li><b>Service</b></li>
                            <li><Link to="/kontakt">{t("contact_form", "Kontaktformular")}</Link></li>
                            <li>{localStorage.getItem("lngCode") === "en" ? <Link to="/privacy">{t("copyright", "Datenschutz")}</Link> : <Link to="/datenschutz">{t("copyright", "Datenschutz")}</Link>}</li>
                            <li>{localStorage.getItem("lngCode") === "en" ? <Link to="/imprint">{t("impressum", "Impressum")}</Link> : <Link to="/impressum">{t("impressum", "Impressum")}</Link>}</li>
                            <li>{localStorage.getItem("lngCode") === "en" ? <Link to="/terms">{t("agb", "AGB")}</Link> : <Link to="/agb">{t("agb", "AGB")}</Link>}</li>
                            <li>{localStorage.getItem("lngCode") === "en" ? <Link to="/right-of-withdrawal">{t("widerruf", "Widerruf")}</Link> : <Link to="/widerruf">{t("widerruf", "Widerruf")}</Link>}</li>
                        </ul>
                    </div>
                    <div>
                        <ul className="space-y-1">
                            <li><b>{t("products", "Artikel")}</b></li>
                            <li><Link to="/category/muenzen">{t("coins", "Münzen")}</Link></li>
                            <li><Link to="/category/medaillen">{t("medals", "Medaillen")}</Link></li>
                            <li><Link to="/category/banknoten">{t("banknotes", "Banknoten")}</Link></li>
                            <li><Link to="/category/numismatische_literatur">{t("numlit", "Numismatische Literatur")}</Link></li>
                            <li><Link to="//mgm-schmuck.de">Schmuck</Link></li>
                        </ul>
                    </div>
                    <div className="space-y-1">
                        <span><b>{t("payment_methods", "Zahlungsmöglichkeiten")}</b></span>
                        <div className="flex flex-wrap space-x-2">
                            <span className="pl-2"><FontAwesomeIcon size="3x" icon={['fab', 'cc-visa']} /></span>
                            <span><FontAwesomeIcon size="3x" icon={['fab', 'cc-amex']} /></span>
                            <span><FontAwesomeIcon size="3x" icon={['fab', 'cc-mastercard']} /></span>
                            <span><FontAwesomeIcon size="3x" icon={['fab', 'cc-paypal']} /></span>
                            <span><img className="w-12 pt-1" src="/images/vorkasse.svg" alt="Vorkasse" /></span>
                        </div>
                        <div className="pt-10 ">
                            <div className="flex flex-row space-x-5">
                                <div className="text-xs flex flex-col justify-evenly">
                                    <img className="mr-auto w-32 ml-auto" src="/images/siegel.png" alt="siegel"></img>
                                    <p className="text-center">Vertreten im Verband der Deutschen Münzenhändler e.V.</p>
                                </div>
                                <div className="text-xs flex flex-col justify-evenly">
                                    <img className="mr-auto w-20 ml-auto" src="/images/assoc.png" alt="siegel"></img>
                                    <p className="text-center">Member of the International Association of Professional Numismats (IAPN)</p>
                                </div>
                            </div>

                        </div>
                        <div className="pt-2 text-xs">
                            <hr />
                            <p className="text-right">© {new Date().getFullYear()} Münzgalerie München MGM Handelsgmbh &amp; Co. Joker KG</p>
                            <p className="text-right">Edelmetallpreise von gold.de</p>
                        </div>
                    </div>
                </div>
            </footer>
    );
}