import React from 'react'
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../BreadCrumbs';

export default function AuslandCoinGrid() {
    const { t } = useTranslation(["navbar"]);
    return (
        <div>
            <BreadCrumbs steps={[{title:t("coins", "Münzen"), link:"/category/muenzen"}, {title:t("foreign_coins", "Ausländische Münzen")}]}/>
        </div>
    )
}

