import React, { Fragment } from 'react'
import { Link } from 'react-router-dom';
import ReactTooltip from "react-tooltip";

export default function SteinWidget(props) {

    const val = props.value.toString();
    console.log(val);
    return (
        <Fragment key={props.key + "steinGrad"}>


            <ReactTooltip id={`steinGrad${val}`} >
                <div className='max-w-lg'>
                    <div className='flex flex-col space-y-2 text-sm'>
                        {val.includes("Turmaline") &&
                            <div>
                                <b className="text-green-300">Turmalin</b> :

                                <ul className='list-disc pl-5'>
                                    <li>Verschiedene Einzelminerale, daher unterschiedliche chemische Zusammensetzung</li>
                                    <li>Kommt vom singhalesischen Wort <i>thuramali</i> - allg. Sammelbegriff für bunte Schmuckstücke aus Sri Lanka</li>
                                    <li>Vorkommen in Brasilien, Mosambik, Nigeria, Namibia</li>
                                </ul>
                                <ul className='list-square pl-5 text-purple-300'>
                                    <li>Symbolstein des Geburtsmonats September</li>
                                    <li>Kann allen Sternzeichen zugeordnet werden</li>
                                    <li>Soll Narben schneller verschwinden lassen, indem man darüber streicht</li>
                                </ul>
                            </div>
                        }
                        {val.includes("Rubin") &&
                            <div>
                                <b className="text-green-300">Rubin</b> :

                                <ul className='list-disc pl-5'>
                                    <li>Varietät des Minerals Korund (Aluminiumoxid), bei Rubinen ist Chrom das farbgebende Element</li>
                                    <li>Einer der seltensten Edelsteine der Welt und nach dem Diamanten das zweithärteste Mineral</li>
                                    <li>Abgeleitet aus dem mittelalterlichen <i>rubens</i> für rot</li>
                                    <li>Kommen insbesondere aus Myanmar, Thailand und Sri Lanka. Ostafrikanische Rubine sind auch begehrt.</li>
                                </ul>
                                <ul className='list-square pl-5 text-purple-300'>
                                    <li>Steht für Romantik, Liebe und Leidenschaft</li>
                                    <li>Symbolstein des Geburtsmonats Juli</li>
                                    <li>Werden den Sternzeichen Widder, Skorpion und Jungfrau zugeordnet</li>
                                </ul>
                            </div>
                        }
                        {val.includes("Smaragd") &&
                            <div>
                                <b className="text-green-300">Smaragd</b> :

                                <ul className='list-disc pl-5'>
                                    <li>Varietät des Minerals Beryll, durch Beimengung von Chrom- und Vanadium Ionen grüne Färbung</li>
                                    <li>Abgeleitet aus dem Griechischen <i>smaragdos</i> für grüner Stein</li>
                                    <li>Kommen v.A. aus Kolumbien und Brasilien, auch in Südafrika gibt es kleine Fundstellen</li>
                                </ul>
                                <ul className='list-square pl-5 text-purple-300'>
                                    <li>Steht für Schönheit, Gerechtigkeit und Harmonie</li>
                                    <li>Symbolstein des Geburtsmonats Mai</li>
                                    <li>Wird Sternzeichen Krebs und Waage zugeordnet</li>
                                    <li>Bringt dem Träger seelisches Gleichgewicht und Klarheit</li>
                                </ul>
                            </div>
                        }
                        {val.includes("Saphir") &&
                            <div>
                                <b className="text-green-300">Saphir</b> :

                                <ul className='list-disc pl-5'>
                                    <li>Varietät des Minerals Korund (Aluminiumoxid), bei Saphiren stammt die Farbe von den Elementen Eisen und Titan</li>
                                    <li>Meist blau, aber auch alle anderen Farben außer rot</li>
                                    <li>Abgeleitet aus dem griechischen <i>sappheiros</i> für blau oder aus dem sanskritischen sanipriya, dem Saturn heilig</li>
                                    <li>Vorkommen Kaschmir, Burma, Sri Lanka, Indien und neuerdings in den USA</li>
                                </ul>
                                <ul className='list-square pl-5 text-purple-300'>
                                    <li>Steht für Ruhe, Reinheit und Frieden</li>
                                    <li>Symbolstein des Geburtsmonats September</li>
                                    <li>Wird Sternzeichen Jungfrau zugeordnet</li>
                                    <li>Heilstein für Schlaflosigkeit, Darmentzündungen, hoher Blutdruck, chronische Entzündungen</li>
                                </ul>
                            </div>
                        }
                        {val.includes("Amethyst") &&
                            <div>
                                <b className="text-green-300">Amethyst</b> :

                                <ul className='list-disc pl-5'>
                                    <li>Violette Varietät des Minerals Quarz</li>
                                    <li>Fundorte in Brasilien, Uruguay, Namibia, etc.</li>
                                </ul>
                                <ul className='list-square pl-5 text-purple-300'>
                                    <li>Stein wirkt schmerzlindernd, beeinflusst körperliche Regeneration von Krankheiten positiv</li>
                                    <li>Sternzeichen: Jungfrau, Fisch, Widder, Wassermann und Steinbock</li>
                                    <li>Hilft bei Migräne, Kopfschmerzen, Wetterfühligkeit, Verspannungen und Akne</li>
                                </ul>
                            </div>
                        }
                        {val.includes("Citrin") &&
                            <div>
                                <b className="text-green-300">Citrin</b> :

                                <ul className='list-disc pl-5'>
                                    <li>Gelbfarbige Varietät des Minerals Quarz</li>
                                    <li>Aus dem lateinischen <i>citrus</i> „Zitrone“</li>
                                    <li>Fundorte in Argentinien und Brasilien, jedoch sind natürliche Citrine sehr selten, meistens handelt es sich um gebrannte Amethyste</li>
                                </ul>
                                <ul className='list-square pl-5 text-purple-300'>
                                    <li>Wird als Heilstein gerne bei Diabeteserkrankungen verwendet, soll besonders bei Kindern eine starke Wirkung zeigen</li>
                                    <li>Soll Selbstbewusstsein stärken, bei Verdauungsproblemen, Magenentzündungen, Arthritis, Hautentzündungen und Gebärmutterentzündungen helfen</li>
                                    <li>Galt schon bei den Römern als Bewahrer vor dem bösen Blick, stärkt die Körpermitte</li>
                                </ul>
                            </div>
                        }
                        {val.includes("Granat") &&
                            <div>
                                <b className="text-green-300">Citrin</b> :

                                <ul className='list-disc pl-5'>
                                    <li>Mineral der Inselsilikate</li>
                                    <li>Aus dem lateinischen Granum „<i>Korn</i>“ oder „<i>Kern</i>“, bezieht sich auf das Vorkommen des Minerals in Körnern</li>
                                    <li>Fundorte in v.A. in Südafrika, USA oder Sri Lanka</li>
                                </ul>
                                <ul className='list-square pl-5 text-purple-300'>
                                    <li>Schenkt Halt, Zuversicht, Vertrauen und Hoffnung</li>
                                    <li>Kräftigung des Herzens, hilft bei Gicht, Kopfschmerzen und Fieber</li>
                                    <li>Sternzeichen: Skorpion</li>
                                </ul>
                            </div>
                        }





                    </div>

                </div>
            </ReactTooltip>

            <Link to={props.to} data-tip data-for={`steinGrad${val}`} className="text-blue-500 cursor-pointer dotted-underline">{val}</Link>
        </Fragment>
    )
}
