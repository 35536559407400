import React from 'react'
import { Link } from 'react-router-dom';

export default function TagWidget(props) {
    return (
        <>
            <div className="font-semibold col-span-2">Suchkriterien</div><div className="col-span-4 flex space-x-1 ">
                {props.value && props.value.map((item, n) => 
                    <>{n ? ", " : ""}<Link key={n} to={`/category/${props.category ? props.category.toLowerCase() : "all"}/?tag=${item['tag']}`} className="text-blue-500 hover:underline cursor-pointer">{item["tag"]}</Link></> 
            )} </div>
        </>
    )
}
