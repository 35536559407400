//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { detailsProduct } from '../actions/productActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import ProductDetailList from '../components/ProductDetailList';
import ImageSwiper from '../components/ImageSwiper';
import { useTranslation } from 'react-i18next';
import currencyFormat from '../utils/currency';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createWish } from '../actions/wishActions';
import { WISH_CREATE_RESET } from '../constants/wishConstants';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ProductQuestion from '../components/ProductQuestion';
import ProductSell from '../components/ProductSell';
import ShippingInformation from '../components/ShippingInformation';
import CustomProductDetailRouter from '../components/CustomProductDetailRouter';
import ImageZoomer from '../components/ImageZoomer';
import { newSeconds } from '../utils/date';

export default function ProductScreen(props) {
  const { t } = useTranslation(["product"]);

  const dispatch = useDispatch();
  const [qty, setQty] = useState(1);
  const [currentImage, setCurrentImage] = useState(0);
  const productId = props.match.params.id;
  const productDetails = useSelector(state => state.productDetails);
  const { loading, error, product } = productDetails;

  const wishCreate = useSelector(state => state.wishCreate);
  const { success: successWish } = wishCreate;

  useEffect(() => {
    dispatch({ type: WISH_CREATE_RESET });
    dispatch(detailsProduct(productId));
  }, [dispatch, productId]);
  const addToCartHandler = () => {
    props.history.push(`/cart/${productId}?qty=${qty}`);
  }

  const addToWishlistHandler = () => {
    dispatch(createWish(productId))
  }

  const steuerklasseHandler = (steuerklasse) => {
    if (steuerklasse === 0) {
      return "*" + t("steuerklasse0", "Umsatzsteuerfrei gemäß § 25 c UStG");
    } else if (steuerklasse === 1 || steuerklasse === 2) {
      return "*" + t("inklmwst", "inklusive Mehrwertsteuer");
    } else if (steuerklasse === 5) {
      return "*" + t("steuerklasse5", "Steuerfreie Ausfuhr");
    } else if (steuerklasse === 6) {
      return "*" + t("steuerklasse6", "Steuerfreie Innergemeinschaftliche Lieferung (EU mit VAT)");
    } else if (steuerklasse === 7) {
      return "*" + t("steuerklasse7", "Steuerfrei gemäß § 4 Abs. 8b UStG");
    } else if (steuerklasse === 8) {
      return "*" + t("steuerklasse8", "Steuerschuldnerschaft des Leistungsgempfängers nach § 13b Abs. 5 S. 1 UStG i.V. m § 13b Abs. 2 Nr. 9 UStG i.V.m. 14a Abs. 5 UStG");
    }
    return "*" + t("diffbest", "Der Verkauf dieses Artikels unterliegt der Differenzbesteuerung nach § 25 a UStG")
  }

  return (
    <div className="padded-container">
      <div className="px-5p">

        {loading ? (
          <LoadingBox></LoadingBox>
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <div>
            {successWish && <MessageBox variant="success">{t("wish_create_success", "Artikel wurde zur Wunschliste zugefügt")}</MessageBox>}

            <div className="flex flex-col md:flex-row items-center md:items-start justify-center md:space-x-4  space-y-4">
              <div className="flex flex-col-reverse md:flex-row md:space-x-4">
           {new Date(product.createdAt).getTime() + newSeconds > Date.now() ? <div className="flex flex-row md:pt-0 pt-5 md:flex-col"><div className="text-sm px-2 items-center text-yellow-700 bg-yellow-100">{t("new", "Neu")}</div></div> : <div></div>}
              
                <div className="md:p-4 md:w-96">
                  {currentImage && currentImage.includes("mp4") ? 
                    <div className="flex  justify-center pb-5 w-96">
                      <video   playsInline muted autoPlay loop>
                        <source src={product.video}
                          type="video/mp4" />
                      </video>
                    </div>
                  :  
                
                  <ImageZoomer className="" src={currentImage || product.image[0]} alt={product.name} />
        }
                </div>
              </div>

              <div className="w-full md:w-96">
                <div className="bg-gray-100 p-5 rounded ">
                  <div className="flex flex-row">

                    <div className="flex justify-between w-full">

                      <div className="text-lg">
                        <h1 className='font-bold'>{product.brand}</h1>
                      </div>
                      <div className="">
                        <div className="text-xl">{  product.stock === 0 ? "" : `${currencyFormat(product.price)}*`}</div>
                      </div>
                    </div>
                  </div>
                  <ul className="">
                    <li>
                      <div className="">
                        <h1 className="">{product.name}</h1>
                      </div>
                    </li>
                    <CustomProductDetailRouter category={product.category} erhaltung={product.detail ? product.detail.Erhaltung ? product.detail.Erhaltung : '' : ''} />
                    <li>
                      <div className="">
                        <div>
                          {product.stock > 0 ? (
                            <div>
                              <div className="text-green-500">{t("available", "sofort lieferbar")}</div>
                              <div className="text-gray-900 text-sm">{t("shipment", "Lieferung ca.: 1 - 3 Werktage")} <span className="text-xs text-gray-500">({t("within_germany", "innerhalb Deutschlands")})</span></div>
                            </div>
                          ) : (
                            <div>
                              <p className="text-red-500">{t("soldout", "Ausverkauft")}</p>
                              <p className="text-sm text-gray-600">{t("soldout_msg", "Gerne informieren wir Sie, wenn dieser oder ein vergleichbarer Artikel wieder vorrätig sein sollte. Fügen Sie diesen einfach zu Ihrer Wunschliste hinzu.")}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </li>
                    {product.stock > 0 ? (
                      <>
                        <li className="py-2 space-y-2">
                          {product.stock > 1 &&
                            <div className="flex flex-row md:space-x-2 items-center">
                              <div className="text-sm">{t("qty", "Stk")}.:</div>
                              <div>
                                <select value={qty} onChange={e => setQty(e.target.value)}>
                                  {[...Array(product.stock).keys()].map(
                                    x => (
                                      <option key={x + 1} value={x + 1}>
                                        {x + 1}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>}
                          <div className="flex items-center">
                            <button type="button" onClick={addToCartHandler} className="transition duration-300 ease-in-out group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                              <span className="px-2"><FontAwesomeIcon size="lg" icon="shopping-bag" /></span>{t("shopping_cart", "In den Warenkorb")}
                            </button>
                          </div>
                        </li>
                        <li className="py-2">
                          <div>
                            <div>
                              <button type="button" onClick={addToWishlistHandler} className="transition duration-300 ease-in-out group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                <span className="px-2"><FontAwesomeIcon size="lg" icon={["fas", "heart"]} /></span>{t("add_to_wishlist", "Zur Wunschliste")}
                              </button>
                            </div>
                          </div>
                        </li>
                      </>
                    ) : <li><div>  <div className="py-2">
                      <button type="button" onClick={addToWishlistHandler} className="transition duration-300 ease-in-out group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <FontAwesomeIcon size="lg" icon={["fas", "heart"]} /> <span className="px-2"> {t("add_to_wishlist", "Zur Wunschliste")}</span>
                      </button>
                    </div>
                    </div>
                    </li>}
                  </ul>
                </div>
                <div className="text-gray-500 text-xs p-3">
                  {steuerklasseHandler(Number(product.steuerklasse))}

                </div>
              </div>
            </div>
            {product.image && product.image.length > 1 && <ImageSwiper setCurrentImage={setCurrentImage} alt={(product.brand+" "+product.name).replace(/[^a-zA-Z0-9 ]/g, "")} images={product.image} video={product.video ? product.video : ""}/>}

            <div className="flex flex-row items-center justify-center md:space-x-4 space-y-4 mb-10">

              <Tabs className="sm:w-full md:w-10/12 lg:w-8/12 text-xs md:text-sm">
                <TabList className="space-x-2 text-gray-400">
                  <Tab className="select-none focus:outline-none inline-block py-2 px-2 cursor-pointer uppercase" selectedClassName="text-black border-bottom border-blue-500">{t("details", "Details")}</Tab>
                  <Tab className="select-none focus:outline-none inline-block py-2 px-2 cursor-pointer uppercase" selectedClassName="text-black border-bottom border-blue-500">{t("shipping_info", "Versand")}</Tab>
                  <Tab className="select-none focus:outline-none inline-block py-2 px-2 cursor-pointer uppercase" selectedClassName="text-black border-bottom border-blue-500">{t("questions", "Fragen")}</Tab>
                  <Tab className="select-none focus:outline-none inline-block py-2 px-2 cursor-pointer uppercase" selectedClassName="text-black border-bottom border-blue-500">{t("sell", "Verkaufen")}</Tab>
                </TabList>
                <TabPanel selectedClassName="block bg-gray-100 px-5 py-5 h-full" className="h-0">
                  <ProductDetailList detail={product.detail} category={product.category} item_number={product.item_number} />
                </TabPanel>

                <TabPanel selectedClassName="block bg-gray-100 px-5 py-5 h-full" className="h-0">
                  <ShippingInformation />
                </TabPanel>
                <TabPanel selectedClassName="block bg-gray-100 px-5 py-5 h-full" className="h-0">
                  <ProductQuestion product={product} />
                </TabPanel>
                <TabPanel selectedClassName="block bg-gray-100 px-5 py-5 h-full" className="h-0">
                  <ProductSell product={product} />
                </TabPanel>
              </Tabs>

            </div>
          </div >
        )
        }
      </div >
    </div>

  );

}
