import { useEffect } from 'react'

export default function useBackButton(action) {
    useEffect(()=> {
        function goBackTwoPages(e) {
            action ()
        }
        window.addEventListener('popstate', goBackTwoPages);
        return () => window.removeEventListener('popstate', goBackTwoPages)
    },)
   
}
