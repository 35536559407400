import Axios from "axios";
import {
    PRODUCT_CREATE_FAIL,
    PRODUCT_CREATE_REQUEST,
    PRODUCT_CREATE_SUCCESS,
    PRODUCT_DELETE_FAIL,
    PRODUCT_DELETE_REQUEST,
    PRODUCT_DELETE_SUCCESS,
    PRODUCT_DETAILS_FAIL,
    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_FILTER_REQUEST,
    PRODUCT_IMPORT_FAIL,
    PRODUCT_IMPORT_REQUEST,
    PRODUCT_IMPORT_SUCCESS,
    PRODUCT_INDEX_FAIL,
    PRODUCT_INDEX_REQUEST,
    PRODUCT_INDEX_SUCCESS,
    PRODUCT_LIST_FAIL,
    PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_LITERATURE_FAIL,
    PRODUCT_LITERATURE_REQUEST,
    PRODUCT_LITERATURE_SUCCESS,
    PRODUCT_NEWEST_FAIL,
    PRODUCT_NEWEST_REQUEST,
    PRODUCT_NEWEST_SUCCESS,
    PRODUCT_SEARCH_FAIL,
    PRODUCT_SEARCH_REQUEST,
    PRODUCT_SEARCH_SUCCESS,
    PRODUCT_UPDATE_FAIL,
    PRODUCT_UPDATE_REQUEST,
    PRODUCT_UPDATE_SUCCESS,
    PRODUCT_ZERO_FAIL,
    PRODUCT_ZERO_REQUEST,
    PRODUCT_ZERO_SUCCESS,
} from "../constants/productConstants";

export const listProducts =
    ({
        id = "",
        text = "",
        category = "",
        order = "",
        minPrice = 0,
        maxPrice = 0,
        custom = "",
        stock = "",
        legierung = "",
        pagesize = "",
        pageNumber = "",
    }) =>
    async (dispatch) => {
        dispatch({
            type: PRODUCT_LIST_REQUEST,
        });
        try {
            const { data } = await Axios.get(
                `/api/products?id=${id}&custom=${custom}&pageNumber=${pageNumber}&text=${text}&category=${category}&minPrice=${minPrice}&maxPrice=${maxPrice}&order=${order}&stock=${stock}&legierung=${legierung} &pagesize=${pagesize}`
            );
            dispatch({ type: PRODUCT_LIST_SUCCESS, payload: data });
        } catch (error) {
            dispatch({ type: PRODUCT_LIST_FAIL, payload: error.message });
        }
    };

export const listProducts2 = (category, search) => async (dispatch) => {
    dispatch({
        type: PRODUCT_LIST_REQUEST,
    });
    try {
        const { data } = await Axios.get(
            `/api/products/search/?type=${category}&${
                search ? search.substring(1) : ""
            }`
        );
        dispatch({ type: PRODUCT_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: PRODUCT_LIST_FAIL, payload: error.message });
    }
};
export const detailsProduct = (productId) => async (dispatch) => {
    dispatch({
        type: PRODUCT_DETAILS_REQUEST,
        payload: productId,
    });
    try {
        const { data } = await Axios.get(`/api/products/${productId}`);
        dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: PRODUCT_DETAILS_FAIL,
            payload:
                error.response || error.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const createProduct = () => async (dispatch, getState) => {
    dispatch({ type: PRODUCT_CREATE_REQUEST });
    const {
        userSignin: { userInfo },
    } = getState();
    try {
        const { data } = await Axios.post(
            "/api/products/",
            {},
            {
                headers: { Authorization: `Bearer ${userInfo.token}` },
            }
        );
        dispatch({ type: PRODUCT_CREATE_SUCCESS, payload: data.products });
    } catch (error) {
        dispatch({
            type: PRODUCT_CREATE_FAIL,
            payload:
                error.response || error.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const searchProductViaCat = (cat1) => async (dispatch, getState) => {
    dispatch({ type: PRODUCT_LIST_REQUEST });
    try {
        const { data } = await Axios.get(
            `/api/products/cat1_search?cat1=${cat1}`
        );
        dispatch({ type: PRODUCT_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: PRODUCT_LIST_FAIL,
            payload:
                error.response || error.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const updateProduct = (product) => async (dispatch, getState) => {
    dispatch({ type: PRODUCT_UPDATE_REQUEST, payload: product });
    const {
        userSignin: { userInfo },
    } = getState();
    try {
        const { data } = await Axios.put(
            `/api/products/${product._id}`,
            product,
            {
                headers: { Authorization: `Bearer ${userInfo.token}` },
            }
        );
        dispatch({ type: PRODUCT_UPDATE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: PRODUCT_UPDATE_FAIL,
            payload:
                error.response || error.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteProduct = (productId) => async (dispatch, getState) => {
    dispatch({ type: PRODUCT_DELETE_REQUEST, payload: productId });
    const {
        userSignin: { userInfo },
    } = getState();
    try {
        const { data } = await Axios.delete(`/api/products/${productId}`, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: PRODUCT_DELETE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: PRODUCT_DELETE_FAIL,
            payload:
                error.response || error.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const zeroProduct = (productId) => async (dispatch, getState) => {
    dispatch({ type: PRODUCT_ZERO_REQUEST, payload: productId });
    const {
        userSignin: { userInfo },
    } = getState();
    try {
        const { data } = await Axios.put(
            `/api/products/zero/${productId}`,
            {},
            {
                headers: { Authorization: `Bearer ${userInfo.token}` },
            }
        );
        dispatch({ type: PRODUCT_ZERO_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: PRODUCT_ZERO_FAIL,
            payload:
                error.response || error.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const listLiteratures = () => async (dispatch, getState) => {
    dispatch({ type: PRODUCT_LITERATURE_REQUEST });
    try {
        const { data } = await Axios.get(`/api/products/literaturelist/`);
        dispatch({ type: PRODUCT_LITERATURE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: PRODUCT_LITERATURE_FAIL,
            payload:
                error.response || error.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};


export const importProducts =
    (category, productList) => async (dispatch, getState) => {
        dispatch({ type: PRODUCT_IMPORT_REQUEST, payload: category });
        const {
            userSignin: { userInfo },
        } = getState();
        try {
            const { data } = await Axios.post(
                "/api/products/import/",
                { category, productList },
                {
                    headers: { Authorization: `Bearer ${userInfo.token}` },
                }
            );
            dispatch({ type: PRODUCT_IMPORT_SUCCESS, payload: data });
        } catch (error) {
            dispatch({
                type: PRODUCT_IMPORT_FAIL,
                payload:
                    error.response || error.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };

export const searchProducts = (query) => async (dispatch, getState) => {
    dispatch({ type: PRODUCT_SEARCH_REQUEST, payload: query });
    try {
        const { data } = await Axios.get(`/api/products/search_query/${query}`);
        dispatch({ type: PRODUCT_SEARCH_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: PRODUCT_SEARCH_FAIL,
            payload:
                error.response || error.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const newestProducts = () => async (dispatch, getState) => {
    dispatch({ type: PRODUCT_NEWEST_REQUEST });
    try {
        const { data } = await Axios.get("/api/products/newest/");
        dispatch({ type: PRODUCT_NEWEST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: PRODUCT_NEWEST_FAIL,
            payload:
                error.response || error.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const filterProducts = (input) => async (dispatch, getState) => {
    dispatch({ type: PRODUCT_FILTER_REQUEST, payload: input });
    dispatch({ type: PRODUCT_LIST_REQUEST, payload: input });
    try {
        const { data } = await Axios.post("/api/products/filter/", {
            data: input,
        });
        dispatch({ type: PRODUCT_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: PRODUCT_LIST_FAIL,
            payload:
                error.response || error.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const searchIndex = (query) => async (dispatch, getState) => {
    dispatch({ type: PRODUCT_INDEX_REQUEST, payload: query });
    try {
        const { data } = await Axios.get(`/api/products/search_index/${query}`);
        dispatch({ type: PRODUCT_INDEX_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: PRODUCT_INDEX_FAIL,
            payload:
                error.response || error.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};
