import React from 'react'
import { Link } from 'react-router-dom'
import EmailAddress from '../components/EmailAddress'
import PhoneNumber from '../components/PhoneNumber'
import ScrollToTopMount from '../components/ScrollToTopMount'

export default function WiderrufScreen() {
    return (
        <div className="px-5p pb-5">
            <ScrollToTopMount />
            <Link to="/right-of-withdrawal"><span className="text-white p-2 rounded float-right bg-blue-500">English Version</span></Link>
            <h1 className="text-3xl font-extrabold text-gray-900 tracking-tight py-2">Widerrufsrecht</h1>
            <div className="py-2 space-y-2">
                <div className="space-y-2 text-sm">
                    <p><b>Widerrufsbelehrung</b></p>
                    <p><b>Widerrufsrecht</b></p>
                    <p>Sie können Ihre im Onlineshop der Münzgalerie München abgegebene Vertragserklärung innerhalb von 14 Tagen ohne Angabe von Gründen mündlich oder schriftlich in Textform (z. B. Brief, Fax, E-Mail [Münzgalerie München MGM Handelsgmbh &amp; Co. Joker KG, Stiglmaierplatz 2, 80333 München, Deutschland]) widerrufen. Die Widerrufsfrist beginnt, sobald Sie oder ein von Ihnen benannter Dritter, der nicht Frachtführer ist, die Waren erhalten haben bzw. hat. Sofern Sie mehrere Waren im Rahmen einer einheitlichen Bestellung bestellt haben und die Waren getrennt geliefert werden, beginnt die Widerrufsfrist, sobald Sie oder ein von Ihnen benannter Dritter, der nicht Frachtführer ist, die letzte Ware erhalten haben bzw. hat. Sollten Sie ihr Widerrufsrecht ausüben wollen, müssen Sie uns hierüber mittels einer eindeutigen Erklärung, in der Sie angeben, den Vertrag widerrufen zu wollen, informieren. Zur Wahrung der Widerrufsfrist genügt die rechtzeitige Absendung des Widerrufs vor Ablauf der Widerrufsfrist. Ein Musterformular zur Erklärung des Widerrufs (nachfolgend „Widerrufsformular“) finden Sie <Link className="underline" to="/documents/Withdrawal_Form_MGM.pdf">HIER</Link> zum Download. Ein Widerrufsformular senden wir Ihnen zudem per E-Mail zu und/oder fügen dies der bestellten Ware in Papierform bei. Die Verwendung des Musterformulars ist freiwillig und dient dazu, Ihnen die Ausübung des Widerrufsrechts zu erleichtern.
</p>
                    <br />
                    <p>Der Widerruf ist zu richten an</p>
                    <br />
                    <div className="pl-10 space-y-2">
                        <p>Münzgalerie München MGM</p>
                        <p>Handelsgesellschaft mbH &amp; Co. Joker KG</p>
                        <p>Stiglmaierplatz 2</p>
                        <p>D-80333 München</p>
                        <p>Telefon: <PhoneNumber number={"+49895233660"} text={"+49 (89) 5233660"}/></p>
                        <br />
                        <p>Telefax: <PhoneNumber number="+498932795990" text="(089) 32 79 59 90"/></p>
                        <p>E-Mail: <EmailAddress email="mgm@muenzgalerie.de"/></p>
                    </div>
                    <br />
                    <p><b>Widerrufsfolgen</b></p>
                    <p>Im Falle eines wirksamen Widerrufs sind die beiderseits empfangenen Leistungen zurückzugewähren und ggf. gezogene Nutzungen (z. B. Zinsen) herauszugeben. Können die empfangenen Leistungen sowie Nutzungen (z. B. Gebrauchsvorteile) nicht oder teilweise nicht oder nur in verschlechtertem Zustand zurückgewähren beziehungsweise herausgeben, müssen Sie insoweit Wertersatz leisten. Für die Verschlechterung der Sache und für gezogene Nutzungen müssen Sie Wertersatz nur leisten, soweit die Nutzungen oder die Verschlechterung auf einen Umgang mit der Sache zurückzuführen ist, der über die Prüfung der Eigenschaften und der Funktionsweise hinausgeht. Unter „Prüfung der Eigenschaften und der Funktionsweise“ versteht man das Testen und Ausprobieren der jeweiligen Ware, wie es etwa im Ladengeschäft möglich und üblich ist. Die Rücksendung erfolgt auf unsere Gefahr. Sofern der Käufer von seinem Widerrufsrecht Gebrauch macht, wird vereinbart, dass dieser die Kosten der Rücksendung zu tragen hat, wenn die gelieferte Ware der bestellten entspricht und wenn der Preis der zurückzusendenden Sache einen Betrag von 40 Euro nicht übersteigt, oder wenn der Käufer bei einem höheren Preis der Sache zum Zeitpunkt des Widerrufs noch nicht die Gegenleistung oder eine vertraglich vereinbarte Teilzahlung erbracht hat. Anderenfalls ist die Rücksendung für den Käufer kostenfrei. Die Münzgalerie München MGM Handelsgmbh &amp; Co. Joker KG  erstattet den gezahlten Kaufpreis im Falle eines erfolgreichen Widerrufs auf ein zu benennendes Konto des Kunden. Sollte die Rücknahme der Ware aus Kulanz erfolgen, besteht kein Anspruch auf eine Rückzahlung, sondern lediglich auf einen Gutschein.</p>
                    <p><b>Ausschluss des Widerrufsrechts</b></p>
                    <p>(1) Das Widerrufsrecht besteht gemäß § 312g Absatz 2 Nr. 1 BGB nicht bei Verträgen zur Lieferung von Waren, die nicht vorgefertigt sind und für deren Herstellung eine individuelle Auswahl oder Bestimmung durch den Verbraucher maßgeblich ist oder die eindeutig auf die persönlichen Bedürfnisse zugeschnitten sind. </p>
                    <p>(2) Kein Widerrufsrecht besteht gem. § 312 d IV Nr. 6 BGB bei Fernabsatzverträgen die die Lieferung von Waren zum Gegenstand haben, deren Preis auf dem Finanzmarkt Schwankungen unterliegt, auf die der Unternehmer keinen Einfluss hat und die innerhalb der Widerrufsfrist auftreten können. Hierzu zählen auch Edelmetalle, deren Wert täglichen Schwankungen unterliegt.</p>
                    <p><b>Ende der Widerrufsbelehrung</b></p>
                </div>
            </div>
        </div>
    )
}
