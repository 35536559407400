import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

export default function OldRightArrow(props) {
    return (
        <Link to={`${props.link}${props.page + 1}`} className={
            props.page === props.pages
                ? "pointer-events-none relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-gray-100 font-medium text-gray-500"
                : "relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white font-medium text-gray-500 hover:bg-gray-50"}>
            <span className="sr-only">&gt;</span>
            <FontAwesomeIcon icon="angle-right" />
        </Link>
    )
}
