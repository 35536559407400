import { ORDER_CONFIRM_FAIL, ORDER_CONFIRM_REQUEST, ORDER_CONFIRM_RESET, ORDER_CONFIRM_SUCCESS,  ORDER_CONFIRM_NO_EMAIL_FAIL, ORDER_CONFIRM_NO_EMAIL_REQUEST, ORDER_CONFIRM_NO_EMAIL_RESET, ORDER_CONFIRM_NO_EMAIL_SUCCESS,  ORDER_CREATE_FAIL, ORDER_CREATE_REQUEST, ORDER_CREATE_RESET, ORDER_CREATE_SUCCESS, ORDER_DELETE_FAIL, ORDER_DELETE_REQUEST, ORDER_DELETE_RESET, ORDER_DELETE_SUCCESS, ORDER_SEND_FAIL, ORDER_SEND_REQUEST, ORDER_SEND_RESET, ORDER_SEND_SUCCESS, ORDER_DETAILS_FAIL, ORDER_DETAILS_REQUEST, ORDER_DETAILS_SUCCESS, ORDER_LIST_FAIL, ORDER_LIST_REQUEST, ORDER_LIST_SUCCESS, ORDER_PAYMENT_FAIL, ORDER_PAYMENT_REQUEST, ORDER_PAYMENT_RESET, ORDER_PAYMENT_SUCCESS, ORDER_SELF_LIST_FAIL, ORDER_SELF_LIST_REQUEST, ORDER_SELF_LIST_SUCCESS } from "../constants/orderConstants";

export const orderCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case ORDER_CREATE_REQUEST:
            return { loading: true };
        case ORDER_CREATE_SUCCESS:
            return { loading: false, success: true, order: action.payload };
        case ORDER_CREATE_FAIL:
            return { loading: false, error: action.payload };
        case ORDER_CREATE_RESET:
            return {};
        default:
            return state;
    }
};

export const orderDetailsReducer = (state = { loading: true }, action) => {
    switch (action.type) {
        case ORDER_DETAILS_REQUEST:
            return { loading: true };
        case ORDER_DETAILS_SUCCESS:
            return { loading: false, order: action.payload };
        case ORDER_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const orderPayReducer = (state = {}, action) => {
    switch (action.type) {
        case ORDER_PAYMENT_REQUEST:
            return { loading: true };
        case ORDER_PAYMENT_SUCCESS:
            return { loading: false, success: true };
        case ORDER_PAYMENT_FAIL:
            return { loading: false, error: action.payload };
        case ORDER_PAYMENT_RESET:
            return {};
        default:
            return state;
    }
};

export const orderSelfListReducer = (state = { orders: [], }, action) => {
    switch (action.type) {
        case ORDER_SELF_LIST_REQUEST:
            return { loading: true };
        case ORDER_SELF_LIST_SUCCESS:
            return { loading: false, orders: action.payload };
        case ORDER_SELF_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const orderListReducer = (state = { orders: [] }, action) => {
    switch (action.type) {
        case ORDER_LIST_REQUEST:
            return { loading: true };
        case ORDER_LIST_SUCCESS:
            return { loading: false, orders: action.payload.orders, pages: action.payload.pages, page: action.payload.page, count: action.payload.count, order: action.payload };
        case ORDER_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const orderDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case ORDER_DELETE_REQUEST:
            return { loading: true };
        case ORDER_DELETE_SUCCESS:
            return { loading: false, success: true };
        case ORDER_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case ORDER_DELETE_RESET:
            return {};
        default:
            return state;
    }
};

export const orderSendReducer = (state = {}, action) => {
    switch (action.type) {
        case ORDER_SEND_REQUEST:
            return { loading: true };
        case ORDER_SEND_SUCCESS:
            return { loading: false, success: true };
        case ORDER_SEND_FAIL:
            return { loading: false, error: action.payload };
        case ORDER_SEND_RESET:
            return {};
        default:
            return state;
    }
};

export const orderConfirmReducer = (state = {}, action) => {
    switch (action.type) {
        case ORDER_CONFIRM_REQUEST:
            return { loading: true };
        case ORDER_CONFIRM_SUCCESS:
            return { loading: false, success: true };
        case ORDER_CONFIRM_FAIL:
            return { loading: false, error: action.payload };
        case ORDER_CONFIRM_RESET:
            return {};
        default:
            return state;
    }
};


export const orderConfirmNoMailReducer = (state = {}, action) => {
    switch (action.type) {
        case ORDER_CONFIRM_NO_EMAIL_REQUEST:
            return { loading: true };
        case ORDER_CONFIRM_NO_EMAIL_SUCCESS:
            return { loading: false, success: true };
        case ORDER_CONFIRM_NO_EMAIL_FAIL:
            return { loading: false, error: action.payload };
        case ORDER_CONFIRM_NO_EMAIL_RESET:
            return {};
        default:
            return state;
    }
};
