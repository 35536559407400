import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import currencyFormat from '../utils/currency';
import LoadingBox from './LoadingBox';

export default function ProductListItem(props) {
  const [didLoad, setLoad] = useState(false);
  const history = useHistory();

  const { product } = props;

  const handleClick = (e) => {
    if (e.type === 'click') {
      history.push(`/product/${product._id}`);
    }
  }
  return (
    <tr key={product._id} onClick={handleClick} className="hover:bg-gray-50 transition duration-300 ease-in-out cursor-pointer max-h-10 text-sm border-b text-gray-700">
      <td className=" p-2">
        {!didLoad && <LoadingBox variant="spinner" />}<img onLoad={() => setLoad(true)} className="w-10" src={product.image[0]} alt="bild" onMouseOver={product.image.length > 1 ? e => (e.currentTarget.src = product.image[1]) : undefined} onMouseOut={product.image.length > 1 ? e => (e.currentTarget.src = product.image[0]) : undefined} />

      </td>
      <td className="hidden md:table-cell text-black">{product.brand}</td>
      <td className="text-xs md:text-sm p-2">{product.detail.Jahr}</td>
      <td className="text-xs md:text-sm p-2">{product.name}</td>
      <td className="font-bold text-sm md:text-base">{currencyFormat(product.price)}</td>
    </tr >
  );
}