import React from 'react'
import arrayContainsSubstring from '../utils/string';

export default function TagButton(props) {
    const classes = "text-sm cursor-pointer m-1 md:w-44 h-10 md:h-20 align-middle text-gray-400 transition duration-500 ease-in-out transform hover:-translate-y-1 rounded inline-block border-4 border-width-4 p-4"
    
    const tagButtonHandler = (e) => {
        e.preventDefault();
        if (props.type === "tag") {
            props.setCurrentTheme(["tag", arrayContainsSubstring(props.currentTheme[1], props.value) ? props.currentTheme[1].filter(x => !x.includes(props.value)): props.currentTheme[1], props.currentTheme[2].includes(props.value) ? props.currentTheme[2].filter(x => x !== props.value): [...props.currentTheme[2], props.value]])
            
        } else if (props.type === "subtag") {
            props.setCurrentTheme(["subtag", props.currentTheme[1].includes(`${props.owner}:${props.value}`) ? props.currentTheme[1].filter(x => x !== `${props.owner}:${props.value}`) : [...props.currentTheme[1], `${props.owner}:${props.value}`], props.currentTheme[2].includes(props.owner) ? props.currentTheme[2]: [...props.currentTheme[2], props.owner]])
        }
    };
    return (
        <label htmlFor={props.value}>

            <div className={
                props.type === "tag" && (props.currentTheme[2].includes(props.value) || props.currentTheme[2].length === 1)
                ? "text-purple-800 bg-purple-100 hover:bg-purple-200 border-purple-300 " + classes 
                : props.type === "subtag" && arrayContainsSubstring(props.currentTheme[1], props.value)
                ? "text-blue-800 bg-blue-100 hover:bg-blue-200 border-blue-300 " + classes : 
                props.type === "subtag" && props.currentTheme[2].includes(props.owner)
                ? "text-gray-800 bg-gray-100 hover:bg-gray-200 border-gray-300 " + classes  :
                props.type === "subtag" && !props.currentTheme[2].includes(props.owner) ? "hidden" : classes}>
                <div className="flex h-full justify-center items-center text-center">
                    <input name={props.type} onClick={tagButtonHandler} id={props.value} type="radio" value={props.value} className="hidden" />
                    <span className="w-full select-none">{props.children}</span>
                </div>
            </div>
        </label>
    )
}
