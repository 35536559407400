import React from 'react'
import { useTranslation } from 'react-i18next';

export default function CoinsCategoryScreen(props) {
    const { t } = useTranslation(["navbar"]);


    return (
        <div className="px-5p space-y-2">
            <h1 className="text-3xl font-extrabold text-gray-900 tracking-tight py-2">{t("coins_by_place", "Münzen nach Sammelgebiete")}</h1>
            <div className="flex items-center flex-col space-y-2 md:flex-row md:place-content-center py-2 md:space-y-0 md:space-x-2">
                <button className="rounded transition duration-500 ease-in-out transform hover:translate-y-1 bg-gray-100 hover:bg-blue-50 w-52 h-52 shadow" onClick={() => props.history.push("/category/altdeutsche_muenzen/")}>
                    <div className="font-bold">{t("old_german_coins", "Altdeutsche Münzen")}</div>
                    <p className="text-gray-500 text-sm">description...</p>
                </button>
                <button className="rounded transition duration-500 ease-in-out transform hover:translate-y-1 bg-gray-100 hover:bg-blue-50 w-52 h-52 shadow" onClick={() => props.history.push("/category/antike_muenzen/")}>
                    <div className="font-bold">{t("ancient_coins", "Antike Münzen")}</div>
                    <p className="text-gray-500 text-sm">description...</p>
                </button>
                <button className="rounded transition duration-500 ease-in-out transform hover:translate-y-1 bg-gray-100 hover:bg-blue-50 w-52 h-52 shadow" onClick={() => props.history.push("/category/auslaendische_muenzen/")}>
                    <div className="font-bold">{t("foreign_coins", "Ausländische Münzen")}</div>
                    <p className="text-gray-500 text-sm">description...</p>
                </button>
                <button className="rounded transition duration-500 ease-in-out transform hover:translate-y-1 bg-gray-100 hover:bg-blue-50 w-52 h-52 shadow" onClick={() => props.history.push("/category/deutschland_1871_muenzen/")}>
                    <div className="font-bold">{t("germany_1871_coins", "Deutschland ab 1871")}</div>
                    <p className="text-gray-500 text-sm">description...</p>
                </button>
                <button className="rounded transition duration-500 ease-in-out transform hover:translate-y-1 bg-gray-100 hover:bg-blue-50 w-52 h-52 shadow" onClick={() => props.history.push("/category/bullion_muenzen/")}>
                    <div className="font-bold">{t("bullion_coins", "Bullion Münzen")}</div>
                    <p className="text-gray-500 text-sm">description...</p>
                </button>
            </div>
        </div>
    )
}
