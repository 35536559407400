import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { listProducts2 } from '../actions/productActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import ProductGridScreen from './ProductGridScreen';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Pagination from '../components/Pagination';
import PageSizeWidget from '../components/PageSizeWidget';
import CustomFilterRouter from '../components/CustomFilterRouter';
import SubcategoryGrid from '../components/SubcategoryGridRouter';
import useDebouncedValue from '../components/useDebouncedValue';

import SubcategoryBottomTextRouter from '../components/SubcategoryBottomTextRouter';
import ScrollToTopMount from '../components/ScrollToTopMount';

export default function ProductOverviewScreen(props) {
  const { t } = useTranslation(["overview"]);

  const { text = 'all', minPrice = 0, maxPrice = 0, pagesize = 20, order = 'newest', stock = 'available', legierung = 1, pageNumber = 1, custom = 'all' } = useParams();

  const [maxPriceState, setMaxPriceState] = useState(maxPrice);
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const { loading, error, products, page, pages, count } = productList;

  const history = useHistory();
  const location = useLocation();

  const { category } = useParams();



  const debouncedPrice = useDebouncedValue(maxPriceState, 500);



  const triggerPriceChange = (value) => {
    // props.history.push(getFilterUrl({ maxPrice: value }));
  };





  const bubblesState = useSelector((state) => state.productFilter);
  const { filter: bubbleFilter } = bubblesState;



  useEffect(() => {

    if (debouncedPrice !== maxPrice) {
      triggerPriceChange(debouncedPrice);
    }
    dispatch(listProducts2(category, location.search));
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })

  }, [dispatch, location.search, category, minPrice, maxPrice, debouncedPrice, order, stock, legierung, pagesize, pageNumber, custom]);






  return (
    <div className="px-5p">
      <ScrollToTopMount />

      <div>
        <div className="">
          {loading ? (
            <LoadingBox></LoadingBox>
          ) : error ? (
            <MessageBox variant="danger">{error}</MessageBox>
          ) : (
            products.length === 0 && text !== 'all' && (
              <MessageBox>{t("search_part1", "Ihre Suche nach")} <b>{text}</b> {t("search_part2", "ergab leider keinen Treffer.")}<br /> {t("search_part3", "Überprüfen Sie die Rechtschreibung oder versuchen sie es mit einem anderen Begriff.")}</MessageBox>
            )
          )}

          <div>
            <SubcategoryGrid category={category} />
          </div>
          <div className="flex flex-col ">
            <div className="flex flex-col ">
              <div className="space-y-2 w-full ">
                {count ? <CustomFilterRouter category={category} page={page} pagesize={pagesize} /> : <></>}
              </div>
            </div>
            <div >
              {pages > 0 && <div className="hidden md:flex justify-center bg-gray-200 text-uppercase h-1px items-center my-5 "><div className="text-sm px-2 inline-block bg-white"> {count} {t("products", "Artikel")}</div></div>}
              <ProductGridScreen productList={productList} category={category} /> 
            </div>
          </div>
          {!bubbleFilter && count ?
            <>
              <div className="flex justify-end">
                <PageSizeWidget />
              </div>
              <div className="flex py-2 justify-center">
                <Pagination page={page} pages={pages} />
              </div>
            </> : <></>
          }
          <SubcategoryBottomTextRouter category={category} />


        </div>
      </div>
    </div>
  )
};