import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deleteWish, listWishSelf } from '../actions/wishActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { WISH_DELETE_RESET } from '../constants/wishConstants';
import currencyFormat from '../utils/currency';

export default function WishlistScreen(props) {
    const { t } = useTranslation(["wishlist"]);

    const userSignin = useSelector((state) => state.userSignin);
    const { userInfo } = userSignin;
    
    const wishlist = useSelector((state)=> state.wishes_cached);
    const { wishlistItems, error: errorCachedWishList } = wishlist;

    const wishListSelf = useSelector((state) => state.wishListSelf); 
    const { loading } = wishListSelf; 
    const dispatch = useDispatch();

    useEffect(()=> {
        dispatch({type: WISH_DELETE_RESET});
        dispatch(listWishSelf());
    }, [dispatch, userInfo]);
    const deleteHandler = (productId) => {
        dispatch(deleteWish(productId));
    }
    const addToCartHandler = (productId) => {
        props.history.push(`/cart/${productId}?qty=1`);
    }

    return (
        <div className="px-5p space-y-2">
 
            
            <h1 className="text-3xl font-extrabold text-gray-900 tracking-tight py-2">{t("my_wishlist", "Meine Wunschliste")}</h1>
            {loading ? <LoadingBox></LoadingBox> : 
                errorCachedWishList  ? <MessageBox variant="danger">{errorCachedWishList}</MessageBox> :
                    (  wishlistItems.length === 0
                        ? <MessageBox variant="information">{t("empty_wishlist","Ihre Wunschliste ist leer.")}</MessageBox>
                        : <div className="-my-2 overflow-x-auto md:-mx-6">
                            <div className="py-2 align-middle inline-block min-w-full md:px-6">
                                <div className="shadow overflow-hidden border-b border-gray-200 rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t("product", "Artikel")}</th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden md:table-cell">{t("price", "Preis")}</th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden md:table-cell">{t("stock", "Verfügbarkeit")}</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {
                                                wishlistItems.map((wish) => (
                                                    <tr key={wish.product}>
                                                    <td className="px-6 py-1 md:py-4 whitespace-nowrap block md:table-cell">
                                    
                                                        <div className="flex items-center">
                                                            <div className="flex-shrink-0 h-10 w-10">
                                                           
                                                                <Link to={`/product/${wish.product}`}>
                                                                    <img className="h-10 w-10 rounded-full" src={wish.image} alt={wish.name}/>
                                                                </Link>
                                                            </div>
                                                            <div className="ml-4">
                                                                <Link to={`/product/${wish.product}`}>
                                                                    <div className="text-sm font-medium text-gray-900">
                                                                        {wish.brand}
                                                                    </div>
                                                                    <div className="text-sm text-gray-500">
                                                                        {wish.name}
                                                                    </div>
                                                                    <div className="text-sm text-gray-900">
                                                                        {wish.item_number}
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-1 md:py-4  whitespace-nowrap text-sm text-gray-500 text-right md:text-left block md:table-cell">
                                                        <div className="flex justify-between">
                                                            <div className="inline: md:hidden">{t("price", "Preis")}</div>
                                                            <div>{currencyFormat(wish.price)}</div>
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-1 md:py-4 whitespace-nowrap text-sm text-gray-500 text-right md:text-left block md:table-cell">
                                                        <div className="flex justify-between">
                                                            <div className="inline: md:hidden">{t("stock", "Verfügbarkeit")}</div>
                                                            <div>{wish.stock > 0 ? <span className="px-2 border border-transparent rounded-md bg-green-100">{t("available","auf Lager")}</span>: <span className="px-2 border border-transparent rounded-md bg-red-100">{t("unavailable","nicht vorrätig")}</span>}</div>
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-1 md:py-4  whitespace-nowrap text-sm font-medium text-right md:text-left justify-between block  md:table-cell">
                                                        <div className="flex flex-col space-x-0 space-y-2 md:space-y-0 md:space-x-4 md:flex-row justify-end ">
                                                            {wish.stock > 0 && <button type="button" onClick={() => addToCartHandler(wish.product)} className="transition duration-300 ease-in-out group relative  flex justify-center py-2 px-2 border border-transparent text-xs font-medium rounded-md text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                                <span className="px-2"><FontAwesomeIcon size="1x" icon="shopping-bag"/></span>{t("shopping_cart","In den Warenkorb")}
                                                            </button>}
                                                            <button className="transition duration-300 ease-in-out text-red-600 hover:text-red-900" type="button" onClick={()=>deleteHandler(wish.product)}><FontAwesomeIcon icon="times"/></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}
        </div>
    )
}

// {errorDelete && <MessageBox variant="danger">{errorDelete}</MessageBox>}
// {successDelete && <MessageBox variant="success">Artikel wurde aus der Wunschliste entfernt</MessageBox>}