import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createGuestUser, signin } from '../actions/userActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';

export default function SigninScreen(props) {
    const { t } = useTranslation(["signin"]);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const redirect = props.location.search
        ? props.location.search.split('=')[1]
        : '/';

    const userSignin = useSelector((state) => state.userSignin);
    const { userInfo, loading, error } = userSignin;

    const userGuest = useSelector((state) => state.userGuest);
    const { loading: loadingGuest, error: errorGuest } = userGuest;
    const dispatch = useDispatch();
    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(signin(email, password));
    };

    const createGuest = (e) => {
        e.preventDefault();
        dispatch(createGuestUser());
    };

    useEffect(() => {
        if (userInfo) {
            props.history.push(redirect);
        }

    }, [props.history, redirect, userInfo]);
    return (
        <div className="flex flex-col px-5p">
            {(loading || loadingGuest) && <LoadingBox></LoadingBox>}
            {error && <MessageBox variant="danger">{error}</MessageBox>}
            {errorGuest && <MessageBox variant="danger">{errorGuest}</MessageBox>}

            <div className="flex flex-col md:flex-row space-y-10 divide-y md:divide-y-0 md:space-y-0 md:space-x-10 items-end justify-center py-12 px-4 px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8">
                    <div>
                        <img className="mx-auto h-12 w-auto" src="/images/favicon.png" alt="Workflow" />
                        <h2 className="mt-2 text-center text-3xl font-extrabold text-gray-900">
                            {t("signin", "Anmelden")}
                        </h2>
                        <p className="mt-2 text-center text-sm text-gray-600">
                            {t("new_user", "Neuer Benutzer")}?{' '}
                            <Link to={`/register?redirect=${redirect}`} className="font-medium text-indigo-600 hover:text-indigo-500">
                                {t("create_account", "Konto anlegen")}
                            </Link>
                        </p>
                    </div>
                    <form className="mt-8 space-y-6" onSubmit={submitHandler}>
                        <input type="hidden" name="remember" value="true" />
                        <div className="rounded-md shadow-sm -space-y-px">
                            <div>
                                <label htmlFor="email-address" className="sr-only">{t("email", "E-Mail Adresse")}</label>
                                <input id="email-address" name="email-address" type="email" autoComplete="email-address" onChange={(e) => setEmail(e.target.value)} required className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" placeholder={t("email", "E-Mail Adresse")} />
                            </div>
                            <div>
                                <label htmlFor="password" className="sr-only">{t("password", "Passwort")}</label>
                                <input id="password" name="password" type="password" autoComplete="current-password" onChange={(e) => setPassword(e.target.value)} required className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" placeholder={t("password", "Passwort")} />
                            </div>
                        </div>

                        <div className="flex items-center justify-between">
                            <div className="flex items-center">

                            </div>

                            <div className="text-sm">
                                <Link to="/reset-password" className="font-medium hover:text-gray-800">
                                    {t("forgot_password", "Passwort vergessen")}?
                                </Link>
                            </div>
                        </div>

                        <div>
                            <button type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                    <FontAwesomeIcon size="lg" icon="lock" />
                                </span>
                                {t("login", "Einloggen")}

                            </button>
                        </div>
                    </form>
                </div>
                {redirect === "shipping" &&
                    <div className="max-w-md w-full space-y-8">
             
                        <div className="mt-8">
                            <div className="rounded-md -space-y-px text-sm pb-6">
                                {t("guest_text", "Beachten Sie bitte, dass Sie Ihre Daten, wenn Sie sich nicht registrieren, bei jeder weiteren Bestellung erneut eingeben müssen. Die bestellungsrelevanten Daten müssen aufgrund gesetzlicher Aufbewahrungspflichten aber in jedem Fall wie bei einem Kundenkonto gespeichert werden.")}
                            </div>
                            <button type="submit" onClick={(e) => createGuest(e)} className="group relative w-full flex justify-center mt-12 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                {t("continue_as_guest", "Als Gast fortfahren")}
                            </button>
                        </div>
                    </div>
                }
            </div>
        </div>

    );
}