import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { listLiteratures } from '../actions/productActions';
import ProductBox from '../components/ProductBox';
import AccordionComponent from '../components/AccordionComponent';

export default function LiteratureScreen(props) {
    const { t } = useTranslation(["wishlist"]);
    const [searchInput, setSearchInput] = useState("")

    const userSignin = useSelector((state) => state.userSignin);
    const { userInfo } = userSignin;

    const productLiterature = useSelector((state) => state.productLiterature);
    const { loading, error, literatures } = productLiterature;


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(listLiteratures());
    }, [dispatch]);

    useEffect(() => {

    },[searchInput])

    return (
        <div className="px-5p space-y-2">


            <h1 className="text-3xl font-extrabold text-gray-900 tracking-tight py-2">{t("literatur_search", "Suche nach Literatur")}</h1>
            {loading ? <LoadingBox></LoadingBox> :
                error ? <MessageBox variant="danger">{error}</MessageBox>
                    :
                    // JSON.stringify(literatures)
                    <>
                        <div className='space-y-2 md:w-96 '>
                            <div className='border flex flex-row'>
                                <span className="flex px-2 bg-gray-100 items-center"><FontAwesomeIcon icon="search" /></span>
                                <input placeholder="Literatur Abkürzung / Titel" onChange={(e) => setSearchInput(e.target.value)} className="w-full p-2 text-left focus:outline-none text-sm" value={searchInput} />
                            </div>
                        </div>
                        {
                            literatures.sort((a, b) => a.shortname.localeCompare(b.shortname)).map((literature, i) =>
                            (!searchInput || literature.shortname.toUpperCase().indexOf(searchInput.toUpperCase()) > -1 ) || literature.titles.map(item => { return item.name.toUpperCase()}).filter(name => name.indexOf(searchInput.toUpperCase()) > -1 ).length > 0 ? 
                            <div className="w-full" key={i}>
                                    <div className='font-semibold'>{literature.shortname}</div>
                                    <div className='pl-5 flex flex-wrap w-full flex-col'>
                                        {literature.titles.sort((c, d) => c.name.localeCompare(d.name)).map((title, j) =>
                                            <div className='italic text-gray-800 w-full' key={j}>
                                                <AccordionComponent titleClass={`${searchInput && title.name.toUpperCase().indexOf(searchInput.toUpperCase()) > -1 ? "font-bold": "font-normal"}`} title={`${title.name} (${title.items.length})`} id={j}>
                                                    <div className='grid grid-cols-1 md:grid-cols-8 not-italic list-none align-center bg-gray-100 border p-3 gap-3'>
                                                        {title.items.sort((e, f) => e.literaturident && f.literaturident && e.literaturident.localeCompare(f.literaturident, undefined, { numeric: true, sensitivity: 'base' })).map((item, k) =>
                                                            <ProductBox height="x" key={k} catalogident={item.literaturident} product={item} />

                                                        )}
                                                    </div>
                                                </AccordionComponent>
                                            </div>
                                        )}
                                    </div>

                                </div> : <></>
                            )
                        }

                    </>
            }

        </div>
    )
}

// {errorDelete && <MessageBox variant="danger">{errorDelete}</MessageBox>}
// {successDelete && <MessageBox variant="success">Artikel wurde aus der Wunschliste entfernt</MessageBox>}