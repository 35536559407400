export default function currencyFormat(value) {
    const lng = localStorage.getItem("lngCode")
    if (lng === "de") {
        return new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR'
        }).format(value)
    } else {
        return new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'EUR'
        }).format(value)
    }
}


export function percentChange(y1, y2) {
     return (((y2-y1)/y1)*100).toFixed(2);
}
    