import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function BreadCrumbs(props) {
    return (
        <ul className="text-sm md:text-base mt-6 flex space-x-3 text-gray-500">
            <li className='text-sm tracking-widest'><Link to="/"><FontAwesomeIcon icon="home" /></Link></li>
            {props.steps.map(((step, i) => (
                i === props.steps.length - 1 ?
                    <li key={i} className='space-x-3 text-gray-500 uppercase text-sm tracking-widest'><span>›</span><span>{step.title}</span></li>
                    :
                    <li key={i} className='space-x-3 text-gray-800 uppercase text-sm tracking-widest'><span>›</span><Link to={step.link}>{step.title}</Link></li>)))
            }
        </ul>
    )
}