import { CART_ADD_ITEM, CART_ADD_ITEM_FAIL, CART_EMPTY, CART_REMOVE_ITEM, CART_SAVE_SHIPPING_INFO, CART_ITEM_AVAILABLE_REQUEST, CART_ITEM_AVAILABLE_SUCCESS, CART_ITEM_AVAILABLE_FAIL, CART_ITEM_AVAILABLE_RESET  } from "../constants/cartConstants";

export const cartReducer = (state = { cartItems: [] }, action) => {
    switch (action.type) {
        case CART_ADD_ITEM:
            const item = action.payload;
            const existItem = state.cartItems.find(x => x.product === item.product);
            if (existItem) {
                return {
                    ...state,
                    error: '',
                    cartItems: state.cartItems.map((x) =>
                        x.product === existItem.product ? item : x
                    ),
                }
            } else {
                return { ...state, error: '', cartItems: [...state.cartItems, item] };
            }
        case CART_REMOVE_ITEM:
            return {
                ...state, error: '', cartItems: state.cartItems.filter(x => x.product !== action.payload),
            };
        case CART_SAVE_SHIPPING_INFO:
            return { ...state, shippingInfo: action.payload };
        case CART_ADD_ITEM_FAIL:
            return { ...state, error: action.payload };
        case CART_EMPTY:
            return { ...state, error: '', cartItems: [] };
        default:
            return state;
    }
};

export const cartAvailableReducer = (state = {}, action) => {
    switch (action.type) {
        case CART_ITEM_AVAILABLE_REQUEST:
            return { loading: true };
        case CART_ITEM_AVAILABLE_SUCCESS:
            return { loading: false, unavailable: action.payload };
        case CART_ITEM_AVAILABLE_FAIL:
            return { loading: false, error: action.payload };
        case CART_ITEM_AVAILABLE_RESET:
            return {};
        default:
            return state;
    }
};
