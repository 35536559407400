import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentMetal } from '../actions/metalActions';
import { GET_METAL_RESET } from "../constants/metalConstants";
import currencyFormat, { percentChange } from '../utils/currency';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function PricesWidget() {
    const { t } = useTranslation(["prices"]);

    const [currentGold, setCurrentGold] = useState(0);
    const [currentSilver, setCurrentSilver] = useState(0);
    const [currentPlatinum, setCurrentPlatinum] = useState(0);
    const [currentPalladium, setCurrentPalladium] = useState(0);
    const [goldPercent, setGoldPercent] = useState(0);
    const [silverPercent, setSilverPercent] = useState(0);
    const [platinumPercent, setPlatinumPercent] = useState(0);
    const [palladiumPercent, setPalladiumPercent] = useState(0);

    const [widgetVisible, setWidgetVisible] = useState(true);

    const metals = useSelector(state => state.metals);
    const { metal } = metals;
    const dispatch = useDispatch();


    useEffect(() => {
        if (!metal) {
            dispatch(getCurrentMetal())
            dispatch({ type: GET_METAL_RESET });
        }
        else {
            setCurrentGold(metal.gold_price);
            setCurrentSilver(metal.silver_price);
            setCurrentPlatinum(metal.platin_price);
            setCurrentPalladium(metal.palladium_price);
            //setCurrentWechselkurs(metal.wechselkurs);
            setGoldPercent(percentChange(metal.old_gold_price, metal.gold_price));
            setSilverPercent(percentChange(metal.old_silver_price, metal.silver_price));
            setPlatinumPercent(percentChange(metal.old_platin_price, metal.platin_price));
            setPalladiumPercent(percentChange(metal.old_palladium_price, metal.palladium_price));

        }

    }, [dispatch, metal]);

    return (
        <div className="px-2">
            <div className="text-xs md:text-sm md:flex md:space-x-6">
                {widgetVisible &&
                    <>
                        <div className="inline-flex space-x-2">
                            <b>Gold:</b>
                            <span>{currencyFormat(currentGold)}</span>
                            {goldPercent >= 0 ?
                                <span className="inline-flex items-center text-green-500 space-x-1">
                                    <FontAwesomeIcon icon="caret-up" />
                                    <span>+{goldPercent}%</span>
                                </span>
                                :
                                <span className="inline-flex items-center text-red-500 space-x-1">
                                    <FontAwesomeIcon icon="caret-down" />
                                    <span>{goldPercent}%</span>
                                </span>
                            }
                        </div>
                        <div className="inline-flex  space-x-2">
                            <b>{t("silver", "Silber")}:</b>
                            <span>{currencyFormat(currentSilver)}</span>
                            {silverPercent >= 0 ?
                                <span className="inline-flex items-center text-green-500 space-x-1">
                                    <FontAwesomeIcon icon="caret-up" />
                                    <span>+{silverPercent}%</span>
                                </span>
                                :
                                <span className="inline-flex items-center text-red-500 space-x-1">
                                    <FontAwesomeIcon icon="caret-down" />
                                    <span>{silverPercent}%</span>
                                </span>
                            }
                        </div>
                        <div className="inline-flex space-x-2">
                            <b>{t("platinum", "Platin")}:</b>
                            <span>{currencyFormat(currentPlatinum)}</span>
                            {platinumPercent >= 0 ?
                                <span className="inline-flex items-center text-green-500 space-x-1">
                                    <FontAwesomeIcon icon="caret-up" />
                                    <span>+{platinumPercent}%</span>
                                </span>
                                :
                                <span className="inline-flex items-center  text-red-500 space-x-1">
                                    <FontAwesomeIcon icon="caret-down" />
                                    <span>{platinumPercent}%</span>
                                </span>
                            }
                        </div>
                        <div className="inline-flex space-x-2">
                            <b>Palladium:</b>
                            <span>{currencyFormat(currentPalladium)}</span>
                            {palladiumPercent >= 0 ?
                                <span className="inline-flex items-center  text-green-500 space-x-1">
                                    <FontAwesomeIcon icon="caret-up" />
                                    <span>+{palladiumPercent}%</span>
                                </span>
                                :
                                <span className="inline-flex items-center  text-red-500 space-x-1">
                                    <FontAwesomeIcon icon="caret-down" />
                                    <span>{palladiumPercent}%</span>
                                </span>
                            }
                        </div>
                        <button className="md:hidden w-full md:w-auto md:py-0 py-1  px-2 rounded bg-gray-200" onClick={() => setWidgetVisible(false)}>{t("hide", "Ausblenden")}</button>
                    </>}
                {!widgetVisible && <button className="px-2 rounded bg-gray-200" onClick={() => setWidgetVisible(true)}>{t("show", "Einblenden")}</button>}

            </div>
        </div>
    )
}
