import { GET_METAL_FAIL, GET_METAL_REQUEST, GET_METAL_RESET, GET_METAL_SUCCESS } from "../constants/metalConstants";

export const metalReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_METAL_REQUEST:
            return { loading: true };
        case GET_METAL_SUCCESS:
            return { loading: false, success: true, metal: action.payload };
        case GET_METAL_FAIL:
            return { loading: false, error: action.payload };
        case GET_METAL_RESET:
            return {};
        default:
            return state;
    }
};