export const payments = {
    CASH: "cash",
    VISA: "visa",
    AMEX: "amex",
    MASTERCARD: "mastercard",
    PAYPAL: "paypal",
    VORKASSE: "vorkasse"
};

export const shippings = {
    STANDARD: "standard",
    SELF_PICKUP: "selbstabholung"
};