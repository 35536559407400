import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../BreadCrumbs';

export default function KarlGoetzMedalGrid() {
    const { t } = useTranslation(["navbar"]);
    return (
        <>
        <div>
            <BreadCrumbs steps={[{title:t("medals", "Medaillen"), link:"/category/medaillen"}, {title:t("karl_goetz_medals", "Medaillen von Karl Goetz")}]}/>
        </div>
        <div className="my-2 bg-gray-50 md:space-y-0 space-y-4 p-5 text-gray-500 flex md:flex-row flex-col md:space-x-10 items-center">
            <img className="h-48 pt-1"src="/images/karlgoetz.png" alt="karlgoetz" />
            <div className="text-sm md:text-base">
            {t("karl_goetz_text", "Karl Goetz (1875 Augsburg – 1950 München) war der rührigste und finanziell erfolgreichste deutsche Medailleur des 20. Jahrhunderts. Seine Karriere begann als Graveur und Juwelier in München, doch seine satirischen Medaillen vom Ersten Weltkrieg bis zur Hyperinflation brachten ihm Weltruhm, wenn auch seine beißenden Sprüche und hochpatriotischen Themen unserem heutigen Zeitempfinden nicht mehr entsprechen. Dennoch, seine Werke lesen sich heutzutage wie eine Bilderchronik der politischen und sozialen Umbrüche Deutschlands. Sein Stil, vom europäischen Art nouveau geprägt und an den Medaillen der Renaissance orientiert, ist einzigartig in seiner Kraft und in seinem Bildwitz.")} <Link to="/karlgoetz" className="text-blue-500">{t("read_more", "Mehr lesen")}</Link>
           </div>
        </div>
        </>
    )
}

