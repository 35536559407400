import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AsyncSelect from "react-select/async";

export default function AutocompleteSelect(props) {
    const [value, setValue] = useState(props.value ? props.value : null);

    const location = useLocation();
    const history = useHistory();

    const filterInputs = (inputValue) => { };

    const customStyles = {
        option: (styles, { data }) => ({
            ...styles,
            fontWeight: data.subgroups ? 700 : undefined,
            cursor: "pointer",
        }),
        control: (styles) => ({
            ...styles,
            cursor: "pointer",
        }),
        container: (provided) => ({
            ...provided,
            minWidth: 200,
            width: props.stretch ? "100%" : undefined,
        }),
    };

    const handleChange = (e) => {
        if (props.setValue) {
            props.setValue(e);
        }

        setValue(e);

        if (props.changeKey) {
            const search = location.search ? location.search.substring(1) : "";
            const searchJson = search
                ? JSON.parse(
                    '{"' +
                    decodeURI(search)
                        .replace(/"/g, '\\"')
                        .replace(/&/g, '","')
                        .replace(/=/g, '":"') +
                    '"}'
                )
                : {};
            searchJson["page"] = 1;
            if (e && e.value) {
                searchJson[props.changeKey] = e.value;
            } else {
                delete searchJson[props.changeKey];
            }

            history.push({
                pathname: location.pathname,
                search: `?${new URLSearchParams(searchJson)}`,
            });
        }
    };

    const loadOptions = async (inputValue) => {
        if (props.url) {
            const response = await fetch(`/api/products/${props.url}`);
            const json = await response.json();
            const output = json.map((i) => ({
                label: `${i._id[props.changeKey + "_name"]}`,
                value: i._id[props.changeKey + "_id"],
            }));

            return output.filter((i) =>
                i.label.toLowerCase().includes(inputValue.toLowerCase())
            );
        } else {
            return props.options;
        }
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (!params.get(props.changeKey)) {
            setValue(null);
        }
    }, [location, props.changeKey, props.url]);

    const options = props.options
        ? { defaultOptions: props.options }
        : { defaultOptions: true };
    return (
        <>
            <AsyncSelect
                cacheOptions
                key={props.url}
                isSearchable={props.isSearchable}
                styles={customStyles}
                components={{
                    IndicatorSeparator: () => null,
                }}
                isClearable
                placeholder={props.title}
                value={value}
                onChange={(e) => handleChange(e)}
                loadOptions={loadOptions}
                {...options}
                className="cursor-pointer text-sm "
            />
        </>
    );
}
