import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchBoxOverlay from './SearchBoxOverlay';

export default function SearchBox(props) {

    const [screenVisible, setScreenVisible] = useState(false);
    const submitHandler = (e) => {
        e.preventDefault();
        setScreenVisible(true)
    };

    return (
        <div>
            <div className="space-x-5">
                <button onClick={submitHandler} className="focus:outline-none transition duration-300 ease-in-out hover:text-yellow-600 " type="submit"><FontAwesomeIcon icon="search" size="lg"/></button>
            </div>
            {screenVisible && 
                <SearchBoxOverlay history={props.history} setScreenVisible={setScreenVisible}/>
            }
        </div>
    )
}
