import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function JsonKeyValueInput(props) {

    const [key, setKey] = useState(props.detailKey);
    const [value, setValue] = useState(props.detailValue);
    const [editDisabled, setEditDisabled] = useState(true);

    const deleteEntry = (e) => {
        e.preventDefault();
        setKey("");
        setValue("");
        const details = props.detail;
        delete details[props.detailKey];
        props.onSelectDetail(details);
    };

    const saveEntry = (e) => {
        e.preventDefault();
        var details = props.detail;
        details[props.detailKey] = value;
        
        props.onSelectDetail(details);
        setEditDisabled(true);
    }

    const editEntry = (e) => {
        e.preventDefault();
        setEditDisabled(false);
    }


    return (
        <>
            {(key || value) &&
                <div className="space-x-4">
                    <input readOnly disabled className="w-40 border h-10 border-grey-light rounded px-3 relative" type="text" value={key || ""}/>
                    <input readOnly={editDisabled} disabled={editDisabled} className="w-96 border h-10 border-grey-light rounded px-3 relative" type="text" value={value || ""} onChange={(e) => setValue(e.target.value)} />
                    {!editDisabled && <button onClick={(e) => saveEntry(e)} className="bg-blue-500 p-2 rounded hover:bg-blue-400"><FontAwesomeIcon color="white" icon="check" /></button>}
                    {editDisabled && <button onClick={(e) => editEntry(e)} className="bg-yellow-500 p-2 rounded hover:bg-yellow-400"><FontAwesomeIcon color="white" icon="pen" /></button>}
                    <button onClick={(e) => deleteEntry(e)} className="bg-red-500 p-2 rounded hover:bg-red-400"><FontAwesomeIcon color="white" icon="trash" /></button>
                </div>
            }
        </>
    )
}
