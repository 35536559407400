import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { savePassword } from '../actions/userActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { USER_PASSWORD_RESET_SAVE_RESET } from '../constants/userConstants';

export default function ResetPasswordScreen(props) {

    const { t } = useTranslation(["register"]);

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    
    const userResetPass = useSelector(state => state.userResetPass);
    const { success, loading, error} = userResetPass;

    const dispatch = useDispatch();
    const submitHandler = (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            alert(t("password_mismatch","Passwörter stimmen nicht überein"))
        } else {
            const data = {
                hash: props.match.params.hash,
                password : password
            };
            dispatch(savePassword(data));
        }
    };

    useEffect(() => {
        dispatch({type: USER_PASSWORD_RESET_SAVE_RESET})
        
    }, [dispatch]);

    return (
        <div className="flex flex-col px-5p">
        {success && <MessageBox variant="success">{t("success_reset","Sie haben Ihr Passwort erfolgreich geändert!")}{' '}<Link className="underline" to="/signin">{t("click_to_login","Klicken Sie hier, um sich anzumelden")}</Link></MessageBox>}
        {loading && <LoadingBox></LoadingBox>}
        {error && <MessageBox variant="danger">{error}</MessageBox>}

        <div className="min-h-screen flex flex-row items-start justify-center py-12 px-4 px-6 lg:px-8">

            <div className="max-w-md w-full space-y-8">
                <div>
                    <img className="mx-auto h-12 w-auto" src="/images/favicon.png" alt="Workflow" />
                    <h2 className="mt-2 text-center text-3xl font-extrabold text-gray-900">
                        {t("change_pass","Password ändern")}
                    </h2>
                    <p className="mt-2 text-center text-sm text-gray-600">
                        {t("enter_new_info", "Geben Sie ein neues Passwort für Ihr Konto ein und bestätigen Sie es.")}
           
                    </p>
                </div>
                <form className="mt-8 space-y-6" onSubmit={submitHandler}>
                    <input type="hidden" name="remember" value="true" />
                    <div className="rounded-md shadow-sm -space-y-px">
                        <div>
                            <label htmlFor="password" className="sr-only">{t("new_password", "Neues Passwort")}</label>
                            <input id="password" name="password" type="password" onChange={(e) => setPassword(e.target.value)} required className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" placeholder={t("new_password", "Neues Passwort")} />
                        </div>
                        <div>
                            <label htmlFor="confirmPassword" className="sr-only">{t("new_confirm_password", "Neues Passwort bestätigen")}</label>
                            <input id="confirmPassword" name="confirmPassword" type="password" onChange={(e) => setConfirmPassword(e.target.value)} required className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" placeholder={t("new_confirm_password", "Neues Passwort bestätigen")} />
                        </div>
                    </div>
                    <div>
                        <button type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            {t("save_pass","Passwort speichern")}
                        </button>
                    </div>
                </form>
            </div>

        </div>
    </div>
    )
}
