import Axios from 'axios';
import {
    USER_CONTACT_FORM_FAIL,
    USER_CONTACT_FORM_REQUEST,
    USER_CONTACT_FORM_SUCCESS,
    USER_DELETE_FAIL,
    USER_DELETE_REQUEST,
    USER_DELETE_SUCCESS,
    USER_DETAILS_FAIL,
    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_GUEST_CREATE_FAIL,
    USER_GUEST_CREATE_REQUEST,
    USER_GUEST_CREATE_SUCCESS,
    USER_LIST_FAIL,
    USER_LIST_REQUEST,
    USER_LIST_SUCCESS,
    USER_PASSWORD_RESET_HASH_FAIL,
    USER_PASSWORD_RESET_HASH_REQUEST,
    USER_PASSWORD_RESET_HASH_SUCCESS,
    USER_PASSWORD_RESET_SAVE_FAIL,
    USER_PASSWORD_RESET_SAVE_REQUEST,
    USER_PASSWORD_RESET_SAVE_SUCCESS,
    USER_REGISTER_FAIL,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_SELL_PRODUCT_FAIL,
    USER_SELL_PRODUCT_REQUEST,
    USER_SELL_PRODUCT_SUCCESS,
    USER_SEND_QUERY_FAIL,
    USER_SEND_QUERY_REQUEST,
    USER_SEND_QUERY_SUCCESS,
    USER_SIGNIN_FAIL,
    USER_SIGNIN_REQUEST,
    USER_SIGNIN_SUCCESS,
    USER_SIGNOUT,
    USER_UPDATE_FAIL,
    USER_UPDATE_PROFILE_FAIL,
    USER_UPDATE_PROFILE_REQUEST,
    USER_UPDATE_PROFILE_SUCCESS,
    USER_UPDATE_REQUEST,
    USER_UPDATE_SUCCESS,
} from '../constants/userConstants';

export const signin = (email, password) => async (dispatch) => {
    dispatch({ type: USER_SIGNIN_REQUEST, payload: { email, password } });
    try {
        const { data } = await Axios.post('/api/users/signin', { email, password });
        dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
        localStorage.setItem('userInfo', JSON.stringify(data));
    } catch (error) {
        dispatch({
            type: USER_SIGNIN_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const register = (info) => async (dispatch) => {
    dispatch({ type: USER_REGISTER_REQUEST });
    try {
        const { data } = await Axios.post('/api/users/register', info);
        dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
        dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
        localStorage.setItem('userInfo', JSON.stringify(data));
    } catch (error) {
        dispatch({
            type: USER_REGISTER_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const signout = () => (dispatch) => {
    localStorage.removeItem('userInfo');
    localStorage.removeItem('cartItems');
    localStorage.removeItem('shippingInfo')
    localStorage.removeItem('wishlistItems')
    dispatch({ type: USER_SIGNOUT });
    document.location.href = '/signin';
};

export const detailsUserSelf = (userId) => async (dispatch, getState) => {
    dispatch({ type: USER_DETAILS_REQUEST, payload: userId });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.get(`/api/users/self`, {
            headers: { Authorization: `Bearer ${userInfo?.token}` },
        });
        dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: USER_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const detailsUser = (userId) => async (dispatch, getState) => {
    dispatch({ type: USER_DETAILS_REQUEST, payload: userId });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.get(`/api/users/${userId}`, {
            headers: { Authorization: `Bearer ${userInfo?.token}` },
        });
        dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: USER_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const updateUserProfile = (user) => async (dispatch, getState) => {
    dispatch({ type: USER_UPDATE_PROFILE_REQUEST, payload: user });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.put(`/api/users/profile`, user, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: data });
        dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
        localStorage.setItem('userInfo', JSON.stringify(data));
    } catch (error) {
        dispatch({
            type: USER_UPDATE_PROFILE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const listUsers = ({ name = '', surname = '', email = '', pageNumber = '' }) => async (dispatch, getState) => {
    dispatch({ type: USER_LIST_REQUEST });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.get(`/api/users?name=${name}&surname=${surname}&email=${email}&pageNumber=${pageNumber}`, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        });
        dispatch({ type: USER_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: USER_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteUser = (userId) => async (dispatch, getState) => {
    dispatch({ type: USER_DELETE_REQUEST });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.delete(`/api/users/${userId}`, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: USER_DELETE_SUCCESS, data: data });
    } catch (error) {
        dispatch({
            type: USER_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const updateUser = (user) => async (dispatch, getState) => {
    dispatch({ type: USER_UPDATE_REQUEST, payload: user });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.put(`/api/users/${user._id}`, user, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: USER_UPDATE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: USER_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const forgotPassword = (email, lng) => async (dispatch, getState) => {
    dispatch({ type: USER_PASSWORD_RESET_HASH_REQUEST });
    try {
        const { data } = await Axios.post('/api/users/hash', { email, lng }, {
            headers: { 'Content-Type': 'application/json' },
            credentials: 'same-origin'
        });
        dispatch({ type: USER_PASSWORD_RESET_HASH_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: USER_PASSWORD_RESET_HASH_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const savePassword = (info) => async (dispatch, getState) => {
    dispatch({ type: USER_PASSWORD_RESET_SAVE_REQUEST });
    try {
        const { data } = await Axios.post("/api/users/reset", info, {
            headers: { 'Content-Type': 'application/json' },
            credentials: 'same-origin'
        });
        dispatch({ type: USER_PASSWORD_RESET_SAVE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: USER_PASSWORD_RESET_SAVE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const sendProductQuestion = (info) => async (dispatch, getState) => {
    dispatch({ type: USER_SEND_QUERY_REQUEST });
    try {
        const { data } = await Axios.post("/api/users/question", info, {
            headers: { 'Content-Type': 'application/json' },
            credentials: 'same-origin',
        });
        dispatch({ type: USER_SEND_QUERY_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: USER_SEND_QUERY_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message
        });
    }
};

export const sellProduct = (info) => async (dispatch, getState) => {
    dispatch({ type: USER_SELL_PRODUCT_REQUEST });
    try {
        const { data } = await Axios.post("/api/users/sell", info, {
            headers: { 'Content-Type': 'application/json' },
            credentials: 'same-origin',
        });
        dispatch({ type: USER_SELL_PRODUCT_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: USER_SELL_PRODUCT_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message
        });
    }
};

export const sendContactForm = (info) => async (dispatch, getState) => {
    dispatch({ type: USER_CONTACT_FORM_REQUEST });
    try {
        const { data } = await Axios.post("/api/users/contact", info, {
            headers: { 'Content-Type': 'application/json' },
            credentials: 'same-origin',
        });
        dispatch({ type: USER_CONTACT_FORM_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: USER_CONTACT_FORM_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message
        });
    }
};

export const createGuestUser = () => async (dispatch, getState) => {
    dispatch({ type: USER_GUEST_CREATE_REQUEST });
    try {
        const { data } = await Axios.post("/api/users/guest", {}, {
            headers: { 'Content-Type': 'application/json' },
            credentials: 'same-origin',
        });
        dispatch({ type: USER_GUEST_CREATE_SUCCESS, payload: data });
        dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
        localStorage.setItem('userInfo', JSON.stringify(data));
    } catch (error) {
        dispatch({
            type: USER_GUEST_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message
        });
    }
};