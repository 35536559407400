import React from 'react'
import { useTranslation } from 'react-i18next';

export default function CheckoutSteps(props) {
    const { t } = useTranslation(["checkoutsteps"]);

    return (
        <div className="px-5p select-none mt-6">
            <ul className="select-none flex items-start " id="breadcrumb">
                <li className="select-none text-xs font-medium uppercase tracking-wider"><span className={props.step1 ? 'active' : ''} ><b className="inline">1.</b> {t("cart","Warenkorb")}</span></li>
                <li className="select-none text-xs font-medium uppercase tracking-wider"><span className={props.step2 ? 'active' : ''} ><b className="inline">2.</b> {t("shipping","Versand")}</span></li>
                <li className="select-none text-xs font-medium uppercase tracking-wider"><span className={props.step3 ? 'active' : ''} ><b className="inline">3.</b> {t("overview","Übersicht")}</span></li>
            </ul>
        </div>
    )
}