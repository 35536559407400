import { useEffect, useState } from 'react'

/* 
Debouncing is a mechanism to postpose the execution of a function. 
This prevents potentially costly operations (e.g., requesting data from the backend) 
from being executed prematurely.
*/
export default function useDebouncedValue(value, wait) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const id = setTimeout(() => setDebouncedValue(value), wait);
        return () => clearTimeout(id);
    }, [value, wait])

    return debouncedValue;
}
