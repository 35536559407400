import React from 'react'

export default function VerticalTileBlock(props) {
    return (
        <div className={` ${props.backgroundClass} relative transition-all duration-500 ease-in-out border overflow-hidden cursor-pointer ${props.hovered !== props.hoverId && props.hovered !== 0 ? "md:w-32" : "md:w-full md:bg-opacity-0"}`}
            onMouseEnter={() => props.toggleHover(props.hoverId)} onMouseLeave={() => props.toggleHover(0)}>
            <div className={`h-full relative z-10 p-3 transition-all duration-500 ease-in-out overflow-hidden ${props.hovered === props.hoverId ? 'md:opacity-100 ' : 'md:opacity-0  '}`}>{props.children}</div>
        </div>

    )
}

// <div className={`uppercase tracking-widest  bg-gray-10 font-bold text-white relative z-10 transition-all max-h-10 duration-500 ease-in-out text-center ${props.hovered !== props.hoverId && props.hovered !== 0 ? "" : "text-2xl"} ${props.hovered === props.hoverId ? 'opacity-0 p-0' : 'opacity-100  p-3'}`}>
// <span className="bg-black px-1 bg-opacity-40 ">{props.title}</span>
// </div>
