import React from 'react';
import { Link } from 'react-router-dom';
import OldRightArrow from './subcomponents/pagination/OldRightArrow';
import OldLeftArrow from './subcomponents/pagination/OldLeftArrow';

export default function OldPagination(props) {
    const pageLimit = 1;

    const range = (from, to, step = 1) => {
        let i = from;
        const range = [];
      
        while (i <= to) {
          range.push(i);
          i += step;
        }
      
        return range;
      }

    const pagesCalculator = () => {
        const totalPages = props.pages;
        const currentPage = props.page;
        const pageNeighbours = pageLimit;
        const totalNumbers = (pageLimit * 2) + 3;
        const totalBlocks = totalNumbers + 2;


        if (totalPages > totalBlocks) {
            const startPage = Math.max(2, currentPage - pageNeighbours);
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
            let pages = range(startPage, endPage);
            /**
             * hasLeftSpill: has hidden pages to the left
             * hasRightSpill: has hidden pages to the right
             * spillOffset: number of hidden pages either to the left or to the right
             */
            const hasLeftSpill = startPage > 2;
            const hasRightSpill = (totalPages - endPage) > 1;
            const spillOffset = totalNumbers - (pages.length + 1);

            switch (true) {
                // handle: (1) < {5 6} [7] {8 9} (10)
                case (hasLeftSpill && !hasRightSpill): {
                    const extraPages = range(startPage - spillOffset, startPage - 1);
                    pages = ["...", ...extraPages, ...pages];
                    break;
                }

                // handle: (1) {2 3} [4] {5 6} > (10)
                case (!hasLeftSpill && hasRightSpill): {
                    const extraPages = range(endPage + 1, endPage + spillOffset);
                    pages = [...pages, ...extraPages, "..."];
                    break;
                }

                // handle: (1) < {4 5} [6] {7 8} > (10)
                case (hasLeftSpill && hasRightSpill):
                default: {
                    pages = ["...", ...pages, "..."];
                    break;
                }
            }

            return [1,...pages, totalPages];
        }

        return range(1, totalPages);

    }
    const pageArray = pagesCalculator();

    return (
        props.pages > 1 &&
        <nav className="pt-5 relative z-0 flex flex-wrap shadow-sm justify-center" aria-label="Pagination">
            <OldLeftArrow page={props.page} link={props.link} />
            {pageArray.map((x) => (
                x === "..." ?
                    <Link to="#" disabled className='relative md:inline-flex items-center px-3 md:px-4 py-2 border border-gray-300 bg-white font-medium cursor-default text-gray-700 bg-gray-50' key={x}>...</Link>
                    :
                    <Link className={x === props.page ? 'relative md:inline-flex items-center px-3 md:px-4 py-2 border border-gray-300 bg-white font-medium text-gray-700 bg-blue-50 hover:bg-blue-100'
                        : 'relative md:inline-flex items-center px-3 md:px-4 py-2 border border-gray-300 bg-white font-medium text-gray-700 hover:bg-gray-50'
                    } key={x} to={`${props.link}${x}`}>{x}
                    </Link>
            ))
            }
            <OldRightArrow page={props.page} link={props.link} />
        </nav >
    )
}