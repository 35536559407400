const SHIPPING_PRICES = [5, 9.50, 15];
const SHIPPING_DURATIONS = ["1 - 3", "3 - 7", "5 - 14"];
const SHIPPING_COURIER = ["DHL Standard", "Deutsche Post", "Deutsche Post"]
const FREE_SHIPPING = [300, 1000, Infinity];

export const shippingPriceDisplay = (country, deliveryAddressVisible, deliveryCountry, itemsPrice) => {
    if (deliveryAddressVisible) {
        return checkFreeShipping(deliveryCountry, SHIPPING_PRICES, itemsPrice);
    } else {
        return checkFreeShipping(country, SHIPPING_PRICES, itemsPrice)
    }
}

function checkFreeShipping(country, shipping_prices, itemsPrice) {
    const free_shipping_threshold = checkCountry(country, FREE_SHIPPING);
    if (itemsPrice >= free_shipping_threshold) {
        return 0;
    } else {
        return checkCountry(country, shipping_prices);
    }
}

export const shippingDurationDisplay = (country, deliveryAddressVisible, deliveryCountry) => {
    if (deliveryAddressVisible) {
        return checkCountry(deliveryCountry, SHIPPING_DURATIONS);
    } else {
        return checkCountry(country, SHIPPING_DURATIONS)
    }
}

export const shippingCourierDisplay = (country, deliveryAddressVisible, deliveryCountry) => {
    if (deliveryAddressVisible) {
        return checkCountry(deliveryCountry, SHIPPING_COURIER);
    } else {
        return checkCountry(country, SHIPPING_COURIER)
    }
}

export function checkCountry(country, listCompare) {
    switch (country) {
        case "Deutschland":
            return listCompare[0];
        case "Belgien":
        case "Bulgarien":
        case "Dänemark":
        case "Estland":
        case "Finnland":
        case "Frankreich":
        case "Griechenland":
        case "Irland":
        case "Italien":
        case "Kroatien":
        case "Lettland":
        case "Litauen":
        case "Luxemburg":
        case "Malta":
        case "Niederlande":
        case "Österreich":
        case "Polen":
        case "Portugal":
        case "Rumänien":
        case "Schweden":
        case "Slowakei":
        case "Slowenien":
        case "Spanien":
        case "Tschechische Republik":
        case "Ungarn":
        case "Zypern":
            return listCompare[1];
        default:
            return listCompare[2];
    }
}
