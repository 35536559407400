import { PP_INIT_FAIL, PP_INIT_REQUEST, PP_INIT_SUCCESS, PP_INIT_RESET, PP_ASSERT_REQUEST, PP_ASSERT_FAIL, PP_ASSERT_SUCCESS, PP_ASSERT_RESET } from '../constants/transactionConstants';

export const ppInitReducer = (state = {}, action) => {
    switch (action.type) {
        case PP_INIT_REQUEST:
            return { loading: true };
        case PP_INIT_SUCCESS:
            return { loading: false, success: true, transaction: action.payload };
        case PP_INIT_FAIL:
            return { loading: false, error: action.payload };
        case PP_INIT_RESET:
            return {};
        default:
            return state;
    }
};

export const ppAssertReducer = (state = {}, action) => {
    switch (action.type) {
        case PP_ASSERT_REQUEST:
            return { loading: true };
        case PP_ASSERT_SUCCESS:
            return { loading: false, success: true, orderId: action.payload.orderId };
        case PP_ASSERT_FAIL:
            return { loading: false, error: action.payload };
        case PP_ASSERT_RESET:
            return {};
        default:
            return state;
    }
};