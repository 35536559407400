export const CART_ADD_ITEM = 'CART_ADD_ITEM';
export const CART_ADD_ITEM_FAIL = 'CART_ADD_ITEM_FAIL';
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM';
export const CART_SAVE_SHIPPING_INFO = 'CART_SAVE_SHIPPING_INFO';
export const CART_SAVE_PAYMENT_METHOD = 'CART_SAVE_PAYMENT_METHOD';
export const CART_EMPTY = 'CART_EMPTY';


export const CART_ITEM_AVAILABLE_REQUEST = 'CART_ITEM_AVAILABLE_REQUEST';
export const CART_ITEM_AVAILABLE_SUCCESS = 'CART_ITEM_AVAILABLE_SUCCESS';
export const CART_ITEM_AVAILABLE_FAIL = 'CART_ITEM_AVAILABLE_FAIL';
export const CART_ITEM_AVAILABLE_RESET = 'CART_ITEM_AVAILABLE_RESET';