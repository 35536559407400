import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { register } from '../actions/userActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import CountryOptions from '../components/CountryOptions';

export default function RegisterScreen(props) {
    const { t } = useTranslation(["register"]);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [addressExtra, setAddressExtra] = useState('');
    const [country, setCountry] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [city, setCity] = useState('');
    const [birthdate, setBirthdate] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const redirect = props.location.search
        ? props.location.search.split('=')[1]
        : '/';

    const userRegister = useSelector((state) => state.userRegister);
    const { userInfo, loading, error } = userRegister;
    const dispatch = useDispatch();
    const submitHandler = (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            alert(t("password_mismatch", "Passwörter stimmen nicht überein"))
        } else {
            dispatch(register({firstName, lastName, company, email, phone, address, addressExtra, country, postalCode, city, birthdate, password}));
        }
    };
    useEffect(() => {
        if (userInfo) {
            props.history.push(redirect);
        }
    }, [props.history, redirect, userInfo]);
    return (
        <div className="flex flex-col px-5p">
            {loading && <LoadingBox></LoadingBox>}
            {error && <MessageBox variant="danger">{error}</MessageBox>}

            <div className="min-h-screen flex flex-row items-start justify-center py-12 px-4 px-6 lg:px-8">

                <div className="max-w-md w-full space-y-8">
                    <div>
                        <img className="mx-auto h-12 w-auto" src="/images/favicon.png" alt="Workflow" />
                        <h2 className="mt-2 text-center text-3xl font-extrabold text-gray-900">
                            {t("register", "Registrieren")}
                        </h2>
                        <p className="mt-2 text-center text-sm text-gray-600">
                            {t("account_exists", "Konto existiert bereits")}?{' '}
                            <Link to={`/signin?redirect=${redirect}`} className="font-medium text-indigo-600 hover:text-indigo-500">
                                {t("signin", "Anmelden")}
                            </Link>
                        </p>
                    </div>
                    <form className="mt-8 space-y-6" onSubmit={submitHandler}>
                        <input type="hidden" name="remember" value="true" />
                        <div className="rounded-md shadow-sm -space-y-px">
                            <div className="flex">
                                <div className="flex-grow  required-wrapper2">
                                    <input id="firstName" type="text" onChange={(e) => setFirstName(e.target.value)} required className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-tl-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" placeholder={t("firstName", "Vorname")} />
                                </div>
                                <div className="flex-grow required-wrapper2">
                                    <input id="lastName" type="text" onChange={(e) => setLastName(e.target.value)} required className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-tr-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" placeholder={t("lastName", "Nachname")} />
                                </div>
                            </div>
                            <div>
                                <input id="company" type="text" onChange={(e) => setCompany(e.target.value)} className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" placeholder={t("company", "Firma")} />
                            </div>

                            <div className="required-wrapper2">
                                <input id="email-address" name="email-address" type="email" onChange={(e) => setEmail(e.target.value)} required className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" placeholder={t("email", "E-Mail Adresse")} />
                            </div>
                            <div>
                                <input id="phone" name="phone" type="tel" onChange={(e) => setPhone(e.target.value)} className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" placeholder={t("phone", "Telefonnummer")} />
                            </div>
                            <div>
                                <input id="birthdate" name="birthdate" type="text" onBlur={(e) => e.target.type = "text"} onFocus={(e) => e.target.type = 'date'} onChange={(e) => setBirthdate(e.target.value)} className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" placeholder={t("birthdate", "Geburtsdatum")} />
                            </div>
                            <div>
                                <input id="address" name="address" type="text" onChange={(e) => setAddress(e.target.value)} className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" placeholder={t("address", "Adresse")} />
                            </div>
                            <div>
                                <input id="addressExtra" name="addressExtra" type="text" onChange={(e) => setAddressExtra(e.target.value)} className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" placeholder={t("addressExtra", "Adresszusatz")} />
                            </div>
                            <div className="flex">
                                <div>
                                    <input id="plz" name="plz" type="text" onChange={(e) => setPostalCode(e.target.value)} className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" placeholder={t("plz", "Postleitzahl")} />
                                </div>
                                <div className="flex-grow">
                                    <input id="city" name="city" type="text" onChange={(e) => setCity(e.target.value)} className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" placeholder={t("city", "Stadt")} />
                                </div>
                            </div>
                            <div>
                                <select onChange={(e) => setCountry(e.target.value)} defaultValue="" id="country" name="country" className={!country?"appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-500 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm":"appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm"} >
                                    <option className="" value="" disabled>{t("country", "Land")}</option>
                                    <CountryOptions></CountryOptions>
                                </select>

                            </div>
                            <div className="required-wrapper2">
                                <input id="password" name="password" type="password" onChange={(e) => setPassword(e.target.value)} required className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" placeholder={t("password", "Passwort")} />
                            </div>
                            <div className="required-wrapper2">
                                <input id="confirmPassword" name="confirmPassword" type="password" onChange={(e) => setConfirmPassword(e.target.value)} required className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm" placeholder={t("confirm_password", "Passwort bestätigen")} />
                            </div>
                        </div>
                        <div>
                            <button type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                {t("create_account", "Konto erstellen")}
                            </button>
                        </div>
                    </form>
                </div>

            </div>
        </div>

    );
}