import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    ComposableMap,
    Geographies,
    Geography,
    ZoomableGroup,
    Marker,
    Graticule,
    Annotation,

} from "react-simple-maps";
import ReactTooltip from "react-tooltip";
import { searchProductViaCat } from "../actions/productActions";
import ProductGridScreen from "./ProductGridScreen";
import { geoCentroid, geoArea } from "d3-geo";
import { FontAwesomeIcon } from "../../node_modules/@fortawesome/react-fontawesome/index";

export default function WorldMapScreen() {
    const [position, setPosition] = useState({ coordinates: [0, 30], zoom: 2 });

    const productList = useSelector((state) => state.productList);
    const { loading, error, products } = productList;

    function handleMoveEnd(position) {
        setPosition(position);
    }

    const geoUrl = "/world/world-countries.json";

    const dispatch = useDispatch();


    const [content, setContent] = useState("");

    const searchCat1 = (cat1) => {
        dispatch(searchProductViaCat(cat1))
    };

    const handleFilter = ({ constructor: { name } }) => {
        //disable zoom with scroll
        return name !== "WheelEvent";
    };




    return (
        <div id="wrapper" className="-mt-10 flex-grow  overflow-hidden ">
            <div>
                <div className="absolute z-10 flex flex-row justify-around " style={{ pointerEvents: "none", top: "200px", marginLeft: "5%" }}>
                    <div className="w-full">
                        <div className="border bg-gray-50 p-2 text-sm rounded bg-opacity-50">

                            <h1 className="text-3xl font-extrabold text-gray-900 tracking-tight py-2 ">Weltkarte</h1>

                            Wählen Sie das gewünschte
                            Land aus, und alle zugehörigen Artikel werden in der Übersicht
                            angezeigt.
                            <br />
                            Mit Hilfe des Mausrads können Sie die Karte vergrößern und
                            verkleinern.


                        </div>
                        <div className=" z-x0" style={{ top: "0px", pointerEvents: "all" }}>
                            {products ?
                                <div className="flex md:flex-row flex-col space-x-2 ">
                                    <div className="">
                                        {products.coins || productList.medals ?
                                            <ProductGridScreen height="h-32 " width="md:w-32 w-96" className="grid grid-cols-2 gap-2" productList={{ products: products.coins.concat(products.medals), loading: loading, error: error }} />
                                            : <></>
                                        }
                                    </div>
                                </div>
                                :
                                <></>}
                        </div>
                    </div>

                </div>
                <div className="fixed top-1/2 z-10 pl-1">
                    <div className="flex flex-col space-y-1 ">

                        <button className="w-10 h-10 bg-black text-white"><FontAwesomeIcon icon="search-plus" /></button>
                        <button className="w-10 h-10 bg-black text-white"><FontAwesomeIcon icon="search-minus" /></button>
                    </div>
                </div>
            </div>
            <ComposableMap
                style={{ height: "auto" }}
                className="cursor-move fixed top-0 left-0 right-0"

            >
                <ZoomableGroup
                    position={position}
                    center={position.coordinates}
                    zoom={position.zoom}
                    onMoveEnd={handleMoveEnd}
                    filterZoomEvent={handleFilter}
                >
                    <Graticule stroke="lightgray" />

                    <Geographies geography={geoUrl}>
                        {({ geographies }) =>
                            <>
                                {geographies.map((geo) => {

                                    return (

                                        <Geography
                                            key={geo.rsmKey}
                                            stroke="#FFF"
                                            fill="#DDD"

                                            geography={geo}
                                            onMouseEnter={
                                                () => setContent(geo.properties.name)
                                            }

                                            onMouseLeave={
                                                () => setContent("")
                                            }

                                            onClick={() => {
                                                geo.properties.cat1 ?
                                                    searchCat1(
                                                        geo.properties.cat1
                                                    ) : alert("Keine Artikel in diesem Land");
                                            }}
                                            className="transition duration-300 ease-in-out cursor-pointer"
                                            style={{
                                                default: {
                                                    fill: "#D6D6DA",
                                                    outline: "none",
                                                },
                                                hover: {
                                                    fill: "#48a2d6",
                                                    outline: "none",
                                                },
                                                pressed: {
                                                    fill: "#36007c",
                                                    outline: "none",
                                                },
                                            }}
                                        />
                                    )
                                })}
                            </>
                        }
                    </Geographies>
                </ZoomableGroup>
            </ComposableMap>
        </div>




        //     <div className="flex-grow fixed" style={{ zIndex: "0" }} data-tip="">
        //         <ComposableMap
        //             style={{ height: "auto" }}
        //             className="cursor-move"

        //         >
        //             <ZoomableGroup
        //                 position={position}
        //                 center={position.coordinates}
        //                 zoom={position.zoom}
        //                 onMoveEnd={handleMoveEnd}
        //             >
        //                 <Graticule stroke="lightgray" />

        //                 <Geographies geography={geoUrl}>
        //                     {({ geographies }) => 
        //                         <>
        //                             {geographies.map((geo) => {

        //                                 return (

        //                                     <Geography
        //                                         key={geo.rsmKey}
        //                                         stroke="#FFF"
        //                                         fill="#DDD"

        //                                         geography={geo}
        //                                         onMouseEnter={
        //                                             () => setContent(geo.properties.name)
        //                                         }

        //                                         onMouseLeave={
        //                                             () => setContent("")
        //                                         }

        //                                         onClick={() => {
        //                                             geo.properties.cat1 ?
        //                                                 searchCat1(
        //                                                     geo.properties.cat1
        //                                                 ) : alert("Keine Artikel in diesem Land");
        //                                         }}
        //                                         className="transition duration-300 ease-in-out cursor-pointer"
        //                                         style={{
        //                                             default: {
        //                                                 fill: "#D6D6DA",
        //                                                 outline: "none",
        //                                             },
        //                                             hover: {
        //                                                 fill: "#48a2d6",
        //                                                 outline: "none",
        //                                             },
        //                                             pressed: {
        //                                                 fill: "#36007c",
        //                                                 outline: "none",
        //                                             },
        //                                         }}
        //                                     />
        //                                 )
        //                             })}
        //                         </>
        //                     }
        //                 </Geographies>
        //             </ZoomableGroup>
        //         </ComposableMap>
        //     </div>

        // </div>
    );
}
