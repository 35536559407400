import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ImageSwiper(props) {
    return (
        <div className="flex items-start justify-center md:p-4">
            <ul className="flex flex-row flex-wrap  ">
                {
                    props.images.map((image, i) => (
                        <button className="focus:outline-none  px-2 py-1" key={i} onClick={() => props.setCurrentImage(image)}>
                            <li className="flex  border transition duration-300 ease-in-out hover:border-gray-400 w-20 h-20 md:w-40 md:h-40  justify-center items-center p-2" >
                                <img src={image} className="max-h-full max-w-full" alt={props.alt ? (props.alt+" Bild "+(i+1)): image} />
                            </li>
                        </button>
                    ))
                }
                {props.video &&
                    <button className="focus:outline-none  px-2 py-1" onClick={() => props.setCurrentImage(props.video)}>
                        <li className="flex border text-gray-300 transition duration-300 ease-in-out hover:border-gray-400 w-20 h-20 md:w-40 md:h-40  justify-center items-center p-2" >
                            <video>
                                <source src={props.video + "#t=0.1"} type="video/mp4" />
                            </video>
                            <div className="md:text-base text-xs absolute w-20 h-20 md:w-40 md:h-40 flex justify-end py-1 px-1 text-gray-300 hover:text-gray-400 transition duration-300 ease-in-out">
                                <FontAwesomeIcon icon="video" size="1x" />
                            </div>
                        </li>
                    </button>}
            </ul>
        </div>
    );
}