import React from 'react'
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';
import ProductScreen from './screens/ProductScreen';
import CartScreen from './screens/CartScreen';
import SigninScreen from './screens/SigninScreen';
import RegisterScreen from './screens/RegisterScreen';
import HeaderBox from './components/HeaderBox';
import FooterBox from './components/FooterBox';
import ShippingInfoScreen from './screens/ShippingInfoScreen';
import OrderScreen from './screens/OrderScreen';
import OrderHistoryScreen from './screens/OrderHistoryScreen';
import UserProfileScreen from './screens/UserProfileScreen';
import PrivateRoute from './components/PrivateRoute';
import AdminRoute from './components/AdminRoute';
import ProductListScreen from './screens/ProductListScreen';
import ProductEditScreen from './screens/ProductEditScreen';
import OrderListScreen from './screens/OrderListScreen';
import UserListScreen from './screens/UserListScreen';
import UserEditScreen from './screens/UserEditScreen';
import ProductOverviewScreen from './screens/ProductOverviewScreen';
import WishlistScreen from './screens/WishlistScreen';
import WishesListScreen from './screens/WishesListScreen';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen';
import ResetPasswordScreen from './screens/ResetPasswordScreen';
import ContactScreen from './screens/ContactScreen';
import TagSearchScreen from './screens/TagSearchScreen';
import ImpressumScreen from './screens/ImpressumScreen';
import AGBScreen from './screens/AGBScreen';
import DatenschutzScreen from './screens/DatenschutzScreen';
import DatenschutzEngScreen from './screens/DatenschutzEngScreen';
import WiderrufScreen from './screens/WiderrufScreen';
import CoinsCategoryScreen from './screens/CoinsCategoryScreen';
import AGBEngScreen from './screens/AGBEngScreen';
import WiderrufEngScreen from './screens/WiderrufEngScreen';
import ImpressumEngScreen from './screens/ImpressumEngScreen';
import ProductImportScreen from './screens/ProductImportScreen';
import TransactionCheckScreen from './screens/TransactionCheckScreen';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import ExternalRoute from './components/ExternalRoute';
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga";
import useGoogleAnalytics from './components/useGoogleAnalytics';
import CollectionScreen from './screens/CollectionScreen';
import CatalogsScreen from './screens/CatalogsScreen';
import CatalogItemScreen from './screens/CatalogItemsScreen';
import WorldMapScreen from './screens/WorldMapScreen';
import LiteratureScreen from './screens/LiteratureScreen';

export default function Routes() {
    const TRACKING_ID = "UA-196843269-1";
    ReactGA.initialize(TRACKING_ID);
    useGoogleAnalytics();

    const { t } = useTranslation(["header"]);

    const handleDeclineCookie = () => {
        Cookies.remove("_ga");
        Cookies.remove("_gat");
        Cookies.remove("_gid");
    }

    const location = useLocation();
    return (
        <div className="flex flex-col font-source min-h-full">
            <div className="border-b border-gray-200">
                <HeaderBox></HeaderBox>
                <CookieConsent onDecline={handleDeclineCookie} flipButtons declineButtonStyle={{ background: "rgb(53,53,53)" }} declineButtonText={t("decline", "Ablehnen")} buttonText={t("accept", "Akzeptieren")} enableDeclineButton>{t("cookie_message", "Wir nutzen Cookies und Dienste Dritter, um diese Website für Sie so interessant wie möglich zu gestalten. Sind Sie damit einverstanden?")} <Link className="underline text-blue-500" to="/datenschutz">{t("more", "Mehr")}</Link></CookieConsent>

            </div>
            <main className={`py-2 min-h-full flex-grow  ${location.pathname.indexOf('world') > -1 && "flex overflow-hidden"}`}>
                <Route path="/cart/:id?" component={CartScreen}></Route>
                <Route path="/wishlist" component={WishlistScreen}></Route>

                <Route path="/literatur" component={LiteratureScreen}></Route>
                <Switch>
                    <AdminRoute path="/product/:id/edit" component={ProductEditScreen} exact></AdminRoute>
                    <Route path="/product/:randomstring?/:id" component={ProductScreen} exact></Route>
                </Switch>
                <Route path="/signin" component={SigninScreen}></Route>
                <Route path="/register" component={RegisterScreen}></Route>
                <Route path="/shipping" component={ShippingInfoScreen}></Route>
                <Route path="/reset-password" component={ForgotPasswordScreen} exact></Route>
                <Route path="/reset-password/:hash" component={ResetPasswordScreen} exact></Route>
                <Route path="/search/text/:text" component={ProductOverviewScreen} exact></Route>
                <Route path="/search/category/:category/custom/:custom/text/:text/min/:minPrice/max/:maxPrice/order/:order/stock/:stock/pagesize/:pagesize/page/:pageNumber" component={ProductOverviewScreen} exact></Route>
                <Route path="/search/category/:category/custom/:custom/text/:text/min/:minPrice/max/:maxPrice/order/:order/stock/:stock/legierung/:legierung/pagesize/:pagesize/page/:pageNumber" component={ProductOverviewScreen} exact></Route>
                <Route path="/category/:category" component={ProductOverviewScreen} exact></Route>
                <Route path="/kontakt" component={ContactScreen}></Route>
                <Route path="/kataloge" exact component={CatalogsScreen} />
                <Route path="/kataloge/:id" exact component={CatalogItemScreen} />
                <Route path="/world" exact component={WorldMapScreen} />
                <ExternalRoute path="/muenzkataloge" url="//www.muenzgalerie.de/Kataloge#MuenzenKataloge" />
                <ExternalRoute path="/muenzankauf" url="//www.muenzgalerie.de/Muenzankauf" />
                <ExternalRoute path="/mailchimp" url="//mailchimp.com/legal/data-processing-addendum" />
                <ExternalRoute path="/mailchimp-privacy" url="//mailchimp.com/legal/privacy" />
                <ExternalRoute path="/matomo-privacy" url="//matomo.org/privacy-policy" />
                <ExternalRoute path="/mgmindex" url="//mgmindex.de" />
                <ExternalRoute path="/karlgoetz" url="//mgmindex.de/index.php?title=Karl_Goetz_(1875-1950)" />
                <ExternalRoute path="/erhaltung" url="//mgmindex.de/MuenzenSammeln:Erhaltungsgrade_von_M%C3%BCnzen" />
                <Route path="/tag_search" component={TagSearchScreen}></Route>
                <Route path="/impressum" component={ImpressumScreen}></Route>
                <Route path="/imprint" component={ImpressumEngScreen}></Route>
                <Route path="/agb" component={AGBScreen}></Route>
                <Route path="/terms" component={AGBEngScreen}></Route>
                <Route path="/datenschutz" component={DatenschutzScreen}></Route>
                <Route path="/privacy" component={DatenschutzEngScreen}></Route>
                <Route path="/widerruf" component={WiderrufScreen}></Route>
                <Route path="/right-of-withdrawal" component={WiderrufEngScreen}></Route>
                <Route path="/muenzen_sammelgebiete" component={CoinsCategoryScreen}></Route>
                <PrivateRoute path="/transaction/:id" component={TransactionCheckScreen} exact></PrivateRoute>
                <PrivateRoute path="/order/:id" component={OrderScreen}></PrivateRoute>
                <PrivateRoute path="/orderhistory" component={OrderHistoryScreen}></PrivateRoute>
                <PrivateRoute path="/profile" component={UserProfileScreen}></PrivateRoute>
                {/* <PrivateRoute path="/collections" component={CollectionScreen}></PrivateRoute> */}
                <AdminRoute path="/import" component={ProductImportScreen} exact></AdminRoute>
                <AdminRoute path="/productlist/" component={ProductListScreen} exact></AdminRoute>
                <AdminRoute path="/productlist/id/:id/name/:name/category/:category/page/:pageNumber" component={ProductListScreen} exact></AdminRoute>
                <AdminRoute path="/orderlist" component={OrderListScreen} exact></AdminRoute>
                <AdminRoute path="/orderlist/id/:id/name/:name/surname/:surname/city/:city/min/:minPrice/max/:maxPrice/dateFrom/:dateFrom/dateTo/:dateTo/paymentMethod/:paymentMethod/paid/:paid/sent/:sent/confirmed/:confirmed/page/:pageNumber" component={OrderListScreen} exact></AdminRoute>
                <AdminRoute path="/userlist" component={UserListScreen} exact></AdminRoute>
                <AdminRoute path="/userlist/name/:name/surname/:surname/email/:email/page/:pageNumber" component={UserListScreen} exact></AdminRoute>
                <AdminRoute path="/user/:id/edit" component={UserEditScreen}></AdminRoute>
                <AdminRoute path="/wishlists/" component={WishesListScreen}></AdminRoute>
                <Route path="/" component={HomeScreen} exact></Route>

            </main>
            <FooterBox></FooterBox>

        </div>
    )
}
