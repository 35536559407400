import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import NominalWidget from './detail/NominalWidget';
import ErhaltungWidget from './detail/ErhaltungWidget';
import TagWidget from './detail/TagWidget';
import LinkedWidget from './detail/LinkedWidget';
import regexifyString from 'regexify-string';
import { Link } from 'react-router-dom';
import SteinWidget from './detail/SteinWidget';

export default function ProductDetailList(props) {
  const { t } = useTranslation(["product"]);

  function NewlineText(props) {
    const detects = ["Rubin", "Smaragd", "Saphir", "Turmalin", "Amethyst", "Citrin", "Granat"]
    const text = props.text;
    const newText = text.split('\n').map(str => {
      const result = regexifyString({
        pattern: /\[\[.*?\]\]/gim,
        decorator: (match, index) => {
          const outputMatch = match.replace("[[","").replace("]]","")
          const keyx = props.keyx === 'Besatz' ? "bid" : props.keyx === "Extra-Material" ? "mid" :  "tag";
            const el = detects.find(a => outputMatch.includes(a));
            const linker = `/category/${props.category ? props.category.toLowerCase() : "all"}/?${keyx}=${outputMatch}`
            return el ? <SteinWidget keyx={keyx} to={linker} value={outputMatch}/> : <Link className="text-blue-500 hover:underline cursor-pointer" to={linker}>{outputMatch}</Link>;
        },
        input: str,
    });
    return <p>{result}</p>
    } );
 

    return newText;
  }
  function NewlineTextExtraMaterial(props) {
    const text = props.text;
    const newText = text.split('\n').map(str => {
      
    return <p><Link className="text-blue-500 hover:underline cursor-pointer" to={`/category/${props.category ? props.category.toLowerCase() : "all"}/?mid=${str}`}>{str}</Link></p>
    } );
 

    return newText;
  }

  const detailList = props.detail ? Object.entries(props.detail).map(([keyx, value], i) => {
    return (
      value && (
        keyx === "Nominal"  ? <NominalWidget key={i} keyx={keyx} value={value}/>
      : keyx === "Erhaltung"  ? <ErhaltungWidget key={i} keyx={keyx} value={value}/>
      : keyx === 'tag_link' ? <TagWidget key={i} keyx={keyx} category={props.category ? props.category : ""} value={value}/>
      : (keyx === 'Material' && props.detail.material_id) ? <LinkedWidget key={i} category={props.category} title={keyx} value={value} searchKey="mid" searchValue={props.detail.material_id}/>
      : keyx === 'Besatz' ?
      <><div className="col-span-2 font-semibold" key={keyx}>{keyx}</div><div className="col-span-4"><NewlineText category={props.category ? props.category: ""} key={i} keyx={keyx} text={value} /></div></>
      : keyx === 'Extra-Material' ?       
      <><div className="col-span-2 font-semibold" key={keyx}>{keyx}</div><div className="col-span-4"><NewlineTextExtraMaterial category={props.category ? props.category: ""} key={i} keyx={keyx} text={value} /></div></>
      : (keyx.includes("_id") || keyx === 'hideMGM' || keyx==="literatur_link" || keyx === "mashop" || (keyx === "Jahr" && props.detail.hasOwnProperty("Jahr*")))? <Fragment key={i}></Fragment>  
      : <><div className="col-span-2 font-semibold" key={keyx}>{keyx}</div><div className="col-span-4"> {value.toString()}</div></>)
    )
  }) : null;
  return (
    <div className='grid grid-cols-6 gap-x-2 '>
      <div className="font-semibold col-span-2">ID:</div><div className="col-span-4">{props.item_number}</div> 
      {detailList || t("no_details", "Keine Details vorhanden.")}
    </div>
  )
}
