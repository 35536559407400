import React from 'react'

export default function AccordionComponent(props) {
    return (
        <div className="rounded-t-lg tab w-full overflow-hidden ">
            <input defaultChecked={props.checked} type="checkbox" className="hidden" id={props.id} />
            <label className={"tab-owner flex items-center space-x-2 cursor-pointer border-b "+props.titleClass} htmlFor={props.id}>
                <div className="text-blue-600">{props.title}</div>
            </label>
            <div className="bg-gray-50 max-h-0 tab-content transition-all duration-300 whitespace-pre-line">
                {props.children}
            </div>
        </div>
    )
}
