import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import ProductBox from '../components/ProductBox';
import ProductListItem from '../components/ProductListItem';

export default function ProductGridScreen(props) {

    const { t } = useTranslation(["overview"]);
    const { loading, error, products } = props.productList;

    return (
        loading ? <LoadingBox></LoadingBox>
            : error ? <MessageBox variant="danger">{error}</MessageBox> : (
                props.category === "numismatische_literatur" ?
                    <table className="table-auto w-full">
                        <thead className="bg-gray-100">
                            <tr className=" rounded-full  rounded text-gray-500 px-6 py-3 text-left text-xs text-gray-500 tracking-wider uppercase">
                                <th></th>
                                <th className="text-left  font-medium  hidden md:table-cell ">{t("category", "Kategorie")}</th>
                                <th className="text-left  font-medium  p-2">{t("year", "Jahr")}</th>
                                <th className="text-left  font-medium  p-2 ">{t("authorTitle", "Autor/Titel")}</th>
                                <th className="text-left  font-medium  p-2 ">{t("price_only", "Preis")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                products.map((product) => (
                                    <ProductListItem key={product._id} product={product} />
                                ))
                            }
                        </tbody>
                    </table>
                    :
                    products.length > 0 ? 
                    <ul className={`${props.className ? props.className : "grid grid-cols-2 md:grid-cols-4 gap-4"} `}>
                        {
                           products.map((product) => (
                                <ProductBox height={props.height} key={product._id} product={product} />
                            ))
                        }
                    </ul>
                     : <MessageBox hideCloseButton>Keine Artikel unter dieser Kategorie / diesem Suchbegriff gefunden.</MessageBox> )
    );


}
