import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import { importProducts } from '../actions/productActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { PRODUCT_IMPORT_RESET } from '../constants/productConstants';

export default function ProductImportScreen() {
    const [fileName, setFileName] = useState();
    const [selectedData, setSelectedData] = useState([]);

    const productImport = useSelector(state => state.productImport);
    const { success, error, loading } = productImport;


    const fileHandler = (e) => {
        e.preventDefault();
        dispatch({ type: PRODUCT_IMPORT_RESET });

        var files = e.target.files;
        if (files && files.length > 0) {
            setSelectedData([]);
            var f = files[0];
            setFileName(f.name)
            var reader = new FileReader();
            reader.onload = function (e) {
                var data = e.target.result;
                let readedData = XLSX.read(data, { type: 'binary' });
                const wsname = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsname];
                /* Convert array to json*/
                const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1, defval: "" });
                setSelectedData(dataParse);
            };
            reader.readAsBinaryString(f);
        }
    }


    const fileToTypeMap = {
        "muenzen_altdtsch.xls": "ALTDEUTSCHE_MUENZEN",
        "muenzen_altdtsch.xlsx": "ALTDEUTSCHE_MUENZEN",
        "muenzen_antik.xls": "ANTIKE_MUENZEN",
        "muenzen_antik.xlsx": "ANTIKE_MUENZEN",
        "muenzen_ausland.xls": "AUSLAENDISCHE_MUENZEN",
        "muenzen_ausland.xlsx": "AUSLAENDISCHE_MUENZEN",
        "muenzen_gold.xls": "AUSLAENDISCHE_MUENZEN",
        "muenzen_gold.xlsx": "AUSLAENDISCHE_MUENZEN",
        "muenzen_bund.xls": "DEUTSCHLAND_1871_MUENZEN",
        "muenzen_bund.xlsx": "DEUTSCHLAND_1871_MUENZEN",
        "muenzen_bullion.xls": "BULLION_MUENZEN",
        "muenzen_bullion.xlsx": "BULLION_MUENZEN",
        "medaillen.xls": "MEDAILLEN",
        "medaillen.xlsx": "MEDAILLEN",
        "literatur_neu.xls": "NUMISMATISCHE_LITERATUR",
        "literatur_neu.xlsx": "NUMISMATISCHE_LITERATUR",
        "schmuck.xls" : "SCHMUCK",
        "schmuck.xlsx" : "SCHMUCK",
        "banknoten.xls" : "BANKNOTEN",
        "banknoten.xlsx" : "BANKNOTEN",
        "banknoten2.xls" : "BANKNOTEN2",
        "banknoten2.xlsx" : "BANKNOTEN2",
    }

    const dispatch = useDispatch();
    const uploadHandler = (e) => {
        if (selectedData && fileName && fileToTypeMap.hasOwnProperty(fileName)) {
            dispatch(importProducts(fileToTypeMap[fileName], selectedData.slice(1)));
        }
    };


    return (
        <div className="px-5p">
            {loading && <LoadingBox />}
            {error && <MessageBox variant="danger">{error}</MessageBox>}
            {success && <MessageBox variant="success">Artikel erfolgreich importiert</MessageBox>}
            <h1 className="text-3xl font-extrabold text-gray-900 tracking-tight py-2">Artikelimport</h1>
            <ul className="text-gray-700 text-sm">
                <li><b className="float-left w-48">Altdeutsche Münzen:</b> muenzen_altdtsch.xls</li>
                <li><b className="float-left w-48">Antike Münzen:</b> muenzen_antik.xls</li>
                <li><b className="float-left w-48">Ausländische Münzen:</b> muenzen_ausland.xls / muenzen_gold.xls</li>
                <li><b className="float-left w-48">Bullion Münzen:</b>muenzen_bullion.xls</li>
                <li><b className="float-left w-48">Deutschland ab 1871 Münzen:</b>  muenzen_bund.xls</li>
                <li><b className="float-left w-48">Medaillen:</b>  medaillen.xls</li>
                <li><b className="float-left w-48">Literatur:</b>  literatur_neu.xls</li>
                <li><b className="float-left w-48">Schmuck:</b>  schmuck.xls</li>
            </ul>
            {!success && !loading && selectedData.length > 0 && fileName && fileToTypeMap.hasOwnProperty(fileName) &&
                <>
                    <MessageBox variant="info">Datei ({fileName}) erkannt und wird nach Bestätigung zur <span className="font-bold">{fileToTypeMap[fileName]}</span> Datenbank hinzugefügt.</MessageBox>
                    <button className="group relative  flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md  bg-purple-100 hover:bg-purple-200 focus:outline-none" type="button" onClick={uploadHandler}>
                        Bestätigen
                    </button>
                </>
            }
            {!loading && <div className="flex justify-center">
                <label className="w-64 flex flex-col items-center px-4 py-6 bg-white text-blue-500 rounded-lg shadow-lg tracking-wide border border-blue-500 cursor-pointer hover:bg-blue-500 hover:text-white">
                    <svg className="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                    </svg>
                    <span className="mt-2 text-base leading-normal">Datei auswählen</span>
                    <input onChange={fileHandler} accept=".xls, .xlsx" type='file' className="hidden" />
                </label>
            </div>}
            {!success && selectedData &&
                <div className="w-full py-4">
                    <table className="table-fixed text-xs bg-white border rounded mb-4">
                        <tbody>
                            <tr className="border-b">
                                {selectedData.length > 0 && selectedData[0].map((col, colkey) => (
                                    <th key={colkey} className="text-xs text-left px-2">{col}</th>
                                ))}
                            </tr>
                            {selectedData.length > 1 && selectedData.slice(1).map((row, rowkey) => (
                                <tr key={rowkey} className="border-b hover:bg-orange-100 bg-gray-100  px-2">
                                    {row.map((col, colkey) => (
                                        <td key={rowkey + colkey * selectedData.length}>{col}</td>
                                    ))
                                    }
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div >
            }
        </div >


    )
}
