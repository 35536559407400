import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, listUsers } from '../actions/userActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { USER_DETAILS_RESET } from '../constants/userConstants';
import { optionalField } from '../utils/string';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import OldPagination from '../components/OldPagination';
import { dateMediumFormat } from '../utils/date';

export default function UserListScreen(props) {

    const userList = useSelector((state) => state.userList);
    const { loading, error, users, page, pages } = userList;
    const { name = 'all', surname = 'all', email = 'all', pageNumber = 1} = useParams();


    const userDelete = useSelector((state) => state.userDelete);
    const { loading: loadingDelete, error: errorDelete, success: successDelete } = userDelete;

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(listUsers({
            name: name !== "all" ? name : '', 
            surname: surname !== "all" ? surname : '', 
            email: email !== "all" ? email : '', 
            pageNumber: pageNumber
        }));
        dispatch({ type: USER_DETAILS_RESET });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, successDelete, pageNumber]);

    const deleteHandler = (user) => {
        if (window.confirm(`Möchten Sie den User ${user.firstName} ${user.lastName} löschen?`)) {
            dispatch(deleteUser(user._id));
        }
    }

    const getFilterUrl = (filter) => {
        const filterName = filter.name || name;
        const filterSurname = filter.surname || surname;
        const filterEmail = filter.email || email;
        const filterPageNumber = filter.page || pageNumber;

        return `/userlist/name/${filterName}/surname/${filterSurname}/email/${filterEmail}/page/${filterPageNumber}`;
    };
    const history = useHistory();
    const activateFilter = () => {
        var name = document.getElementById('nameInput').value || "all";
        var surname = document.getElementById('surnameInput').value  || "all";
        var email = document.getElementById('emailInput').value  || "all";
        history.push(getFilterUrl({name: name, surname: surname, email: email, page: 1}));
      }

    return (
        <div className="px-5p space-y-2">
            {loadingDelete && <LoadingBox></LoadingBox>}
            {errorDelete && <MessageBox variant="danger">{error}</MessageBox>}
            {successDelete && (
                <MessageBox variant="success">User wurde erfolgreich gelöscht</MessageBox>
            )}
            <div className="flex flex-row justify-between py-2">
                <h1 className="text-3xl font-extrabold text-gray-\900 tracking-tight">Userliste</h1>
            </div>

            {loading ? (
                <LoadingBox></LoadingBox>
            ) : error ? (
                <MessageBox variant="danger">{error}</MessageBox>

            ) : (
                <div className="-my-2 overflow-x-auto md:-mx-6 ">
                    <form className="w-full text-sm">
                        <div className="grid grid-cols-4 grid-rows-1 px-3 mb-3 justify-center">
                            <div className="px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="nameInput">
                                    Vorname
                                </label>
                                <input className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded px-1 py-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="nameInput" type="text" placeholder="" defaultValue={name === "all" ? '' : name} />
                            </div>
                            <div className="px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="surnameInput">
                                    Nachname
                                </label>
                                <input className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded px-1 py-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="surnameInput" type="text" placeholder="" defaultValue={surname === "all" ? '' : surname} />
                            </div>     <div className="px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="emailInput">
                                    E-Mail
                                </label>
                                <input className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded px-1 py-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="emailInput" type="text" placeholder="" defaultValue={email === "all" ? '' : email} />
                            </div>
                        </div>
                        <div className="flex px-6 pb-2 justify-end">
                            <button onClick={activateFilter} className="mr-4 transition duration-300 ease-in-out hover:bg-gray-400 hover:border-gray-800 hover:text-white bg-gray-200 p-2 border border-gray-400 rounded px-6">Suchen</button>
                            <Link to="/userlist" className="transition duration-300 ease-in-out hover:bg-gray-400 hover:border-gray-800 hover:text-white bg-gray-200 p-2 border border-gray-400 rounded px-6">Filter zurücksetzen</Link>
                        </div>
                        <div className="px-6 text-gray-500 text-xs mb-2">
                            Gastkontos sind ausgeblendet
                        </div>
                    </form>
                    <div className="py-2 align-middle inline-block min-w-full md:px-6">
                        <div className="shadow overflow-hidden border-b border-gray-200 rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                                        <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                                        <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">E-Mail</th>
                                        <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Admin</th>
                                        <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Erstellt am</th>
                                        <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {users.map((user) => (
                                        !user.isGuest && 
                                        <tr key={user._id}>
                                            <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-900">{user._id}</td>
                                            <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-500">{user.firstName} {user.lastName} {optionalField(user.company)}</td>
                                            <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-500">{user.email}</td>
                                            <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-500">{user.isAdmin ? 'Ja' : 'Nein'}</td>
                                            <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-500">{dateMediumFormat(user.createdAt)}</td>
                                            <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-500 space-x-1 text-right">
                                                <button type="button" onClick={() => props.history.push(`/user/${user._id}/edit`)} className="px-2 border border-transparent rounded-md bg-yellow-100 hover:bg-yellow-200 focus:outline-none">
                                                    Bearbeiten
                                                </button>
                                                <button type="button" onClick={() => deleteHandler(user)} className="px-2 border border-transparent rounded-md bg-red-100 hover:bg-red-200 focus:outline-none">
                                                    Löschen
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                    <div className="flex py-2 justify-center">
                        <OldPagination page={page} pages={pages} link={getFilterUrl({}).substr(0, getFilterUrl({}).lastIndexOf('/')+1)}/>
                    </div>
                </div>

            )
            }
        </div>
    );
}
