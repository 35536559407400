export const dateFormat = (value) => {
    const lng = localStorage.getItem("lngCode")
    if (value) {
        const date = new Date(value);

        if (lng && lng === "en") {
            return new Intl.DateTimeFormat('en-GB').format(date);
        } else {
            return new Intl.DateTimeFormat('de-DE').format(date);

        }
    } else {
        return ""
    }
}

export const dateFullFormat = (value) => {
    const lng = localStorage.getItem("lngCode")
    const date = new Date(value);

    if (lng && lng === "en") {
        return new Intl.DateTimeFormat('en-GB', { dateStyle: 'full', timeStyle: 'long' }).format(date);
    } else {
        return new Intl.DateTimeFormat('de-DE', { dateStyle: 'full', timeStyle: 'long' }).format(date);
    }
}


export const dateMediumFormat = (value) => {
    const lng = localStorage.getItem("lngCode")
    const date = new Date(value);

    if (lng && lng === "en") {
        return new Intl.DateTimeFormat('en-GB', { dateStyle: 'long', timeStyle: 'medium' }).format(date);
    } else {
        return new Intl.DateTimeFormat('de-DE', { dateStyle: 'long', timeStyle: 'medium' }).format(date);
    }
}



export const newSeconds = 120960000; //2 weeks
