import React from 'react'
import { useTranslation } from 'react-i18next';
import EmailAddress from './EmailAddress';
import PhoneNumber from './PhoneNumber';

export default function OpeningHours() {
    const { t } = useTranslation("opening_hours");

    return (
        <div className="w-full">
            <ul className="space-y-1 ">
                <li> <b>Münzgalerie München MGM Handelsgesellschaft mbH &amp; Co. Joker KG </b> </li>
                <li> Stiglmaierplatz 2 </li>
                <li> 80333 München </li>
                <li> <b className="float-left w-20">{t("telephone","Telefon")}:</b> <PhoneNumber number={"+49895233660"} text={"+49 (89) 5233660"}/></li>
                <li> <b className="float-left w-20">E-Mail:</b> <EmailAddress email="mgm@muenzgalerie.de"/></li>
                <li> <b className="float-left w-20">Mo - Fr:</b> 9:00 - 18:00 {t("uhr","Uhr")}</li>
            </ul>
        </div>
    )
}
