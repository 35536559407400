import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { listCatalogs } from '../actions/catalogActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';

export default function CatalogsScreen() {
    const { t } = useTranslation(["catalog"])
    const dispatch = useDispatch();
    const catalogList = useSelector((state) => state.catalogList);
    const { catalogs, error, loading } = catalogList;
    useEffect(() => {
        dispatch(listCatalogs({})
        );

    }, [dispatch]);


    return (
        <div className="px-5p space-y-2 pb-10 ">
            <div className="py-2 ">
                <h1 className="text-3xl font-extrabold text-gray-900 tracking-tight">{t("catalogs", "Kataloge")}</h1>
            </div>
            {error && <MessageBox variant="danger">{error}</MessageBox>}
            {loading ? <LoadingBox /> :
                catalogs ?
                    <>
                        {catalogs.length > 0 && catalogs.map((catalogYear, i) => (
                            <>
                                <div key={i} className="flex align-center bg-gray-100 text-gray-400 md:text-3xl p-3">
                                    {catalogYear._id.year}
                                </div>
                                <div className="grid grid-cols-2 md:grid-cols-4 gap-2 pb-5">
                                    {catalogYear.catalogs && catalogYear.catalogs.length > 0 && catalogYear.catalogs.map((catalog, j) => (
                                        <Link className="flex flex-col space-y-2 hover:opacity-75 transition transition-opacity duration-300 ease-in-out" to={`/kataloge/${catalog.id}`}>
                                            <img className="object-contain h-40 md:h-96 border" alt={catalog.name} src={catalog.image} />
                                            <div className="px-2 text-gray-800 font-bold uppercase tracking-widest text-sm ">{catalog.name}</div>
                                            <div className="px-2 text-xs uppercase tracking-widest">{catalog.subtext}</div>
                                        </Link>
                                    ))}


                                </div>
                            </>
                        ))}


                    </> : <></>}
        </div>
    )
}
