import { PRODUCT_CREATE_FAIL, PRODUCT_CREATE_REQUEST, PRODUCT_CREATE_RESET, PRODUCT_CREATE_SUCCESS, PRODUCT_DELETE_FAIL, PRODUCT_DELETE_REQUEST, PRODUCT_DELETE_RESET, PRODUCT_DELETE_SUCCESS, PRODUCT_DETAILS_FAIL, PRODUCT_DETAILS_REQUEST, PRODUCT_DETAILS_SUCCESS, PRODUCT_FILTER_REQUEST, PRODUCT_FILTER_RESET, PRODUCT_IMPORT_FAIL, PRODUCT_IMPORT_REQUEST, PRODUCT_IMPORT_RESET, PRODUCT_IMPORT_SUCCESS, PRODUCT_INDEX_FAIL, PRODUCT_INDEX_REQUEST, PRODUCT_INDEX_RESET, PRODUCT_INDEX_SUCCESS, PRODUCT_LIST_FAIL, PRODUCT_LIST_REQUEST, PRODUCT_LIST_SUCCESS, PRODUCT_LITERATURE_FAIL, PRODUCT_LITERATURE_REQUEST, PRODUCT_LITERATURE_SUCCESS, PRODUCT_NEWEST_FAIL, PRODUCT_NEWEST_REQUEST, PRODUCT_NEWEST_SUCCESS, PRODUCT_SEARCH_FAIL, PRODUCT_SEARCH_REQUEST, PRODUCT_SEARCH_RESET, PRODUCT_SEARCH_SUCCESS, PRODUCT_UPDATE_FAIL, PRODUCT_UPDATE_REQUEST, PRODUCT_UPDATE_RESET, PRODUCT_UPDATE_SUCCESS, PRODUCT_ZEIT_FAIL, PRODUCT_ZEIT_REQUEST, PRODUCT_ZEIT_RESET, PRODUCT_ZEIT_SUCCESS, PRODUCT_ZERO_FAIL, PRODUCT_ZERO_REQUEST, PRODUCT_ZERO_RESET, PRODUCT_ZERO_SUCCESS } from "../constants/productConstants";

export const productListReducer = (
    state = { loading: true, products: [] }, action) => {
    switch (action.type) {
        case PRODUCT_LIST_REQUEST:
            return { loading: true };
        case PRODUCT_LIST_SUCCESS:
            return { loading: false, products: action.payload.products, pages: action.payload.pages, page: action.payload.page, count: action.payload.count };
        case PRODUCT_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const productLiteratureReducer =  (state = { loading: true }, action) => {
    switch (action.type) {
        case PRODUCT_LITERATURE_REQUEST:
            return { loading: true };
        case PRODUCT_LITERATURE_SUCCESS:
            return { loading: false, literatures: action.payload };
        case PRODUCT_LITERATURE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const productFilterReducer = (
    state = {}, action) => {
    switch (action.type) {
        case PRODUCT_FILTER_REQUEST:
            return { filter: action.payload };
        case PRODUCT_FILTER_RESET:
            return {};
        default:
            return state;
    }
};

export const productDetailsReducer = (state = { loading: true }, action) => {
    switch (action.type) {
        case PRODUCT_DETAILS_REQUEST:
            return { loading: true };
        case PRODUCT_DETAILS_SUCCESS:
            return { loading: false, product: action.payload };
        case PRODUCT_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const productCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case PRODUCT_CREATE_REQUEST:
            return { loading: true };
        case PRODUCT_CREATE_SUCCESS:
            return { loading: false, success: true, product: action.payload };
        case PRODUCT_CREATE_FAIL:
            return { loading: false, error: action.payload };
        case PRODUCT_CREATE_RESET:
            return {};
        default:
            return state;
    }
};

export const productUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case PRODUCT_UPDATE_REQUEST:
            return { loading: true };
        case PRODUCT_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case PRODUCT_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case PRODUCT_UPDATE_RESET:
            return {};
        default:
            return state;
    }
};

export const productDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case PRODUCT_DELETE_REQUEST:
            return { loading: true };
        case PRODUCT_DELETE_SUCCESS:
            return { loading: false, success: true };
        case PRODUCT_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case PRODUCT_DELETE_RESET:
            return {};
        default:
            return state;
    }
};

export const productZeroReducer = (state = {}, action) => {
    switch (action.type) {
        case PRODUCT_ZERO_REQUEST:
            return { loading: true };
        case PRODUCT_ZERO_SUCCESS:
            return { loading: false, success: true };
        case PRODUCT_ZERO_FAIL:
            return { loading: false, error: action.payload };
        case PRODUCT_ZERO_RESET:
            return {};
        default:
            return state;
    }
};

export const productImportReducer = (state = {}, action) => {
    switch (action.type) {
        case PRODUCT_IMPORT_REQUEST:
            return { loading: true };
        case PRODUCT_IMPORT_SUCCESS:
            return { loading: false, success: true };
        case PRODUCT_IMPORT_FAIL:
            return { loading: false, error: action.payload };
        case PRODUCT_IMPORT_RESET:
            return {};
        default:
            return state;
    }
};

export const productSearchReducer = (state = {}, action) => {
    switch (action.type) {
        case PRODUCT_SEARCH_REQUEST:
            return { loading: true };
        case PRODUCT_SEARCH_SUCCESS:
            return { loading: false, success: true, results: action.payload };
        case PRODUCT_SEARCH_FAIL:
            return { loading: false, error: action.payload };
        case PRODUCT_SEARCH_RESET:
            return {};
        default:
            return state;
    }
}

export const productIndexReducer = (state = {}, action) => {
    switch (action.type) {
        case PRODUCT_INDEX_REQUEST:
            return { loading: true };
        case PRODUCT_INDEX_SUCCESS:
            return { loading: false, success: true, result: action.payload };
        case PRODUCT_INDEX_FAIL:
            return { loading: false, error: action.payload };
        case PRODUCT_INDEX_RESET:
            return {};
        default:
            return state;
    }
}


export const productNewestReducer = (
    state = { loading: true }, action) => {
    switch (action.type) {
        case PRODUCT_NEWEST_REQUEST:
            return { loading: true };
        case PRODUCT_NEWEST_SUCCESS:
            return { loading: false, coins: action.payload.coins, medals: action.payload.medals};
        case PRODUCT_NEWEST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};