import React from 'react';
import { Link, useLocation} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function PageSizeWidget(props) {
    const pageSizeOptions = [20, 40, 80];
    const { t } = useTranslation(["overview"]);

    // const linkCreator = (val) => {
    //     var link = props.link;
    //     const firstSubstr = link.substring(0, link.indexOf("pagesize"))+"pagesize/";
    //     const secondSubstr = "/"+link.substring(link.indexOf("page/"));
    //     return firstSubstr + val + secondSubstr;
    // }
    const location = useLocation();

    const search = location.search ? location.search.substring(1) : "";
    const searchJson = search ? JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}') : {};
    const currentPageSize = searchJson && searchJson["size"] ? Number(searchJson["size"] ) : "";
    return (
        <div className="flex flex-col md:flex-row items-end md:space-y-0 space-y-2 md:items-center pt-5 md:space-x-2 ">
            <nav className="relative z-0 flex flex-wrap shadow-sm justify-center space-x-2" aria-label="Pagination">
            
    
                
                {(pageSizeOptions).map((x) => { 
                    const newJson = searchJson;
                    newJson["size"] = x;
                    return <Link className={searchJson && searchJson["size"] && Number(x)  === currentPageSize
                            ? 'text-xs rounded relative md:inline-flex items-center px-3 py-2 border border-gray-300 bg-white font-medium text-gray-800 bg-gray-100'
                            : 'text-xs rounded relative md:inline-flex items-center px-3 py-2 border border-gray-300 bg-white font-medium text-gray-800 hover:bg-gray-50'
                        } key={x} to={{pathname: location.pathname, search: `?${new URLSearchParams(searchJson)}`}}>{x}
                        </Link>
                    }
                )}
        

            
            </nav>
           <div className="text-gray-500 text-xs uppercase">{t("per_page", "Artikel pro Seite")}</div> 
        </div>
    )
}