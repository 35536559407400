import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { addToCart, removeFromCart } from '../actions/cartActions';
import MessageBox from '../components/MessageBox';
import currencyFormat from '../utils/currency';
import { detailsProduct } from '../actions/productActions';
// import MessageBox from '../components/MessageBox';

export default function CartScreen(props) {
    const { t } = useTranslation(["cart"]);

    const productId = props.match.params.id;
    const qty = props.location.search
        ? Number(props.location.search.split('=')[1])
        : 1;
    const cart = useSelector(state => state.cart);
    const { cartItems, error } = cart;
    const dispatch = useDispatch();

    const productDetails = useSelector(state => state.productDetails);
    const { product } = productDetails;
    useEffect(() => {
        if (productId) {
            dispatch(detailsProduct(productId));
            if (product && qty <= product.stock) {
                dispatch(addToCart(productId, qty));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);//, productId, product, qty]);
    const removeFromCartHandler = (id) => {
        dispatch(removeFromCart(id));
    };
    const checkoutHandler = () => {
        props.history.push('/signin?redirect=shipping');
    };
    return (
        <div className="px-5p space-y-2">
            <h1 className="text-3xl font-extrabold text-gray-900 tracking-tight py-2">{t("my_cart","Mein Warenkorb")}</h1> 
            {error && <MessageBox variant="danger">{error}</MessageBox>}

            {
                cartItems.length === 0
                    ? <MessageBox variant="information">{t("empty_cart","Ihr Warenkorb ist leer.")}</MessageBox>

                    : <div className="flex flex-col md:flex-row justify-center md:space-x-2 mt-6">
                        <div className="-my-2 overflow-x-auto md:-mx-6 ">
                            <div className="py-2 align-middle inline-block min-w-full md:px-6 ">
                                <div className="border overflow-hidden border-b border-gray-200 rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    {t("item","Artikel")}
                                        </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden md:table-cell">
                                                    {t("qty","Menge")}
                                        </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden md:table-cell">
                                                    {t("single_price","Einzelpreis")}
                                        </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden md:table-cell">
                                                    {t("total_price","Gesamtpreis")}
                                        </th>
                                                <th scope="col" className="relative px-6 py-3 hidden md:table-cell">
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {
                                                cartItems.map((item) => (
                                                    <tr key={item.product}>
                                                        <td className="px-6 py-1 md:py-4 whitespace-nowrap block md:table-cell">
                                                            <div className="flex items-center">
                                                                <div className="flex-shrink-0 h-10 w-10">
                                                                 
                                                                    <Link to={`/product/${item.product}`}><img className="h-10 w-10 rounded-full" src={item.image} alt={item.name} /></Link>
                                                                </div>
                                                                <div className="ml-4">
                                                                    <Link to={`/product/${item.product}`}>
                                                                        <div className="text-sm font-medium text-gray-900">
                                                                            {item.brand}
                                                                        </div>
                                                                        <div className="text-sm text-gray-500">
                                                                            {item.name}
                                                                        </div>
                                                                        <div className="text-sm text-gray-900">
                                                                            {item.item_number}
                                                                        </div>

                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-1 md:py-4  whitespace-nowrap block  md:table-cell">
                                                            <div className="flex justify-between"><div className=" whitespace-nowrap text-sm text-gray-500 inline: md:hidden">Menge:</div>

                                                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full border">
                                                                    <select className="focus:outline-none w-10" value={item.qty} onChange={e => dispatch(addToCart(item.product, Number(e.target.value)))}>
                                                                        {[...Array(item.stock).keys()].map(
                                                                            x => (
                                                                                <option key={x + 1} value={x + 1}>
                                                                                    {x + 1}
                                                                                </option>
                                                                            )
                                                                        )}
                                                                    </select>
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-1 md:py-4  whitespace-nowrap text-sm text-gray-500 text-right md:text-left block md:table-cell">
                                                            <div className="flex justify-between"><div className="inline: md:hidden">{t("single_price","Einzelpreis")}:</div> <div>{currencyFormat(item.price)}</div> </div>
                                                        </td>
                                                        <td className="px-6 py-1 md:py-4  whitespace-nowrap text-sm text-right md:text-left text-gray-500 block md:table-cell">
                                                            <div className="flex justify-between"><div className="inline: md:hidden">{t("total_price","Gesamtpreis")}:</div> <div>{currencyFormat(item.price * item.qty)}</div> </div>
                                                        </td>
                                                        <td className="px-6 py-1 md:py-4  whitespace-nowrap text-sm font-medium text-right md:text-left justify-between block  md:table-cell">
                                                            <button className="transition duration-300 ease-in-out text-red-600 hover:text-red-900" type="button" onClick={() => removeFromCartHandler(item.product)}><FontAwesomeIcon icon="times"/></button>
                                                        </td>
                                                    </tr>
                                                )
                                                )}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="-my-2 overflow-x-auto md:-mx-6 lg:-mx-8">
                            <div className="py-2 align-middle inline-block min-w-full md:px-6 lg:px-8">
                                <div className="border overflow-hidden border-b border-gray-200 rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    {t("subtotal","Zwischensumme")}  - {cartItems.reduce((a, c) => a + c.qty, 0)} {t("items","Artikel")}:
                                        </th>

                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            <tr>
                                                <td className="px-6 py-1 md:py-2 whitespace-nowrap block md:table-cell">
                                                    <div className="flex flex-col space-y-4 justify-end items-end md:items-center md:justify-center">
                                                        <div className="text-l h-6 w-10 mr-3 md:mr-0">
                                                            <b className="-">{currencyFormat(cartItems.reduce((a, c) => a + c.price * c.qty, 0))}</b>
                                                        </div>
                                                        <button type="button" onClick={checkoutHandler} disabled={cartItems.length === 0} className="transition duration-300 ease-in-out group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                            {t("payment","Zur Kasse")}
                                                        </button>

                                                    </div>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
}