import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import ProductBox from './ProductBox'

export default function NewestProducts(props) {
    const { t } = useTranslation(["home"]);

    return (
        <div className="scrolling-wrapper flex flex-col py-2 md:py-3">
            <div className="font-semibold tracking-widest py-2 uppercase">{props.title}</div>
            <div className="scrolling-wrapper bg-gray-100 list-none	w-full border p-5 border-dashed space-x-5">
                {props.products && props.products.map((product, i) =>
                    <ProductBox width="96" mobileWidth="60" key={i} product={product} />
                )
                }
            </div>
            <div className="flex justify-center md:justify-end p-2">
                <Link to={props.link} className="transition duration-300 ease-in-out border p-2 w-52 text-center uppercase border-gray-300  text-blue-800  hover:border-gray-500 tracking-widest">{t("discover_more","Entdecke mehr")}</Link>
            </div>
        </div>
    )
}
