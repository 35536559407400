import React from "react";
import AltdeutscheCoinGrid from "./subcategorygrids/AltdeutscheCoinGrid";
import AntikeCoinGrid from "./subcategorygrids/AntikeCoinGrid";
import AuslandCoinGrid from "./subcategorygrids/AuslandCoinGrid";
import BullionCoinGrid from "./subcategorygrids/BullionCoinGrid";
import CoinSubcategoryGrid from "./subcategorygrids/CoinSubcategoryGrid";
import MedalSubcategoryGrid from "./subcategorygrids/MedalSubcategoryGrid";
import Deutschland1871CoinGrid from "./subcategorygrids/Deutschland1871CoinGrid";
import DeutscheMedalGrid from "./subcategorygrids/DeutscheMedalGrid";
import ForeignMedalGrid from "./subcategorygrids/ForeignMedalGrid";
import ThemedMedalGrid from "./subcategorygrids/ThemedMedalGrid";
import AuslandBanknotenSubcategoryGrid from "./subcategorygrids/AuslandBanknotenSubcategoryGrid";
import NumismatikSubcategoryGrid from "./subcategorygrids/NumismatikSubcategoryGrid";
import KarlGoetzMedalGrid from "./subcategorygrids/KarlGoetzMedalGrid";
import BanknotenSubcategoryGrid from "./subcategorygrids/BanknotenSubcategoryGrid";
import DeutschlandBanknotenSubcategoryGrid from "./subcategorygrids/DeutschlandBanknotenSubcategoryGrid";

export default function SubcategoryGridRouter(props) {

    return (
        <>
            {props.category === "muenzen" && <CoinSubcategoryGrid />}
            {props.category === "altdeutsche_muenzen" && (
                <AltdeutscheCoinGrid />
            )}
            {props.category === "antike_muenzen" && <AntikeCoinGrid />}
            {props.category === "auslaendische_muenzen" && <AuslandCoinGrid />}
            {props.category === "bullion_muenzen" && <BullionCoinGrid />}
            {props.category === "deutschland_1871_muenzen" && (
                <Deutschland1871CoinGrid />
            )}

            {props.category === "medaillen" && <MedalSubcategoryGrid />}
            {props.category === "auslaendische_medaillen" && (
                <ForeignMedalGrid />
            )}
            {props.category === "thematische_medaillen" && <ThemedMedalGrid />}
            {props.category === "deutsche_medaillen" && <DeutscheMedalGrid />}
            {props.category === "karl_goetz_medaillen" && (
                <KarlGoetzMedalGrid />
            )}

            {props.category === "numismatische_literatur" && (
                <NumismatikSubcategoryGrid />
            )}

           
            
            {props.category === "banknoten" && <BanknotenSubcategoryGrid />}
            {props.category === "auslaendische_banknoten" && (
                <AuslandBanknotenSubcategoryGrid />
            )}
            {props.category === "deutschland_banknoten" && (
                <DeutschlandBanknotenSubcategoryGrid />
            )}
        </>
    );
}
