import React from 'react'

export default function CustomProductDetailRouter(props) {
    return (
        <>
            {(props.category === 'MUENZEN' || props.category === 'MEDAILLEN' || props.category === 'BANKNOTEN') &&
                <li className="text-xs py-1 text-gray-500">{props.erhaltung}</li>}
        </>
    )
}

