import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { signout } from '../actions/userActions';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

export default function MessageBox(props) {
  const [visible, setVisible] = useState(true)
  const closeButtonHandler = (e) => {
    e.preventDefault();
    if (props.onClose) {
      props.onClose();
    }
    setVisible(false);
  }

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.children && props.children === 'Invalid user token') {
      dispatch(signout());
    }
  },[props.children])

  return (
    <>
      {visible && (
        props.variant === 'danger'? 
          <div className="select-none	text-white px-6 py-4 border-0 rounded relative my-2 text-red-800 bg-red-100 flex justify-between items-center">
            <span className="inline-block align-middle">
              {props.children}
            </span>
            {!props.hideCloseButton && 
              <button className="focus:outline-none" onClick={closeButtonHandler}>
                <FontAwesomeIcon icon="times"/> 
              </button>}
          </div>
        : props.variant === 'success' ?
            <div className="select-none	text-white px-6 py-4 border-0 rounded relative my-2 text-green-800 bg-green-100 flex justify-between items-center">
              <span className="inline-block align-middle">
                {props.children}
              </span>
              {!props.hideCloseButton && 
              <button className="focus:outline-none" onClick={closeButtonHandler}>
                <FontAwesomeIcon icon="times"/> 
              </button>}
            </div>
        :   <div className="select-none	text-white px-6 py-4 border-0 rounded relative my-2 text-blue-800 bg-blue-100 flex justify-between items-center">
              <span className="inline-block align-middle">
                {props.children}
              </span>

              {!props.hideCloseButton && 
              <button className="focus:outline-none" onClick={closeButtonHandler}>
                <FontAwesomeIcon icon="times"/> 
              </button>}
            </div>

      )}
    </>

  );
}