import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TagButton from '../components/TagButton';

export default function TagSearchScreen() {
    const { t } = useTranslation(["tagsearch"]);
    const [currentTheme, setCurrentTheme] = useState(["", [""], [""]]);
    const resetHandler = (e) => {
        e.preventDefault();
        setCurrentTheme(["", [""], [""]])
    }
    const submitHandler = (e) => {
        e.preventDefault();

    }
    const tagTree = {
        "payload": [
            { "tag": "Architektur", "subtags": ["Brücken", "Denkmäler", "Kirchen", "Schlösser & Burgen", "Stadtansichten"] },
            { "tag": "Bildung & Wissenschaft", "subtags": ["Astronomie & Astrologie", "Medizin", "Justiz", "Schule & Uni", "Technik"] },
            { "tag": "Ereignisse", "subtags": ["Ausstellung", "Historische Ereignisse", "Hungersnot", "Jubiläum", "Kalender"] },
            { "tag": "Familienereignisse", "subtags": ["Kinder & Geburt", "Kommunion, Konfirmation, Firmung", "Liebe & Ehe", "Taufe"] },
            { "tag": "Feste", "subtags": ["Fasching/Karneval", "Ostern", "Weihnachten"] },
            { "tag": "Gegenstände (Artificialia)", "subtags": ["Landkarten", "Orden & Ehrenzeichen", "Wappen"] },
            { "tag": "Geldwesen", "subtags": ["Banken & Versicherung", "Münzen & Geldwesen", "Numismatik"] },
            { "tag": "Mythen, Legenden & Geschichten", "subtags": ["Comic/Fantasy/Sci-Fi", "Fabelwesen", "Götter", "Personifikation & Allegorie"] },
            { "tag": "Natur", "subtags": ["Bergbau", "Landwirtschaft", "Pflanzen", "Tiere"] },
            { "tag": "Organisationen", "subtags": ["Freimaurer", "Militaria", "Schlaraffia", "Schützenvereine", "Studentica"] },
            { "tag": "Personen", "subtags": ["Berühmte Person", "Gelehrte", "Päpste", "Politiker", "Royal"] },
            { "tag": "Religion", "subtags": ["Heilige", "Islam", "Judaica", "Reformation"] },
            { "tag": "Schöne Künste", "subtags": ["Kunst", "Literatur", "Musik"] },
            { "tag": "Sport", "subtags": ["Fußball", "Jagd", "Olympia"] },
            { "tag": "Vergnügen", "subtags": ["Bier & Wein", "Erotik", "Film/Fernsehen", "Theater/Oper"] },
            { "tag": "Verkehr & Transport", "subtags": ["Auto", "Eisenbahn", "Luft- & Raumfahrt", "Post", "Schiffe & Seefahrt"] }

        ]
    }
    const tagButton = (key, type, owner, value) => {
        return <TagButton key={key} currentTheme={currentTheme} setCurrentTheme={setCurrentTheme} type={type} owner={owner} value={value}>{value || owner}</TagButton>;
    }

    const tagButtonsGroup1 = tagTree.payload.map((d, i) => {
        return tagButton(i, "tag", "", d.tag)
    });

    const tagButtonsGroup2 = tagTree.payload.map((d, i) => {
        const owner = d.tag;
        return d.subtags.map((e, j) => {
            return tagButton(i * 30 + j, "subtag", owner, e)
        })
    })
    return (
        <div className="px-5p">
            <h1 className="text-3xl font-extrabold text-gray-900 tracking-tight py-2">{t("keyword_search", "Schlagwortsuche")}</h1>
            <form className="">
                <div className="text-right w-full space-x-2 py-2">
                    <button onClick={submitHandler} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-400 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"><span className="pr-2"><FontAwesomeIcon icon="search" /></span>Suchen</button>
                    <button onClick={resetHandler} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"><span className="pr-2"><FontAwesomeIcon icon="redo" /></span>Zurücksetzen</button>
                </div>
                <div>
                    <div>
                        {tagButtonsGroup1}
                        {tagButtonsGroup2}
                    </div>
                </div>
                <div className="text-right w-full space-x-2 py-2">
                    <button onClick={submitHandler} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-400 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"><span className="pr-2"><FontAwesomeIcon icon="search" /></span>Suchen</button>
                    <button onClick={resetHandler} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"><span className="pr-2"><FontAwesomeIcon icon="redo" /></span>Zurücksetzen</button>
                </div>
            </form>

        </div>
    )
}