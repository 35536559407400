export const WISH_LIST_SELF_REQUEST = 'WISH_LIST_SELF_REQUEST';
export const WISH_LIST_SELF_SUCCESS = 'WISH_LIST_SELF_SUCCESS';
export const WISH_LIST_SELF_FAIL = 'WISH_LIST_SELF_FAIL';

export const WISH_CREATE_REQUEST = 'WISH_CREATE_REQUEST';
export const WISH_CREATE_SUCCESS = 'WISH_CREATE_SUCCESS';
export const WISH_CREATE_FAIL = 'WISH_CREATE_FAIL';
export const WISH_CREATE_RESET = 'WISH_CREATE_RESET';

export const WISH_DELETE_REQUEST = 'WISH_DELETE_REQUEST';
export const WISH_DELETE_SUCCESS = 'WISH_DELETE_SUCCESS';
export const WISH_DELETE_FAIL = 'WISH_DELETE_FAIL';
export const WISH_DELETE_RESET = 'WISH_DELETE_RESET';

export const WISH_LIST_REQUEST = 'WISH_LIST_REQUEST';
export const WISH_LIST_SUCCESS = 'WISH_LIST_SUCCESS';
export const WISH_LIST_FAIL = 'WISH_LIST_FAIL';

export const WISH_ADD_ITEM = 'WISH_ADD_ITEM';
export const WISH_ADD_ITEM_FAIL = 'WISH_ADD_ITEM_FAIL';
export const WISH_REMOVE_ITEM = 'WISH_REMOVE_ITEM';