export const PRODUCT_LITERATURE_REQUEST = "PRODUCT_LITERATURE_REQUEST";
export const PRODUCT_LITERATURE_SUCCESS = "PRODUCT_LITERATURE_SUCCESS";
export const PRODUCT_LITERATURE_FAIL = "PRODUCT_LITERATURE_FAIL";
export const PRODUCT_LITERATURE_RESET = "PRODUCT_LITERATURE_RESET";

export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";

export const PRODUCT_CREATE_REQUEST = "PRODUCT_CREATE_REQUEST";
export const PRODUCT_CREATE_SUCCESS = "PRODUCT_CREATE_SUCCESS";
export const PRODUCT_CREATE_FAIL = "PRODUCT_CREATE_FAIL";
export const PRODUCT_CREATE_RESET = "PRODUCT_CREATE_RESET";

export const PRODUCT_UPDATE_REQUEST = "PRODUCT_UPDATE_REQUEST";
export const PRODUCT_UPDATE_SUCCESS = "PRODUCT_UPDATE_SUCCESS";
export const PRODUCT_UPDATE_FAIL = "PRODUCT_UPDATE_FAIL";
export const PRODUCT_UPDATE_RESET = "PRODUCT_UPDATE_RESET";

export const PRODUCT_DELETE_REQUEST = "PRODUCT_DELETE_REQUEST";
export const PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS";
export const PRODUCT_DELETE_FAIL = "PRODUCT_DELETE_FAIL";
export const PRODUCT_DELETE_RESET = "PRODUCT_DELETE_RESET";

export const PRODUCT_ZERO_REQUEST = "PRODUCT_ZERO_REQUEST";
export const PRODUCT_ZERO_SUCCESS = "PRODUCT_ZERO_SUCCESS";
export const PRODUCT_ZERO_FAIL = "PRODUCT_ZERO_FAIL";
export const PRODUCT_ZERO_RESET = "PRODUCT_ZERO_RESET";

export const PRODUCT_IMPORT_REQUEST = "PRODUCT_IMPORT_REQUEST";
export const PRODUCT_IMPORT_SUCCESS = "PRODUCT_IMPORT_SUCCESS";
export const PRODUCT_IMPORT_FAIL = "PRODUCT_IMPORT_FAIL";
export const PRODUCT_IMPORT_RESET = "PRODUCT_IMPORT_RESET";

export const PRODUCT_SEARCH_REQUEST = "PRODUCT_SEARCH_REQUEST";
export const PRODUCT_SEARCH_SUCCESS = "PRODUCT_SEARCH_SUCCESS";
export const PRODUCT_SEARCH_FAIL = "PRODUCT_SEARCH_FAIL";
export const PRODUCT_SEARCH_RESET = "PRODUCT_SEARCH_RESET";

export const PRODUCT_INDEX_REQUEST = "PRODUCT_INDEX_REQUEST";
export const PRODUCT_INDEX_SUCCESS = "PRODUCT_INDEX_SUCCESS";
export const PRODUCT_INDEX_FAIL = "PRODUCT_INDEX_FAIL";
export const PRODUCT_INDEX_RESET = "PRODUCT_INDEX_RESET";

export const PRODUCT_NEWEST_REQUEST = "PRODUCT_NEWEST_REQUEST";
export const PRODUCT_NEWEST_SUCCESS = "PRODUCT_NEWEST_SUCCESS";
export const PRODUCT_NEWEST_FAIL = "PRODUCT_NEWEST_FAIL";

export const PRODUCT_FILTER_REQUEST = "PRODUCT_FILTER_REQUEST";
export const PRODUCT_FILTER_SUCCESS = "PRODUCT_FILTER_SUCCESS";
export const PRODUCT_FILTER_FAIL = "PRODUCT_FILTER_FAIL";
export const PRODUCT_FILTER_RESET = "PRODUCT_FILTER_RESET";
