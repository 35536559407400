import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { getCookieConsentValue } from 'react-cookie-consent';

import analytics from "../analytics"

export default function useGoogleAnalytics() {
    const location = useLocation();

    useEffect(() => {
        const cookieValue = getCookieConsentValue();
        if (cookieValue === "true") {
            const currentPath = location.pathname + location.search
            analytics.sendPageview(currentPath)
        } 
    }, [location])
}