export default function convertToCookieFormat(data) {
    let result = [];
    data.forEach(function (entry) {
        result.push({
            "name": entry.product.name, 
            "image": entry.product.image[0], 
            "item_number": entry.product.item_number,
            "price" : entry.product.price,
            "stock" : entry.product.stock,
            "product": entry.product._id
        })
    });
    return result;
}