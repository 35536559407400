import React, { useState } from "react";
import { FontAwesomeIcon } from "../../node_modules/@fortawesome/react-fontawesome/index";
import useKeyPress from "./useKeyPress";

export default function ImageZoomer(props) {
    const [backgroundImage, setBackgroundImage] = useState(`url(${props.src})`);
    const [backgroundPosition, setBackgroundPosition] = useState('0% 0%');
    const [showModal, setShowModal] = useState(false);
    const handleMouseMove = (e) => {
        const { left, top, width, height } = e.target.getBoundingClientRect()
        const x = (e.pageX - left) / width * 100
        const y = (e.pageY - top) / height * 100
        setBackgroundPosition(`${x}% ${y}%`);
        setBackgroundImage(`url(${props.src})`)
    }

    useKeyPress('Escape', () => {
        setShowModal(false);
    });

    return (
        <>
            {showModal &&

                <div className="bg-black bg-opacity-70 h-full w-full fixed z-10 top-0 left-0 overflow-y-scroll">
                    <div className="md:mt-10 relative overlay-content flex justify-center">
                        <div className="bg-white shadow border border-black p-10">
                            <div className="flex w-full justify-end">

                                <button className="transition duration-300 ease-in-out text-black focus:outline-none hover:opacity-60" onClick={() => setShowModal(false)} ><FontAwesomeIcon icon="times" size="2x" /></button>
                            </div>
                            <div className='flex w-full justify-between'>
                                <img src={props.src} alt={props.alt} />

                            </div>
                        </div>
                    </div>
                </div>}
            <figure onClick={() => setShowModal(true)} onMouseMove={handleMouseMove} style={{ backgroundImage: backgroundImage, backgroundPosition: backgroundPosition, cursor: 'pointer' }}>
                <img src={props.src} alt={props.alt} />
            </figure>
        </>
    );
}



