import React from 'react'
import { Link } from 'react-router-dom'
import EmailAddress from '../components/EmailAddress'
import PhoneNumber from '../components/PhoneNumber'
import ScrollToTopMount from '../components/ScrollToTopMount'

export default function WiderrufEngScreen() {
    return (
        <div className="px-5p pb-5">
            <ScrollToTopMount />
            <Link to="/datenschutz"><span className="text-white p-2 rounded float-right bg-blue-500">Deutsche Version</span></Link>
            <h1 className="text-3xl font-extrabold text-gray-900 tracking-tight py-2">Right of Withdrawal</h1>
            <div className="py-2 space-y-2">
                <div className="space-y-2 text-sm">
                    <p><b>Right of Withdrawal Disclosure</b></p>
                    <p>Consumers (any natural person who conducts a legal transaction which may not be attributed to any of his or her commercial or independent professional activities) are entitled to a statutory right of withdrawal.</p>
                    <p>Consumers have the right to withdraw from their contract declaration within 14 days for any reason by giving written notice (e.g. letter, fax, e-mail) within two weeks or – if you have already received the item prior to expiry of this period -- by sending back the item. The withdrawal period shall commence upon receipt of this written notification, however not prior to the buyer's receiving merchandise and not prior to our fulfilling our duty to inform pursuant to §§ 312c Section 2 of the German Civil Code (BGB) in conjunction with § 1 Section 1.2 and 4 German Civil Code Disclosure Requirements (BGB-InfoV), along with our obligations pursuant to § 312e Section 1 Subsection 1 of the German Civil Code (BGB) in conjunction with § 3 of German Civil Code Disclosure Requirements (BGB-InfoV). In order to uphold the withdrawal period, it is sufficient to timely dispatch the withdrawal notice or the merchandise. The withdrawal is to be addressed to:</p>
                    <p></p>
                    <br />
                    <div className="pl-10 space-y-2">
                        <p>Münzgalerie München MGM</p>
                        <p>Handelsgesellschaft mbH &amp; Co. Joker KG</p>
                        <p>Stiglmaierplatz 2</p>
                        <p>D-80333 München</p>
                        <p>Telefon: <PhoneNumber number={"+49895233660"} text={"+49 (89) 5233660"}/></p>
                        <br />
                        <p>Telefax: <PhoneNumber number="+498932795990" text="(089) 32 79 59 90" /></p>
                        <p>E-Mail: <EmailAddress email="mgm@muenzgalerie.de" /></p>
                    </div>
                    <br />
                    <Link className="underline" to="/documents/Withdrawal_Form_MGM.pdf">Model withdrawal form</Link>
                    <br />
                    <p><b>Effects of Withdrawal</b></p>
                    <p>In the event of a valid withdrawal, each party shall return to the other party any benefits received (e.g. interest). In case you are unable to return the goods or services rendered by us in whole or part, or only return them in a deteriorated condition, you must provide compensation for their value. This does not apply to the provision of goods if the deterioration of the goods results exclusively from inspecting them – as would have been possible, for example, in a shop. Otherwise you can avoid the obligation to provide compensation for the value of goods which have deteriorated through their proper use by not taking the goods into use as if they were your property and by refraining from any action that would diminish their value. Goods that are suitable for dispatch by parcel are to be sent back at our risk. You are to bear the cost of return shipping if the goods were delivered as ordered and the price of the goods being returned does not exceed 40 Euros or, in the event of a higher value, if at the time of revocation you have not yet paid in full or contractually arranged to make partial payment. Otherwise return shipping shall be free of charge to you. Any goods that are unsuitable for dispatch will be collected at your address. Obligations to reimburse payments must be fulfilled within 30 days. For you this term begins when you dispatch either your declaration of revocation or the goods; for us it begins upon their receipt.</p>
                    <p><b>Exclusion of Withdrawal</b></p>
                    <p>(1) The right of withdrawal may not apply to contracts for supply of goods to be manufactured according to customer specifications or clearly tailored to personal needs according to § 312g 2 Nr. 1 BGB.</p>
                    <p>(2) We wish to point out that there is no right of withdrawal for the delivery of goods or the rendering of financial services, the price of which is subject to financial market fluctuations on which the company has no influence and which may occur within the withdrawal period according to § 312 d IV Nr. 6 BGB. In particular, this applies to services in connection with stocks, share certificates issued by an investment company or a foreign investment firm and other tradable securities, foreign currencies, derivatives or money market instruments. We further wish to point out that some coins are traded at "spot prices" and therefore losses may occur during the withdrawal period due to market fluctuation.</p>
                    <p><b>End of Right of Withdrawal Disclosure</b></p>
                </div>
            </div>
        </div>
    )
}
