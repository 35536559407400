import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Axios from 'axios';
import { detailsProduct, updateProduct } from '../actions/productActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { PRODUCT_UPDATE_RESET } from '../constants/productConstants';
import JsonKeyValueInput from '../components/JsonKeyValueInput';
import JsonKeyValueInsert from '../components/JsonKeyValueInsert';

export default function ProductEditScreen(props) {
    const productId = props.match.params.id;
    const [name, setName] = useState('');
    const [image, setImage] = useState(['']);
    const [category, setCategory] = useState('');
    const [subcategory, setSubcategory] = useState('');
    const [brand, setBrand] = useState('');
    const [price, setPrice] = useState('');
    const [stock, setStock] = useState('');
    const [item_number, setItemNumber] = useState('');
    const [description, setDescription] = useState('');
    const [video, setVideo] = useState('');
    const [detail, setDetail] = useState('');
    const [steuerklasse, setSteuerklasse] = useState('');

    const productDetails = useSelector((state) => state.productDetails);
    const { loading, error, product } = productDetails;

    const productUpdate = useSelector((state) => state.productUpdate);
    const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = productUpdate;

    const dispatch = useDispatch();

    const [detailChanging, setDetailChanging] = useState(false);
    useEffect(() => {
        if (detailChanging) {
            setDetailChanging(false);
        }
    }, [detailChanging]);

    useEffect(() => {
        if (successUpdate) {
            props.history.push('/productlist');
        }
        if (!product || product._id !== productId || successUpdate) {
            dispatch({ type: PRODUCT_UPDATE_RESET });
            dispatch(detailsProduct(productId))
        } else {
            setName(product.name);
            setPrice(product.price);
            setImage(product.image);
            setCategory(product.category);
            setSubcategory(product.subcategory);
            setBrand(product.brand);
            setStock(product.stock);
            setItemNumber(product.item_number);
            setDescription(product.description);
            setVideo(product.video)
            setDetail(product.detail);
            setSteuerklasse(product.steuerklasse)
        }
    }, [product, dispatch, productId, successUpdate, props.history]);

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(updateProduct(
            {
                _id: productId,
                name,
                price,
                image,
                category,
                subcategory,
                brand,
                stock,
                item_number,
                steuerklasse,
                description,
                video,
                detail,
            }
        ));
    };

    const [loadingUpload, setLoadingUpload] = useState(false);
    const [errorUpload, setErrorUpload] = useState('');
    const userSignin = useSelector((state) => state.userSignin);
    const { userInfo } = userSignin;
    const uploadFileHandler = async (e) => {
        const files = e.target.files;
        const bodyFormData = new FormData();
        for (let i = 0; i < files.length; i++) {
            bodyFormData.append(`images`, files[i]);
        }
        setLoadingUpload(true);
        try {
            const { data } = await Axios.post('/api/uploads', bodyFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`,
                },
            });
            setImage(data);
            setLoadingUpload(false);
        } catch (error) {
            setErrorUpload(error.message);
            setLoadingUpload(false);
        }
    };

    const handleDetail = (detail) => {
        setDetailChanging(true);
        setDetail(detail);
    }

    return (
        <div className="px-5p space-y-2">
            <div className="py-2">
                <h1 className="text-3xl font-extrabold text-gray-900 tracking-tight">Artikel #<i className="font-medium text-gray-500">{item_number}</i> bearbeiten:</h1>
                <h2>Interne ID: <span className="font-medium text-gray-500">({productId})</span> </h2>
            </div>

            <form onSubmit={submitHandler}>

                {loadingUpdate && <LoadingBox></LoadingBox>}
                {errorUpdate && <MessageBox variant="danger">{errorUpdate}</MessageBox>}
                {loading ? (
                    <LoadingBox></LoadingBox>
                ) : error ? (
                    <MessageBox variant="danger">{error}</MessageBox>
                ) : (
                    <div className="text-sm divide-y space-y-2">
                        <div className="flex flex-col py-2 space-y-2">
                            <label className="uppercase text-gray-900" htmlFor="name">Name</label>
                            <input id="name" type="text" className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" placeholder="Artikelname" value={name || ''} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className="flex flex-col py-2 space-y-2">
                            <label className="uppercase text-gray-900" htmlFor="price">Preis</label>
                            <div className="flex flex-wrap items-stretch w-full mb-4 relative">
                                <input id="price" type="number" className="leading-normal  w-96 border h-10 border-grey-light rounded rounded-r-none px-3 relative" placeholder="Artikelpreis" value={price || ''} onChange={(e) => setPrice(e.target.value)} />
                                <div className="flex -mr-px">
                                    <span className="flex items-center leading-normal bg-gray-100 rounded rounded-l-none border border-l-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark text-sm">€</span>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col py-2 space-y-2">
                            <label className="uppercase text-gray-900">Bilderposition verwalten</label>
                            <div className='flex  space-x-2 '>

                                {image && image.map((item, i) =>
                                    <div className='flex flex-col w-52 border p-1 space-y-1  '>
                                        {i + 1}
                                        <img src={item} className="w-52 h-52" alt="image" />
                  
                                        <hr />

                                        <div className='flex space-x-2 items-center'> <span>Position</span>{image.map((itemx, j) =>
                                            <div onClick={() => setImage(image.map(function (inneritem, k) {return k === j ? product.image[i] : inneritem}))} className='p-1 bg-blue-100 hover:bg-blue-200 rounded w-5 text-center cursor-pointer'>{j+1}</div>)}
                                        </div>
                                        <div className='flex space-x-2'>
                                            <input type="text" disabled value={product.image[i]} />
                                        </div>
                                        <img src={product.image[i]} className="w-52 h-52" alt="image" />

                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col py-2 space-y-2">
                            <label className="uppercase text-gray-900" htmlFor="imageFile">Bilder hochladen</label>
                            <label className='text-xs text-red-500'>ACHTUNG! (Hiermit werden bereits vorhandene Bilder überschrieben!)</label>
                            <input type="file" id="imageFile" label="Bild auswählen" onChange={uploadFileHandler} multiple />
                            {loadingUpload && <LoadingBox></LoadingBox>}
                            {errorUpload && <MessageBox variant="danger">{errorUpload}</MessageBox>}
                        </div>
                        <div className="flex flex-col py-2 space-y-2">
                            <label className="uppercase text-gray-900" htmlFor="category">Category</label>
                            <input className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" id="category" type="text" value={category || ''} onChange={(e) => setCategory(e.target.value)} />
                        </div>
                        <div className="flex flex-col py-2 space-y-2">
                            <label className="uppercase text-gray-900" htmlFor="subCategory">Unterkategorie</label>
                            <input className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" id="subCategory" type="text" value={subcategory || ''} onChange={(e) => setSubcategory(e.target.value)} />
                        </div>
                        <div className="flex flex-col py-2 space-y-2">
                            <label className="uppercase text-gray-900" htmlFor="brand">Marke/Titel/Land/Gebiet</label>
                            <input className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" id="brand" type="text" value={brand || ''} onChange={(e) => setBrand(e.target.value)} />
                        </div>
                        <div className="flex flex-col py-2 space-y-2">
                            <label className="uppercase text-gray-900" htmlFor="stock">Bestand</label>
                            <input className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" id="stock" type="text" value={stock || ''} onChange={(e) => setStock(e.target.value)} />
                        </div>
                        <div className="flex flex-col py-2 space-y-2">
                            <label className="uppercase text-gray-900" htmlFor="item_number">Artikelnummer</label>
                            <input className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" id="item_number" type="text" value={item_number || ''} onChange={(e) => setItemNumber(e.target.value)} />
                        </div>
                        <div className="flex flex-col py-2 space-y-2">
                            <label className="uppercase text-gray-900" htmlFor="steuerklasse">Steuerklasse</label>
                            <input className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" id="steuerklasse" type="number" value={steuerklasse || ''} onChange={(e) => setSteuerklasse(e.target.value)} />
                        </div>
                        <div className="flex flex-col py-2 space-y-2">
                            <label className="uppercase text-gray-900" htmlFor="description">Beschreibung</label>
                            <input className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" id="description" type="text" value={description || ''} onChange={(e) => setDescription(e.target.value)} />
                        </div>
                        <div className="flex flex-col py-2 space-y-2">
                            <label className="uppercase text-gray-900" htmlFor="video">Videopfad</label>
                            <input className="leading-normal w-96 border h-10 border-grey-light rounded px-3 relative" id="video" type="text" value={video || ''} onChange={(e) => setVideo(e.target.value)} />
                        </div>
                        <div className="flex flex-col py-2 space-y-2">
                            <label className="uppercase text-gray-900">Details</label>
                            {detail && Object.entries(detail).map(([key, value]) => {
                                return (
                                    <JsonKeyValueInput key={key} detailKey={key} detailValue={value} detail={detail} onSelectDetail={handleDetail} />
                                )
                            })}
                            <JsonKeyValueInsert detail={detail} onSelectDetail={handleDetail} />


                        </div>
                        <div className="flex justify-end py-2">
                            <label></label>
                            <button className="group relative  flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md  bg-yellow-100 hover:bg-yellow-200 focus:outline-none" type="submit">Speichern</button>
                        </div>
                    </div>
                )}
            </form>
        </div>
    );
}
