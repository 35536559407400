import Axios from 'axios';
import { CART_ADD_ITEM, CART_ITEM_AVAILABLE_FAIL, CART_ITEM_AVAILABLE_REQUEST, CART_ITEM_AVAILABLE_SUCCESS, CART_REMOVE_ITEM, CART_SAVE_SHIPPING_INFO } from "../constants/cartConstants";

export const addToCart = (productId, qty) => async (dispatch, getState) => {
    const { data } = await Axios.get(`/api/products/${productId}`);
    dispatch({
        type: CART_ADD_ITEM,
        payload: {
            brand: data.brand,
            name: data.name,
            image: data.image[0],
            item_number: data.item_number,
            price: data.price,
            stock: data.stock,
            product: data._id,
            qty,
        }
    });
    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
};

export const removeFromCart = (productId) => async (dispatch, getState) => {
    dispatch({ type: CART_REMOVE_ITEM, payload: productId });
    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
}

export const saveShippingInfo = (data) => (dispatch) => {
    dispatch({ type: CART_SAVE_SHIPPING_INFO, payload: data });
    localStorage.setItem('shippingInfo', JSON.stringify(data));
}

export const checkCartAvailable = (cartItems) => async (dispatch, getState) => {
    dispatch({ type: CART_ITEM_AVAILABLE_REQUEST, payload: cartItems });
    try {
        const { userSignin: { userInfo } } = getState();
        const { data } = await Axios.post(`/api/orders/check`, { cartItems }, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        });
        for (var i = 0; i < data.unavailable.length; i++) {
            const unavailableID = data.unavailable[i];
            dispatch({ type: CART_REMOVE_ITEM, payload: unavailableID });
        }
        localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
        dispatch({ type: CART_ITEM_AVAILABLE_SUCCESS, payload: data.unavailable });

    } catch (error) {
        dispatch({
            type: CART_ITEM_AVAILABLE_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        });
    }
};
