import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { createProduct, deleteProduct, zeroProduct, listProducts } from '../actions/productActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { PRODUCT_CREATE_RESET, PRODUCT_DELETE_RESET } from '../constants/productConstants';
import currencyFormat from '../utils/currency';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dateMediumFormat } from '../utils/date';
import OldPagination from '../components/OldPagination';

export default function ProductListScreen(props) {

    const productList = useSelector((state) => state.productList);
    const { loading, error, products, page, pages } = productList;

    const { id = 'all', name = 'all' , category = 'all', pageNumber = 1 } = useParams();

    const productCreate = useSelector((state) => state.productCreate);
    const { loading: loadingCreate, error: errorCreate, success: successCreate, product: createdProduct } = productCreate;

    const productDelete = useSelector((state) => state.productDelete);
    const { loading: loadingDelete, error: errorDelete, success: successDelete } = productDelete;

    const productZero = useSelector((state) => state.productZero);
    const { loading: loadingZero, error: errorZero, success: successZero } = productZero;

    const dispatch = useDispatch();
    useEffect(() => {
        if (successCreate) {
            dispatch({ type: PRODUCT_CREATE_RESET });
            props.history.push(`/product/${createdProduct._id}/edit`);
        }

        if (successDelete) {
            dispatch({ type: PRODUCT_DELETE_RESET });
        }
        dispatch(listProducts({ id: id !== "all" ? id : '', text: name !== "all" ? name : '', order:'newest', stock: "all", category: category !== "all" ? category : '', pageNumber: pageNumber }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createdProduct, dispatch, props.history, successCreate, successDelete, successZero, pageNumber]);

    const deleteHandler = (product) => {
        if (window.confirm(`Möchten Sie diesen Artikel ${product.name} löschen?`)) {
            dispatch(deleteProduct(product._id));
        }
    };

    const zeroStock = (product) => {
        if (window.confirm(`Möchten Sie den Bestand von diesem Artikel ${product.name} auf 0 setzen?`)) {
            dispatch(zeroProduct(product._id));
        }
    };

    const createHandler = () => {
        dispatch(createProduct())
    };
    
    const getFilterUrl = (filter) => {
        const filterId = filter.id || id;
        const filterName = filter.name || name;
        const filterCategory = filter.category || category;
        const filterPageNumber = filter.page || pageNumber;

        return `/productlist/id/${filterId}/name/${filterName}/category/${filterCategory}/page/${filterPageNumber}`;
    };

    const history = useHistory();

    const activateFilter = () => {
        var id = document.getElementById('idInput').value || "all";
        var name = document.getElementById('nameInput').value || "all";
        var category = document.getElementById('categoryInput').value || "all";
        history.push(getFilterUrl({id: id, name: name, category: category, page: 1}));
    }

    return (
        <div className="px-5p space-y-2">
            <div className="flex flex-row justify-between py-2">
                <h1 className="text-3xl font-extrabold text-gray-900 tracking-tight">Artikelliste</h1>
                <div className="flex space-x-2">
                    <Link to="/import" className="group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md  bg-purple-100 hover:bg-purple-200 focus:outline-none">
                        Artikel import
                    </Link>
                    <button className="group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md  bg-blue-100 hover:bg-blue-200 focus:outline-none" type="button" onClick={createHandler}>
                        Artikel hinzufügen
                    </button>
                </div>
            </div>

            {(loadingDelete || loadingZero || loadingCreate) && <LoadingBox></LoadingBox>}
            {errorDelete && <MessageBox variant="danger">{errorDelete}</MessageBox>}
            {errorCreate && <MessageBox variant="danger">{errorCreate}</MessageBox>}
            {errorZero && <MessageBox variant="danger">{errorZero}</MessageBox>}
            {loading ? (
                <LoadingBox></LoadingBox>
            ) : error ? (
                <MessageBox variant="danger">{error}</MessageBox>

            ) : (
                <div className="-my-2 overflow-x-auto md:-mx-6 ">
                    <form className="w-full text-sm">
                        <div className="grid grid-cols-4 grid-rows-1 px-3 mb-3 justify-center">
                            <div className="px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="idInput">
                                    ID/Artikelnummer
                                </label>
                                <input className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded px-1 py-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="idInput" type="text" placeholder="" defaultValue={id === "all" ? '' : id} />
                            </div>
                            <div className="px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="nameInput">
                                    Name
                                </label>
                                <input className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded px-1 py-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="nameInput" type="text" placeholder="" defaultValue={name === "all" ? '' : name} />
                            </div>
                            <div className="px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="categoryInput">
                                    Kategorie/Unterkategorie
                                 </label>
                                <div className="relative">
                                    <select className="block appearance-none w-full bg-gray-50 border border-gray-200 text-gray-700 py-1 px-1 pr-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="categoryInput" defaultValue={category}>
                                        <option className="font-bold" value="all">Alle</option>
                                        <option className="font-bold" value="BANKNOTEN">Banknoten</option>
                                        <option className="font-bold" value="MEDAILLEN">Medaillen</option>
                                        <option value="AUSLAENDISCHE_MEDAILLEN">&nbsp;&nbsp;Ausländische Medaillen</option>
                                        <option value="DEUTSCHE_MEDAILLEN">&nbsp;&nbsp;Deutsche Medaillen</option>
                                        <option value="KARL_GOETZ_MEDAILLEN">&nbsp;&nbsp;Medaillen von Karl Goetz</option>
                                        <option value="THEMATISCHE_MEDAILLEN">&nbsp;&nbsp;Thematische Medaillen</option>
                                        <option className="font-bold" value="MUENZEN">Münzen</option>
                                        <option value="ALTDEUTSCHE_MUENZEN">&nbsp;&nbsp;Altdeutsche Münzen</option>
                                        <option value="ANTIKE_MUENZEN">&nbsp;&nbsp;Antike Münzen</option>
                                        <option value="AUSLAENDISCHE_MUENZEN">&nbsp;&nbsp;Ausländische Münzen</option>
                                        <option value="BULLION_MUENZEN">&nbsp;&nbsp;Bullion Münzen</option>
                                        <option value="DEUTSCHLAND_1871_MUENZEN">&nbsp;&nbsp;Deutschland ab 1871 Münzen</option>
                                        <option className="font-bold" value="NUMISMATISCHE_LITERATUR">Numismatische Literatur</option>
                                        <option className="font-bold" value="SCHMUCK">Schmuck</option>
                                    </select>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <FontAwesomeIcon icon="caret-down" />
                                    </div>
                                </div>
                            </div>                           
                        </div>
                        <div className="flex px-6 pb-2 justify-end">
                            <button onClick={activateFilter} className="mr-4 transition duration-300 ease-in-out hover:bg-gray-400 hover:border-gray-800 hover:text-white bg-gray-200 p-2 border border-gray-400 rounded px-6">Suchen</button>
                            <Link to="/productlist" className="transition duration-300 ease-in-out hover:bg-gray-400 hover:border-gray-800 hover:text-white bg-gray-200 p-2 border border-gray-400 rounded px-6">Filter zurücksetzen</Link>
                        </div>
                    </form>
                    <div className="py-2 align-middle inline-block min-w-full md:px-6">
                        <div className="shadow overflow-hidden border-b border-gray-200 rounded-lg ">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                                        <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ArtikelNr.</th>
                                        <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Kategorie</th>
                                        <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Unterkategorie</th>
                                        <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                                        <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Preis</th>
                                        <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Bestand</th>
                                        <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Eingestellt am</th>
                                        <th scope="col" className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {products.map((product) => (
                                        <tr key={product._id}>
                                            <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-900"><Link className="text-blue-500" to={`/product/${product._id}`}>{product._id}</Link></td>
                                            <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-500">{product.item_number}</td>
                                            <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-500">{product.category}</td>
                                            <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-500">{product.subcategory}</td>
                                            <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-500">{product.name}</td>
                                            <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-500">{currencyFormat(product.price)}</td>
                                            <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-500">{product.stock}</td>
                                            <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-500">{dateMediumFormat(product.createdAt)}</td>
                                            <td className="px-2 py-1 whitespace-nowrap table-cell text-sm font-medium text-gray-500 space-x-1 text-right">
                                                <button type="button" onClick={() => props.history.push(`/product/${product._id}/edit`)} className="px-2 border border-transparent rounded-md bg-yellow-100 hover:bg-yellow-200 focus:outline-none">
                                                    Bearbeiten
                                                </button>
                                                <button type="button" disabled={product.stock < 1} onClick={() => zeroStock(product)} className="disabled:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed px-2 border border-transparent rounded-md bg-pink-100 hover:bg-pink-200 focus:outline-none">
                                                    Bestand=0
                                                </button>
                                                <button type="button" onClick={() => deleteHandler(product)} className="hidden px-2 border border-transparent rounded-md bg-red-100 hover:bg-red-200 focus:outline-none">
                                                    Löschen
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="py-2">
                            <OldPagination page={page} pages={pages} link={`/productlist/id/${id}/name/${name}/category/${category}/page/`} />
                        </div>
                    </div>
                </div>

            )
            }
        </div>
    );
}
