import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import currencyFormat from '../utils/currency';
import LoadingBox from './LoadingBox';
import { newSeconds } from '../utils/date';

export default function ProductBox(props) {
  const { t } = useTranslation(["product"]);
  const [didLoad, setLoad] = useState(false);

  const { product } = props;

  function process(value) {
    return value === undefined ? '' : value.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').toLowerCase();
  }
  return (
    <Link to={`/product/${process(product.category)}-${process(product.brand)}-${process(product.name)}/${product._id}`}>
      <li key={product._id} className="h-full max-w-2xl text-xs md:text-sm">
        <div className={`product border border-gray-200 flex bg-white flex-nowrap flex-col h-full ${props.width && `md:w-${props.width}`} ${props.mobileWidth && `w-${props.mobileWidth}`} justify-between p-2 md:p-5`}>
          <div className="flex flex-row justify-between pb-2">
            {(new Date(product.createdAt).getTime() + newSeconds) > Date.now() ? <div className="text-sm px-2 items-center flex text-left text-yellow-700 bg-yellow-100">{t("new", "Neu")}</div> : <div></div>}
            <div className="font-semibold text-sm md:text-base text-right">{product.stock === 0 ? <span className="text-red-500">{t("soldout", "Ausverkauft")}</span> : currencyFormat(product.price)}</div>
          </div>
          <div className="flex flex-row flex-nowrap justify-items-center justify-center	items-center">
            {!didLoad && <LoadingBox variant="spinner" />}<img onLoad={() => setLoad(true)} className={`object-contain w-auto md:48 ${props.height ? props.height : "lg:h-52 xl:h-80 2xl:h-96"} `} src={product.image[0].replace("../", "/")} alt={product.brand} onMouseOver={product.image.length > 1 ? e => (e.currentTarget.src = product.image[1].replace("../", "/")) : undefined} onMouseOut={product.image.length > 1 ? e => (e.currentTarget.src = product.image[0].replace("../", "/")) : undefined} />
          </div>
          <div>
            <div className="">
              <div className="flex space-x-2 items-center ">
                {props.catalogident && <div className="font-extrabold text-blue-700 bg-blue-50 rounded p-1">{props.catalogident}</div>}
                <div className="font-semibold">{product.brand}</div>
              </div>
              <div className="">{product.name}</div>
            </div>
          </div>
        </div>
      </li>
    </Link>
  );
}