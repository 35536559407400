import React from 'react'
import { Link } from 'react-router-dom'

export default function NavbarParent(props) {
    return (
        <div>
            <div className="px-3 py-2 flex items-center text-xs uppercase">
                <div className="w-full md:w-auto dropdown md:hover:bg-gray-100 md:px-3 md:py-2 rounded transition duration-300 ease-in-out">

                    {props.link ?
                        <Link className={props.bold && "font-bold"} to={props.link}>{props.title}{props.sup ? <sup className='mx-2 bg-red-500 text-white pl-0.5 py-0.5 rounded text-center'>{props.sup}</sup>:<></>}</Link>
                        : <div className='cursor-default'>{props.title}</div>}
                        { props.children && <ul className=" dropdown-menu relative md:absolute hidden text-gray-700 md:pt-5 z-50 md:-ml-3">{props.children}</ul> }            
                </div>
            </div>
        </div>
    )
}
