
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import React, { useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import VerticalTile from '../components/VerticalTile';
import PhoneNumber from '../components/PhoneNumber';
import NewestProducts from '../components/NewestProducts';
import { useDispatch, useSelector } from 'react-redux';
import { newestProducts } from '../actions/productActions';

export default function HomeScreen(props) {
  const { t } = useTranslation(["home"]);


  const productNewest = useSelector((state) => state.productNewest);
  const { coins, medals } = productNewest;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(newestProducts());
  }, [dispatch]);
  return (
    <div className="content px-5p">
      <div className="flex flex-col mt-2 md:flex-row space-y-2 md:space-y-0 md:space-x-2">
        <div className="flex flex-col w-full">
          <Carousel className="block cursor-pointer -px-5p" showArrows autoPlay showThumbs={false}>
              <Link to="/category/muenzen" className='block'>
                <img src="images/Muenz-kaiser.jpg" alt="carousel1" />
              </Link>
              <Link to="/category/medaillen" className='block'>
                <img src="images/Image-Plaketten.jpg" alt="carousel2" />
              </Link>
              <a href="//mgm-schmuck.de" className='block'>
                <img src="images/Image-Schmuck-1.jpg" alt="carousel3" />
              </a>
          </Carousel>
          <div className="grid grid-cols-2 gap-4 p-3 md:flex md:divide-x md:flex-row  md:w-full md:justify-evenly pt-10">
            <div className="md:w-1/4 flex flex-col space-y-2 justify-start items-center">
              <FontAwesomeIcon icon="store" size="lg" />
              <span className="tracking-widest text-center text-sm md:text-base">Click &amp; Collect</span>
              <span className="text-gray-500  text-xs md:text-sm text-center">{t("order_online", "Online bestellen und im Laden abholen")}</span>
            </div>
            <div className="md:w-1/4 flex flex-col space-y-2 md:pl-4 justify-start items-center">
              <FontAwesomeIcon icon="stamp" size="lg" />
              <span className="tracking-widest text-center text-sm md:text-base">{t("authenticity", "Garantierte Echtheit")}</span>
              <span className="text-gray-500  text-xs md:text-sm text-center">{t("products_auth", "Alle unsere Produkte sind von Experten überprüft")}</span>
            </div>
            <div className="md:w-1/4 flex flex-col space-y-2 md:pl-4 justify-start items-center">
              <FontAwesomeIcon icon="globe-americas" size="lg" />
              <span className="tracking-widest text-center text-sm md:text-base">{t("worldwide_shipping", "Weltweiter Versand")}</span>
              <span className="text-gray-500  text-xs md:text-sm text-center">{t("fast_and_secure", "Schneller und sicherer Versand")}</span>
            </div>
            <div className="md:w-1/4 flex flex-col space-y-2 md:pl-4 justify-start items-center">
              <FontAwesomeIcon icon="phone" size="lg" />
              <span className="tracking-widest text-center text-sm md:text-base">{t("telephone_support", "Telefonische Beratung")}</span>
              <div className="flex flex-col w-full">
                <div className="text-xs md:text-sm text-center w-full"><PhoneNumber number={"+49895233660"} text={"+49 (89) 5233660"} /></div>
                <span className="text-gray-500 text-xs md:text-sm text-center w-full">Mo-Fr: 9:00 - 18:00 {t("uhr", "Uhr")}</span>
              </div>
            </div>
          </div>

          <h2 className="md:px-0 pt-4 tracking-widest font-semibold uppercase text-lg md:text-4xl text-gray-700">
            Münzgalerie München [MGM]
          </h2>
          <h2 className="md:px-0 pb-4 md:pt-3 tracking-widest uppercase text-lg md:text-4xl text-gray-700">
            Numismatik &amp; Schmuck seit 1975
          </h2>
          

            <NewestProducts title={t("coins", "Münzen")} products={coins} link="/category/muenzen/" />
            <NewestProducts title={t("medals","Medaillen")} products={medals} link="/category/medaillen/" />
          <hr />

          <VerticalTile />
        </div>
      </div>
    </div>
  );
}
