import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';

export default function RightArrow(props) {
    const location = useLocation();
    const search = location.search ? location.search.substring(1) : "";
    const searchJson = search ? JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}') : {};
    searchJson["page"] = props.page + 1;
    return (
        <Link to={{pathname: location.pathname, search: `?${new URLSearchParams(searchJson)}`}} className={
            parseInt(props.page) === parseInt(props.pages)
                ? "pointer-events-none relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-gray-100 font-medium text-gray-500"
                : "relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white font-medium text-gray-500 hover:bg-gray-50"}>
            <span className="sr-only">&gt;</span> 
            <FontAwesomeIcon icon="angle-right" />
        </Link>
    )
}
