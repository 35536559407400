import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, Route, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signout } from '../actions/userActions';
import SearchBox from './SearchBox';
import LanguageSelector from './LanguageSelector';
import { useTranslation } from 'react-i18next';
import PricesWidget from './PricesWidget';
import MainNavbar from './MainNavbar';

export default function HeaderBox() {
    const { t } = useTranslation(["header"]);

    const [navbarOpen, setNavbarOpen] = useState(false);


    const cart = useSelector(state => state.cart);
    const { cartItems } = cart;

    const wishes_cached = useSelector(state => state.wishes_cached);
    const { wishlistItems } = wishes_cached;

    const userSignin = useSelector((state) => state.userSignin);
    const { userInfo } = userSignin;
    const dispatch = useDispatch();
    const signoutHandler = () => {
        dispatch(signout());
    };

    const location = useLocation();
    return (
        <header className={`flex flex-col  ${ location.pathname.indexOf('world') > -1 ? "fixed w-full z-20": ""}`}>
            <div className="flex items-center flex-row justify-between bg-gray-100 py-1 px-5p text-xs md:text-sm fg-gray-700">
                <LanguageSelector ></LanguageSelector>
                <PricesWidget></PricesWidget>

                <div className="md:w-52">
                    <div className="flex flex-row space-x-2">

                        {
                            userInfo ? (
                                <div className="dropdown z-10">
                                    <Link className="inline-flex items-center space-x-1" to="#">
                                        <span>{userInfo.firstName} {userInfo.lastName}</span>
                                        <FontAwesomeIcon icon="angle-down" />
                                    </Link>
                                    <ul className="dropdown-menu right-0 md:right-auto left-auto absolute hidden text-gray-700 pt-1">
                                        {!userInfo.isGuest && <li className=""><Link to="/profile" className="font-normal bg-gray-50 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap">{t("my_account", "Mein Konto")}</Link></li>}
                                        <li className=""><Link to="/cart" className="font-normal bg-gray-50 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap">{t("my_cart", "Mein Warenkorb")}</Link></li>
                                        <li className=""><Link to="/wishlist" className="font-normal bg-gray-50 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap">{t("my_wishlist", "Meine Wunschliste")}</Link></li>
                                        <li className=""><Link to="/orderhistory" className="font-normal bg-gray-50 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap">{t("my_orders", "Meine Bestellungen")}</Link></li>
                                        {/* <li className=""><Link to="/collections" className="font-normal bg-gray-50 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap">{t("my_collections", "Meine Sammlungen")}</Link></li> */}
                                        <hr></hr>
                                        <li className=""><Link to="#signout" onClick={signoutHandler} className="font-normal rounded-b bg-gray-50 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap">{t("signout", "Abmelden")}</Link></li>
                                    </ul>
                                </div>
                            ) : (
                                <div className="md:space-x-4  md:space-y-0 space-y-3 flex md:block flex-col">
                                    <Link className="hover:opacity-75" to="/signin">{t("signin", "Anmelden")}</Link>
                                    <Link className="hover:opacity-75" to="/register">{t("register", "Registrieren")}</Link>

                                </div>
                            )

                        }
                        {userInfo && userInfo.isAdmin && (
                            <div className="space-x-4 items-center flex">
                                <div className="text-blue-800 dropdown z-10 ">
                                    <Link className="inline-flex items-center space-x-1" to="#">
                                        <span>Admin</span>
                                        <FontAwesomeIcon icon="angle-down" />
                                    </Link>
                                    <ul className="dropdown-menu right-0 md:right-auto left-auto absolute hidden text-gray-700 pt-1 z-10">
                                        <li className=""><Link to="/productlist" className="font-normal bg-blue-50 hover:bg-blue-200 py-2 px-4 block whitespace-no-wrap">Artikelliste</Link></li>
                                        <li className=""><Link to="/orderlist" className="font-normal bg-blue-50 hover:bg-blue-200 py-2 px-4 block whitespace-no-wrap">Bestellungsliste</Link></li>
                                        <li className=""><Link to="/userlist" className="font-normal bg-blue-50 hover:bg-blue-200 py-2 px-4 block whitespace-no-wrap">Userliste</Link></li>
                                        <li className=""><Link to="/wishlists" className="font-normal rounded-b bg-blue-50 hover:bg-blue-200 py-2 px-4 block whitespace-no-wrap">Wunschlisten</Link></li>
                                    </ul>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex flex-col space-y-5 md:space-y-0 md:flex-row flex-nowrap px-5p py-2 items-center justify-between bg-white">
                <div className="w-full flex md:block md:w-auto md:flex-initial md:flex-shrink py-2">
                    <Link to="/" >
                        <img className="w-60" src="/images/logo.svg" alt="mgm-logo" />
                    </Link>
                    <div className="flex md:hidden flex-row justify-end items-center flex-nowrap space-x-5 px-5 w-1/2">
                        <div>
                            <Route
                                render={({ history }) => (
                                    <SearchBox history={history}></SearchBox>
                                )}>

                            </Route>
                        </div>
                        <Link className="transition duration-300 ease-in-out hover:text-red-500 relative flex" to="/wishlist"><FontAwesomeIcon icon="heart" size="lg" />
                            {wishlistItems.length > 0 && (
                                <span className="absolute left-3 -top-1 rounded-full bg-red-600 w-4 h-4 p-0 m-0 text-white font-mono text-xs leading-tight text-center">
                                    {wishlistItems.length}
                                </span>
                            )}
                        </Link>
                        <Link className="transition duration-300 ease-in-out hover:text-green-700 relative flex" to="/cart"><FontAwesomeIcon icon="shopping-bag" size="lg" />
                            {cartItems.length > 0 && (
                                <span className="absolute left-3 -top-1 rounded-full bg-red-600 w-4 h-4 p-0 m-0 text-white font-mono text-xs leading-tight text-center">
                                    {cartItems.reduce((a, c) => a + c.qty, 0)}
                                </span>
                            )}
                        </Link>
                        <button className="transition duration-300 ease-in-out relative flex md:hidden focus:outline-none" onClick={() => setNavbarOpen(!navbarOpen)}>
                            <FontAwesomeIcon icon="bars" size="lg" />
                        </button>
                    </div>
                </div>
                <div className="flex flex-col-reverse md:flex-row w-full items-center">
                    <MainNavbar navbarOpen={navbarOpen} />
                    <div className="hidden md:flex flex-row justify-center flex-nowrap space-x-5 md:space-x-10 w-60">
                        <div>
                            <Route
                                render={({ history }) => (
                                    <SearchBox history={history}></SearchBox>
                                )}>

                            </Route>
                        </div>
                        <Link className="transition duration-300 ease-in-out hover:text-red-500 relative flex" to="/wishlist"><FontAwesomeIcon icon="heart" size="lg" />
                            {wishlistItems.length > 0 && (
                                <span className="absolute left-3 -top-1 rounded-full bg-red-600 w-4 h-4 p-0 m-0 text-white font-mono text-xs leading-tight text-center">
                                    {wishlistItems.length}
                                </span>
                            )}
                        </Link>
                        <Link className="transition duration-300 ease-in-out hover:text-green-700 relative flex" to="/cart"><FontAwesomeIcon icon="shopping-bag" size="lg" />
                            {cartItems.length > 0 && (
                                <span className="absolute left-3 -top-1 rounded-full bg-red-600 w-4 h-4 p-0 m-0 text-white font-mono text-xs leading-tight text-center">
                                    {cartItems.reduce((a, c) => a + c.qty, 0)}
                                </span>
                            )}
                        </Link>
                    </div>
                </div>
            </div >
            <div >
            </div>
        </header >
    );
}