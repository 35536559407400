import Axios from 'axios';
import { WISH_ADD_ITEM, WISH_REMOVE_ITEM, WISH_CREATE_FAIL, WISH_CREATE_REQUEST, WISH_CREATE_SUCCESS, WISH_DELETE_FAIL, WISH_DELETE_REQUEST, WISH_DELETE_SUCCESS, WISH_LIST_FAIL, WISH_LIST_REQUEST, WISH_LIST_SELF_FAIL, WISH_LIST_SELF_REQUEST, WISH_LIST_SELF_SUCCESS, WISH_LIST_SUCCESS } from '../constants/wishConstants';
import convertToCookieFormat from '../utils/wish';

export const listWishSelf = () => async(dispatch, getState) => {
    dispatch({type: WISH_LIST_SELF_REQUEST});
    const {userSignin: {userInfo}} = getState();
    
    try {
        const { data } = await Axios.get('/api/wishlist/self', {
            headers: { Authorization: `Bearer ${userInfo.token}`}
        });        
        var wishState = getState().wishes_cached.wishlistItems;
        wishState =  convertToCookieFormat(data);
        localStorage.setItem('wishlistItems', JSON.stringify(wishState));
        dispatch({type: WISH_LIST_SELF_SUCCESS, payload: data});
    } catch (error) {
        const message = error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
        dispatch({type: WISH_LIST_SELF_FAIL, payload: message});
    }
    
};

export const createWish = (productId) => async(dispatch, getState) => {
    const { data } = await Axios.get(`/api/products/${productId}`);
    dispatch({ 
        type: WISH_ADD_ITEM, 
        payload: {
            brand: data.brand,
            name: data.name,
            image: data.image[0],
            item_number: data.item_number,
            price: data.price,
            stock: data.stock,
            product: data._id
        }
    });
    localStorage.setItem('wishlistItems', JSON.stringify(getState().wishes_cached.wishlistItems));
    
    const {userSignin:{userInfo}} = getState();
    //if user is registered, save in backend
    if (userInfo) {
        dispatch({ type: WISH_CREATE_REQUEST, payload: productId });
        try {
            const wish = {productId: productId, userId: userInfo._id};
            const {data} = await Axios.post('/api/wishlist/', wish, {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                }
            });
            dispatch({type: WISH_CREATE_SUCCESS, payload: data.wish});
        } catch (error) {
            dispatch({
                type: WISH_CREATE_FAIL,
                payload: error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message
            });
        }
    }
};

export const deleteWish = (productId) => async(dispatch, getState) => {
    dispatch({ type: WISH_REMOVE_ITEM, payload: productId});
    localStorage.setItem('wishlistItems', JSON.stringify(getState().wishes_cached.wishlistItems));
    
    const {userSignin:{userInfo}} = getState();
    //if user is registered, delete in backend
    if (userInfo) {
        dispatch({ type: WISH_DELETE_REQUEST, payload: productId});
        try {
            const { data } = await Axios.delete(`/api/wishlist/${productId}`, {
                headers : { Authorization: `Bearer ${userInfo.token}`},
            });
            dispatch({type: WISH_DELETE_SUCCESS, payload: data})
        } catch (error) {
            const message = error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
            dispatch({type: WISH_DELETE_FAIL, payload: message});
        }
    }
};


export const listWishes = () => async (dispatch, getState) => {
    dispatch({type: WISH_LIST_REQUEST});
    const { userSignin : {userInfo}} = getState();
    try{
        const { data } = await Axios.get('/api/wishlist/', {
            headers: { Authorization: `Bearer ${userInfo.token}`},
        });
        dispatch({type: WISH_LIST_SUCCESS, payload: data});
    } catch (error) {
        const message = error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
        dispatch({type: WISH_LIST_FAIL, payload: message});
    }
};
