import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import AutocompleteSelect from './filters/AutocompleteSelect';
import NormalInput from './filters/NormalInput';
import useDebouncedValue from './useDebouncedValue';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function CustomFilterRouter({ category, page, pagesize }) {
    const { t } = useTranslation(["overview"]);

    const dispatch = useDispatch();

    const { category: categoryParam } = useParams();

    const sortOptions = [
        { value: "newest", label: t("newest", "Neuheiten") },
        { value: "az", label: t("az", "Alphabetisch aufsteigend") },
        { value: "za", label: t("za", "Alphabetisch absteigend") },
        { value: "lowest", label: t("price_asc", "Preis aufsteigend") },
        { value: "highest", label: t("price_desc", "Preis absteigend") },
    ];

    const availOptions = [
        { value: "all", label: t("all", "Alle") },
        { value: "available", label: t("available_only", "Sofort lieferbar") },
        { value: "outofstock", label: t("unavailable_only", "Ausverkauft") },
    ];

    const materialOptions = [
        { value: 1, label: "Gold" },
        { value: 2, label: t("silver", "Silber") },
        { value: 3, label: t("other", "Sonstiges") },
    ];

    const steinOptions = [
        { value: "Amethyst", label: "Amethyst" },
        { value: "Aquamarin", label: "Aquamarin" },
        { value: "Diamant", label: "Diamant" },
        { value: "Granat", label: "Granat" },
        { value: "Perl", label: "Perle" },
        { value: "Rubin", label: "Rubin" },
        { value: "Saphir", label: "Saphir" },
        { value: "Smaragd", label: "Smaragd" },
        { value: "Turmalin", label: "Turmalin" },
    ];




    const erhMark = (val) => {
        const marks = {
            1: 'PP',
            2: 'PP-',
            3: 'St',
            4: 'f.St',
            5: 'vz-St',
            6: 'vz+',
            7: 'vz',
            8: 'f.vz',
            9: 'ss-vz',
            10: 'ss+',
            11: 'ss',
            12: 'f.ss',
            13: 's-ss',
            14: 's+',
            15: 's',
            16: 'sge',
            17: 'ge',

        }
        return marks[val];
    };

    const bcCheck = (val) => {
        return (val < 0 ? `${val * -1} ${t("bc", "v. Chr.")}` : val);
    };

    // const mainRef = createRef();
    // const executeScroll = () => window.scrollTo({ left: 0, top: mainRef.current.offsetTop, behavior: 'smooth' })

    const history = useHistory();
    const location = useLocation();
    const [price, setPrice] = useState()
    const debouncedPrice = useDebouncedValue(price, 1000);


    useEffect(() => {
        if (typeof price !== 'undefined') {
            const search = location.search ? location.search.substring(1) : "";
            const searchJson = search
                ? JSON.parse(
                    '{"' +
                    decodeURI(search)
                        .replace(/"/g, '\\"')
                        .replace(/&/g, '","')
                        .replace(/=/g, '":"') +
                    '"}'
                )
                : {};
            searchJson["page"] = 1;

            searchJson["price"] = debouncedPrice;



            history.push({
                pathname: location.pathname,
                search: `?${new URLSearchParams(searchJson)}`,
            });
        }

    }, [debouncedPrice])
    return (
        <div className='pt-2'>
            <div className='flex flex-col md:flex-row '>
                <div className={`flex md:flex md:flex-nowrap flex-wrap flex-row flex-shrink items-center space-x-2  `}>
                    {(category.includes("muenzen") || category.includes("banknote")) && <AutocompleteSelect changeKey="cat1" url={`cat1?subclass=${categoryParam}`} title={(t("region", "Land/Gebiet"))} />}
                    {category.includes("medaillen") && <AutocompleteSelect changeKey="cat1" title={t("regionthema", "Land/Thema")} url={`cat1?subclass=${categoryParam}`} />}
                    {(category.includes("muenzen") || category.includes("medaillen")) && <AutocompleteSelect title={"Regent"} changeKey="regierung" url={`regierung?subclass=${categoryParam}`} />}
                    {category.includes("muenzen") && <AutocompleteSelect title={t("alloy", "Material")} options={materialOptions} changeKey="material" />}
                    <NormalInput value={price} setValue={setPrice} placeholder={t("price", "Preis bis")} type="number" />
                    {/* <DropdownSelect title={t("year", "Jahr")}  />
                    <DropdownSelect title={t("erhaltung", "Erhaltung")} />
                
                    <DropdownSelect title={t("price", "Preis")} /> */}



                    <AutocompleteSelect isSearchable={false} changeKey="order" options={sortOptions} title={t("sort_by", "Sortieren nach")} />
                    <AutocompleteSelect isSearchable={false} changeKey="stock" options={availOptions} title={t("availability", "Verfügbarkeit")} />
                </div>

            </div>
            <div className="flex justify-center">
                {location.search && <button onClick={() => history.push(location.pathname)} className="mt-2 bg-gray-100 text-sm rounded  p-2 hover:bg-gray-200">Filter zurücksetzen <FontAwesomeIcon icon="times" /></button>}

            </div>

        </div>
    )
}

