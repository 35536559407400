export const USER_SIGNIN_REQUEST = 'USER_SIGNIN_REQUEST';
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS';
export const USER_SIGNIN_FAIL = 'USER_SIGNIN_FAIL';

export const USER_SIGNOUT = 'USER_SIGNOUT';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL';
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET';

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST';
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS';
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL';
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET';

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAIL = 'USER_LIST_FAIL';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL';
export const USER_DELETE_RESET = 'USER_DELETE_RESET';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET';

export const USER_PASSWORD_RESET_HASH_REQUEST = 'USER_PASSWORD_RESET_HASH_REQUEST';
export const USER_PASSWORD_RESET_HASH_SUCCESS = 'USER_PASSWORD_RESET_HASH_SUCCESS';
export const USER_PASSWORD_RESET_HASH_FAIL = 'USER_PASSWORD_RESET_HASH_FAIL';
export const USER_PASSWORD_RESET_HASH_RESET = 'USER_PASSWORD_RESET_HASH_RESET';

export const USER_PASSWORD_RESET_SAVE_REQUEST = 'USER_PASSWORD_RESET_SAVE_REQUEST';
export const USER_PASSWORD_RESET_SAVE_SUCCESS = 'USER_PASSWORD_RESET_SAVE_SUCCESS';
export const USER_PASSWORD_RESET_SAVE_FAIL = 'USER_PASSWORD_RESET_SAVE_FAIL';
export const USER_PASSWORD_RESET_SAVE_RESET = 'USER_PASSWORD_RESET_SAVE_RESET';

export const USER_SEND_QUERY_REQUEST = 'USER_SEND_QUERY_REQUEST';
export const USER_SEND_QUERY_SUCCESS = 'USER_SEND_QUERY_SUCCESS';
export const USER_SEND_QUERY_FAIL = 'USER_SEND_QUERY_FAIL';
export const USER_SEND_QUERY_RESET = 'USER_SEND_QUERY_RESET';

export const USER_SELL_PRODUCT_REQUEST = 'USER_SELL_PRODUCT_REQUEST';
export const USER_SELL_PRODUCT_SUCCESS = 'USER_SELL_PRODUCT_SUCCESS';
export const USER_SELL_PRODUCT_FAIL = 'USER_SELL_PRODUCT_FAIL';
export const USER_SELL_PRODUCT_RESET = 'USER_SELL_PRODUCT_RESET';

export const USER_CONTACT_FORM_REQUEST = 'USER_CONTACT_FORM_REQUEST';
export const USER_CONTACT_FORM_SUCCESS = 'USER_CONTACT_FORM_SUCCESS';
export const USER_CONTACT_FORM_FAIL = 'USER_CONTACT_FORM_FAIL';
export const USER_CONTACT_FORM_RESET = 'USER_CONTACT_FORM_RESET';

export const USER_GUEST_CREATE_REQUEST = 'USER_GUEST_CREATE_REQUEST';
export const USER_GUEST_CREATE_SUCCESS = 'USER_GUEST_CREATE_SUCCESS';
export const USER_GUEST_CREATE_FAIL = 'USER_GUEST_CREATE_FAIL';
