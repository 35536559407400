import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import VerticalTileBlock from './VerticalTileBlock';
import VerticalTileVisible from './VerticalTileVisible';

export default function VerticalTile() {
  const { t } = useTranslation(["home"]);
  const [hovered, setHovered] = useState(0);
  const toggleHover = (value) => setHovered(value);
  const muenzenItems = [
    { name: t("by_category","nach Kategorien"), description: t("coins_text_2","Ob Antike, Altdeutschland oder ausländische Münzen - Entdecken Sie unser Angebot sortiert nach Kategorien und Gebieten"), link: "/category/muenzen", image: "/images/mkategor.jpg" },
    { name: t("catalogues","Kataloge"), description: t("coins_text_3","Entdecken Sie unsere regelmäßig erscheinenden Münzkataloge"), link: "/muenzkataloge", image: "/images/coinkatalog.jpg" },
    { name: t("sell_coin","Münzen verkaufen / Schätzen"), description: t("coins_text_4","Sie möchten wissen was Ihre Münze wert ist oder Ihre Münzsammlung verkaufen?"), link: "/muenzankauf", image: "/images/mankauf.jpg" },
  ]

  const medaillenItems = [
    { name: t("by_category","nach Kategorien"), description: t("medals_text_2","Entdecken Sie die Medaillen, sortiert nach Kategorien und Themengebieten"), link: "/category/medaillen", image: "/images/medKateg.jpg" },
    { name: t("catalogues","Kataloge"), description: t("medals_text_3","Stöbern Sie in unseren thematisch sortierten Katalogen für Medaillen"), link: "/muenzkataloge", image: "/images/tbd.jpg" },
    { name: t("sell_medal","Medaillen verkaufen / schätzen"), description: t("medals_text_4","Sie besitzen eine Medaillensammlung und möchten diese schätzen lassen - Gerne machen wir Ihnen ein Angebot"), link: "/muenzankauf", image: "/images/mankauf.jpg" },
  ]

  const numlitItems = [
    { name: t("by_category","nach Kategorien"), description: t("numismatik_text_2","Finden Sie numismatische Literatur nach Kategorien sortiert"), link: "/category/numismatische_literatur", image: "/images/tbd.jpg" },
  ]


  const banknotenItems = [
    { name: t("by_category","nach Kategorien"), description: "Entdecken Sie in unserer wechselndes Angebot an Banknoten, sortiert nach Kategorien", link: "/category/banknoten", image: "/images/banknotenCateg.jpg" },
    { name: t("sell_banknotes","Banknoten verkaufen / Schätzen"), description: "Bei uns können Sie Ihre Banknoten schätzen lassen und/oder verkaufen", link: "/muenzankauf", image: "/images/mankauf.jpg" },

  ]


  return (
    <div className="horizontal-accordion">
      <div className="w-full flex flex-col md:space-y-0 space-y-5 md:flex-row pt-3 h-tile">
        <VerticalTileBlock backgroundClass="muenzenPattern" toggleHover={toggleHover} hovered={hovered} hoverId={1}>

          <VerticalTileVisible title={t("coins_cat","Münzen")} description={t("coins_text_1","Von antiken griechischen oder römischen Münzen über solche aus dem Mittelalter und der frühen Neuzeit bis hin zu modernen Euro-Prägungen – bei der Münzgalerie München findet vom interessierten Neuling bis zum versierten Sammler jeder ein Stück für sich oder als Geschenk")} buttons={muenzenItems} />
        </VerticalTileBlock>
        <VerticalTileBlock backgroundClass="medalPattern" toggleHover={toggleHover} hovered={hovered} hoverId={2}>
          <VerticalTileVisible title={t("medals_cat","Medaillen")} description={t("medals_text_1","Kunstwerke in Bronze, Messing, Eisen, Silber oder Gold. Finden Sie Medaillen aus den unterschiedlichsten Themenbereichen und von bekannten Medailleuren")} buttons={medaillenItems} />
        </VerticalTileBlock>
        <VerticalTileBlock backgroundClass="banknotenPattern" toggleHover={toggleHover} hovered={hovered} hoverId={3}>
          <VerticalTileVisible title={t("banknoten_cat","Banknoten")} description={t("banknoten_text_1","Banknoten für Sammler und Liebhaber")} buttons={banknotenItems} />
        </VerticalTileBlock>
        <VerticalTileBlock backgroundClass="numlitPattern" toggleHover={toggleHover} hovered={hovered} hoverId={4}>
          <VerticalTileVisible title={t("numismatik_cat","Numismatische Literatur")} description={t("numismatik_text_1","Literatur rund um das Thema Numismatik")} buttons={numlitItems} />
        </VerticalTileBlock>
      </div>
    </div>

  )
}

