import React from 'react'
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../BreadCrumbs';

export default function Deutschland1871CoinGrid() {
    const { t } = useTranslation(["navbar"]);
    return (
    <div>
        <BreadCrumbs steps={[{title:t("coins", "Münzen"), link:"/category/muenzen"}, {title:t("germany_1871_coins", "Deutschland ab 1871 Münzen")}]}/>
    </div>
    )
}

