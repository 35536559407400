import React from 'react'
import { useTranslation } from 'react-i18next';
import currencyFormat from '../utils/currency';

export default function ShippingInformation() {
    const { t } = useTranslation(["shipping"]);
    return (
        <div >
            <table className="table-fixed text-left">
                <thead>
                    <tr>
                        <th className="w-1/4"></th>
                        <th className="w-1/4">{t("shippingDuration", "Versanddauer")}</th>
                        <th className="w-1/4">{t("shippingFees", "Versandkosten")}</th>
                        <th className="w-1/4">{t("freeShipping", "Kostenfreie Lieferung ab")}</th>

                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><b>{t("germany", "Deutschland")}</b></td>
                        <td>1 - 3 {t("werktage", "Werktage")}</td>
                        <td>{currencyFormat(5)}</td>
                        <td>{currencyFormat(300)}</td>
                    </tr>
                    <tr>
                        <td><b>EU {t("countries", "Länder")}</b></td>
                        <td>3 - 7 {t("werktage", "Werktage")}</td>
                        <td>{currencyFormat(9.5)}</td>
                        <td>{currencyFormat(1000)}</td>
                    </tr>
                    <tr>
                        <td><b>International</b></td>
                        <td>5 - 14 {t("werktage", "Werktage")}</td>
                        <td>{currencyFormat(15)}</td>
                        <td>-</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}
